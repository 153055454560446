import axios from "axios";

import { getLinksUrl } from "./endpoints";

const getLinks = async () => {
  const url = getLinksUrl();
  const links = await axios.get(url);
  return links;
};

const addLink = async (data) => {
  const url = getLinksUrl();
  const result = await axios.post(url, data);
  return result;
};

const editLink = async (data) => {
  const url = getLinksUrl();
  const result = await axios.put(url, data);
  return result;
};

const removeLink = async (_id) => {
  const url = getLinksUrl();
  const result = await axios.delete(url, {
    params: { _id },
  });
  return result;
};

const removeLinks = async (ids) => {
  const url = getLinksUrl();
  const result = await axios.delete(url, { data: { ids } });
  return result;
};

export { getLinks, addLink, editLink, removeLink, removeLinks };
