import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import {
  showErrorToast,
  removeItemWithSlice,
  getToolsTypesArray,
} from "../../../utils/utility";
import AddEditDialog from "./AddEditDialog";
import CustomMUIDataTable from "../../../components/CustomMUIDataTable";
import PlusAddButton from "../../../components/PlusAddButton";
import EditIconButton from "../../../components/EditIconButton";
import RemoveIconButton from "../../../components/RemoveIconButton";
import ConfirmDialog from "../../../components/ConfirmDialog";
import * as LINK_SERVICE from "../../../services/link";
import { setLoadingStatus } from "../../../actions/loading";
import { tools, notifications } from "../../../constants";
import { commonMUITableOptions } from "../../../utils/styles";

class LinkManage extends Component {
  state = {
    addEditDialogOpened: false,
    confirmDialogOpened: false,
    dialogData: null,
    removeId: null,
    removeIdList: null,
    links: [],
  };

  async componentDidMount() {
    const { setLoadingStatus } = this.props;
    setLoadingStatus({ loading: true, text: "Loading Links..." });
    try {
      const { data: links } = await LINK_SERVICE.getLinks();
      setLoadingStatus({ loading: false });
      let availableLinkTypes = [];
      tools.forEach(tool => availableLinkTypes.push(tool.value));
      const displayLinks = links.filter((link) => {
        return availableLinkTypes.includes(link.toolsType);
      });
      this.setState({ links: displayLinks });
    } catch (error) {
      console.log(
        "ant : [containers Admin LinkManage componentDidMount] error => ",
        error
      );
      setLoadingStatus({ loading: false });
      if (error.response) {
        const { message } = error.response.data;
        showErrorToast(message);
      }
    }
  }

  createTableData = (links) => {
    const tableData = links.map((linkItem) => {
      const { _id, toolsType, type, link } = linkItem;
      const toolsTypeArray = getToolsTypesArray(tools, [], null);
      const { label: toolsName } = toolsTypeArray.find(
        (tools) => tools.value === toolsType
      );
      const row = [toolsName, type, link, _id];
      return row;
    });
    return tableData;
  };

  columns = () => [
    { name: "Tools Type", options: { filter: false } },
    { name: "Link Type", options: { filter: false } },
    { name: "Link", options: { filter: false } },
    {
      name: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{ display: "flex" }}>
              <EditIconButton
                color="primary"
                onClick={() => this.openEditDialogHandler(value)}
              />
              <RemoveIconButton
                color="primary"
                onClick={() => this.openCloseConfirmDialogHandler(true, value)}
              />
            </div>
          );
        },
      },
    },
  ];

  options = {
    ...commonMUITableOptions,
    selectableRows: "multiple",
    onRowsDelete: (rowsDeleted) => {
      const { links } = this.state;
      const idList = links.map((item) => item._id);
      const removeIdList = idList.filter(
        (id, index) => rowsDeleted.lookup[index]
      );
      this.openCloseConfirmDialogHandler(true, null, removeIdList);
    },
    customToolbar: () => {
      return <PlusAddButton onClick={this.openAddDialogHandler} />;
    },
  };

  openAddDialogHandler = () => {
    const { links } = this.state;
    if (links.length === tools.length * 2) {
      showErrorToast(notifications.NOT_ABLE_TO_ADD_LINK);
    } else {
      this.openCloseAddEditDialogHandler(true);
    }
  };

  openEditDialogHandler = (linkId) => {
    const { links } = this.state;
    const dialogData = links.find((link) => link._id === linkId);
    this.openCloseAddEditDialogHandler(true, dialogData);
  };

  closeAddEditDialogHandler = () => {
    this.openCloseAddEditDialogHandler(false);
  };

  openCloseAddEditDialogHandler = (opened, dialogData = null) => {
    this.setState({ addEditDialogOpened: opened, dialogData });
  };

  removeLinkHandler = async (removeId) => {
    try {
      const response = await LINK_SERVICE.removeLink(removeId);
      const { data: linkFromBackend } = response;
      this.removeLink(linkFromBackend);
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        showErrorToast(message);
      }
    }
  };

  removeMultiLinksHandler = async (removeIds) => {
    try {
      await LINK_SERVICE.removeLinks(removeIds);
      this.removeMultiLiks(removeIds);
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        showErrorToast(message);
      }
    }
  };

  addEditLinkHandler = (link) => {
    let { links } = this.state;
    links = [...links];
    const targetIndex = links.findIndex((item) => item._id === link._id);

    if (targetIndex >= 0) {
      links[targetIndex] = link;
    } else {
      links = [...links, link];
    }
    this.setState({ links });
  };

  removeLink = (link) => {
    let { links } = this.state;

    const targetIndex = links.findIndex((item) => item._id === link._id);
    links = removeItemWithSlice(links, targetIndex);
    this.setState({ links });
  };

  removeMultiLinks = (linkIds) => {
    let { links } = this.state;

    links = links.filter((item) => !linkIds.includes(item._id));
    this.setState({ links });
  };

  openCloseConfirmDialogHandler = (opened, removeId, removeIdList) => {
    this.setState({ confirmDialogOpened: opened, removeId, removeIdList });
  };

  confirmRemoveDialogHandler = () => {
    const { removeId, removeIdList } = this.state;
    if (removeId) {
      this.removeLinkHandler(removeId);
    } else if (removeIdList && removeIdList.length > 0) {
      this.removeMultiLinksHandler(removeIdList);
    }
  };

  render() {
    const {
      addEditDialogOpened,
      dialogData,
      confirmDialogOpened,
      removeIdList,
    } = this.state;
    const { links } = this.state;
    const tableData = this.createTableData(links);

    return (
      <Fragment>
        {addEditDialogOpened && (
          <AddEditDialog
            manageTitle="Link"
            opened={addEditDialogOpened}
            isAddDialog={dialogData ? false : true}
            dialogData={dialogData}
            addEditLink={this.addEditLinkHandler}
            closed={this.closeAddEditDialogHandler}
            links={links}
          />
        )}
        <CustomMUIDataTable
          title="Links"
          data={tableData}
          columns={this.columns()}
          options={this.options}
        />
        <ConfirmDialog
          isMultiple={!!removeIdList}
          opened={confirmDialogOpened}
          closed={() => this.openCloseConfirmDialogHandler(false)}
          confirmed={this.confirmRemoveDialogHandler}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  defaultLanguage: state.auth.user.defaultLanguage,
});

const mapActionToProps = {
  setLoadingStatus,
};

export default connect(mapStateToProps, mapActionToProps)(LinkManage);
