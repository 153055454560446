import React, {useMemo} from 'react';


import {
  formatTimeFrame,
  getPremiumLockedFilters
} from "../../../utils/utility";
import Filter from "../../HarmonicPatterns/filter";
import withStyles from "@material-ui/core/styles/withStyles";
import CloseIcon from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import ContainedButton from "../../../components/ContainedButton";
import AddonIndication from "../AddonIndication";
import FullScreenIcon from "@material-ui/icons/Fullscreen";
import HarmonicPatternsDivergence from "../HarmonicPatternsDivergence";

const styles = () => {
  return {
    container: {
      height: '5%',
      display: 'flex',
      flexDirection: 'row',
    },
    chartParamsContainer: {
      flex: 1,
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    miscContainer: {
      flex: 1,
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
    },
    selectWrapper: {
      marginRight: '20px',
    },
  }
};


const symbolToSelectValue = s => ({label: s, value: s});
const timeframeToSelectValue = v => ({label: formatTimeFrame(parseInt(v)), value: parseInt(v)});


const EagleViewToolbar = props => {

  const {
    classes,
    addon,
    notificationFilters,
    toggleEagleView,
    handleNotificationFiltersChange,
    syncEagleView,
    onRequestFullScreen,
    harmonicPatterns,
    hiddenPatterns,
    toggleHiddenPattern,
    activePattern,
    toggleActivePattern,
    technicalIndicator,
    indicationFilters,
    onIndicationFiltersChange,
    userSubscriptions,
  } = props;

  const symbolOptions = useMemo(() => (
    addon.symbols.map(symbolToSelectValue)
  ), [addon]);
  const timeframeOptions = useMemo(() => (
    addon.timeframes.map(timeframeToSelectValue)
  ), [addon]);

  let harmonicPremiumLockedFilters = {};
  if (addon.harmonicConfig) {
    harmonicPremiumLockedFilters = getPremiumLockedFilters(addon.harmonicConfig.premium, userSubscriptions);
  }
  let indicationPremiumLockedFilters = {};
  if (addon.engulfingAndOPConfig) {
    indicationPremiumLockedFilters = getPremiumLockedFilters(addon.engulfingAndOPConfig.premium || {}, userSubscriptions);
  }

  return (
    <div className={classes.container}>
      <div className={classes.chartParamsContainer}>
        <Filter
          filters={notificationFilters}
          onChange={handleNotificationFiltersChange}
          availableFilters={addon.harmonicConfig.available}
          symbolOptions={symbolOptions}
          timeframeOptions={timeframeOptions}
          premiumLockedFilters={harmonicPremiumLockedFilters}
        />
        <AddonIndication
          filters={indicationFilters}
          onFiltersChange={onIndicationFiltersChange}
          addon={addon}
          premiumLockedFilters={indicationPremiumLockedFilters}
        />
        <HarmonicPatternsDivergence
          patterns={harmonicPatterns}
          hidden={hiddenPatterns}
          toggleHidden={toggleHiddenPattern}
          activePattern={activePattern}
          toggleActivePattern={toggleActivePattern}
          technicalIndicator={technicalIndicator}
        />
      </div>
      <div className={classes.miscContainer}>
        <ContainedButton
          disabled={false}
          onClick={syncEagleView}
        >
          <SyncIcon />
          <span>Sync Filters</span>
        </ContainedButton>
        <ContainedButton
          disabled={false}
          onClick={toggleEagleView}
        >
          <CloseIcon />
          <span>Exit Eagle View</span>
        </ContainedButton>
        <ContainedButton
          disabled={false}
          onClick={onRequestFullScreen}
        >
          <FullScreenIcon />
        </ContainedButton>
      </div>
    </div>
  )
};


export default withStyles(styles)(EagleViewToolbar);
