import React from "react";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import SettingsIcon from "@material-ui/icons/Settings";
import TimelineIcon from "@material-ui/icons/Timeline";
import HelpIcon from "@material-ui/icons/Help";
import WifiTetheringIcon from "@material-ui/icons/WifiTethering";
import DateRangeIcon from '@material-ui/icons/DateRange';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Avatar from '@material-ui/core/Avatar';
//Dashboard
import Dashboard from "../containers/Dashboard";
// Admin
import UserManage from "../containers/Admin/UserManage";
import BrokerManage from "../containers/Admin/BrokerManage";
import SymbolManage from "../containers/Admin/SymbolManage";
import NotificationManage from "../containers/Admin/NotificationManage";
import RuleManage from "../containers/Admin/RuleManage";
import VideoManage from "../containers/Admin/VideoManage";
import ReportManage from "../containers/Admin/ReportManage";
import LinkManage from "../containers/Admin/LinkManage";
import AddonsManage from '../containers/Admin/AddonsManage';
import SubscriptionsManage from '../containers/Admin/SubscriptionsManage';
import Config from '../containers/Admin/Config';
// Tools
//HeatMap
import HeatMap from "../containers/HeatMap";
import EconomicCalendar from "../containers/EconomicClendar";


// Stats
import TradeStrategies from "../containers/Stats/TradeStrategies";
// Settings
import ProfileSetting from "../containers/Settings/ProfileSetting";
// Support
import Feedback from "../containers/Support/Feedback";

import constants from "../constants";
import { validateRoleSkuAccess } from "./utility";
import i18n from "./i18n";

const defaultAvatarLink =
  "//www.gravatar.com/avatar/51b444b6cac7ba2671011bf7616c5d59?s=200&r=pg&d=mm";

const pageLinks = {
  // Authentication
  Login: {
    url: "/login",
  },
  Register: {
    url: "/register",
  },
  Logout: {
    url: "/logout",
  },
  ForgotPassword: {
    url: "/forgot-password",
    meliusUrl: "https://bebackoffice.com/forgotpassword",
  },
  ResetPassword: {
    url: "/reset-password/:token",
  },
  Verification: {
    url: "/verification/:token",
  },
  //Dashboard
  Dashboard: {
    url: "/dashBoard",
  },
  // Admin
  UserManage: {
    url: "/admin/users",
  },
  BrokerManage: {
    url: "/admin/brokers",
  },
  SymbolManage: {
    url: "/admin/symbols",
  },
  NotificationManage: {
    url: "/admin/notifications",
  },
  RuleManage: {
    url: "/admin/rules",
  },
  VideoManage: {
    url: "/admin/videos",
  },
  ReportManage: {
    url: "/admin/reports",
  },
  AddonsManage: {
    url: '/admin/addons',
  },
  Config: {
    url: '/admin/config',
  },
  SubscriptionsManage: {
    url: '/admin/subscriptions',
  },
  LinkManage: {
    url: "/admin/links",
  },
  // Tools
  ToolChart: {
    url: "/tools/chart",
  },
  CloudAnalyser: {
    url: "/tools/cosmic-cloud",
  },
  CloudAnalyser2: {
    url: "/tools/cloud-analyser2",
  },
  ExpertTradeIdeas: {
    url: "/tools/expert-trade-ideas",
  },
  BrickStrategy: {
    url: "/tools/brick-strategy",
  },
  GannHarmonic: {
    url: "/tools/gann-harmonic",
  },
  ZoneToZone: {
    url: "/tools/zone-to-zone",
  },
  US30: {
    url: "/tools/us30",
  },
  EuroTitan: {
    url: "/tools/3-sister",
  },
  PrecisionScanner: {
    url: "/tools/paple-beta-testing"
  },
  NFXTurbo: {
    url: "/tools/pulsar"
  },
  NFXExpress: {
    url: "/tools/nfx-express",
  },
  WallStreetWolf: {
    url: "/tools/wallstreet-wolf",
  },
  wallstreet30: {
    url: "/tools/wallstreet-titan",
  },
  ButterFlyEffects: {
    url: "/tools/butterfly-effects",
  },
  PurpleRain: {
    url: '/tools/paple-beta-testing',
  },
  EconomicCalendar: {
    url: "/economicCalendar"
  },
  HeatMap: {
    url: "/heatMap"
  },
  News: {
    url: "/news"
  },
  // Video
  TrainningVideos: {
    url: "/training/videos",
  },
  // Stats
  TradeStrategies: {
    url: "/stats/trade-strategies",
  },
  // Settings
  ProfileSetting: {
    url: "/settings/profile",
  },
  // Support
  Feedback: {
    url: "/support/feedback",
  },
};

export const userRoleList = [
  constants.roles.ADMIN_ROLE,
  constants.roles.CHART_ADMIN_ROLE,
  constants.roles.PROVIDER_ROLE,
  constants.roles.USER_ROLE,
  constants.roles.SCHEDULE_ADMIN_ROLE,
];

const sidebarItems = (addons = []) => [
  {
    title: i18n.t("sideDrawer.dashBoard"), //'Dashboard',
    Icon: <DashboardIcon />,
    link: pageLinks.Dashboard.url,
    roles: userRoleList,
    component: Dashboard,
    targetBlank: false
  },
  {
    title: i18n.t("sideDrawer.admin"), //'Admin',
    Icon: <VerifiedUserIcon />,
    subItems: [
      {
        subTitle: i18n.t("sideDrawer.users"), // 'Users',
        link: pageLinks.UserManage.url,
        component: UserManage,
        roles: [constants.roles.ADMIN_ROLE],
      },
      {
        subTitle: i18n.t("sideDrawer.brokers"), //'Brokers',
        link: pageLinks.BrokerManage.url,
        component: BrokerManage,
        roles: [constants.roles.ADMIN_ROLE, constants.roles.CHART_ADMIN_ROLE],
      },
      {
        subTitle: i18n.t("sideDrawer.symbols"), //'Symbols',
        link: pageLinks.SymbolManage.url,
        component: SymbolManage,
        roles: [constants.roles.ADMIN_ROLE, constants.roles.CHART_ADMIN_ROLE],
      },
      {
        subTitle: i18n.t("sideDrawer.notifications"), //'Notifications',
        link: pageLinks.NotificationManage.url,
        component: NotificationManage,
        roles: [constants.roles.ADMIN_ROLE, constants.roles.CHART_ADMIN_ROLE],
      },
      {
        subTitle: i18n.t("sideDrawer.rules"), //'Rules',
        link: pageLinks.RuleManage.url,
        component: RuleManage,
        roles: [constants.roles.ADMIN_ROLE],
      },
      {
        subTitle: i18n.t("sideDrawer.links"), //'Rules',
        link: pageLinks.LinkManage.url,
        component: LinkManage,
        roles: [constants.roles.ADMIN_ROLE],
      },
      {
        subTitle: i18n.t("sideDrawer.videos"), //'Videos',
        link: pageLinks.VideoManage.url,
        component: VideoManage,
        roles: [constants.roles.ADMIN_ROLE],
      },
      {
        subTitle: i18n.t("sideDrawer.reports"), //'Reports',
        link: pageLinks.ReportManage.url,
        component: ReportManage,
        roles: [constants.roles.ADMIN_ROLE],
      },
      {
        subTitle: 'Addons',
        link: pageLinks.AddonsManage.url,
        component: AddonsManage,
        roles: [constants.roles.ADMIN_ROLE],
      },
      {
        subTitle: 'Subscriptions',
        link: pageLinks.SubscriptionsManage.url,
        component: SubscriptionsManage,
        roles: [constants.roles.ADMIN_ROLE],
      },
      {
        subTitle: 'Config',
        link: pageLinks.Config.url,
        component: Config,
        roles: [constants.roles.ADMIN_ROLE],
      }
    ],
    roles: [constants.roles.ADMIN_ROLE, constants.roles.CHART_ADMIN_ROLE],
  },
  ...addons.map(
    addon => ({
      title: addon.name,
      Icon: <Avatar variant="square" src={addon.image} style={{ height: 22, width: 24, borderRadius: 0 }} />,
      link: `/addons/${addon._id}`,
      roles: userRoleList,
      skuIds: addon.subscriptions,
      targetBlank: false,
      isTool: true,
    })
  ),
  {
    title: i18n.t("sideDrawer.live"), //'Live',
    Icon: <WifiTetheringIcon />,
    link: constants.wowURL,
    roles: userRoleList,
    skuIds: [constants.skuIdList.MELIUS_LIVE],
    targetBlank: true,
  },
  {
    title: i18n.t("sideDrawer.economicCalendar"), //'economic Calendar',
    Icon: < DateRangeIcon />,
    link: pageLinks.EconomicCalendar.url,
    component: EconomicCalendar,
    roles: userRoleList,
    targetBlank: false,
  },
  {
    title: i18n.t("sideDrawer.heatMap"), //'heatMap',
    Icon: <BubbleChartIcon />,
    link: pageLinks.HeatMap.url,
    component: HeatMap,
    roles: userRoleList,
    targetBlank: false,
  },
  {
    title: i18n.t("sideDrawer.stats"), //'Stats',
    Icon: <TimelineIcon />,
    subItems: [
      {
        subTitle: i18n.t("sideDrawer.iGotTradeStrategies"), //'iGotTrade Strategies',
        link: pageLinks.TradeStrategies.url,
        component: TradeStrategies,
        roles: [
          constants.roles.ADMIN_ROLE,
          constants.roles.CHART_ADMIN_ROLE,
          constants.roles.PROVIDER_ROLE,
        ],
      },
    ],
    roles: [
      constants.roles.ADMIN_ROLE,
      constants.roles.CHART_ADMIN_ROLE,
      constants.roles.PROVIDER_ROLE,
    ],
  },
  {
    title: i18n.t("sideDrawer.settings"), //'Settings',
    Icon: <SettingsIcon />,
    subItems: [
      {
        subTitle: i18n.t("sideDrawer.profile"), //'Profile',
        link: pageLinks.ProfileSetting.url,
        component: ProfileSetting,
        roles: userRoleList,
      },
    ],
    roles: userRoleList,
  },
  {
    title: i18n.t("sideDrawer.support"), //'Support',
    Icon: <HelpIcon />,
    subItems: [
      {
        subTitle: i18n.t("sideDrawer.feedback"), //'Feedback',
        link: pageLinks.Feedback.url,
        component: Feedback,
        roles: [constants.roles.USER_ROLE],
      },
    ],
    roles: [constants.roles.USER_ROLE],
  }
];

const sidebarItemsValue = sidebarItems();
const toolItems = sidebarItems().filter(item => item.isTool);

const ROLES_SKUIDS = {
  CLOUD_ANALYSER: {
    roles: userRoleList,
    skuIds: [constants.skuIdList.CLOUD_ANALYSER],
  },
  GANN_HARMONIC: {
    roles: userRoleList,
    skuIds: [constants.skuIdList.CLOUD_ANALYSER],
  },
  ZONE_TO_ZONE: {
    roles: userRoleList,
    skuIds: [constants.skuIdList.CLOUD_ANALYSER],
  },
  US30: {
    roles: userRoleList,
    skuIds: [constants.skuIdList.US30, constants.skuIdList.US30_2],
  },
  EURO_TITAN: {
    roles: userRoleList,
    skuIds: [constants.skuIdList.EURO_TITAN, constants.skuIdList.EURO_TITAN_1, constants.skuIdList.EURO_TITAN_2, constants.skuIdList.EURO_TITAN_3, constants.skuIdList.EURO_TITAN_4],
  },
  PRECISION_SCANNER: {
    roles: userRoleList,
    skuIds: [constants.skuIdList.PRECISION_SCANNER, constants.skuIdList.PRECISION_SCANNER_1, constants.skuIdList.PRECISION_SCANNER_2, constants.skuIdList.PRECISION_SCANNER_3],
  },
  NFX_EXPRESS: {
    roles: userRoleList,
    skuIds: [constants.skuIdList.CLOUD_ANALYSER],
  },
  NFX_TURBO: {
    roles: userRoleList,
    skuIds: [constants.skuIdList.NFX_TURBO, constants.skuIdList.NFX_TURBO_1, constants.skuIdList.NFX_TURBO_2, constants.skuIdList.NFX_TURBO_3, constants.skuIdList.NFX_TURBO_4],
  },
  WALLSTREET_WOLF: {
    roles: userRoleList,
    skuIds: [constants.skuIdList.CLOUD_ANALYSER],
  },
  WALLSTREET_30: {
    roles: userRoleList,
    skuIds: [constants.skuIdList.CLOUD_ANALYSER],
  },
  BUTTERFLY_EFFECTS: {
    roles: userRoleList,
    skuIds: [constants.skuIdList.CLOUD_ANALYSER],
  },
  PURPLE_RAIN: {
    roles: userRoleList,
    skuIds: [constants.skuIdList.PURPLE_RAIN],
  },
  TRAINING_VIDEOS: {
    roles: userRoleList,
    skuIds: [],
  }
};

const jwtTokenKey = "jwtToken";

const getDefaultRedirectedComponent = (userInfo) => {
  // TODO: Component indexes hardcoded
  if (
    userInfo.roles.find((element) => element === constants.roles.ADMIN_ROLE)
  ) {
    return sidebarItemsValue[0].component;
    // return sidebarItemsValue[1].subItems[0].component;
  }
  if (
    userInfo.roles.find(
      (element) => element === constants.roles.CHART_ADMIN_ROLE
    )
  ) {
    // return sidebarItemsValue[1].subItems[1].component;
    return sidebarItemsValue[0].component;
  }
  if (
    userInfo.roles.find(
      (element) => element === constants.roles.PROVIDER_ADMIN_ROLE
    )
  ) {
    return sidebarItemsValue[0].component;
  }
  if (userInfo.roles.find((element) => element === constants.roles.USER_ROLE)) {
    const visibleSidebarItems = toolItems.filter(
      (sidebarSubItem) =>
        validateRoleSkuAccess(
          sidebarSubItem.roles,
          sidebarSubItem.skuIds,
          userInfo
        )
    );
    if (visibleSidebarItems.length !== 0) {
      return visibleSidebarItems[0].component;
    } else {
      // return sidebarItemsValue[4].subItems[0].component;
      return sidebarItemsValue[0].component;
    }
  }
};

export {
  pageLinks,
  jwtTokenKey,
  sidebarItems,
  defaultAvatarLink,
  getDefaultRedirectedComponent,
  ROLES_SKUIDS,
};
