import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import MenuItem from "@material-ui/core/MenuItem";

import {getSpacingUnit} from '../../utils/utility';

const styles = theme => {
  const spacingUnit = getSpacingUnit(theme);
  return {
    chips: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    chip: {
      margin: spacingUnit / 4,
    },
    normalMessage: {
      display: 'none',
      color: ''
    },
    errorMessage: {
      display: 'inline',
      color: '#f44336'
    }
  };
};

// TODO: * validation required
const CustomMultiSelect = ({
                           classes,
                           theme,
                           label,
                           selected,
                           items,
                           validation,
                           changed
}) => {
  if (validation === undefined) {
    validation = true;
  }
  const getStyles = value => {
    return {
      fontWeight:
        selected.includes(value)
          ? theme.typography.fontWeightMedium
          : theme.typography.fontWeightRegular
    };
  };

  const onChangeHandler = event => {
    changed(event.target.value);
  };

  const getValueLabel = value => items.find(i => i.value === value).label;

  return (
    <FormControl margin='normal' fullWidth>
      <InputLabel htmlFor='role-multi-select'>{label}</InputLabel>
      <Select
        autoWidth
        multiple
        name='role-multi-select'
        value={selected}
        onChange={onChangeHandler}
        input={<Input id='role-multi-select'/>}
        renderValue={selected => (
          <div className={classes.chips}>
            {selected.map(value => (
              <Chip key={value} label={getValueLabel(value)}
                    className={classes.chip}/>
            ))}
          </div>
        )}>
        {items.map(item => (
          <MenuItem key={item.label} value={item.value}
                    style={getStyles(item.value)}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default withStyles(styles, {withTheme: true})(CustomMultiSelect);
