
import historyProvider from './historyProvider.js';
import store from '../../../store';
import { SET_LAST_QUOTE } from '../../../actions/types';
import config from '../../../config';

const io = require('socket.io-client');
const socket = io(config.socketUrl);
const subs = [];

export let addNewTick = null; 

export const subscribeBars = (
	mainStrategy,
	symbolInfo,
	resolution,
	updateCb,
	uid,
	resetCache) => {
	const channelString = createChannelString(symbolInfo);
	addNewTick = updateCb;

	const newSub = {
		channelString,
		uid,
		resolution,
		symbolInfo,
		lastBar: historyProvider.history[symbolInfo.name].lastBar,
		listener: updateCb
	};
	subs.push(newSub);
};

export const unsubscribeBars = uid => {
        const subIndex = subs.findIndex(e => e.uid === uid);
        if (subIndex === -1) {
            return;
        }
        const sub = subs[subIndex];
        socket.emit('SubRemove', { subs: [sub.channelString] });
        subs.splice(subIndex, 1);
    }

export const addTickData = tickData => {
	const data = JSON.parse(tickData);
    const tickItem = {
        ts: new Date(data.time).getTime(),
        ask: data.ask,
        bid: data.bid,
        strategyId: data.strategyId
    };

    if (subs.length !== 0) {
        const sub = subs[subs.length - 1];
        if (tickItem.ts < sub.lastBar.time) {
            return null;
        }
        const lastBar = updateBar(tickItem, sub);
        lastBar.open = parseFloat(lastBar.open);
        lastBar.high = parseFloat(lastBar.high);
        lastBar.low = parseFloat(lastBar.low);
        lastBar.close = parseFloat(lastBar.close);
        sub.listener(lastBar);
        store.dispatch({
            type: SET_LAST_QUOTE,
            payload: lastBar
        });
		sub.lastBar = lastBar;
		return lastBar;
	}
	return null;
};

function updateBar(data, sub) {
    let lastBar = sub.lastBar;
    let resolution = sub.resolution;
    if (resolution.includes('D')) {
        // 1D = 1440m
        resolution = 1440;
    } else if (resolution.includes('W')) {
        // 1week = 10080m
        resolution = 10080;
    }
    const coeff = resolution * 60;
    const rounded = Math.floor(data.ts / (1000 * coeff)) * coeff;
    const lastBarSec = lastBar.time / 1000;
    let newLastBar;

    // Get lastbar
    if (rounded > lastBarSec) {
        newLastBar = {
            time: rounded * 1000,
            open: lastBar.close,
            high: lastBar.close,
            low: lastBar.close,
            close: data.bid
        };
    } else {
        if (data.bid < lastBar.low) {
            lastBar.low = data.bid;
        } else if (data.bid > lastBar.high) {
            lastBar.high = data.bid;
        }
        lastBar.close = data.bid;
        newLastBar = lastBar;
    }
    return newLastBar;
}

function createChannelString(symbolInfo) {
    const channel = symbolInfo.name.split(/[:/]/);
    const exchange = channel[0];
    const to = channel[2];
    const from = channel[1];
    return `0~${exchange}~${from}~${to}`;
}
