import React, {useState} from 'react';
import DialogWrapper from "../../../../hoc/DialogWrapper";
import HarmonicPatternsFiltersList
  from "../../../../components/HarmonicPatternsFiltersList";
import DialogActionsWrapper from "../../../../hoc/DialogActionsWrapper";
import CustomTextButton from "../../../../components/CustomTextButton";
import OutlinedButton from "../../../../components/OutlinedButton";
import constants from "../../../../constants";


const DEFAULT_HARMONIC_FILTERS = {
  names: constants.harmonicPatternNames,
  types: constants.harmonicPatternTypes,
  // medium, large, xLarge
  sizes: [0, 1, 2, 3],
  additionalIndicators: constants.harmonicPatternAdditionalIndicators,
  indicatorNotification: constants.harmonicPatternIndicatorNotification,
  deviationTypes: constants.harmonicPatternDeviationTypes,
  deviationValue: null,
};

const DEFAULT_SELECTED_HARMONIC_FILTERS = {
  names: [],
  types: [],
  sizes: [],
  deviationTypes: [],
  deviationValue: null,
  additionalIndicators: [],
  indicatorNotification: [],
};


const HPConfig = props => {
  const {
    addon,
    onSubmit,
    onClose,
    subscriptions = [],
  } = props;

  const [config, setConfig] = useState({
    available: addon.harmonicConfig ? addon.harmonicConfig.available : DEFAULT_HARMONIC_FILTERS,
    default_: addon.harmonicConfig ? addon.harmonicConfig.default_ : DEFAULT_SELECTED_HARMONIC_FILTERS,
    premium: addon.harmonicConfig ? addon.harmonicConfig.premium : DEFAULT_SELECTED_HARMONIC_FILTERS,
  });

  const handleChange = (type, data) => {
    setConfig(prev => ({...prev, [type]: data}));
  };

  const handleSubmit = () => {
    onSubmit({
      ...addon,
      harmonicConfig: config,
    });
    onClose();
  };

  return (
    <DialogWrapper
      opened={true}
      dialogTitle={`Harmonic Pattern filters available for ${props.addon.name}`}
      dialogActions={
        <DialogActionsWrapper>
          <CustomTextButton onClick={onClose}>
            Cancel
          </CustomTextButton>
          <OutlinedButton
            type='button'
            disabled={false}
            onClick={handleSubmit}
          >
            Save
          </OutlinedButton>
        </DialogActionsWrapper>
      }
    >
      <HarmonicPatternsFiltersList
        configMode
        subscriptions={subscriptions}
        filters={config.available}
        defaultFilters={config.default_}
        premiumFilters={config.premium}
        onChange={filters => handleChange('available', filters)}
        onChangeDefault={filters => handleChange('default_', filters)}
        onChangePremium={filters => handleChange('premium', filters)}
      />
    </DialogWrapper>
  );
};

export default HPConfig;
