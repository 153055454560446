import axios from "axios";
import urlJoin from "url-join";
import config from "../config";

import { getSignalUrl, getSignalsByStrategyIdUrl } from "./endpoints";

const addSignal = async (data) => {
  const url = getSignalUrl();
  const result = await axios.post(url, data);
  return result;
};

const editSignal = async (data) => {
  const url = getSignalUrl();
  const result = await axios.put(url, data);
  return result;
};

const removeSignal = async (_id) => {
  const url = getSignalUrl();
  const result = await axios.delete(url, {
    params: { _id },
  });
  return result;
};

const removeSignals = async (ids) => {
  const url = getSignalUrl();
  const result = await axios.delete(url, { data: { ids } });
  return result;
};

const getAllSignalsByStrategyId = async (strategyId) => {
  const url = getSignalsByStrategyIdUrl(strategyId);
  const result = await axios.get(url);
  return result;
};

const getSignalsByStrategyId = async (strategyId, archiveWeeks, sltponly) => {
  const url = getSignalsByStrategyIdUrl(strategyId);
  const result = await axios.get(url, {
    params: { archiveWeeks, sltponly },
  });
  return result;
};

const getSignalReport = async (year, toolIndex) => {
  const result = await axios.get(
    urlJoin(config.proxyUrl, `/api/signals-report?year=${year}&toolIndex=${toolIndex}`)
  );
  return result;
};

const getSignalsByAddonId = async addonId => {
  const res = await axios.get(
    urlJoin(config.proxyUrl, `/api/signals/addon/${addonId}`)
  );
  return res.data;
};

export {
  addSignal,
  editSignal,
  removeSignal,
  removeSignals,
  getSignalsByStrategyId,
  getAllSignalsByStrategyId,
  getSignalReport,
  getSignalsByAddonId,
};
