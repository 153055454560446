import React, {PureComponent} from 'react';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import OutlinedButton from '../../../../components/OutlinedButton';
import CustomTextButton from '../../../../components/CustomTextButton';
import DialogWrapper from '../../../../hoc/DialogWrapper';
import DialogActionsWrapper from '../../../../hoc/DialogActionsWrapper';
import {
  getAddEditDialogMetadata,
} from '../../../../utils/utility';
import CustomMultiSelect from "../../../../components/CustomMultiSelect";
import CustomSwitch from "../../../../components/CustomSwitch";
import CustomImageField from "../../../../components/CustomImageField";
import CustomSelectValidator
  from "../../../../components/CustomSelectValidator";
import TextEditor from "../../../../components/TextEditor";
import DialogContentText from "@material-ui/core/DialogContentText";
import AutoCompleteInput from "../../../../components/AutocompleteInput";

const toMultiSelectItem = item => ({
  value: item,
  label: item,
});

const toTimeframeItem = item => ({
  value: item,
  label: item,
});

class AddEditDialog extends PureComponent {
  state = {
    name: '',
    image: null,
    hidden: false,
    comingSoon: false,
    signalsEnabled: true,
    subscriptions: [],
    symbols: [],
    timeframes: [],
    brokers: [],
    chartAddons: [],
    indicators: [],
    links: [
      {
        type: 'sense',
        link: '',
      },
      {
        type: 'telegram',
        link: '',
      },
    ],
    chartAppearanceId: null,
    rules: '',
    telegramLink: '',
    senseLink: '',
  };

  componentDidMount() {
    const {isAddDialog, dialogData} = this.props;
    // edit
    if (!isAddDialog) {
      const {links, ...state} = dialogData;
      if (Array.isArray(links)) {
        const telegramLink = links.find(l => l.type === 'telegram');
        if (telegramLink) {
          state.telegramLink = telegramLink.link;
        }
        const senseLink = links.find(l => l.type === 'sense');
        if (senseLink) {
          state.senseLink = senseLink.link;
        }
      }
      if (!state.chartAppearanceId) {
        state.chartAppearanceId = 'None';
      }
      this.setState(state);
    }
  }

  filedChangeHandler = field => value => {
    this.setState({[field]: value});
  };

  switchChangeHandler = field => event => {
    this.setState({[field]: event.target.checked});
  };

  handleSubmit = async () => {
    const {createAddon, updateAddon} = this.props;
    let method = createAddon;
    if (!this.props.isAddDialog) {
      method = updateAddon;
    }
    const {telegramLink, senseLink, ...rest} = this.state;
    const links = [];
    if (telegramLink !== '') {
      links.push({
        type: 'telegram',
        link: telegramLink,
      })
    }
    if (senseLink) {
      links.push({
        type: 'sense',
        link: senseLink,
      })
    }
    const payload = {
      ...rest,
      links,
    }
    if (payload.chartAppearanceId === 'None') {
      payload.chartAppearanceId = null;
    }
    await method(payload);
    this.onCloseHandler();
  };

  inputChangeHandler = event => {
    this.setState({[event.target.name]: event.target.value});
  };

  // this is handler for autocomplete input
  autoCompleteMultiChangeHandler = field => value => {
    this.setState({
      [field]: value.map(s => s.value)
    })
  };


  multiFieldChangeHandler = field => value => {
    this.setState({[field]: value});
  };

  brokerChangeHandler = brokerId => {
    this.setState({
      symbols: brokerId
    })
  };

  currencyChangeHandler = currency => {
    this.setState({currency});
  };

  digitsChangeHandler = event => {
    const incrementStep = 1 / Math.pow(10, event.target.value);
    this.setState({
      digits: event.target.value,
      tick: incrementStep,
      incrementStep: incrementStep
    });
  };

  onCloseHandler = () => {
    const {closed} = this.props;
    closed && closed();
  };

  triggerSubmitHandler = () => {
    this.form.isFormValid(false).then((isValid) => {
      if (isValid) {
        this.handleSubmit();
      }
    });
  };

  render() {
    const {isAddDialog, manageTitle} = this.props;
    const {
      submitButtonDisabled
    } = this.state;
    const {
      dialogTitle,
      dialogDescription,
      submitButtonName,
      cancelButtonName
    } = getAddEditDialogMetadata(isAddDialog, manageTitle);

    const {octConfig: {available}} = this.props;

    return (
      <DialogWrapper
        opened={true}
        dialogTitle={dialogTitle}
        dialogDescription={dialogDescription}
        dialogActions={
          <DialogActionsWrapper>
            <CustomTextButton onClick={this.onCloseHandler}>
              {cancelButtonName}
            </CustomTextButton>
            <OutlinedButton type='button' disabled={submitButtonDisabled}
                            onClick={this.triggerSubmitHandler}>
              {submitButtonName}
            </OutlinedButton>
          </DialogActionsWrapper>
        }>
        <ValidatorForm
          ref={(ref) => {
            this.form = ref;
          }}
          onSubmit={this.handleSubmit}
          onError={errors => console.log(errors)}
        >
          <TextValidator
            fullWidth
            name='name'
            label='Name'
            value={this.state.name}
            margin='normal'
            onChange={this.inputChangeHandler}
            validators={['required']}
            errorMessages={['Name cannot be empty']}
          />
          <CustomSelectValidator
            label='Chart Appearance'
            value={this.state.chartAppearanceId}
            changed={this.filedChangeHandler('chartAppearanceId')}
            validators={[]}
            items={
              [
                {value: 'None', label: 'None'},
                ...this.props.octConfig.available.chartAppearances.map(chartAppearance => ({
                  value: chartAppearance._id,
                  label: chartAppearance.name,
                }))
              ]
            }
          />
          <CustomImageField
            image={this.state.image}
            onChange={this.filedChangeHandler('image')}
          />
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
            <CustomSwitch
              label='Hidden'
              checked={this.state.hidden}
              switchHandler={this.switchChangeHandler('hidden')}
            />
            <CustomSwitch
              label='Coming soon'
              checked={this.state.comingSoon}
              switchHandler={this.switchChangeHandler('comingSoon')}
            />
            <CustomSwitch
              label='Enable signals'
              checked={this.state.signalsEnabled}
              switchHandler={this.switchChangeHandler('signalsEnabled')}
            />
          </div>
          <AutoCompleteInput
            multi
            options={available.symbols.map(toMultiSelectItem)}
            onChange={this.autoCompleteMultiChangeHandler('symbols')}
            value={this.state.symbols.map(toMultiSelectItem)}
            inputLabel='Symbols'
            placeholder='Select symbols'
          />
          <CustomMultiSelect
            label='Brokers'
            selected={this.state.brokers}
            changed={this.filedChangeHandler('brokers')}
            items={
              available.brokers.map(toMultiSelectItem)
            }
          />
          <CustomMultiSelect
            label='Timeframes'
            selected={this.state.timeframes}
            changed={this.filedChangeHandler('timeframes')}
            items={
              available.timeframes.map(toTimeframeItem)
            }
          />
          <CustomMultiSelect
            label='Indicators'
            selected={this.state.indicators}
            changed={this.filedChangeHandler('indicators')}
            items={
              available.indicators.map(toMultiSelectItem)
            }
          />
          <AutoCompleteInput
            multi
            options={
              this.props.subscriptions.map(
                sub => ({
                  label: sub.name,
                  value: sub.value,
                })
              )
            }
            onChange={this.autoCompleteMultiChangeHandler('subscriptions')}
            value={this.state.subscriptions.map(toMultiSelectItem)}
            inputLabel='Subscriptions'
            placeholder='Select subscriptions'
          />
          <DialogContentText>Rules</DialogContentText>
          <TextEditor
            description={this.state.rules}
            updatedDescription={this.filedChangeHandler('rules')}
          />
          <DialogContentText>Links</DialogContentText>
          <TextValidator
            fullWidth
            name='telegramLink'
            label='Telegram'
            value={this.state.telegramLink}
            margin='normal'
            onChange={this.inputChangeHandler}
          />
          <TextValidator
            fullWidth
            name='senseLink'
            label='Sense'
            value={this.state.senseLink}
            margin='normal'
            onChange={this.inputChangeHandler}
          />
          <DialogActionsWrapper/>
        </ValidatorForm>
      </DialogWrapper>
    );
  }
}

export default AddEditDialog;
