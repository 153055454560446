import React, {useEffect, useMemo, useState} from 'react';
import constants from "../../constants";
import {
  getHarmonicPatternsList,
} from '../../services/harmonic_pattern';
import OutlinedButton from "../../components/OutlinedButton";
import HarmonicPatternsTable from "../../components/HarmonicPatternsTable";
import {
  formatTimeFrame, getPremiumLockedFilters,
  getQueryParam,
  parseHarmonicPattern
} from "../../utils/utility";
import withStyles from "@material-ui/core/styles/withStyles";
import {getAddonById} from "../../services/addon";
import Filter from "./filter";
import {connect} from "react-redux";

const ALL_TIMEFRAMES = 'All timeframes';

const DEFAULT_HARMONIC_FILTERS = {
  names: [
    "ABCD",
    "Alt_ABCD",
    "Gartley",
    "Golden_Pattern",
    "Bat",
    "Alt_Bat",
    "Crab",
    "Butterfly",
    "DeepCrab",
    "Shark",
    "CP",
    "Cypher"
  ],
  types: constants.harmonicPatternTypes,
  // medium, large, xLarge
  sizes: [0, 1, 2, 3],
  additionalIndicators: constants.harmonicPatternAdditionalIndicators,
  deviationTypes: [
    constants.harmonicPatternDeviationTypes[0],
    constants.harmonicPatternDeviationTypes[1],
  ],
  indicatorNotification: constants.harmonicPatternIndicatorNotification,
  deviationValue: null,
  ids: [],
  symbol: [],
  pageSize: 25,
  page: 1,
  timeframe: [],
};

const styles = theme => {
  return {
    filtersBlock: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '10px',
    },
    symbolSelectWrapper: {
      width: '100px',
    },
    pagination: {
      margin: '20px 0 0 20px',
    },
    select: {
      height: '27px',
      minWidth: '140px',
    },
    divider: {
      margin: '0 5px',
    },
  };
};

const symbolToSelectValue = s => ({label: s, value: s});
const timeframeToSelectValue = v => ({label: formatTimeFrame(parseInt(v)), value: parseInt(v)});
const ensureAddonHPFilters = ({filters, addon}) => {
  const _filters = {...filters};
  if ((_filters.timeframe === ALL_TIMEFRAMES) || !_filters.timeframe) {
    _filters.timeframe = addon.timeframes;
  }
  if (!_filters.symbol) {
    _filters.symbol = addon.symbols;
  }
  return _filters;
};

const HarmonicPatterns = ({classes, history, userSubscriptions = []}) => {

  const addonId = getQueryParam('addon');
  const [addon, setAddon] = useState(null);

  const [filters, setFilters] = useState(DEFAULT_HARMONIC_FILTERS);
  const [harmonicPatterns, setHarmonicPatterns] = useState({
    data: [],
    total: 0,
  });

  useEffect(() => {
    getAddonById(addonId).then(addon => {
      setAddon(addon);
      if (addon.harmonicConfig) {
        setFilters({
          ...addon.harmonicConfig.default_,
          symbol: addon.symbols,
          pageSize: 25,
          page: 1,
          timeframe: addon.timeframes,
        });
      } else {
        setFilters({
          ...DEFAULT_HARMONIC_FILTERS,
          symbol: addon.symbols,
          pageSize: 25,
          page: 1,
          timeframe: addon.timeframes,
        })
      }
    })
  }, []);

  useEffect(() => {
    if (addon) {
      const _filters = ensureAddonHPFilters({filters, addon});
      getHarmonicPatternsList(_filters).then(
        res => setHarmonicPatterns({
          data: res.data.map(parseHarmonicPattern),
          total: res.total,
        })
      );
    }
  }, [filters]);

  useEffect(() => {
    window.addEventListener('realtime_update', handleRealtimeUpdate);
    return () => window.removeEventListener('realtime_update', handleRealtimeUpdate);
  }, [filters, addon]);

  const handleRealtimeUpdate = event => {
    const detail = event.detail;
    if (addon) {
      if (detail && detail.type === constants.socketEvent.NEW_HARMONIC_PATTERN_EVENT) {
        if (filters.page === 1) {
          const _filters = ensureAddonHPFilters({filters, addon});
          getHarmonicPatternsList(_filters).then(
            res => setHarmonicPatterns({
              data: res.data.map(parseHarmonicPattern),
              total: res.total,
            })
          );
        }
      }
    }
  };

  const handleHarmonicPatternClick = octId => {
    if (octId && addonId) {
      history.push(`/addons/${addonId}?pattern=${octId}`);
    }
  };

  const handleFiltersChange = newFilters => {
    setFilters({...newFilters, page: 1});
  };

  const symbolOptions = useMemo(() => {
    if (addon) {
      return addon.symbols.map(symbolToSelectValue);
    }
    return [];
  }, [addon]);
  const timeframeOptions = useMemo(() => {
    if (addon) {
      return addon.timeframes.map(timeframeToSelectValue)
    }
    return [];
  }, [addon]);

  const setNextPage = () => setFilters(prev => ({...prev, page: prev.page + 1}));
  const setPrevPage = () => setFilters(prev => ({...prev, page: prev.page - 1}));

  const hasNextPage = harmonicPatterns.total > filters.page * filters.pageSize;
  const hasPrevPage = filters.page > 1;

  if (!addon) {
    return null;
  }

  const premiumLockedFilters = getPremiumLockedFilters(addon.harmonicConfig.premium, userSubscriptions);

  return (
    <div>
      <div className={classes.filtersBlock}>
        <Filter
          filters={filters}
          onChange={handleFiltersChange}
          availableFilters={addon.harmonicConfig.available}
          symbolOptions={symbolOptions}
          timeframeOptions={timeframeOptions}
          premiumLockedFilters={premiumLockedFilters}
        />
      </div>
      <HarmonicPatternsTable
        patterns={harmonicPatterns.data}
        onRowClick={handleHarmonicPatternClick}
      />
      <div className={classes.pagination}>
        <OutlinedButton onClick={setPrevPage} disabled={!hasPrevPage}>Prev</OutlinedButton>
        <OutlinedButton onClick={setNextPage} disabled={!hasNextPage}>Next</OutlinedButton>
      </div>
    </div>
  );
};


const mapStateToProps = state => {
  return {
    userSubscriptions: state.auth.user.subscriptions,
  };
};

export default connect(mapStateToProps)(withStyles(styles, {withTheme: true})(HarmonicPatterns));
