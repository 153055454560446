
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { getSpacingUnit } from '../../../utils/utility';

const styles = theme => {
	const spacingUnit = getSpacingUnit(theme);
    return {
		root: {
			backgroundColor: theme.palette.subBackColor2,
			minHeight: spacingUnit * 5,
			width: 300,
			[theme.breakpoints.down('sm')]: {
				width: '100%',
			}
		},
		tabItem: {
			width: '50%',
			minWidth: 'unset',
			minHeight: spacingUnit * 5,
			'& > span': {
				width: 'initial'
			}
		},
		indicator: {
			backgroundColor: theme.palette.mainForeColor,
			width: '50%'
		}
    };
};

const SignalsTab = ({ classes, focusedSignalCategory, signalCategoryChanged }) => {
	const tabChangeHandler = (event, focusedSignalCategory) => {
		signalCategoryChanged(focusedSignalCategory);
	};

    return (
		<Tabs 
			className={classes.root}
			value={focusedSignalCategory} 
			onChange={tabChangeHandler}
			classes={{indicator: classes.indicator}}>
			<Tab label='Active' className={classes.tabItem} />
			<Tab label='Archive' className={classes.tabItem} />
			{/* <Tab label='Pending' className={classes.tabItem} style={{visibility: 'hidden'}} /> */}
		</Tabs>
    );
};

export default withStyles(styles, {withTheme: true})(SignalsTab);
