import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import PasswordPanel from "./PasswordPanel";
import ProfilePanel from "./ProfilePanel";

const styles = (theme) => {
  return {
    page: {
      height: "calc(100vh - 96px)",
      backgroundColor: theme.palette.subBackColor2,
    },
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.subBackColor3,
    },
    paper: {
      backgroundColor: theme.palette.subBackColor3,
      paddingLeft: 20,
    },
    tabs: {
      display: "flex",
      justifyContent: "flex-start",
    },
    tab: {
      color: theme.palette.grayColor[7], //mainForeColor
    },
  };
};

class ProfileSetting extends Component {
  state = {
    value: 0,
  };

  inputChangeHandler = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    return (
      <Fragment>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Tabs
              className={classes.tabs}
              value={this.state.value}
              indicatorColor="primary"
              textColor="primary"
              onChange={this.inputChangeHandler}
            >
              <Tab className={classes.tab} label="Profile" />
              {/* TODO: hide password change tab now */}
              {/* <Tab className={classes.tab} label='Password' /> */}
            </Tabs>
          </Paper>
          {value === 0 && <ProfilePanel />}
          {value === 1 && <PasswordPanel />}
        </div>
      </Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ProfileSetting);
