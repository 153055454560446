// tools
export const SET_NEW_SIGNAL = "SET_NEW_SIGNAL";
export const SET_UPDATE_SIGNAL = "SET_UPDATE_SIGNAL";
export const SET_REMOVE_SIGNAL = "SET_REMOVE_SIGNAL";
export const SET_REMOVE_SIGNALS = "SET_REMOVE_SIGNALS";

export const SET_SELECTED_STRATEGY = "SET_SELECTED_STRATEGY";
export const SET_SELECTED_SYMBOL_ID = "SET_SELECTED_SYMBOL_ID";

export const UPDATE_SIGNALS = "UPDATE_SIGNALS";
export const INITIALIZE_SIGNALS = "INITIALIZE_SIGNALS";

export const SET_CHART_DETAILED_DATA = "SET_CHART_DETAILED_DATA";
export const SET_CHART_INFO_DATA = "SET_CHART_INFO_DATA";

export const SET_STRATEGY_SYMBOLS = "SET_STRATEGY_SYMBOLS";

// strategy
export const SET_STRATEGIES = "SET_STRATEGIES";
// broker
export const SET_BROKERS = "SET_BROKERS";
// symbol
export const SET_SYMBOLS = "SET_SYMBOLS";

export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const EDIT_CURRENT_USER_INFO = "EDIT_CURRENT_USER_INFO";

export const SET_CURRENT_THEME = "SET_CURRENT_THEME";
export const GET_ERRORS = "GET_ERRORS";
export const SET_LOADING_STATUS = "SET_LOADING_STATUS";

export const SET_LAST_QUOTE = "SET_LAST_QUOTE";
export const SET_CURRENT_SIDEBAR = "SET_CURRENT_SIDEBAR";
export const CLEAR_TOOLS = "CLEAR_TOOLS";
export const SET_TICK_DATA = "SET_TICK_DATA";
export const SET_STRATEGY_TYPE = "SET_STRATEGY_TYPE";
export const SET_SERVER_TIME = "SET_SERVER_TIME";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";

export const SET_TIME_FRAME = "SET_TIME_FRAME";
export const SET_TV_HEIGHT = "SET_TV_HEIGHT";

// addon
export const SET_ADDONS = 'SET_ADDONS';
export const SET_OCT_CONFIG = 'SET_OCT_CONFIG';

// subscription
export const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS';
export const ADD_SUBSCRIPTION = 'ADD_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const REMOVE_SUBSCRIPTION = 'REMOVE_SUBSCRIPTION';

// config
export const SET_CONFIG = 'SET_CONFIG';

