import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ValidatorForm } from "react-material-ui-form-validator";

import CustomSelectValidator from "../../components/CustomSelectValidator";
import { getSpacingUnit } from "../../utils/utility";
import constants from "../../constants";
import CardWrapper from "../../hoc/CardWrapper";

const styles = (theme) => {
  const spacingUnit = getSpacingUnit(theme);

  return {
    item: {
      padding: spacingUnit * 1.5,
      margin: spacingUnit,
      borderRadius: spacingUnit / 2,
      backgroundColor: theme.palette.subBackColor2,
    },
    customCardHeaderTitle: {
      padding: spacingUnit,
    },
    filters: {
      padding: spacingUnit,
    },
  };
};

const DisplayBoard = ({
  title,
  subTitle,
  values,
  reportDate,
  onChangeReportDate,
  classes,
  toolIndex,
  setToolIndex
}) => {
  const [strategy, setStrategy] = useState(toolIndex);
  const strategyChangeHandler = (strategy) => {
    setStrategy(strategy);
    setToolIndex(strategy);
  };

  return (
    <CardWrapper
      headerColor="primary"
      headerHeight="60px"
      title={title}
      subTitle={subTitle}
    >
      <Grid
        className={classes.filters}
        container
        spacing={16}
        alignItems="stretch"
      >
        <Grid item xs={12} sm={12} md={8}>
          <ValidatorForm onSubmit={() => {}}>
            <CustomSelectValidator
              label="Strategy"
              value={strategy}
              changed={strategyChangeHandler}
              items={constants.tools}
            />
          </ValidatorForm>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              views={["year"]}
              label="Year"
              margin="normal"
              value={reportDate}
              onChange={onChangeReportDate}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <div>
        {values.map((value, index) => (
          <div key={index} className={classes.item}>
            <Typography>
              {value.label}: {value.value}
            </Typography>
          </div>
        ))}
      </div>
    </CardWrapper>
  );
};

export default withStyles(styles, { withTheme: true })(DisplayBoard);
