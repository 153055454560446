import React, {Fragment} from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import {colors} from '../../../utils/styles';
import {Popover, Typography} from "@material-ui/core";
import {
  resetNewHarmonicPatternsCount
} from "../../../services/harmonic_pattern";
import HarmonicPatternsTable from "../../../components/HarmonicPatternsTable";
import OutlinedButton from "../../../components/OutlinedButton";
import {Link} from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import ContainedButton from "../../../components/ContainedButton";
import NotificationsIcon from "@material-ui/icons/Notifications";

const styles = () => {
  return {
    container: {
      marginLeft: '10px',
    },
    selectedContainer: {
      marginLeft: '10px',
      backgroundColor: 'rgb(33, 150, 243)',
    },
    root: {
      padding: '10px',
    },
    tableWrapper: {
      margin: '10px 0',
    },
    notificationBadge: {
      marginRight: '5px',
      backgroundColor: colors.red900,
      color: '#fff',
      height: 'auto',
    }
  }
};

const HarmonicPattersNotifications = props => {
  const {
    classes,
    patterns,
    count,
    resetCount,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    if (count) {
      resetNewHarmonicPatternsCount().then(resetCount);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <ContainedButton
        onClick={handleClick}
        customClasses={anchorEl !== null ? classes.selectedContainer : classes.container}
      >
        <NotificationsIcon />
        <span>
          Notifications {count ? <Chip label={count} className={classes.notificationBadge}/> : null}
        </span>
      </ContainedButton>
      <Popover
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={20}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.root}>
          <Typography>Latest patterns</Typography>
          <div className={classes.tableWrapper}>
            <HarmonicPatternsTable patterns={patterns.slice(0, 8)} onRowClick={props.handlePatternNotificationClick} />
          </div>
          <Link to={`/hp?addon=${props.addonId}`}>
            <OutlinedButton>
              All patterns
            </OutlinedButton>
          </Link>
        </div>
      </Popover>
    </Fragment>
  )
};

export default withStyles(styles, { withTheme: true })(HarmonicPattersNotifications);
