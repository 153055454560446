import { createMuiTheme } from "@material-ui/core/styles";

import { colors } from "../utils/styles";

// TODO: * naming and theme use case
const lightTheme = createMuiTheme({
  palette: {
    primary: {
      main: colors.purple500,
    },
    secondary: {
      main: "#00f1a2",
    },
    mainBackColor: colors.white,
    subNavBackColor: colors.white,
    subBackColor1: colors.purple500,
    subBackColor3: colors.white,
    subBackColor2: colors.ghostwhite,
    subBackColor4: colors.deepPurple500,
    subBackColor5: colors.white,
    iconColor: colors.white,
    buttonColor1: colors.purple400,
    buttonColor2: colors.deepOrange400,
    buttonColor3: colors.teal400,
    buttonColor4: colors.blueGrey500,
    buttonColor5: colors.grey,
    buttonColor6: colors.lightBlue,
    buttonRedColor: colors.red900,
    mainForeColor: colors.purple500,
    subForeColor1: colors.grey,
    subForeColor2: colors.black,
    subForeColor3: colors.grey,
    subForeColor4: colors.grey,
    subForeColor5: colors.white,
    lineColor: colors.lightgrey,
    mainRedColor: colors.purple500,
    borderColor: colors.dark,
    highlightColor: colors.black,
    cardBackColor: colors.white,
    cardDescColor: colors.grey600,
    radiusButtonColor: colors.deepPurple500,
    selectorBgColor: colors.white,
    selectorHoverBgColor: colors.lightgrey,
    selectorForeColor: colors.grey700,
    scheduleForeColor: colors.black,
    scheduleForeColor2: colors.white,
    scheduleBackColor1: colors.purple500, //'#7e57c2',
    scheduleMainColor: colors.purple500,
    subBorderColor: colors.grey900,
    warningColor: [
      colors.warning1,
      colors.warning2,
      colors.warning3,
      colors.warning4,
    ],
    dangerColor: [
      colors.danger1,
      colors.danger2,
      colors.danger3,
      colors.danger4,
    ],
    infoColor: [colors.info1, colors.info2, colors.info3, colors.info4],
    successColor: [
      colors.success1,
      colors.success2,
      colors.success3,
      colors.success4,
    ],
    primaryColor: [
      colors.primary1,
      colors.primary2,
      colors.primary3,
      colors.primary4,
      colors.primaryScheduleItemIn,
      colors.primaryScheduleItem,
      colors.primary2,
    ],
    roseColor: [colors.rose1, colors.rose2, colors.rose3, colors.rose4],
    grayColor: [
      colors.gray1,
      colors.gray2,
      colors.gray3,
      colors.gray4,
      colors.gray5,
      colors.gray6,
      colors.gray7,
      colors.gray8,
      colors.gray9,
      colors.gray10,
      colors.gray11,
      colors.gray12,
    ],
  },
  typography: {
    useNextVariants: true,
    primary: {
      color: "rgba(0, 0, 0, 0.87)",
    },
  },
  overrides: {
    MUIDataTable: {
      root: {
        backgroundColor: "#FF000",
      },
      paper: {
        boxShadow: "none",
      },
    },
    MUIDataTableSelectCell: {
      checked: {
        color: `${colors.purple500} !important`,
      },
    },
  },
});

const tableDarkThemeVariable = {
  root: {
    backgroundColor: "#11141E",
  },
};

const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: colors.white,
    },
    secondary: {
      main: "#00f1a2",
    },
    mainBackColor: "#11141E",
    subNavBackColor: colors.dark,
    subBackColor1: colors.dark,
    subBackColor2: "#0B0D14",
    subBackColor3: "#1D222F",
    subBackColor4: colors.deepPurple500,
    iconColor: colors.white,
    buttonColor1: colors.dark,
    buttonColor2: colors.dark,
    buttonColor3: colors.dark,
    buttonColor4: colors.dark,
    buttonColor5: colors.dark,
    buttonColor6: colors.lightBlue,
    buttonRedColor: colors.red900,
    mainForeColor: colors.white,
    subForeColor1: colors.white,
    subForeColor2: "#61646D",
    subForeColor3: "#BBBDC1",
    subForeColor4: "#61646D",
    subForeColor5: colors.white,
    lineColor: "#3F434E",
    mainRedColor: colors.red900,
    borderColor: colors.cyan400,
    subBorderColor: colors.grey400,
    highlightColor: colors.white,
    cardBackColor: colors.mediumDark,
    cardDescColor: colors.grey700,
    radiusButtonColor: colors.deepPurple500,
    selectorBgColor: colors.mediumDark,
    selectorHoverBgColor: colors.grey800,
    selectorForeColor: colors.grey700,
    scheduleForeColor: "#abb1be",
    scheduleForeColor2: "white",
    scheduleBackColor1: "#2e364d",
    scheduleMainColor: "#1b202e",
    marketLabelBack : '#132133',
    marketlabelColor : "#2196f1",
    activeLabelBack : "#122524",
    activeLabel:"#05c58d",
    closeLabelBack:"#292b34",
    longPriceLabel: "#142940",
    longLable : "#1e7cc7",
    shortPriceLabel :"#361725",
    shortLabelBack : "#c62241",
    warningColor: [
      colors.warning1,
      colors.warning2,
      colors.warning3,
      colors.warning4,
    ],
    dangerColor: [
      colors.danger1,
      colors.danger2,
      colors.danger3,
      colors.danger4,
    ],
    infoColor: [colors.info1, colors.info2, colors.info3, colors.info4],
    successColor: [
      colors.success1,
      colors.success2,
      colors.success3,
      colors.success4,
    ],
    primaryColor: [
      colors.darkPrimary1,
      colors.darkPrimary2,
      colors.darkPrimary3,
      colors.darkPrimary4,
      colors.primaryScheduleItemIn,
      colors.primaryScheduleItem,
      colors.darkPrimary5,
    ],
    roseColor: [colors.rose1, colors.rose2, colors.rose3, colors.rose4],
    grayColor: [
      colors.gray1,
      colors.gray2,
      colors.gray3,
      colors.gray4,
      colors.gray5,
      colors.gray6,
      colors.gray7,
      colors.gray8,
      colors.gray9,
      colors.gray10,
      colors.gray11,
      colors.gray12,
    ],
  },
  typography: {
    useNextVariants: true,
    primary: {
      color: colors.white,
    },
  },
  overrides: {
    MUIDataTableToolbarSelect: tableDarkThemeVariable,
    MUIDataTableFilter: tableDarkThemeVariable,
    // MuiList: tableDarkThemeVariable,
    MuiPaper: tableDarkThemeVariable,
    MUIDataTableViewCol: tableDarkThemeVariable,
    MUIDataTableSelectCell: {
      checked: {
        color: `${colors.white} !important`,
      },
    },
  },
});

export { lightTheme, darkTheme };
