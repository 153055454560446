
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import AddIcon from '@material-ui/icons/Add';
import { getFontUnit, getSpacingUnit } from '../../utils/utility';

const styles = theme => {
    const fontSizeUnit = getFontUnit(theme);
    const spacingUnit = getSpacingUnit(theme);

    return {
        root: {
			cursor: 'pointer',
			color: theme.palette.subForeColor5,
            fontSize: fontSizeUnit * 3.2,
            margin: spacingUnit / 2
		}
    };
};

const AddIconButton = ({ classes, ...rest }) => {
    return (
        <AddIcon
            className={classes.root}
            {...rest} />
    );
};

export default withStyles(styles, {withTheme: true})(AddIconButton);
