
import React from 'react';
import CKEditor from 'ckeditor4-react';

const TextEditor = ({ description, updatedDescription }) => {
    const onEditorDataChange = evt => {
		updatedDescription(evt.editor.getData());
	}

	return (
		<CKEditor
			data={description}
			onChange={onEditorDataChange}
			disableEnforceFocus={true} />
	);
}

export default TextEditor;