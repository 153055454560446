
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = theme => {
    return {
        root: {
            color: theme.palette.mainForeColor
        }
    };
};

const CustomTextButton = ({ classes, children, ...rest }) => {
    return (
        <Button
			{...rest}
            className={classes.root}>
		    {children}
        </Button>
    );
};

export default withStyles(styles, {withTheme: true})(CustomTextButton);