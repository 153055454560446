
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';

import { getSpacingUnit } from '../../utils/utility';

const styles  = theme => {
    const spacingUnit = getSpacingUnit(theme);
    
    return {
        root: {
            backgroundColor: theme.palette.subBackColor3,
            '& th': {
                backgroundColor: theme.palette.subBackColor3,
                paddingRight: spacingUnit * 2,
                paddingLeft: spacingUnit * 2
            },
            '& td': {
                color: theme.palette.subForeColor3,
                paddingRight: spacingUnit * 2,
                paddingLeft: spacingUnit * 2
            }
        }
    }
};

const CustomMUIDataTable = ({ classes, ...rest }) => {
    return (
        <MUIDataTable
            className={classes.root}
            {...rest} />
    );
};

export default withStyles(styles, {withTheme: true})(CustomMUIDataTable);
