import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';

import { connect } from "react-redux";
import DashboardCard from "../../components/DashboardCard";

const styles = (theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column'
    },
    container: {
      [theme.breakpoints.down('sm')]: {
        padding: 0
      },
      display: 'flex',
      padding: `2px 8px 8px 8px`,
      margin: 0,
      flexGrow: 1,
    },
    headTextPosition: {
      display: 'flex',
      justifyContent: 'center',
      textTransform: "initial",
      marginTop: 20,
      marginBottom: 16,
    }
  };
};

const Dashboard = (props) => {
  const { classes } = props;

  const {available, comingSoon} = props.addons.reduce((acc, addon) => {
    if (!addon.hidden) {
      if (!addon.comingSoon) {
        acc.available.push(addon);
      } else {
        acc.comingSoon.push(addon);
      }
    }
    return acc;
  }, {available: [], comingSoon: []})

  return (
    <div className={classes.root}>
      <Typography className={classes.headTextPosition} variant='h5'>
        Add-ons
        </Typography>
      <Grid container className={classes.container}>
        {
          available.map(addon => (
            <DashboardCard key={addon._id} addon={addon} />
          ))
        }
      </Grid>

      <Typography className={classes.headTextPosition} variant='h5'>
        Coming Soon
        </Typography>
      <Grid container className={classes.container} >
        {
          comingSoon.map(addon => (
            <DashboardCard key={addon._id} addon={addon} comingSoon/>
          ))
        }
      </Grid>
    </div >
  );
}
const mapStateToProps = (state) => ({
  strategies: state.strategy.strategies,
  addons: state.addon.addons,
});

export default connect(mapStateToProps)(withStyles(styles)(Dashboard));
