import urlJoin from "url-join";

import config from "../config";

const signalsPrefix = "api/signals";
const loginPrefix = "api/login";
const registerPrefix = "api/register";
const forgotPasswordPrefix = "api/forgot-password";
const resetPasswordPrefix = "api/reset-password";
const resetUserInfoPrefix = "api/reset-user-info";
const verificationPrefix = "api/verification";
const strategiesPrefix = "api/strategies";
const symbolsPrefix = "api/symbols";
const brokersPrefix = "api/brokers";
const usersPrefix = "api/users";
const historicalDataPrefix = "api/bars/download";
const notificationsPrefix = "api/notifications";
const notificationsByRolePrefix = "api/notifications-by-role";
const closeNotificationUrl = "api/close-notification";
const chartsPrefix = "api/charts";
const feedbacksPrefix = "api/feedbacks";
const rulesPrefix = "api/rules";
const schedulesPrefix = "api/schedules";
const videosPrefix = "api/videos";
const symbolSearchParam = "symbolId=";
const strategySearchParam = "strategyId=";
const linksPrefix = "api/links";
const tickPrefix = "api/quote/last-tick";
const addonsPrefix = 'api/addons';
const subscriptionsPrefix = 'api/subscriptions';
const harmonicPatternPrefix = 'api/harmonic_pattern';
const configPrefix = 'api/config';
const marketplaceItemsPrefix = 'api/marketplace_items';

const getSignalUrl = () => urlJoin(config.proxyUrl, signalsPrefix);

const getSignalsByStrategyIdUrl = (strategyId) => {
  return urlJoin(
    config.proxyUrl,
    signalsPrefix,
    `?${strategySearchParam}${strategyId}`
  );
};

const getStrategiesUrl = () => {
  return urlJoin(config.proxyUrl, strategiesPrefix);
};

const getSymbolUrl = (symbolId) => {
  return urlJoin(config.proxyUrl, symbolsPrefix, symbolId);
};

const getBrokerUrl = (brokerId) => {
  return urlJoin(config.proxyUrl, brokersPrefix, brokerId);
};

const getHistoricalDataUrl = () => {
  return urlJoin(config.proxyUrl, historicalDataPrefix);
};

const getBrokerDataUrl = () => {
  return urlJoin(config.proxyUrl, brokersPrefix);
};

const getSymbolDataUrl = () => {
  return urlJoin(config.proxyUrl, symbolsPrefix);
};

const getUsersUrl = () => {
  return urlJoin(config.proxyUrl, usersPrefix);
};

const getPageUsersUrl = (pageNumber) => {
  return urlJoin(config.proxyUrl, usersPrefix, pageNumber.toString());
};

const getResetUserInfoUrl = () => {
  return urlJoin(config.proxyUrl, resetUserInfoPrefix);
};

const getVerificationUrl = () => {
  return urlJoin(config.proxyUrl, verificationPrefix);
};

const getResetPasswordUrl = () => {
  return urlJoin(config.proxyUrl, resetPasswordPrefix);
};

const getForgotPasswordUrl = () => {
  return urlJoin(config.proxyUrl, forgotPasswordPrefix);
};

const getRegisterUrl = () => {
  return urlJoin(config.proxyUrl, registerPrefix);
};

const getLoginUrl = () => {
  return urlJoin(config.proxyUrl, loginPrefix);
};

const getNotificationsUrl = () => {
  return urlJoin(config.proxyUrl, notificationsPrefix);
};

const getNotificationsByRoleUrl = () => {
  return urlJoin(config.proxyUrl, notificationsByRolePrefix);
};

const getCloseNotificationUrl = () => {
  return urlJoin(config.proxyUrl, closeNotificationUrl);
};

const getChartDataUrl = () => {
  return urlJoin(config.proxyUrl, chartsPrefix);
};

const getFeedbacksUrl = () => {
  return urlJoin(config.proxyUrl, feedbacksPrefix);
};

const getFetchChartUrl = (strategyId) => {
  return urlJoin(
    config.proxyUrl,
    chartsPrefix,
    `?${strategySearchParam}${strategyId}`
  );
};

const getDetailedChartUrl = (strategyId, symbolId) => {
  return urlJoin(
    config.proxyUrl,
    chartsPrefix,
    "detail",
    `?${strategySearchParam}${strategyId}`,
    `&${symbolSearchParam}${symbolId}`
  );
};

const getRulesUrl = () => {
  return urlJoin(config.proxyUrl, rulesPrefix);
};

const getSchedulesUrl = () => {
  return urlJoin(config.proxyUrl, schedulesPrefix);
};

const getVideoUrl = () => urlJoin(config.proxyUrl, videosPrefix);

const getLinksUrl = () => {
  return urlJoin(config.proxyUrl, linksPrefix);
};

const geLastTickUrl = symbolId => {
  return urlJoin(
    config.proxyUrl,
    tickPrefix,
    `?${symbolSearchParam}${symbolId}`
  );
};

const getHarmonicPatternsUrl = (...paths) => {
  const args = [config.proxyUrl, harmonicPatternPrefix, ...paths];
  return urlJoin(...args);
};

const getAddonsUrl = (...paths) => {
  const args = [config.proxyUrl, addonsPrefix, ...paths];
  return urlJoin(...args);
}

const getOCTConfigUrl = () => urlJoin(config.proxyUrl, addonsPrefix, 'oct', 'config');

const getOCTChartUrl = () => urlJoin(config.proxyUrl, addonsPrefix, 'oct', 'chart');

const getSubscriptionsUrl = (id = null) => {
  const args = [config.proxyUrl, subscriptionsPrefix];
  if (id) {
    args.push(id);
  }
  return urlJoin(...args);
};

const getConfigUrl = () => urlJoin(config.proxyUrl, configPrefix);
const getMarketplaceItemsUrl = (...paths) => {
  const args = [config.proxyUrl, marketplaceItemsPrefix, ...paths];
  return urlJoin(...args);
};

export {
  getSignalUrl,
  getStrategiesUrl,
  getSymbolUrl,
  getBrokerUrl,
  getBrokerDataUrl,
  getSymbolDataUrl,
  getHistoricalDataUrl,
  getUsersUrl,
  getSignalsByStrategyIdUrl,
  getChartDataUrl,
  getFetchChartUrl,
  getDetailedChartUrl,
  getResetUserInfoUrl,
  getVerificationUrl,
  getResetPasswordUrl,
  getForgotPasswordUrl,
  getRegisterUrl,
  getLoginUrl,
  getNotificationsUrl,
  getNotificationsByRoleUrl,
  getCloseNotificationUrl,
  getFeedbacksUrl,
  getRulesUrl,
  getSchedulesUrl,
  getVideoUrl,
  getLinksUrl,
  getPageUsersUrl,
  getAddonsUrl,
  getOCTConfigUrl,
  getOCTChartUrl,
  geLastTickUrl,
  getSubscriptionsUrl,
  getHarmonicPatternsUrl,
  getConfigUrl,
  getMarketplaceItemsUrl,
};
