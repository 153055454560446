import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import OutlinedButton from "../../../../components/OutlinedButton";
import { showErrorToast, showInfoToast } from "../../../../utils/utility";
import constants from "../../../../constants";
import { updateMyInfo } from "../../../../services/user";

const styles = (theme) => {
  return {
    root: {
      marginTop: 20,
      backgroundColor: theme.palette.subBackColor3,
    },
    paper: {
      backgroundColor: theme.palette.subBackColor3,
    },
    updateButton: {
      marginTop: 20,
      marginBottom: 20,
    },
  };
};

class PasswordPanel extends Component {
  state = {
    password: "",
    newPassword: "",
    confirmPassword: "",
    updateClicked: false,
  };

  inputChangeHandler = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  checkValidation = () => {
    const { password, newPassword, confirmPassword } = this.state;
    if (
      password.length < 6 ||
      newPassword.length < 6 ||
      confirmPassword.length < 6
    )
      return false;
    if (newPassword !== confirmPassword) return false;
    return true;
  };

  updateUserInfo = async () => {
    this.setState({ updateClicked: true });
    if (!this.checkValidation()) {
      return;
    }
    const { loggedInUser } = this.props;
    const { password, newPassword, confirmPassword } = this.state;
    const data = {
      user: { password, newPassword, confirmPassword, _id: loggedInUser.id },
    };

    if (password === newPassword && password === confirmPassword) {
      showErrorToast(constants.notifications.SAME_PASSWORD);
      return;
    }

    try {
      const result = await updateMyInfo(loggedInUser.id, data);
      if (result.status === constants.response.SUCCESS) {
        showInfoToast(constants.notifications.SUCCESS_UPDATE_PASSWORD);
      }
    } catch (error) {
      console.log("dav : [Password/updateUserInfo] error => ", error);
      if (error.response) {
        const { message } = error.response.data;
        showErrorToast(message);
      } else {
        showErrorToast(constants.notifications.FAIL_UPDATE_PASSWORD);
      }
    }
  };

  render() {
    const { classes } = this.props;
    const {
      password,
      newPassword,
      confirmPassword,
      updateClicked,
    } = this.state;

    const isPasswordError = updateClicked && password.length < 6;
    const isNewPasswordError = updateClicked && newPassword.length < 6;
    const isConfirmPasswordLengthError =
      updateClicked && confirmPassword.length < 6;
    const isConfirmPasswordMatchError =
      updateClicked && newPassword !== confirmPassword;

    const passwordLengthErrorMessage =
      "The password must be at least 6 characters in length";

    let confirmErrorMessage =
      isConfirmPasswordLengthError && passwordLengthErrorMessage;
    if (!confirmErrorMessage) {
      confirmErrorMessage =
        isConfirmPasswordMatchError &&
        "New Password isPassword and confirm password does not match.";
    }
    return (
      <div className={classes.root}>
        <form>
          <Grid container spacing={16} alignItems="center" justify="center">
            <Grid item xs={10} sm={8} md={6}>
              <Grid container alignItems="center" justify="center">
                <TextField
                  error={isPasswordError}
                  label="Current Password"
                  type="Password"
                  value={password}
                  onChange={this.inputChangeHandler("password")}
                  margin="normal"
                  helperText={isPasswordError && passwordLengthErrorMessage}
                  fullWidth
                />
              </Grid>
              <Grid container alignItems="center" justify="center">
                <TextField
                  error={isNewPasswordError}
                  label="New Password"
                  type="Password"
                  value={newPassword}
                  onChange={this.inputChangeHandler("newPassword")}
                  margin="normal"
                  helperText={isNewPasswordError && passwordLengthErrorMessage}
                  fullWidth
                />
              </Grid>
              <Grid container alignItems="center" justify="center">
                <TextField
                  error={
                    isConfirmPasswordLengthError || isConfirmPasswordMatchError
                  }
                  label="Confirm Password"
                  type="Password"
                  value={confirmPassword}
                  onChange={this.inputChangeHandler("confirmPassword")}
                  margin="normal"
                  helperText={
                    (isConfirmPasswordLengthError ||
                      isConfirmPasswordMatchError) &&
                    confirmErrorMessage
                  }
                  fullWidth
                />
              </Grid>
              <Grid container alignItems="center" justify="flex-end">
                <div className={classes.updateButton}>
                  <OutlinedButton fullWidth onClick={this.updateUserInfo}>
                    Update
                  </OutlinedButton>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.auth.user,
});

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(PasswordPanel)
);
