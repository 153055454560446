/* eslint-disable react/no-unused-prop-types */
import React, { PureComponent } from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";

import DialogWrapper from "../../../../hoc/DialogWrapper";
import DialogActionsWrapper from "../../../../hoc/DialogActionsWrapper";
import CustomTextButton from "../../../../components/CustomTextButton";
import CustomSelectValidator from "../../../../components/CustomSelectValidator";
import CustomSwitch from "../../../../components/CustomSwitch";
import IndicatorRow from "../IndicatorRow";
import OutlinedButton from "../../../../components/OutlinedButton";
import {
  timeframeList,
  getAddEditDialogMetadata,
  showErrorToast,
} from "../../../../utils/utility";
import { addStrategy, editStrategy } from "../../../../services/strategy";
import {
  indicatorWithDefaultParam,
  indicatorGenerator,
  indicatorParser,
} from "../../../../utils/indicator";
import constants from "../../../../constants";
import { isValidStrategyParameter } from "../../../../utils/validator";

const initialType = constants.COSMICCLOUD;
const initialIndicatorName = "Ichimoku Cloud";
const initialIndicatorParam = indicatorWithDefaultParam[initialIndicatorName];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class AddEditStrategyDialog extends PureComponent {
  state = {
    name: "",
    type: initialType,
    brokerId: "",
    symbolId: "",
    timeframe: "",
    indicatorList: [],
    description: "",
    parameters: "",
    enableAlert: true,
    currentIndicatorName: initialIndicatorName,
    currentIndicatorParam: initialIndicatorParam,
    currentIndicatorOverlay: false,
    submitButtonDisabled: false,
    symbolIds: [],
    beforeClickSubmit: true,
  };

  componentDidMount() {
    const { isAddDialog, dialogData } = this.props;
    if (!isAddDialog) {
      const {
        name,
        description,
        type,
        brokerId,
        defaultSymbolId: symbolId,
        indicators,
        timeframe,
        enableAlert,
        parameters,
      } = dialogData;
      let { symbolIds } = dialogData;
      if (!symbolIds.includes(symbolId)) {
        symbolIds = [symbolId, ...symbolIds];
      }
      const indicatorList = indicatorParser(indicators);
      this.setState({
        name,
        description,
        type,
        brokerId,
        symbolId,
        timeframe,
        indicatorList,
        enableAlert,
        parameters,
        symbolIds,
      });
    } else {
      this.setState({
        parameters: constants.defaultStrategyParam,
        beforeClickSubmit: true,
      });
    }
    ValidatorForm.addValidationRule("validParameters", (parameters) => {
      return isValidStrategyParameter({ parameters });
    });
  }

  submitHandler = async () => {
    const { isAddDialog, dialogData, loggedInUser } = this.props;
    const {
      currentIndicatorName,
      currentIndicatorParam,
      currentIndicatorOverlay,
      submitButtonDisabled,
      indicatorList,
      symbolId: defaultSymbolId,
      ...rest
    } = this.state;
    const indicators = indicatorGenerator(indicatorList);
    const userId = loggedInUser.id;
    const data = { ...rest, userId, indicators, defaultSymbolId };
    if (!isAddDialog) {
      data._id = dialogData._id;
    }
    this.setState({ beforeClickSubmit: false });
    const strategy = { strategy: data };

    try {
      this.setState({ submitButtonDisabled: true });
      if (isAddDialog) {
        await addStrategy(strategy);
      } else {
        await editStrategy(strategy);
      }
      // TODO: * redux update
    } catch (error) {
      // TODO: * axios handling module
      console.log(
        "dav : [containers Stats TradeStrategy AddEditStrategyDialog submitHandler] error => ",
        error
      );
      if (error.response) {
        const { message: errorMessage } = error.response.data;
        showErrorToast(errorMessage);
      }
    }
    this.onCloseHandler();
  };

  inputChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  typeChangeHandler = (type) => {
    this.setState({ type });
  };

  brokerChangeHandler = (brokerId) => {
    this.setState({ brokerId, symbolId: "" });
  };

  symbolIdsChangeHandler = (event) => {
    const { value } = event.target;
    this.setState({ symbolIds: value });
    if (value.length > 0) {
      this.setState({ symbolId: value[0] });
    }
  };

  symbolChangeHandler = (symbolId) => {
    this.setState({ symbolId });
  };

  timeframeChangeHandler = (timeframe) => {
    this.setState({ timeframe });
  };

  currentIndicatorNameChangeHandler = (event) => {
    this.setState({
      currentIndicatorName: event.target.value,
      currentIndicatorParam: indicatorWithDefaultParam[event.target.value],
      currentIndicatorOverlay: false,
    });
  };

  currentIndicatorParamChangeHandler = (event) => {
    this.setState({ currentIndicatorParam: event.target.value });
  };

  currentIndicatorOverlayChangeHandler = (event) => {
    this.setState({ currentIndicatorOverlay: event.target.value });
  };

  // TODO: * not using currently
  switchHandler = (event) => {
    this.setState({ enableAlert: event.target.checked });
  };

  onCloseHandler = () => {
    const { closed } = this.props;
    closed && closed();
  };

  addIndicatorHandler = () => {
    const {
      indicatorList,
      currentIndicatorName,
      currentIndicatorParam,
      currentIndicatorOverlay,
    } = this.state;
    if (!currentIndicatorName) {
      return;
    }
    const newIndicatorList = [
      ...indicatorList,
      { name: currentIndicatorName, param: currentIndicatorParam, overlay: currentIndicatorOverlay },
    ];
    this.setState({
      indicatorList: newIndicatorList,
      currentIndicatorParam: initialIndicatorParam,
      currentIndicatorName: initialIndicatorName,
      currentIndicatorOverlay: false,
    });
  };

  editIndicatorRowHandler = (index, property) => (event) => {
    // property can be name or param
    if (["name", "param", "overlay"].includes(property)) {
      const { indicatorList } = this.state;
      const newIndicatorList = [...indicatorList];

      newIndicatorList[index][property] = event.target.value;
      if (property === "name") {
        newIndicatorList[index]["param"] =
          indicatorWithDefaultParam[event.target.value];
        newIndicatorList[index]["overlay"] = false;
      }
      this.setState({ indicatorList: newIndicatorList });
    }
  };

  removeIndicatorHandler = (index) => {
    const { indicatorList } = this.state;
    const newIndicatorList = [...indicatorList];
    newIndicatorList.splice(index, 1);
    this.setState({ indicatorList: newIndicatorList });
  };

  triggerSubmitHandler = () => {
    this.form.isFormValid(false).then((isValid) => {
      if (isValid) {
        this.submitHandler();
      }
    });
  };

  render() {
    const { manageTitle, isAddDialog, opened, brokers, symbols } = this.props;
    const {
      name,
      description,
      type,
      brokerId,
      timeframe,
      indicatorList,
      currentIndicatorName,
      currentIndicatorParam,
      currentIndicatorOverlay,
      enableAlert,
      parameters,
      symbolIds,
      beforeClickSubmit,
    } = this.state;
    const {
      dialogTitle,
      dialogDescription,
      submitButtonName,
      cancelButtonName,
    } = getAddEditDialogMetadata(isAddDialog, manageTitle);

    const brokerList = brokers.map((broker) => ({
      value: broker._id,
      label: broker.name,
    }));
    const symbolList = symbols
      .map((symbol) => ({
        value: symbol._id,
        label: symbol.name,
        brokerId: symbol.brokerId,
      }))
      .filter((symbol) => symbol.brokerId === brokerId);
    return (
      <DialogWrapper
        opened={opened}
        dialogTitle={dialogTitle}
        dialogDescription={dialogDescription}
        onClose={this.onCloseHandler}
        dialogActions={
          <DialogActionsWrapper>
            <CustomTextButton onClick={this.onCloseHandler}>
              {cancelButtonName}
            </CustomTextButton>
            <OutlinedButton type="submit" onClick={this.triggerSubmitHandler}>
              {submitButtonName}
            </OutlinedButton>
          </DialogActionsWrapper>
        }
      >
        <ValidatorForm
          ref={(ref) => {
            this.form = ref;
          }}
          onSubmit={this.submitHandler}
          onError={(errors) => {
            console.log(errors);
            this.setState({ beforeClickSubmit: false });
          }}
        >
          <TextValidator
            fullWidth
            name="name"
            label="Name"
            value={name}
            margin="normal"
            onChange={this.inputChangeHandler}
            validators={["required"]}
            errorMessages={["Name cannot be empty"]}
          />
          <CustomSelectValidator
            label="Type"
            value={type}
            changed={this.typeChangeHandler}
            items={constants.tools}
          />
          <CustomSelectValidator
            label="Broker"
            value={brokerId}
            changed={this.brokerChangeHandler}
            items={brokerList}
          />
          <FormControl
            fullWidth
            margin="normal"
            error={symbolIds.length === 0 && !beforeClickSubmit}
          >
            <InputLabel htmlFor="select-multiple-chip">Symbols</InputLabel>
            <Select
              multiple
              value={symbolIds}
              onChange={this.symbolIdsChangeHandler}
              input={<Input id="select-multiple-chip" />}
              renderValue={(selected) => (
                <div>
                  {selected.map((value) => {
                    const selectedItem = symbolList.find(
                      (item) => item.value === value
                    );
                    return <Chip key={value} label={selectedItem && selectedItem.label} />;
                  })}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {symbolList.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Please select Symbols</FormHelperText>
          </FormControl>
          {/* <CustomSelectValidator
                        label='Symbol'
                        value={symbolId}
                        changed={this.symbolChangeHandler}
                        items={symbolList} /> */}
          <CustomSelectValidator
            label="Time Frame"
            value={timeframe}
            changed={this.timeframeChangeHandler}
            items={timeframeList}
          />
          {indicatorList.map((item, index) => (
            // TODO: * update needed
            <IndicatorRow
              key={index}
              name={item.name}
              param={item.param}
              overlay={!item.overlay ? false : item.overlay}
              removeIndicatorHandler={() => this.removeIndicatorHandler(index)}
              onParamChange={this.editIndicatorRowHandler(index, "param")}
              onIndexChange={this.editIndicatorRowHandler(index, "name")}
              onOverlayChange={this.editIndicatorRowHandler(index, "overlay")}
            />
          ))}
          {/* TODO: * update needed */}
          <IndicatorRow
            plus
            name={currentIndicatorName}
            param={currentIndicatorParam}
            overlay={currentIndicatorOverlay}
            addIndicatorHandler={this.addIndicatorHandler}
            onIndexChange={this.currentIndicatorNameChangeHandler}
            onParamChange={this.currentIndicatorParamChangeHandler}
            onOverlayChange={this.currentIndicatorOverlayChangeHandler}
          />
          <TextValidator
            fullWidth
            name="description"
            label="Description"
            value={description}
            margin="normal"
            onChange={this.inputChangeHandler}
          />
          <TextValidator
            fullWidth
            name="parameters"
            label="Parameters"
            value={parameters}
            margin="normal"
            validators={["validParameters"]}
            errorMessages={["Invalid parameters"]}
            onChange={this.inputChangeHandler}
          />
          <CustomSwitch
            switchHandler={this.switchHandler}
            checked={enableAlert}
            label="Enable Browser Alert"
          />
          <DialogActionsWrapper />
        </ValidatorForm>
      </DialogWrapper>
    );
  }
}

export default AddEditStrategyDialog;
