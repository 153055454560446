import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = (theme) => {
  return {
    root: {
      height: '80%'
    },
  };
};

class EconomicClendar extends React.Component {

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-events.js'
    script.async = true;
    script.innerHTML = JSON.stringify({
      "colorTheme": "dark",
      "isTransparent": false,
      "width": "100%",
      "height": "100%",
      "locale": "en",
      "importanceFilter": "-1,0,1"
    })
    document.getElementById("myContainer").appendChild(script);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root} id="myContainer">
        <div className="tradingview-widget-container__widget"></div>
        <div className="tradingview-widget-copyright">
          <a href="https://www.tradingview.com/markets/currencies/economic-calendar/" rel="noopener noreferrer" target="_blank">
            <span className="blue-text">Economic Calendar</span></a> by TradingView
            </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(EconomicClendar);