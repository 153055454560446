import axios from "axios";
import jwt_decode from "jwt-decode";

import {
  getLoginUrl,
  getRegisterUrl,
  getForgotPasswordUrl,
  getResetPasswordUrl,
  getResetUserInfoUrl,
  getVerificationUrl,
} from "../services/endpoints";
import { jwtTokenKey, pageLinks } from "../utils/links";
import { SET_CURRENT_USER, EDIT_CURRENT_USER_INFO } from "./types";
import { setErrors, clearErrors } from "./error";
import setAuthToken from "../services/security/setAuthToken";
import constants from "../constants";
import { sendAuthenticateEvent } from "../services/socket-api";

// TODO: * async/await consistent
export const registerUser = (user, successCallback, errorCallback) => (
  dispatch
) => {
  const url = getRegisterUrl();
  axios
    .post(url, user)
    .then((response) => {
      successCallback();
    })
    .catch((error) => {
      if (error.response) {
        const { status, data } = error.response;
        if (status === constants.response.INTERNAL_SERVER_ERROR) {
          errorCallback(false, data.message);
        } else {
          dispatch(setErrors(error.response.data));
          errorCallback(true);
        }
      } else {
        errorCallback(false);
      }
    });
};

export const loginUser = (
  user,
  history,
  successCallback,
  errorCallback,
  from
) => (dispatch) => {
  const url = getLoginUrl();
  axios
    .post(url, user)
    .then((response) => {
      const { token } = response.data;
      localStorage.setItem(jwtTokenKey, token);
      setAuthToken(token);
      // TODO: api key should be used for decoding
      const userInfo = jwt_decode(token);
      dispatch(setCurrentUser(userInfo));
      if (from) {
        // history.push(from);
        history.push(pageLinks.Dashboard.url)
      } else {
        history.push(pageLinks.Dashboard.url)
        // if (userInfo.roles.includes(constants.roles.ADMIN_ROLE)) {
        //   history.push(pageLinks.Dashboard.url);
        // } else if (userInfo.roles.includes(constants.roles.CHART_ADMIN_ROLE)) {
        //   history.push(pageLinks.Dashboard.url);
        // } else if (userInfo.roles.includes(constants.roles.PROVIDER_ROLE)) {
        //   history.push(pageLinks.Dashboard.url);
        // } else if (userInfo.roles.includes(constants.roles.USER_ROLE)) {
        //   const visibleSidebarItems = sidebarItems()[1].subItems.filter(
        //     (sidebarSubItem) =>
        //       validateRoleSkuAccess(
        //         sidebarSubItem.roles,
        //         sidebarSubItem.skuIds,
        //         userInfo
        //       )
        //   );
        //   if (visibleSidebarItems.length !== 0) {
        //     history.push(visibleSidebarItems[0].link);
        //   } else {
        //     history.push(pageLinks.Dashboard.url);
        //   }
        // } else if (
        //   userInfo.roles.includes(constants.roles.SCHEDULE_ADMIN_ROLE)
        // ) {
        //   history.push(pageLinks.Dashboard.url);
        // }
      }

      sendAuthenticateEvent(userInfo.apiToken);
      successCallback && successCallback(response);
    })
    .catch((error) => {
      if (error.response) {
        dispatch(setErrors(error.response.data));
      }
      errorCallback && errorCallback(error);
    });
};

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// TODO: * it's duplicated with above setCurrentUser
export const editCurrentUserInfo = (user) => (dispatch) => {
  dispatch({
    type: EDIT_CURRENT_USER_INFO,
    payload: user,
  });
};

export const logoutUser = (history) => (dispatch) => {
  localStorage.clear();
  setAuthToken(false);
  dispatch(setCurrentUser(null));

  if (history) {
    history.replace(pageLinks.Login.url);
  }
};

export const sendResetLink = (user, successCallback, errorCallback) => (
  dispatch
) => {
  const url = getForgotPasswordUrl();
  axios
    .post(url, user)
    .then((response) => {
      successCallback(response.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, data } = error.response;
        if (status === constants.response.INTERNAL_SERVER_ERROR) {
          errorCallback(false, data.message);
        } else {
          dispatch(setErrors(error.response.data));
          errorCallback(true);
        }
      } else {
        errorCallback(false);
      }
    });
};

// TODO: * naming and readable issue
export const getUserWithResetToken = async (token) => {
  const url = getResetUserInfoUrl();
  const res = await axios.post(url, { token });
  return res.data;
};

export const resetPassword = (user, successCallback, errorCallback) => (
  dispatch
) => {
  const url = getResetPasswordUrl();
  axios
    .post(url, user)
    .then((response) => {
      dispatch(clearErrors());
      successCallback(response.data.message);
    })
    .catch((error) => {
      if (error.response) {
        dispatch(setErrors(error.response.data));
        errorCallback(true);
      } else {
        errorCallback(false);
      }
    });
};

export const verifyUser = (token, successCallback, errorCallback) => (
  dispatch
) => {
  const url = getVerificationUrl();
  axios
    .post(url, { token })
    .then((response) => {
      successCallback();
    })
    .catch((error) => {
      errorCallback();
    });
};
