import React from 'react';
import {getSpacingUnit} from "../../utils/utility";
import withStyles from "@material-ui/core/styles/withStyles";
import Logo from "../../components/Logo";
import Paper from "@material-ui/core/Paper";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import param from "../../assets/particles.json";
import Particles from "react-particles-js";

const styles = theme => {
  const spacingUnit = getSpacingUnit(theme);
  return {
    background: {
      display: 'flex',
      flexDirection: 'column',
      width: '100vw',
      height: '100vh',
      // background: '#232741',
      background: 'url(https://cdn.pixabay.com/photo/2018/03/29/19/34/northern-lights-3273425_1280.jpg) no-repeat 50%',
      // background: 'url(https://image.freepik.com/free-photo/popular-abstract-background-shining-blue-dust-particles-stars-sparks_75775-385.jpg) no-repeat 50%',
      backgroundSize: 'cover',
      backgroundPositionX: 'center',
      '& > div': {
        height: '100vh'
      }

    },
    main: {
      transform: 'translate(-50%, -50%)',
      left: '50%',
      top: '50%',
      position: 'absolute',
      width: `calc(100% - ${spacingUnit * 4}px);`,
      display: 'block',
      [theme.breakpoints.up(440 + spacingUnit * 3 * 2)]: {
        width: 440,
      }
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: spacingUnit * 3,
      paddingTop: spacingUnit * 5,
      background: 'rgba(3, 12, 26, 0.6);'
    },
    message: {
      marginTop: spacingUnit * 3,
      fontSize: '15px',
      textAlign: 'center',
    },
    white: {
      color: 'white',
    },
    oneRow: {
      display: 'flex',
      width: '100%',
      alignItems: 'center'
    },
    halfLine: {
      borderBottom: '1px solid #384a66',
      height: 0,
      width: '100%',
      margin: spacingUnit
    },
    logo: {
      width: 160,
      height: 60,
      color: theme.palette.subForeColor5
    }
  };
};


const MaintenancePage = ({classes}) => {

  return (
    <div className={classes.background}>
      <Particles
        params={param}
      />
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <div className={classes.oneRow}>
            <div className={classes.halfLine} />
            <Logo className={classes.logo} />
            <div className={classes.halfLine} />
          </div>
          <Typography className={classNames(classes.white, classes.message)}>
            Dear valued customers<br /><br />
            We are currently running system upgrade and adding new products to the platform. You will be able to access Orakle again from Sunday the 19th of December.<br /><br />
            Thank you for your cooperation<br />
            Orakle Team<br />
          </Typography>
        </Paper>
      </main>
    </div>
  )
};


export default withStyles(styles)(MaintenancePage);
