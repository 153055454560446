import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import { verifyUser } from '../../../actions/auth';
import { pageLinks } from '../../../utils/links';
import { getSpacingUnit } from '../../../utils/utility';

const styles = theme => {
    const spacingUnit = getSpacingUnit(theme);
    return {
        main: {
            width: 'auto',
            display: 'block',
            marginLeft: spacingUnit * 3,
            marginRight: spacingUnit * 3,
            [theme.breakpoints.up(400 + spacingUnit * 3 * 2)]: {
                width: 400,
                marginLeft: 'auto',
                marginRight: 'auto'
            }
        },
        paper: {
            marginTop: spacingUnit * 8,
            marginBottom: spacingUnit * 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: `${spacingUnit * 2}px ${spacingUnit * 3}px ${spacingUnit *
                3}px `
        },
        avatar: {
            backgroundColor: theme.palette.mainRedColor,
            width: '3.5rem',
            height: '3.5rem',
            margin: '1rem'
        },
        green: {
            backgroundColor: '#13a544'
        },
        form: {
            width: '100%',
            marginTop: spacingUnit
        },
        submit: {
            marginTop: spacingUnit * 3
        },
        signUp: {
            width: '100%',
            padding: spacingUnit * 2
        },
        center: {
            textAlign: 'center'
        },
        icon: {
            fontSize: '2rem'
        }
    };
};

class Verification extends Component {
    state = {
        isSuccess: false,
        isFailed: false
    };

    signUpHandler = () => {
        this.props.history.push(pageLinks.Register.url);
    };

    signInHandler = () => {
        this.props.history.push(pageLinks.Login.url);
    };

    componentDidMount() {
        const { verifyUser } = this.props;
        const successCallback = () => {
            this.setState({isSuccess: true});
        };
        const errorCallback = () => {
            this.setState({isFailed: true});
        };
        verifyUser(
            this.props.match.params.token,
            successCallback,
            errorCallback
        );
    }

    render() {
        const { classes } = this.props;
        const { isSuccess, isFailed } = this.state;
        return (
            <main className={classes.main}>
                <Paper className={classes.paper}>
                    <Avatar
                        className={classNames(
                            classes.avatar,
                            isSuccess && classes.green
                        )}>
                        {isSuccess ? (
                            <VerifiedUserIcon className={classes.icon} />
                        ) : (
                            <LockOutlinedIcon className={classes.icon} />
                        )}
                    </Avatar>
                    <Typography
                        component='h1'
                        variant='h5'
                        className={classes.center}>
                        {isSuccess
                            ? 'Your email has been verified successfully.'
                            : 'Failed in Email Verification'}
                    </Typography>
                    {isSuccess && (
                        <Button
                            type='button'
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                            onClick={this.signInHandler}>
                            Sign In
                        </Button>
                    )}
                    {isFailed && (
                        <Button
                            type='button'
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                            onClick={this.signUpHandler}>
                            Sign Up
                        </Button>
                    )}
                </Paper>
            </main>
        );
    }
}

const mapActionToProps = {
    verifyUser: verifyUser
};

export default connect(
    null,
    mapActionToProps
)(withStyles(styles)(Verification));
