
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import VerifyIcon from '@material-ui/icons/Link';
import UnVerifyIcon from '@material-ui/icons/LinkOff';
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';

import AddEditDialog from './AddEditDialog';
import CustomMUIDataTable from '../../../components/CustomMUIDataTable';
import PlusAddButton from '../../../components/PlusAddButton';
import EditIconButton from '../../../components/EditIconButton';
import RemoveIconButton from '../../../components/RemoveIconButton';
import ConfirmDialog from '../../../components/ConfirmDialog';
import CustomSwitchButton from '../../../components/CustomSwitchButton';
import { removeUser, removeUsers, editUser, getPageUsers } from '../../../services/user';
import { getRoleLabels, removeItemWithSlice, showErrorToast, getMomentTime, getSpacingUnit } from '../../../utils/utility';
import { commonMUITableOptions } from '../../../utils/styles';
import { debounce } from '../../../utils/utility';
import { setLoadingStatus } from '../../../actions/loading';
import constants from '../../../constants';

const styles = theme => {
    const spacingUnit = getSpacingUnit(theme);
    return {
        icon: {
            marginRight: 0,
            cursor: 'pointer'
        },
        pagination: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
        },
        range: {
            marginRight: spacingUnit
        }
    };
};

class UserManage extends Component {
    state = {
        addEditDialogOpened: false,
        confirmDialogOpened: false,
        dialogData: null,
        removeId: null,
        removeIdList: null,   
        verifyButtonDisabled: false,
        
        //page users
        pageUsers: [],
        pageNumber: 1,
        countOfAllUsers: 0,
        countOfPages: 0,
        rowsPerPage: 10,
        searchText: ''
    };

    async componentDidMount() {
        const { pageNumber } = this.state;       
        this.loadPage(pageNumber);
    }

    loadPage = async (pageNumber, searchQuery) => {
        const { setLoadingStatus } = this.props;
        setLoadingStatus({loading: true, text: 'Loading users...'});
        try {
            const { data: { pageUsers, countOfPages, countOfAllUsers } } = await getPageUsers(pageNumber, searchQuery);
            setLoadingStatus({ loading: false });
            this.setState({
                pageUsers,
                countOfPages,
                countOfAllUsers,
                pageNumber: pageNumber
            });
        } catch (error) {
            console.log(
                "ant : [containers Live Educators componentDidMount] error => ",
                error
            );
            setLoadingStatus({ loading: false });
            this.setState({ loading: true });
            if (error.response) {
                const { message } = error.response.data;
                showErrorToast(message);
            }
        }
    };

    createTableData = users => {
        const tableData = users.map(user => {
            const { name, email, roles, verified, _id, loggedLastTime } = user;
            const type = getRoleLabels(roles);
            const labels = ['Verified', 'Unverified'];
            let lTime = '';
            if(loggedLastTime) {
                lTime = getMomentTime(loggedLastTime, constants.timeFormat);
            }
            const row = [
                name,
                email,
                type.join(', '),
                labels[verified ? 0 : 1],
                lTime,
                _id
            ];
            return row;
        });
        return tableData;
    };

    columns = () => [
        {name: 'Name', options: {filter: false}},
        {name: 'Email', options: {filter: false}},
        {name: 'Type'},
        {name: 'Status'},
        {name: 'Last Login Time'},
        {
            name: 'Action',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    const { verifyButtonDisabled, pageUsers } = this.state;
                    const user = pageUsers.find(user => user._id === value);
                    return (
                        <div style={{display: 'flex'}}>
                            <CustomSwitchButton 
                                color='primary'
                                flag={user.verified} 
                                trueIcon={<VerifyIcon />} 
                                falseIcon={<UnVerifyIcon />} 
                                disabled={verifyButtonDisabled} 
                                onClick={() => this.verifyUserHandler(value)} />
                            <EditIconButton color='primary' onClick={() => this.openEditDialogHandler(value)} />
                            <RemoveIconButton color='primary' onClick={() => this.openCloseConfirmDialogHandler(true, value)} />
                        </div>
                    );
                }
            }
        }
    ];
  
    changePage = (event, page) => {
        this.loadPage(page + 1);
    };

    openAddDialogHandler = () => {
        this.openCloseAddEditDialogHandler(true);
    };
    openEditDialogHandler = userId => {
        const { pageUsers } = this.state;
        const dialogData = pageUsers.find(user => user._id === userId);
        this.openCloseAddEditDialogHandler(true, dialogData);
    };
    closeAddEditDialogHandler = () => {
        this.openCloseAddEditDialogHandler(false);
    };
    openCloseAddEditDialogHandler = (opened, dialogData=null) => {
        this.setState({addEditDialogOpened: opened, dialogData});
    };
    openCloseConfirmDialogHandler = (opened, removeId, removeIdList) => {
        this.setState({confirmDialogOpened: opened, removeId, removeIdList});
    };

    removeUserHandler = async removeId => {
        try {
            const response = await removeUser(removeId);
            const { data: userFromBackend } = response;
            this.removeUser(userFromBackend);
        } catch (error) {
            if (error.response) {
                const { message } = error.response.data;
                showErrorToast(message);
            }
        }
    };

    removeMultiUsersHandler = async ids => {
        try {
            const response = await removeUsers(ids);
            const { data: result } = response;
            if (result.ok) {
                this.removeMultiUsers(ids);
            }
        } catch (error) {
            if (error.response) {
                const { message } = error.response.data;
                showErrorToast(message);
            }
        }
    };

    confirmRemoveDialogHandler = () => {
        const { removeId, removeIdList } = this.state;
        if (removeId) {
            this.removeUserHandler(removeId)
        } else if (removeIdList && removeIdList.length > 0) {
            this.removeMultiUsersHandler(removeIdList);
        }
    }

    verifyUserHandler = async userId => {
        try {
            let { pageUsers } = this.state;
            pageUsers = [...pageUsers];
            const user = pageUsers.find(user => user._id === userId)
            const { _id, email, verified } = user;
            const data = {
                _id,
                email,
                password: '',
                verified: !verified
            };

            this.setState({verifyButtonDisabled: true});
            const response = await editUser(data);
            const { data: userFromBackend } = response;

            const targetIndex = pageUsers.findIndex(user => (
                user._id === userFromBackend._id
            ));
            pageUsers[targetIndex] = userFromBackend;
            this.setState({pageUsers});
        } catch (error) {
            if (error.response) {
                const { message } = error.response.data;
                showErrorToast(message);
            }
        }
        this.setState({verifyButtonDisabled: false});
    };

    addEditUserHandler = userFromBackend => {
        let { pageUsers, pageNumber, searchText } = this.state;

        const targetIndex = pageUsers.findIndex(user => (
            user._id === userFromBackend._id
        ));
        if (targetIndex >= 0) {
            pageUsers[targetIndex] = userFromBackend;        
            this.setState({ pageUsers });
        } else {
            this.loadPage(pageNumber, searchText);
        }
    };

    removeUser = userFromBackend => {
        if (!userFromBackend) {
            return;
        }
        let { pageUsers, pageNumber, rowsPerPage, countOfAllUsers, searchText } = this.state;

        const targetIndex = pageUsers.findIndex(user => (
            user._id === userFromBackend._id
        ));
        pageUsers = removeItemWithSlice(pageUsers, targetIndex);

        if ((pageNumber - 1) === parseInt(countOfAllUsers/rowsPerPage)) { 
            if (pageUsers.length > 0) {
                this.setState({pageUsers, countOfAllUsers: countOfAllUsers - 1});
            } else {
                this.loadPage(pageNumber - 1, searchText);
            }
        } else {
            this.loadPage(pageNumber, searchText); 
        }
    };

    removeMultiUsers = ids => {
        if (!ids) {
            return;
        }
        let { pageUsers } = this.state;

        let newUsers = pageUsers.filter(item => !ids.includes(item._id));
        this.setState({pageUsers: newUsers});
    };

    movePrevHandler = () => {
        const { pageNumber, searchText } = this.state;
        if (pageNumber > 1) {
            this.loadPage(pageNumber - 1, searchText);
        }
    };

    moveNextHandler = () => {
        const { pageNumber, countOfAllUsers, rowsPerPage, searchText } = this.state;
        if (pageNumber * rowsPerPage < countOfAllUsers) {
            this.loadPage(pageNumber + 1, searchText);
        }
    };

    moveFirstHandler = () => {
        const { pageNumber, searchText } = this.state;
        if (pageNumber !== 1) {
            this.loadPage(1, searchText);
        }
    }

    moveLastHandler = () => {
        const { countOfAllUsers, rowsPerPage, pageNumber, searchText } = this.state;
        const lastPageNumber = parseInt(countOfAllUsers / rowsPerPage) + (countOfAllUsers % rowsPerPage ? 1 : 0);
        if (pageNumber !== lastPageNumber) {
            this.loadPage(lastPageNumber, searchText);
        }
    }

    getCurrentRange = () => {
        const { pageNumber, pageUsers, countOfAllUsers, rowsPerPage } = this.state;
        return `${countOfAllUsers === 0 ? 0 : (pageNumber - 1) * rowsPerPage + 1} - ${(pageNumber - 1) * rowsPerPage + pageUsers.length} of ${countOfAllUsers}`; 
    };

    render() {
        const { classes } = this.props;
        const { addEditDialogOpened, dialogData, confirmDialogOpened, removeIdList, pageUsers, countOfAllUsers } = this.state;
        const tableData = this.createTableData(pageUsers);

        const options = {
            ...commonMUITableOptions,
            selectableRows: 'multiple',
            count: countOfAllUsers,
            onRowsDelete: rowsDeleted => {
                const { pageUsers } = this.state;
                const idList = pageUsers.map(item => item._id);
                const removeIdList = idList.filter((id, index) => rowsDeleted.lookup[index]);
                this.openCloseConfirmDialogHandler(true, null, removeIdList);
            },
            customToolbar: () => {
                return (
                    <PlusAddButton onClick={this.openAddDialogHandler} />
                );
            },
            customFooter: () => {
                return (
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={1000}>
                                <div className={classes.pagination}>
                                    <div className={classes.range}>
                                        {this.getCurrentRange()}
                                    </div>
                                    <IconButton
                                        onClick={this.moveFirstHandler}>
                                        <FirstPageIcon className={classes.icon} />
                                    </IconButton>
                                    <IconButton
                                        onClick={this.movePrevHandler}>
                                        <ChevronLeftIcon className={classes.icon} />
                                    </IconButton>
                                    <IconButton
                                        onClick={this.moveNextHandler}>
                                        <ChevronRightIcon className={classes.icon} /> 
                                    </IconButton>
                                    <IconButton
                                        onClick={this.moveLastHandler}>
                                        <LastPageIcon className={classes.icon} /> 
                                    </IconButton>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                )
            },
            filter: true,
            page: 0,
            responsive: 'vertical',
            onSearchChange: debounce(searchText => {
                this.setState({ searchText });
                this.loadPage(1, searchText);
            }, 500),
            onSearchClose: () => {
                if (this.state.searchText) {
                    this.setState({searchText: ''});
                    this.loadPage(1);
                }
            },
            customSearch: () => {
                return true;
            },
        };

        return (
            <Fragment>
                { addEditDialogOpened && (
                    <AddEditDialog
                        manageTitle='User'
                        opened={addEditDialogOpened}
                        isAddDialog={dialogData ? false : true}
                        dialogData={dialogData}
                        addEditUser={this.addEditUserHandler}
                        closed={this.closeAddEditDialogHandler} />
                ) }
                <CustomMUIDataTable
                    title='Users'
                    data={tableData}
                    columns={this.columns()}
                    options={options} />
                <ConfirmDialog
                    isMultiple={!!removeIdList}
                    opened={confirmDialogOpened}
                    closed={() => this.openCloseConfirmDialogHandler(false)}
                    confirmed={this.confirmRemoveDialogHandler} />
            </Fragment>
        );
    }
}

const mapActionToProps = {
    setLoadingStatus
};

export default connect(
    null,
    mapActionToProps
)(withStyles(styles, { withTheme: true })(UserManage));
