import React, {useState} from 'react';
import DialogWrapper from "../../../../hoc/DialogWrapper";
import DialogActionsWrapper from "../../../../hoc/DialogActionsWrapper";
import CustomTextButton from "../../../../components/CustomTextButton";
import OutlinedButton from "../../../../components/OutlinedButton";
import constants from "../../../../constants";
import EngulfingAndOPList from "../../../../components/EngulfingOrOPList";


const EngulfingAndOPConfig = props => {

  const {
    addon,
    onSubmit,
    onClose,
    subscriptions = [],
  } = props;


  const [config, setConfig] = useState({
    available: addon.engulfingAndOPConfig ? addon.engulfingAndOPConfig.available : constants.addonEngulfingAndOPConfig.available,
    default_: addon.engulfingAndOPConfig ? addon.engulfingAndOPConfig.default_ : constants.addonEngulfingAndOPConfig.default_,
    premium: addon.engulfingAndOPConfig ? addon.engulfingAndOPConfig.premium : constants.addonEngulfingAndOPConfig.premium,
  });

  const handleChange = (type, data) => {
    setConfig(prev => ({...prev, [type]: data}));
  };

  const handleSubmit = () => {
    onSubmit({
      ...addon,
      engulfingAndOPConfig: config,
    });
    onClose();
  };

  return (
    <DialogWrapper
      opened={true}
      dialogTitle={`Engulfing and OP indications available for ${props.addon.name}`}
      dialogActions={
        <DialogActionsWrapper>
          <CustomTextButton onClick={onClose}>
            Cancel
          </CustomTextButton>
          <OutlinedButton
            type='button'
            disabled={false}
            onClick={handleSubmit}
          >
            Save
          </OutlinedButton>
        </DialogActionsWrapper>
      }
    >
      <EngulfingAndOPList
        configMode
        subscriptions={subscriptions}
        filters={config.available}
        defaultFilters={config.default_}
        premiumFilters={config.premium}
        onChange={filters => handleChange('available', filters)}
        onChangeDefault={filters => handleChange('default_', filters)}
        onChangePremium={filters => handleChange('premium', filters)}
      />
    </DialogWrapper>
  );
};

export default EngulfingAndOPConfig;
