
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

import OutlinedButton from '../OutlinedButton';
import { getSpacingUnit } from '../../utils/utility';

const styles = theme => {
    const spacingUnit = getSpacingUnit(theme);
    return {
        addTableButton: {
            marginLeft: spacingUnit * 2.5,
            paddingRight: spacingUnit * 1.5
        },
        addIcon: {
            fontSize: spacingUnit * 2.25,
            marginLeft: spacingUnit * .5
        },
    };
};

const PlusAddButton = ({ classes, ...rest }) => {
    return (
        <OutlinedButton {...rest} passedClasses={classes.addTableButton}>
            <AddIcon className={classes.addIcon} />
            ADD
        </OutlinedButton>
    );
};

export default withStyles(styles, {withTheme: true})(PlusAddButton);
