import axios from 'axios';
import {getAddonsUrl, getOCTConfigUrl, getOCTChartUrl} from './endpoints';


export const getAddons = async () => {
  const res = await axios.get(getAddonsUrl());
  return res.data;
};

export const createAddon = async (addonData) => {
  const res = await axios.post(getAddonsUrl(), addonData);
  return res.data;
};

export const updateAddon = async (addonData) => {
  const res = await axios.put(getAddonsUrl(addonData._id), addonData);
  return res.data;
};

export const removeAddon = async (_id) => {
  const res = await axios.delete(getAddonsUrl(_id));
  return res.data;
};

export const getOCTConfig = async () => {
  const res = await axios.get(getOCTConfigUrl());
  return res.data;
};

export const getChartUrl = async (params) => {
  const res = await axios.get(getOCTChartUrl(), {params});
  return res.data.url;
};

export const getLineTools = async addonId => {
  const res = await axios.get(getAddonsUrl(addonId, 'lineTools'));
  return res.data;
};

export const updateLineTools = async ({addonId, lineTools, symbol, broker, timeframe}) => {
  const res = await axios.put(getAddonsUrl(addonId, 'lineTools'), {lineTools, symbol, broker, timeframe});
  return res.data;
};

export const getAddonById = async addonId => {
  const res = await axios.get(getAddonsUrl(addonId));
  return res.data;
};

export const generateSignalToken = async addonId => {
  const res = await axios.post(getAddonsUrl(addonId, 'signalToken'));
  return res.data;
};

export const createTgBot = async ({addonId, token}) => {
  const res = await axios.post(getAddonsUrl('tg-bot'), {addonId, token});
  return res.data;
};

export const updateTgBot = async ({_id, timeframes, symbols, patterns, isActive}) => {
  const res = await axios.put(getAddonsUrl('tg-bot', _id), {timeframes, symbols, patterns, isActive});
  return res.data;
};

export const getTgBot = async botId => {
  const res = await axios.get(getAddonsUrl('tg-bot', botId));
  return res.data;
};

export const removeTgBot = async ({addonId, botId}) => {
  const res = await axios.delete(getAddonsUrl('tg-bot', 'remove'), {
    params: {addonId, botId},
  });
  return res.data;
};
