import React, { Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import {Popover} from "@material-ui/core";
import SettingsIcon from '@material-ui/icons/Settings';
import ContainedButton from "../../../components/ContainedButton";
import HarmonicPatternsFiltersList
  from "../../../components/HarmonicPatternsFiltersList";

const styles = () => {
  return {
    container: {
      marginLeft: '10px',
    },
    selectedContainer: {
      marginLeft: '10px',
      backgroundColor: 'rgb(33, 150, 243)',
    },
  };
};

const HarmonicPatternFilter = (props) => {
  const { classes } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <ContainedButton
        onClick={handleClick}
        customClasses={anchorEl !== null ? classes.selectedContainer : classes.container}
      >
        <SettingsIcon />
        <span>&nbsp;Filter</span>
      </ContainedButton>
      <Popover
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={20}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <HarmonicPatternsFiltersList
          filters={props.filters}
          onChange={props.onChange}
          availableFilters={props.availableFilters}
          notificationsContext={props.notificationsContext}
          premiumLockedFilters={props.premiumLockedFilters}
        />
      </Popover>
    </Fragment>
  )
}

export default withStyles(styles, { withTheme: true })(HarmonicPatternFilter);
