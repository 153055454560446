
import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';

import OutlinedButton from '../OutlinedButton';
import CustomTextButton from '../CustomTextButton';

const styles = theme => {
    return {
        actions: {
            padding: 8
        },
        paper: {
            backgroundColor: theme.palette.subBackColor3
        }
    };
};

class ConfirmDialog extends Component {
    state = {
        confirmButtonDisabled: false
    };

    confirmHandler = async () => {
        const { confirmed, closed } = this.props;
        this.setState({confirmButtonDisabled: true});
        await confirmed();
        this.setState({confirmButtonDisabled: false});
        closed();
    };
    
    render () {
        const { classes, message, isMultiple, opened, closed } = this.props;
        const { confirmButtonDisabled } = this.state;

        const alertMessage = isMultiple ?
            'Do you really want to remove the selected items?' :
            'Do you really want to remove?';

        return (
            <Dialog
                classes={{paper: classes.paper}}
                open={opened}
                onClose={closed}
                fullWidth
                maxWidth='xs'
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title'>Confirm</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        {message || alertMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <CustomTextButton
                        onClick={closed}>
                        No
                    </CustomTextButton>
                    <OutlinedButton
                        disabled={confirmButtonDisabled}
                        onClick={this.confirmHandler}
                        autoFocus>
                        Yes
                    </OutlinedButton>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles, {withTheme: true})(ConfirmDialog);
