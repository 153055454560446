/* eslint-disable react/no-unused-prop-types */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import AddEditStrategyDialog from "./AddEditStrategyDialog";
import PlusAddButton from "../../../components/PlusAddButton";
import CustomMUIDataTable from "../../../components/CustomMUIDataTable";
import CopyButton from "../../../components/CopyButton";
import ConfirmDialog from "../../../components/ConfirmDialog";
import EditIconButton from "../../../components/EditIconButton";
import RemoveIconButton from "../../../components/RemoveIconButton";
import { removeStrategy, removeStrategies } from "../../../services/strategy";
import {
  getTimeframeName,
  getStrategyTypeLabel,
  showErrorToast,
  showInfoToast,
  checkAdminRole,
  getToolKey
} from "../../../utils/utility";
import { setLoadingStatus } from "../../../actions/loading";
import { setStrategies } from "../../../actions/strategy";
import { setSelectedStrategy, initializeSignals } from "../../../actions/tools";
import constants from "../../../constants";
import { commonMUITableOptions } from "../../../utils/styles";

class TradeStrategies extends Component {
  state = {
    addEditDialogOpened: false,
    confirmDialogOpened: false,
    dialogData: null,
    removeId: null,
    removeIdList: null,
  };

  async componentDidMount() {
    const { setLoadingStatus, setStrategies } = this.props;
    setLoadingStatus({ loading: true, text: "Loading..." });
    await setStrategies();
    setLoadingStatus({ loading: false });
  }

  // open close dialog handlers
  openAddDialogHandler = () => {
    this.openCloseAddEditDialogHandler(true);
  };
  openEditDialogHandler = (strategyId) => {
    const { strategies } = this.props;
    const targetStrategy = strategies.find(
      (strategy) => strategy._id === strategyId
    );
    this.openCloseAddEditDialogHandler(true, targetStrategy);
  };
  closeAddEditDialogHandler = () => {
    this.openCloseAddEditDialogHandler(false);
  };
  openCloseAddEditDialogHandler = (opened, dialogData = null) => {
    this.setState({ addEditDialogOpened: opened, dialogData });
  };
  openCloseConfirmDialogHandler = (opened, removeId, removeIdList) => {
    this.setState({ confirmDialogOpened: opened, removeId, removeIdList });
  };
  confirmRemoveStrategyHandler = () => {
    const { removeId, removeIdList } = this.state;
    if (removeId) {
      this.removeStrategyHandler(removeId);
    } else {
      this.removeMultiStrategiesHandler(removeIdList);
    }
  };

  removeMultiStrategiesHandler = async (strategyIds) => {
    const {
      selectedToolStrategy,
      setSelectedStrategy,
      initializeSignals,
      toolIndex
    } = this.props;

    const key = getToolKey(toolIndex);

    try {
      // TODO: * redux update
      await removeStrategies(strategyIds);
      if (selectedToolStrategy &&
        strategyIds.includes(selectedToolStrategy[key]._id)
      ) {
        setSelectedStrategy(toolIndex, null);
        initializeSignals();
      }
      
      showInfoToast(constants.notifications.SUCCESS_REMOVE_STRATEGY);
    } catch (error) {
      console.log(
        "vlad : [TradeStrategies removeStrategyHandler] error => ",
        error
      );
      showErrorToast(constants.notifications.FAIL_REMOVE_STRATEGY);
    }
  };

  removeStrategyHandler = async (strategyId) => {
    const {
      selectedToolStrategy,
      setSelectedStrategy,
      initializeSignals,
      toolIndex
    } = this.props;

    try {
      // TODO: * redux update
      await removeStrategy(strategyId);
      if (
        selectedToolStrategy &&
        strategyId === selectedToolStrategy._id
      ) {
        setSelectedStrategy(toolIndex, null);
        initializeSignals();
      }
      
      showInfoToast(constants.notifications.SUCCESS_REMOVE_STRATEGY);
    } catch (error) {
      console.log(
        "dav : [TradeStrategies removeStrategyHandler] error => ",
        error
      );
      showErrorToast(constants.notifications.FAIL_REMOVE_STRATEGY);
    }
  };

  columns = () => [
    {
      name: "Name",
      options: { filter: false },
    },
    {
      name: "Type",
    },
    {
      name: "Broker",
    },
    {
      name: "Symbol",
    },
    {
      name: "TimeFrame",
      options: { filter: false },
    },
    {
      name: "Token",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <CopyButton value={value} />;
        },
      },
    },
    {
      name: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: "flex" }}>
              <EditIconButton
                color="primary"
                onClick={() => this.openEditDialogHandler(value)}
              />
              <RemoveIconButton
                color="primary"
                onClick={() => this.openCloseConfirmDialogHandler(true, value)}
              />
            </div>
          );
        },
      },
    },
  ];

  options = {
    ...commonMUITableOptions,
    selectableRows: "multiple",
    onRowsDelete: (rowsDeleted) => {
      const { strategies } = this.props;
      const idList = strategies.map((item) => item._id);
      const removeIdList = idList.filter(
        (id, index) => rowsDeleted.lookup[index]
      );
      this.openCloseConfirmDialogHandler(true, null, removeIdList);
    },
    customToolbar: () => {
      return <PlusAddButton onClick={this.openAddDialogHandler} />;
    },
  };

  createTableData = (strategies) => {
    const { loggedInUser } = this.props;

    let filteredStrategies = strategies;
    if (!checkAdminRole(loggedInUser.roles)) {
      filteredStrategies = strategies.filter(
        (strategy) => strategy.userId === loggedInUser.id
      );
    }

    const tableData = filteredStrategies.map((strategy) => {
      const row = [
        strategy.name,
        getStrategyTypeLabel(strategy.type),
        strategy.broker && strategy.broker.name,
        strategy.symbol && strategy.symbol.name,
        getTimeframeName(strategy.timeframe),
        strategy.token,
        strategy._id,
      ];
      return row;
    });
    return tableData;
  };

  render() {
    const { brokers, symbols, loggedInUser, strategies } = this.props;
    const {
      addEditDialogOpened,
      dialogData,
      confirmDialogOpened,
      removeIdList,
    } = this.state;

    const tableData = this.createTableData(strategies);
    return (
      <Fragment>
        <CustomMUIDataTable
          title="Strategies"
          data={tableData}
          columns={this.columns()}
          options={this.options}
        />
        {addEditDialogOpened && (
          <AddEditStrategyDialog
            isAddDialog={dialogData ? false : true}
            manageTitle="Strategy"
            opened={addEditDialogOpened}
            dialogData={dialogData}
            loggedInUser={loggedInUser}
            brokers={brokers}
            symbols={symbols}
            closed={this.closeAddEditDialogHandler}
          />
        )}
        <ConfirmDialog
          isMultiple={!!removeIdList}
          opened={confirmDialogOpened}
          closed={() => this.openCloseConfirmDialogHandler(false)}
          confirmed={this.confirmRemoveStrategyHandler}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.auth.user,
  symbols: state.symbol.data,
  brokers: state.broker.data,
  strategies: state.strategy.strategies,
  selectedToolStrategy: state.tools.selectedToolStrategy,
});

const mapActionToProps = {
  setLoadingStatus,
  setStrategies,
  setSelectedStrategy,
  initializeSignals,
};

export default connect(mapStateToProps, mapActionToProps)(TradeStrategies);
