import axios from 'axios';
import {getSubscriptionsUrl} from './endpoints';


export const getSubscriptions = async () => {
  const res = await axios.get(getSubscriptionsUrl());
  return res.data;
};

export const createSubscription = async (subscriptionData) => {
  const res = await axios.post(getSubscriptionsUrl(), subscriptionData);
  return res.data;
};

export const updateSubscription = async (subscriptionData) => {
  const res = await axios.put(getSubscriptionsUrl(subscriptionData._id), subscriptionData);
  return res.data;
};

export const removeSubscription = async (_id) => {
  const res = await axios.delete(getSubscriptionsUrl(_id));
  return res.data;
};
