/* eslint-disable react/no-unused-prop-types */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import moment from "moment";

// import ToolsCardWrapper from "../../hoc/ToolsCardWrapper";
import ToolsCardContentWrapper from "../../hoc/ToolsCardContentWrapper";
import TransitionDialogWrapper from "../../hoc/TransitionDialogWrapper";
import AddIconButton from "../AddIconButton";
import DashboardIconButton from "../DashboardIconButton";
// import CustomCardHeader from "../CustomCardHeader";
import SignalList from "./SignalList";
import SignalsTable from "./SignalsTable";
import SignalsTab from "./SignalsTab";
import StrategyTimeFrameSelector from "../Tools/StrategyTimeFrameSelector";
import {
  showErrorToast,
  showInfoToast,
  checkAdminKindRole,
  checkAdminRole,
  convertToToolStratSignal,
  isInCurrentWeek,
  isSignalClosed,
  isEmpty,
} from "../../utils/utility";
import ConfirmDialog from "../ConfirmDialog";
import AddEditSignalDialog from "../Tools/AddEditSignalDialog";
import constants from "../../constants";
import CardWrapper from "../../hoc/CardWrapper";
import {
  getSignalsByStrategyId,
  removeSignal,
  removeSignals,
} from "../../services/signal";
import {
  updateSignal,
  removeStratSignal,
  removeStratSignals
} from "../../actions/tools";
import OutlinedButton from "../OutlinedButton";

const DefaultFilterStrategy = {
  _id: "all",
  timeframe: "All",
};

class SignalsCard extends Component {
  state = {
    signalsTableDialogOpened: false,
    removeSignalConfirmDialogOpened: false,
    removeSignalId: null,
    addEditSignalDialogOpened: false,
    signalDialogData: null,
    focusedSignalCategory: constants.signalCategory.ACTIVE,
    filterStrategy: DefaultFilterStrategy,
    allSignals: [],
    confirmDialogOpened: false,
    tabSignalIds: [],
  };

  async componentDidMount() {
    this.getToolSignals();
  }

  async componentDidUpdate(prevProps) {
    const { newSignal, newSignals, strategies } = this.props;

    if (
      (newSignal &&
        (!prevProps.newSignal || prevProps.newSignal._id !== newSignal._id ||
          prevProps.newSignal.openClose !== newSignal.openClose || prevProps.newSignal.sl !== newSignal.sl ||
          prevProps.newSignal.tp !== newSignal.tp)) || prevProps.strategies.length !== strategies.length) {
      this.getToolSignals();
    }
    if (JSON.stringify(prevProps.newSignals) !== JSON.stringify(newSignals)) {
      this.getToolSignals();
    }
  }
  getToolSignals = async () => {
    const { strategies } = this.props;
    let signals = [];
    for (const strategy of strategies) {
      const { data: stratSignals } = await getSignalsByStrategyId(strategy._id);
      signals = [...signals, ...stratSignals];
    }
    this.setState({ allSignals: signals });
  };

  openSignalsTableDialogHandler = () => {
    this.setState({ signalsTableDialogOpened: true });
  };

  closeSignalsTableDialogHandler = () => {
    this.setState({ signalsTableDialogOpened: false });
  };

  openCloseRemoveSingalConfirmDialogHandler = (opened, removeSignalId) => {
    this.setState({
      removeSignalConfirmDialogOpened: opened,
      removeSignalId,
    });
  };

  removeStratSignalHandler = async (signalId) => {
    try {
      const {
        selectedSignal,
        signalSelected,
        selectedStrategy,
        removeStratSignal,
      } = this.props;

      const response = await removeSignal(signalId);
      showInfoToast(constants.notifications.SUCCESS_REMOVE_SIGNAL);
      if (selectedSignal && signalId === selectedSignal.id) {
        signalSelected(null, true);
      }
      this.getToolSignals();
      const { data: removedSignalFromBackend } = response;
      if (removedSignalFromBackend.strategyId === selectedStrategy._id) {
        removeStratSignal(signalId, selectedStrategy.type);
      }
      this.props.onSignalAddedUpdated();
    } catch (error) {
      // TODO: * axios handling module
      console.log(
        "ray : [components SignalsCard removeStratSignalHandler] error =>",
        error
      );
      if (error.response) {
        const { message: errorMessage } = error.response.data;
        showErrorToast(errorMessage);
      }
    }
  };

  openAddSignalDialogHandler = () => {
    this.openCloseAddEditSignalDialogHandler(true);
  };
  openEditSignalDialogHandler = (signal) => {
    this.openCloseAddEditSignalDialogHandler(true, signal);
  };
  closeAddEditSignalDialogHandler = () => {
    this.openCloseAddEditSignalDialogHandler(false);
  };
  openCloseAddEditSignalDialogHandler = (opened, signalDialogData = null) => {
    this.setState({ addEditSignalDialogOpened: opened, signalDialogData });
  };

  signalCategoryChangeHandler = (tabbedSignalCategory) => {
    this.setState({ focusedSignalCategory: tabbedSignalCategory });
  };

  changeFilterStrategyHandler = (filterStrategy) => {
    this.setState({ filterStrategy });
  };

  updateSignalHandler = (signal) => {
    const { updateSignal, selectedStrategy, signalSelected } = this.props;
    this.getToolSignals();
    signalSelected(signal, true);
    if (signal.strategyId === selectedStrategy._id) {
      updateSignal(signal, selectedStrategy.type);
    }
  };

  openCloseConfirmDialogHandler = (opened, signalIds) => () => {
    this.setState({ confirmDialogOpened: opened, tabSignalIds: signalIds });
  };

  confirmRemoveAllSignalsHandler = async () => {
    const { selectedStrategy } = this.props;
    const { tabSignalIds } = this.state;
    await removeSignals(tabSignalIds);
    await this.getToolSignals();

    const tool = Object.keys(constants.tools).find(
      tool => tool.value === selectedStrategy.type
    );

    if (!isEmpty(tool)) {
      removeStratSignals(tabSignalIds, tool.value);
    }
    this.props.onSignalAddedUpdated();
  };

  render() {
    const {
      title,
      selectedStrategy,
      signalSelected,
      selectedSignal,
      selectedSymbolId,
      userRole,
      symbols,
      serverTime,
      height,
      strategies,
      stratSymbols,
    } = this.props;
    const {
      signalsTableDialogOpened,
      removeSignalConfirmDialogOpened,
      removeSignalId,
      addEditSignalDialogOpened,
      signalDialogData,
      focusedSignalCategory,
      filterStrategy,
      allSignals,
      confirmDialogOpened,
    } = this.state;
    const showActionButtons = checkAdminKindRole(userRole);
    const isAdmin = checkAdminRole(userRole);
    let availableSignals = allSignals.map((item) => {
      const symbol = symbols.find((symbol) => symbol._id === item.symbolId);
      const signal = { ...item, symbol: symbol && symbol.name };
      return convertToToolStratSignal(signal);
    });

    if (filterStrategy._id !== "all") {
      availableSignals = availableSignals.filter(
        (signal) => signal.strategyId === filterStrategy._id
      );
    }

    let tabSignals = [];

    if (focusedSignalCategory === constants.signalCategory.ACTIVE) {
      tabSignals = this.props.signals.filter((signal) => {
        return (
          isInCurrentWeek(
            serverTime,
            isSignalClosed(signal.signalKind)
              ? signal.closedTime
              : signal.time
          ) || !isSignalClosed(signal.signalKind)
        );
      });
    } else if (focusedSignalCategory === constants.signalCategory.ARCHIVE) {
      tabSignals = availableSignals.filter((signal) => {
        return (
          !isInCurrentWeek(
            serverTime,
            isSignalClosed(signal.signalKind)
              ? signal.closedTime
              : signal.time
          ) && signal.signalKind !== constants.signalKinds.ACTIVATED
        );
      });
      // const startOfMonth = moment.utc().subtract(1, 'months');
      // tabSignals = tabSignals.filter((signal) => {
      //   const time = isSignalClosed(signal.signalKind)
      //     ? (signal.closedTime ? signal.closedTime : signal.time)
      //     : signal.time;
      //   if (moment(time) >= startOfMonth) {
      //     return true;
      //   }
      //   return false;
      // });
    }
    tabSignals.sort((signal1, signal2) => {
      const signal1Time = isSignalClosed(signal1.signalKind)
        ? signal1.closedTime
        : signal1.time;
      const signal2Time = isSignalClosed(signal2.signalKind)
        ? signal2.closedTime
        : signal2.time;
      return moment(signal1Time) < moment(signal2Time) ? 1 : -1;
    });
    const tabSignalIds = tabSignals.map((signal) => signal._id);

    return (
      <div>
        <CardWrapper
          headerColor="primary"
          headerHeight="60px"
          title={title}
          cardWrapperType = {'signalList'}
          style={{
            flex: "1 1 auto",
            overflowY: "auto",
            minHeight: 0
          }}
          headerAction={
            <Fragment>
              {showActionButtons && (
                <AddIconButton onClick={this.openAddSignalDialogHandler} />
              )}
              <DashboardIconButton
                onClick={this.openSignalsTableDialogHandler}
              />
            </Fragment>
          }
        >
          <Grid container spacing={16} alignItems="flex-end">
            <Grid item xs={isAdmin ? 4 : 12} md={isAdmin ? 8 : 12}>
              <StrategyTimeFrameSelector
                label="Timeframe"
                selectedStrategy={filterStrategy}
                strategies={[DefaultFilterStrategy, ...strategies]}
                setStrategy={this.changeFilterStrategyHandler}
              />
            </Grid>
            {isAdmin && (
              <Grid item xs={4}>
                <OutlinedButton
                  variant="contained"
                  onClick={this.openCloseConfirmDialogHandler(
                    true,
                    tabSignalIds
                  )}
                  buttonType="small"
                >
                  Remove All
                </OutlinedButton>
              </Grid>
            )}
          </Grid>
          <SignalsTab
            focusedSignalCategory={focusedSignalCategory}
            signalCategoryChanged={this.signalCategoryChangeHandler}
            serverTime={serverTime}
          />
          <ToolsCardContentWrapper>
            <SignalList
              signals={tabSignals}
              symbols={symbols}
              allSymbols={this.props.allSymbols}
              selectedSignal={selectedSignal}
              onSignalSelect={this.props.onSignalSelect}
              signalSelected={signalSelected}
              showActionButtons={showActionButtons}
              selectedSymbolId={selectedSymbolId}
              onOpenEditSignal={this.openEditSignalDialogHandler}
              onOpenCloseRemoveSignalConfirm={
                this.openCloseRemoveSingalConfirmDialogHandler
              }
              focusedSignalCategory={focusedSignalCategory}
              height={height}
              strategies={strategies}
            />
          </ToolsCardContentWrapper>
          {signalsTableDialogOpened && (
            <TransitionDialogWrapper
              open={signalsTableDialogOpened}
              onClose={this.closeSignalsTableDialogHandler}
            >
              <SignalsTable
                title={title}
                signals={availableSignals}
                selectedSymbolId={selectedSymbolId}
                closeSignalsTableDialog={this.closeSignalsTableDialogHandler}
                signalSelected={signalSelected}
              />
            </TransitionDialogWrapper>
          )}
          {addEditSignalDialogOpened && (
            <AddEditSignalDialog
              manageTitle='Signal'
              lastBar={this.props.lastBar}
              addon={this.props.addon}
              chartParams={this.props.chartParams}
              allSymbols={this.props.allSymbols}
              onSignalAddedUpdated={this.props.onSignalAddedUpdated}


              selectedStrategy={selectedStrategy}
              selectedSymbolId={selectedSymbolId}
              isAddDialog={!signalDialogData}
              signalDialogData={signalDialogData}
              opened={addEditSignalDialogOpened}
              closed={this.closeAddEditSignalDialogHandler}
              symbols={stratSymbols}
              strategies={strategies}
              onUpdateSignal={this.updateSignalHandler}
              signalSelected={signalSelected}
            />
          )}
          <ConfirmDialog
            opened={removeSignalConfirmDialogOpened}
            closed={() => this.openCloseRemoveSingalConfirmDialogHandler(false)}
            confirmed={() => this.removeStratSignalHandler(removeSignalId)}
          />
          <ConfirmDialog
            opened={confirmDialogOpened}
            message={`Are you sure to delete all ${tabSignalIds.length} signals?`}
            closed={this.openCloseConfirmDialogHandler(false)}
            confirmed={this.confirmRemoveAllSignalsHandler}
          />
        </CardWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userRole: state.auth.user.roles,
  serverTime: state.tools.serverTime,
  height: state.chart.tvHeight,
});

const mapActionToProps = {
  updateSignal,
  removeStratSignal,
  removeStratSignals
};

export default connect(mapStateToProps, mapActionToProps)(SignalsCard);
