import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";

import HighChartCard from "../../../components/HighChartCard";
import DisplayBoard from "../../../components/DisplayBoard";
import ReportSignalsTable from "../../../components/SignalsCard/ReportSignalsTable";
import { isValidDarkTheme } from "../../../utils/styles";
import { setStrategies } from "../../../actions/strategy";
import {
  setStrategyType,
  setChainedStrategies,
  setStratAllSignals,
} from "../../../actions/tools";
import { setLoadingStatus } from "../../../actions/loading";
import { getSignalReport } from "../../../services/signal";
import constants from "../../../constants";
import {
  getAnalyzedSignalReport,
  getUTCDateString,
  getHighChartSeries,
  getDateYear,
  convertToToolStratSignal,
  calcPips,
  getToolKey
} from "../../../utils/utility";
import { sendSubscribeEvent } from "../../../services/socket-api";
import { setTimeFrame, setChartData } from "../../../actions/chart";

const values = (signalReport = {}) => {
  const {
    signalCount = 0,
    pipsSum = 0,
    aveLoss = 0,
    aveWin = 0,
    bestStrategyPips = 0,
    worstStrategyPips = 0,
    bestSignalDate = null,
    worstSignalDate = null,
    wonCount = 0,
    lossCount = 0
  } = signalReport;

  return [
    { label: "Signal count", value: signalCount },
    {
      label: "Won/Loss Count ",
      value: `${wonCount} (${wonCount && signalCount !== 0 ? (wonCount / signalCount * 100).toFixed(1) : 0} %) / 
      ${lossCount} (${lossCount && signalCount !== 0 ? (lossCount / signalCount * 100).toFixed(1) : 0} %) `,
    },
    { label: "Pips sum", value: pipsSum },
    { label: "Average Loss", value: -aveLoss.toFixed(2) },
    { label: "Average Win", value: aveWin.toFixed(2) },
    {
      label: "Best Strategy",
      value: `${getUTCDateString(bestSignalDate)} - ${bestStrategyPips}`,
    },
    {
      label: "Worst Strategy",
      value: `${
        worstSignalDate ? getUTCDateString(worstSignalDate) : ""
        } - ${worstStrategyPips}`,
    }
  ];
};

const ReportManage = ({
  symbols,
  selectedToolSymbolId,
  themeType,
  setStrategyType,
  setStrategies,
  setChainedStrategies,
  setStratAllSignals,
  selectedToolStrategy,
  setTimeFrame,
  setChartData,
  setLoadingStatus,
}) => {
  const [signalReport, setSignalReport] = useState();
  const [chartSeries, setChartSeries] = useState([]);
  const [displaySignals, setDisplaySignals] = useState([]);
  const [reportDate, setReportDate] = useState(new Date());
  const [toolIndex, setToolIndex] = useState(constants.COSMICCLOUD);
  const toolKey = getToolKey(constants.COSMICCLOUD);

  const fontColor = {
    color: isValidDarkTheme(themeType) ? "#fff" : "#1d222f",
  };

  const options = (series) => ({
    chart: {
      type: "column",
      backgroundColor: isValidDarkTheme(themeType) ? "#1d222f" : "#fff",
      height: 465
    },
    title: {
      text: "Monthly Gain(Pips)",
      style: fontColor,
    },
    xAxis: {
      type: "category",
      labels: {
        style: fontColor,
      },
    },
    yAxis: {
      title: {
        align: "high",
        offset: 0,
        text: `<h3 style='text-decoration: underline'>${getDateYear(
          reportDate
        )}</h3>`,
        rotation: 0,
        x: 30,
        y: -40,
        useHTML: true,
        style: fontColor,
      },
      labels: {
        style: fontColor,
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.y:f}",
        },
      },
      boxplot: {
        fillColor: "#123490",
      },
    },
    series: [
      {
        colorByPoint: true,
        data: series,
      },
    ],
  });

  const init = async () => {
    setStrategyType(toolIndex);
    await setStrategies();
    await setChainedStrategies(selectedToolStrategy, toolIndex);
    await setStratAllSignals(
      selectedToolStrategy[toolKey],
      selectedToolSymbolId[toolKey],
      0,
      true
    );
    if (selectedToolSymbolId[toolKey]) {
      sendSubscribeEvent(selectedToolSymbolId[toolKey]);
    }

    fetchReport();
  };

  const fetchReport = async () => {
    const year = getDateYear(reportDate);
    try {
      setLoadingStatus({ loading: true, text: "Loading Report Data..." });
      const {
        data: {
          winPips,
          lossPips,
          bestPips,
          worstPips,
          countWinPips,
          countLossPips,
          bestSignalDate,
          worstSignalDate,
          filteredSignals,
          wonCount,
          lossCount
        },
      } = await getSignalReport(year, toolIndex);
      const signalReportValue = {
        ...getAnalyzedSignalReport(
          winPips,
          lossPips,
          bestPips,
          worstPips,
          countWinPips,
          countLossPips
        ),
        bestSignalDate,
        worstSignalDate,
        wonCount,
        lossCount
      };
      setSignalReport(signalReportValue);
      const series = getHighChartSeries(winPips, lossPips, year);
      setChartSeries(series);

      let signals = filteredSignals.map((signal) => {
        const symbol = symbols.find((symbol) => symbol._id === signal.symbolId);
        let updatedSignal = {
          ...signal,
          symbol: symbol && symbol.name,
        };
        if (updatedSignal.pips === null) {
          updatedSignal = {
            ...updatedSignal,
            pips: calcPips(updatedSignal, symbol.tick, symbol.digits),
          };
        }
        return convertToToolStratSignal(updatedSignal);
      });
      setDisplaySignals(signals);
    } catch (error) {
      console.log("ant : [ReportManage fetchReport] error => ", error);
    }

    setLoadingStatus({ loading: false });
  };

  useEffect(() => {
    init();
  }, [toolIndex]);

  useEffect(() => {
    fetchReport();
  }, [reportDate]);

  useEffect(() => {
    if (selectedToolSymbolId[toolKey]) {
      sendSubscribeEvent(selectedToolSymbolId[toolKey]);
      setChartData(selectedToolStrategy[toolKey], selectedToolSymbolId[toolKey]);
    }
  }, [toolIndex]);

  useEffect(() => {
    if (selectedToolStrategy[toolKey]) {
      setTimeFrame(selectedToolStrategy[toolKey].timeframe);
    }
  }, [toolIndex]);

  return (
    <Grid container spacing={16} alignItems="stretch">
      <Grid item xs={12} sm={12} md={8}>
        <HighChartCard
          title="Monthly Analytics"
          options={options(chartSeries)}
          reportDate={reportDate}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <DisplayBoard
          title="Trade Ideas: Summary"
          values={values(signalReport)}
          reportDate={reportDate}
          onChangeReportDate={setReportDate}
          toolIndex={toolIndex}
          setToolIndex={setToolIndex}
        />
      </Grid>
      <Grid item xs={12}>
        <ReportSignalsTable
          title="Signals"
          signals={displaySignals}
          selectedSymbolId={selectedToolSymbolId}
          closeSignalsTableDialog={() => { }}
          signalSelected={() => { }}
          reportDate={reportDate}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  themeType: state.theme.themeType,
  selectedToolSymbolId: state.tools.selectedToolSymbolId,
  selectedToolStrategy: state.tools.selectedToolStrategy,
  symbols: state.symbol.data,
});

const mapActionToProps = {
  setStrategyType,
  setStrategies,
  setChainedStrategies,
  setStratAllSignals,
  setChartData,
  setTimeFrame,
  setLoadingStatus,
};

export default connect(mapStateToProps, mapActionToProps)(ReportManage);
