import React, {useMemo, useState} from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  getPremiumLockedFilters,
  getSpacingUnit,
} from "../../../utils/utility";
import LinksSelector from "../../../components/Tools/LinksSelector";
import ContainedButton from "../../../components/ContainedButton";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import RuleDialog from "../../../components/TVChartContainer/RuleDialog";
import HarmonicPatternFilter from "../HarmonicPatternFilter";
import PublishIcon from "@material-ui/icons/Publish";
import FullScreenIcon from '@material-ui/icons/Fullscreen';
import SplitScreenIcon from '@material-ui/icons/HorizontalSplit';
import HarmonicPatternsDivergence from "../HarmonicPatternsDivergence";
import HarmonicPattersNotifications from "../HarmonicPatternsNotifications";
import AddonIndication from "../AddonIndication";
import Marketplace from "../Marketplace";

const styles = (theme) => {
  const spacingUnit = getSpacingUnit(theme);
  return {
    container: {
      height: '5%',
      display: 'flex',
      flexDirection: 'row',
    },
    chartParamsContainer: {
      flex: 1,
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    miscContainer: {
      flex: 1,
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
    },
    selectWrapper: {
      marginRight: '20px',
    },
    rule: {
      marginLeft: spacingUnit
    }
  }
};


const AddonToolbar = ({
  classes,
  links = [],
  rules,
  harmonicFilters = null,
  handleHarmonicFiltersChange = null,
  handlePublish = null,
  harmonicPatterns = null,
  hiddenPatterns = [],
  toggleHiddenPattern = null,
  activePattern = null,
  toggleActivePattern = null,
  addonId,
  handlePatternNotificationClick,
  addonHarmonicFilters,
  technicalIndicator,
  onRequestFullScreen,
  toggleEagleView,
  harmonicNotifications,
  harmonicNotificationsCount,
  resetHarmonicNotificationsCount,
  addon,
  indicationFilters,
  onIndicationFiltersChange,
  userSubscriptions,
  handleSelectMarketplaceItem,
  loggedInUserId,
  setLoadingStatus,
}) => {

  const [showRules, setShowRules] = useState(false);

  // some addons may have additional indicators but no actual harmonic patterns.
  const isValidHPAddon = useMemo(() => {
    if (!addonHarmonicFilters) {
      return false;
    }
    const availablePatterns = addonHarmonicFilters.names.filter(n => !['engulfing', 'order_partition'].includes(n));
    return availablePatterns.length > 0;
  }, [addonHarmonicFilters]);

  let harmonicPremiumLockedFilters = {};
  if (addon.harmonicConfig) {
    harmonicPremiumLockedFilters = getPremiumLockedFilters(addon.harmonicConfig.premium, userSubscriptions);
  }
  let indicationPremiumLockedFilters = {};
  if (addon.engulfingAndOPConfig) {
    indicationPremiumLockedFilters = getPremiumLockedFilters(addon.engulfingAndOPConfig.premium || {}, userSubscriptions);
  }

  return (
    <div className={classes.container}>
      <div className={classes.chartParamsContainer}>
        <div />
        {
          addonHarmonicFilters && (
            <HarmonicPatternFilter
              filters={harmonicFilters}
              onChange={handleHarmonicFiltersChange}
              availableFilters={addonHarmonicFilters}
              premiumLockedFilters={harmonicPremiumLockedFilters}
            />
          )
        }
        <AddonIndication
          filters={indicationFilters}
          onFiltersChange={onIndicationFiltersChange}
          addon={addon}
          premiumLockedFilters={indicationPremiumLockedFilters}
        />
        {
          isValidHPAddon && (
            <HarmonicPatternsDivergence
              patterns={harmonicPatterns}
              hidden={hiddenPatterns}
              toggleHidden={toggleHiddenPattern}
              activePattern={activePattern}
              toggleActivePattern={toggleActivePattern}
              technicalIndicator={technicalIndicator}
            />
          )
        }
        <Marketplace
          addonId={addonId}
          handleSelectItem={handleSelectMarketplaceItem}
          loggedInUserId={loggedInUserId}
          setLoadingStatus={setLoadingStatus}
        />
      </div>
      <div className={classes.miscContainer}>
        {
          isValidHPAddon && (
            <ContainedButton
              disabled={false}
              onClick={toggleEagleView}
            >
              <SplitScreenIcon />
              <span>&nbsp;Eagle view</span>
            </ContainedButton>
          )
        }
        {
          links.length ? (
            <LinksSelector links={links || []} />
          ) : null
        }
        {
          isValidHPAddon && (
            <HarmonicPattersNotifications
              addonId={addonId}
              handlePatternNotificationClick={handlePatternNotificationClick}
              patterns={harmonicNotifications}
              count={harmonicNotificationsCount}
              resetCount={resetHarmonicNotificationsCount}
            />
          )
        }
        {
          handlePublish && (
            <ContainedButton
              disabled={false}
              onClick={handlePublish}
            >
              <PublishIcon />
              <span>&nbsp;Publish</span>
            </ContainedButton>
          )
        }
        <div className={classes.rule}>
          <ContainedButton
            onClick={() => setShowRules(true)}
          >
            <LibraryBooksIcon />
            <span>&nbsp;Rules</span>
          </ContainedButton>
          <ContainedButton
            disabled={false}
            onClick={onRequestFullScreen}
          >
            <FullScreenIcon />
          </ContainedButton>
        </div>
      </div>
      <RuleDialog
        opened={showRules}
        closed={() => setShowRules(false)}
        rules={rules}
      />
    </div>
  );
}

export default withStyles(styles)(AddonToolbar);
