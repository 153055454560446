import { SET_CURRENT_USER, EDIT_CURRENT_USER_INFO } from "../actions/types";
import { REHYDRATE } from "redux-persist";
import { isEmpty } from "../utils/utility";
import setAuthToken from "../services/security/setAuthToken";
import { jwtTokenKey } from "../utils/links";

const initialState = {
  isAuthenticated: false,
  user: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REHYDRATE: {
      const token = localStorage.getItem(jwtTokenKey);
      setAuthToken(token);
      return state;
    }
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case EDIT_CURRENT_USER_INFO: {
      const newUser = { ...state.user, ...action.payload };
      return {
        ...state,
        user: newUser,
      };
    }

    default:
      return state;
  }
}
