// exports.QUOTE_FEEDER = 0;
// exports.COSMICCLOUD = 2;
// exports.GANN_HARMONIC = 3;
// exports.ZONE_TO_ZONE = 4;
// exports.EURO_TITAN_CONSERVATIVE = 5;
// exports.NFX_EXPRESS = 6;
// exports.WALLSTREET_WOLF = 7;

// exports.DISCLAIMER_RULE = 100;

const tools = [
  {
    value: 2,
    label: "FX: Cosmic",
    key: "CloudAnalyser",
  },
  {
    value: 10,
    label: "Ind: US30",
    key: "US30",
  },
  {
    value: 60,
    label: "Paple Beta Testing",
    key: "PrecisionScanner",
  },
  {
    value: 70,
    label: "NFX: Pulsar",
    key: "NFXTurbo",
  },
  {
    value: 22,
    label: "NFX: 3Sisters",
    key: "EuroTitan",
  },
  // GANN_HARMONIC
  // {
  //   value: 5,
  //   label: "Gann & Harmonic Patterns",
  //   key: "GannHarmonicPatterns",
  // },
  // ZONE_TO_ZONE
  // {
  //   value: 6,
  //   label: "Gold Zone",
  //   key: "ZoneToZone",
  // },
  // US30
  // {
  //   value: 10,
  //   label: "US30 Beta Testing",
  //   key: "US30",
  // },
  // EURO_TITAN

  // NFX_EXPRESS
  // {
  //   value: 30,
  //   label: "NFX Express",
  //   key: "NFXExpress",
  // },
  // // WALLSTREET_WOLF
  // {
  //   value: 40,
  //   label: "Wall Street Wolf",
  //   key: "WallStreetWolf",
  // },
  // {
  //   value: 40,
  //   label: "Wall Street Titan",
  //   key: "WallStreetTitan",
  // },
  // BUTTERFLY_EFFECTS
  {
    value: 2,
    label: "ButterFly Effects",
    key: "ButterFlyEffects",
  },
  // {
  //   value: 2,
  //   label: "Paple Beta Testing",
  //   key: "PurpleRain",
  // }
  // {
  //   value: 22,
  //   label: "3 Sisters",
  //   key: "EuroTitan",
  // },
];

//if (config.default.featuresInDev)
//{
// tools.push({
//   value: 10,
//   label: "US30 Scanner",
//   key: "US30",
// });

// tools.push({
//   value: 60,
//   label: "Precision Scanner",
//   key: "PrecisionScanner",
// });

// tools.push({
//   value: 70,
//   label: "Pulsar",
//   key: "NFXTurbo",
// });

// tools.push(
//   {
//     value: 22,
//     label: "3 Sisters",
//     key: "EuroTitan",
//   },
// )
//}

module.exports = {
  addonEngulfingAndOPConfig: {
    available: {
      names: [
        'engulfing',
        'orderpartition',
        'hangingman',
        'morningstar',
        'incersehammer',
        'hammer',
        'inversehangingman',
        '3blackvrows',
        '3whitesoldiers',
        'eveningstar'
      ],
      types: ['Bearish', 'Bullish'],
    },
    default_: {
      names: [
        'engulfing',
        'orderpartition',
        'hangingman',
        'morningstar',
        'incersehammer',
        'hammer',
        'inversehangingman',
        '3blackvrows',
        '3whitesoldiers',
        'eveningstar'
      ],
      types: ['Bearish', 'Bullish'],
    },
    premium: {
      names: [],
    },
  },
  timeFormat: "YYYY.MM.DD HH:mm:ss",
  minuteFormat: "YYYY.MM.DD HH:mm",
  minuteAPFormat: "MM/DD/YYYY hh:mm A",
  utcDateTimeFormat: "YYYY-MM-DDTHH:mm",
  utcDateFormat: "MMM Do",
  utcTimeFormat: "HH:mm",
  eventTimeFormat: "HH:mm a",
  monthFormat: "MMM",
  openClose: {
    OPEN: "Open",
    CLOSE: "Close",
  },
  harmonicFiltersStorageKey: '__HARMONIC__FILTERS__',
  addonSettingsStorageKeyPrefix: 'ADDON_SETTING',

  MARKETPLACE_ITEM_STUDY_TEMPLATE: 'MARKETPLACE_ITEM_STUDY_TEMPLATE',
  MARKETPLACE_ITEM_CHART: 'MARKETPLACE_ITEM_CHART',

  signalType: {
    BUY: "Buy",
    SELL: "Sell",
  },

  signalColor: {
    BUY: "#8bc34a",
    SELL: "#d0011b",
    CONTINUATION: "#03a9f4",
    CONSECUTIVE_BACKCOLOR: "red",
    CONSECUTIVE_FORECOLOR: "white",
  },

  PENDING_SIGNAL: {
    KIND: 1,
    COLOR: "#ffc107",
    BUY: "Pending Long",
    SELL: "Pending Short",
  },

  themeType: {
    dark: "dark",
    light: "light",
  },

  notifications: {
    NO_RESPONSE: "No response from the server",
    VERIFY_EMAIL: "Please check your email to verify",
    // strategies
    SUCCESS_REMOVE_STRATEGY: "The strategy is removed successfully",
    SUCCESS_ADD_STRATEGY: "New strategy is added successfully",
    SUCCESS_EDIT_STRATEGY: "The strategy is updated successfully",
    FAIL_ADD_STRATEGY: "Failed to add strategy",
    FAIL_EDIT_STRATEGY: "Failed to update strategy",
    FAIL_REMOVE_STRATEGY: "Failed to remove strategy",
    FAIL_CONNECT: "Failed to connect to server",
    // signals
    SUCCESS_ADD_SIGNAL: "New signal is added successfully",
    SUCCESS_EDIT_SIGNAL: "Signal is updated successfully",
    SUCCESS_REMOVE_SIGNAL: "The signal is removed successfully",
    SUCCESS_REMOVE_USER: "The user is removed successfully",
    FAIL_ADD_SIGNAL: "Failed to add signal",
    FAIL_EDIT_SIGNAL: "Failed to update signal",
    FAIL_REMOVE_SIGNAL: "Failed to remove signal",

    //users
    SUCCESS_ADD_USER: "New user is added successfully",
    SUCCESS_EDIT_USER: "The user is updated successfully",
    FAIL_EDIT_USER: "Failed to update user",
    FAIL_REMOVE_USER: "Failed to remove user",
    FAIL_REMOVE_CURRENT_USER: "You can not delete yourself",
    FAIL_ADD_USER: "Failed to add user",
    SUCCESS_VERIFY_USER: "User Status is updated successfully",
    FAIL_VERIFY_USER: "Failed to update user status",
    FAIL_VERIFY_CURRENT_USER: "You can not change your status yourself",

    SUCCESS_UPDATE_PROFILE: "Your profile data has been updated",
    SUCCESS_UPDATE_PASSWORD: "Your password has been updated",
    FAIL_UPDATE_PROFILE: "Failed to update profile",
    FAIL_UPDATE_PASSWORD: "Failed to update password",
    SAME_PASSWORD: "The passwords are same",
    INVALID_INDICATOR_PARAMS: "Please input correct parameters for ",

    // brokers
    SUCCESS_ADD_BROKER: "New broker is added successfully",
    SUCCESS_EDIT_BROKER: "The broker is updated successfully",
    SUCCESS_REMOVE_BROKER: "The broker is removed successfully",
    FAIL_ADD_BROKER: "Failed to add broker",
    FAIL_EDIT_BROKER: "Failed to update broker",
    FAIL_REMOVE_BROKER: "Failed to remove broker",

    // symbols
    SUCCESS_ADD_SYMBOL: "New symbol is added successfully",
    SUCCESS_EDIT_SYMBOL: "The symbol is updated successfully",
    SUCCESS_REMOVE_SYMBOL: "The symbol is removed successfully",
    FAIL_ADD_SYMBOL: "Failed to add symbol",
    FAIL_EDIT_SYMBOL: "Failed to update symbol",
    FAIL_REMOVE_SYMBOL: "Failed to remove symbol",

    SUCCESS_PUBLISH_CHART_DATA: "The chart data is published successfully",
    FAIL_PUBLISH_CHART_DATA: "Failed to publish chart data",

    //Sth went wrong
    SOMETHING_WORNG: "Oops.. something went wrong",
    // not able to add new rule
    NOT_ABLE_TO_ADD_RULE: "You are not able to add any more rule.",
    SUCCESS_UPDATE_SCHEDULE: "Schedules are updated successfully.",

    SUCCESS_ADD_VIDEO: "New video is added successfully",
    SUCCESS_EDIT_VIDEO: "The video is updated successfully",
    SUCCESS_REMOVE_VIDEO: "The video(s) are removed successfully",
  },

  validation: {
    MESSAGE_NOT_EMPTY: "Message cannot be empty",
    EMAIL_VALID_ERROR: "Email is not valid",
    PHONENUMBER_VALID_ERROR: "Phone Number is not valid",
  },

  messages: {
    REMOVE_USER: "Do you really want to remove this user?",
    UNVERIFY_USER: "Do you really want to unverify user?",
    VERIFY_USER: "Do you really want to verify user?",
    REMOVE_BROKER_CASE:
      "If you delete the broker, all symbols, signals and strategies with this broker will be removed.",
    REMOVE_BROKER_QUESTION: "Are you sure to delete this broker?",
    REMOVE_SYMBOL_CASE:
      "If you delete the symbol, all signals with this symbol will be removed.",
    REMOVE_SYMBOL_QUESTION: "Are you sure to delete this symbol?",
    ADD_SIGNAL_INITIAL_MESSAGE: "Add a new signal data below",
    EDIT_SIGNAL_INITIAL_MESSAGE: "Update signal data",
    INPUT_VALIDATION_ERROR_MESSAGE: "The input values are invalid",
    INPUT_ERROR_FILED_MESSAGE: " value is invalid",
    SAVE_SCHEDULE_MESSAGE: "Are you sure to save schedules?",
    NO_EDUCATORS_FOR_TOPIC_MESSEAGE: "No educators for this Topic",
  },

  tooltips: {
    SUBSCRIPTION:
      "Click SUBSCRIBE to receive Push Notification on your iGoTrade App",
  },

  roles: {
    SCHEDULE_ADMIN_ROLE: 5,
    USER_ROLE: 4,
    PROVIDER_ROLE: 3,
    CHART_ADMIN_ROLE: 2,
    ADMIN_ROLE: 1,
  },

  roleName: {
    SCHEDULE_ADMIN_ROLE: "Schedule Admin",
    USER_ROLE: "User",
    PROVIDER_ROLE: "Provider",
    CHART_ADMIN_ROLE: "Chart Admin",
    ADMIN_ROLE: "Admin",
  },

  skuIdList: {
    TTCLA2020: "TTCLA2020",
    TTRENK2020: "TTRENK2020",
    TTEXPT2020: "TTEXPT2020",
    BRICKSTG01: "BRICKSTG01",
    TGEMINI2020: "TGEMINI2020",
    MGPF30X3: "MGPF30X3", // Live Stream skuids
    MES18M02: "MES18M02",
    MES12M02: "MES12M02",
    MAD12VDX3: "MAD12VDX3",
    MAD18VDX3: "MAD18VDX3",
    AD0MGP90: "AD0MGP90",
    MGPF90X3: "MGPF90X3",
    MEX12LVX3: "MEX12LVX3",
    MEX6LVX3: "MEX6LVX3",
    MEX1LVX3: "MEX1LVX3",
    MAD1VLX3: "MAD1VLX3",
    MES1VLX3: "MES1VLX3",
    MEX3M06: "MEX3M06",
    MAD3M04: "MAD3M04",
    MES3M02: "MES3M02",
    AD0MGP30: "AD0MGP30",
    MES18M02LA: "MES18M02LA",
    MES12M02LA: "MES12M02LA",
    MAD12VDX3LA: "MAD12VDX3LA",
    MEX1LVX3LA: "MEX1LVX3LA",
    MEX3M06LA: "MEX3M06LA",
    MEX6LVX3LA: "MEX6LVX3LA",
    MEX12LVX3LA: "MEX12LVX3LA",
    MES3M02LA: "MES3M02LA",
    MES1VLX3LA: "MES1VLX3LA",
    MAD18VDX3LA: "MAD18VDX3LA",
    MAD3M04LA: "MAD3M04LA",
    MAD1VLX3LA: "MAD1VLX3LA",
    MEGPLVX3: "MEGPLVX3",
    MEGF90X3: "MEGF90X3",
    RTGYFX12: "RTGYFX12",
    RTGYF0X6: "RTGYF0X6",
    RTGYF0X3: "RTGYF0X3",
    RTGYF0X1: "RTGYF0X1",
    MEE9M07: "MEE9M07",

    CLOUD_ANALYSER: 20,
    MELIUS_LIVE: 19,
    US30: 43,
    US30_2: 35,
    EURO_TITAN: 58,
    EURO_TITAN_1: 62,
    EURO_TITAN_2: 65,
    EURO_TITAN_3: 66,
    EURO_TITAN_4: 68,
    PRECISION_SCANNER: 66,
    PRECISION_SCANNER_1: 63,
    PRECISION_SCANNER_2: 35,
    PRECISION_SCANNER_3: 65,
    NFX_TURBO: 58,
    NFX_TURBO_1: 62,
    NFX_TURBO_2: 65,
    NFX_TURBO_3: 66,
    NFX_TURBO_4: 68,
    PURPLE_RAIN: 71,
  },

  newSkuIds: {
    CLOUD_ANALYSER: 20,
    MELIUS_LIVE: 19,
    US30: 43,
    US30_2: 35,
    EURO_TITAN: 58,
    EURO_TITAN_1: 62,
    EURO_TITAN_2: 65,
    EURO_TITAN_3: 66,
    EURO_TITAN_4: 68,
    PRECISION_SCANNER: 66,
    PRECISION_SCANNER_1: 63,
    PRECISION_SCANNER_2: 35,
    PRECISION_SCANNER_3: 65,
    NFX_TURBO: 58,
    NFX_TURBO_1: 62,
    NFX_TURBO_2: 65,
    NFX_TURBO_3: 66,
    NFX_TURBO_4: 68
  },

  brokerTypes: [
    {
      label: "Demo",
      value: false,
    },
    {
      label: "Real",
      value: true,
    },
  ],

  currencyTypes: [
    {
      label: "EUR",
      value: "EUR",
    },
    {
      label: "JPY",
      value: "JPY",
    },
    {
      label: "GBP",
      value: "GBP",
    },
    {
      label: "CAD",
      value: "CAD",
    },
    {
      label: "AUD",
      value: "AUD",
    },
    {
      label: "NZD",
      value: "NZD",
    },
    {
      label: "CHF",
      value: "CHF",
    },
    {
      label: "USD",
      value: "USD",
    },
  ],

  response: {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  },

  signalTypeList: [
    { value: 0, label: "BUY" },
    { value: 1, label: "SELL" },
    { value: 2, label: "BUY LIMIT" },
    { value: 3, label: "SELL LIMIT" },
    { value: 4, label: "BUY STOP" },
    { value: 5, label: "SELL STOP" },
  ],

  // TODO: * just use signalTypeList not following signalsTitle
  signalsTitle: [
    "Buy",
    "Sell",
    "Buy Limit",
    "Sell Limit",
    "Buy Stop",
    "Sell Stop",
  ],

  // TODO: * duplicated with the following
  signalKindList: [
    //{value: 0, label: 'Upcoming Trade', mobileLabel: 'Upcoming', colorType: 'indigo500'},
    // {value: 1, label: 'Future Pending Sell Limit Order', mobileLabel: 'Pending Sell', colorType: 'red500'},
    // {value: 1, label: 'Pending', mobileLabel: 'Pending', colorType: 'lightGreen500'},
    // {value: 2, label: 'Activated Order', mobileLabel: 'Activated', colorType: 'green500'},
    // {value: 4, label: 'TP#1 Hit', mobileLabel: 'TP1', colorType: 'amber500'},
    // {value: 3, label: 'Closed Trade', mobileLabel: 'Closed', colorType: 'indigo500'},

    {
      value: 10,
      label: "PENDING SELL",
      mobileLabel: "PENDING SELL",
      colorType: "orange500",
    },
    {
      value: 11,
      label: "PENDING BUY",
      mobileLabel: "PENDING BUY",
      colorType: "lightGreen500",
    },
    { value: 12, label: "TP1 HIT", mobileLabel: "TP1", colorType: "blue500" },
    { value: 13, label: "TP HIT", mobileLabel: "TP2", colorType: "blue500" },
    { value: 14, label: "SL HIT", mobileLabel: "SL", colorType: "blue500" },
    {
      value: 15,
      label: "ACTIVATED",
      mobileLabel: "ACTIVATED",
      colorType: "blue500",
    },
    {
      value: 16,
      label: "CANCELLED",
      mobileLabel: "CANCELLED",
      colorType: "grey500",
    },
  ],

  signalStatus: {
    active: "Active",
    pending: "Pending",
    slHit: "SL-HIT",
    won: "Won",
    cancelled: "Cancelled",
  },

  signalKind: {
    OP_BUY: 0,
    OP_SELL: 1,
    OP_BUYLIMIT: 2,
    OP_SELLLIMIT: 3,
    OP_BUYSTOP: 4,
    OP_SELLSTOP: 5,
  },

  signalKinds: {
    UPCOMING_SIGNAL: 0,
    PENDING_SIGNAL: 1,
    EXCUTED_SIGNAL: 2,
    TP1_REACHED_SIGNAL: 4,
    CLOSED_SIGNAL: 3,

    PENDING_SELL: 10,
    PENDING_BUY: 11,
    TP1_HIT: 12,
    TP2_HIT: 13,
    SL_HIT: 14,
    ACTIVATED: 15,
    CANCELLED: 16,
  },

  // Tools
  QUOTE_FEEDER: 0,
  DISCLAIMER_RULE: 100,

  COSMICCLOUD: 2,
  GANN_HARMONIC: 5,
  ZONE_TO_ZONE: 6,

  US30: 10,
  EURO_TITAN: 22,
  NFX_EXPRESS: 30,
  WALLSTREET_WOLF: 40,
  WALLSTREET_30: 42,
  BUTTERFLY_EFFECTS: 2,
  PURPLE_RAIN: 2,
  PRECISION_SCANNER: 60,
  NFX_TURBO: 70,
  tools,

  supportedResolutions: ["1", "5", "15", "30", "60", "240", "D"],

  defaultAvatar:
    "//www.gravatar.com/avatar/51b444b6cac7ba2671011bf7616c5d59?s=200&r=pg&d=mm",

  harmonicPatternNames: [
    'ABCD',
    'Alt_ABCD',
    'Golden_Pattern',
    'Gartley',
    'Bat',
    'Alt_Bat',
    'Butterfly',
    'Crab',
    'DeepCrab',
    'Shark',
    'Cypher',
    'CP',
    'ThreeDrives',
    '5-O',
  ],

  harmonicPatternTypes: [
    'Bearish',
    'Bullish',
  ],

  // index in array is actual value we receive from quote feeder
  harmonicPatternSizes: [
    'Small',
    'Medium',
    'Whale',
    'Blue Whale',
  ],

  harmonicPatternAdditionalIndicators: [
    'engulfing',
    'order_partition',
  ],

  harmonicPatternDeviationTypes: [
    'Aggressive',
    'Conservative',
    'MA200',
    'Set Deviation',
  ],

  harmonicPatternIndicatorNotification: [
    'lowerTFEngulfing',
    'orderPartition',
    'retracementPoint',
    'higherTFEngulfing',
    'higherTFOrderPartition',
  ],

  harmonicPatternDeviationValues: [
    0,
    -10,
    -15,
    -20,
    -25,
    -30,
    -40,
    -50,
  ],

  socketEvent: {
    CONNECT_EVENT: "connection",
    DISCONNECT_EVENT: "disconnect",
    SUBSCRIBE_EVENT: "subscribe",
    UNSUBSCRIBE_EVENT: "unsubscribe",
    AUTHENTICATE_EVENT: "authenticate",
    AUTH_RESPONSE_EVENT: "auth-response",
    RECV_TICK_EVENT: "recv-tick",
    RECV_SIGNAL_EVENT: "recv-signal",
    SEND_TICK_EVENT: "send-tick",
    SEND_SIGNAL_EVENT: "send-signal",
    UPDATE_BROKERS_EVENT: "update-brokers",
    UPDATE_SYMBOLS_EVENT: "update-symbols",
    UPDATE_STRATEGIES_EVENT: "update-strategies",
    UPDATE_SIGNAL_SIGNALS_EVENT: "update-signals",
    UPDATE_NOTIFICATIONS_EVENT: "update-notifications",
    UPDATE_CHART: "update-chart",
    LOGOUT_EVENT: "logout",
    RELOGIN_EVENT: "relogin",
    RECV_SERVERTIME_EVENT: "ping",
    NEW_HARMONIC_PATTERN_EVENT: 'new-harmonic-pattern',
    UPDATE_HARMONIC_PATTERN_EVENT: 'update-harmonic-pattern',
    UPDATE_LINE_TOOLS_EVENT: 'line-tools-update',
  },

  notificationTypes: [
    {
      label: "Info",
      value: "info",
    },
    {
      label: "Alert",
      value: "alert",
    },
    {
      label: "Important",
      value: "important",
    },
  ],

  signalCategory: {
    ACTIVE: 0,
    ARCHIVE: 1,
    PENDING: 2,
  },

  schedulerTheme: {
    LIGHT: "#e0e0e0",
    DARK: "#1d2025",
    TOPIC_CARD_HEIGHT: 130,
    EDUCATOR_CARD_HEIGHT: 100,
  },

  scheduleTypes: {
    ONETIME: "One-time",
    REOCCURRING: "Re-occurring",
  },

  educatorTypes: {
    EDUCATOR: "Educator",
    MASTER_EDUCATOR: "Master Educator",
  },

  HOUR_TIMESTAMP: 3600000,
  DAY_TIMESTAMP: 86400000,
  WEEK_TIMESTAMP: 86400000 * 7,

  WEEKDAYS: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  TIMELINES: ["Morning", "Afternoon", "Evening"],

  SCHEDULE_OPTIONS: {
    EDUCATOR: {
      type: "educator",
      title: "EDUCATORS",
    },
    TOPIC: {
      type: "topic",
      title: "TOPICS",
    },
  },

  seperatorChar: "&",
  defaultStrategyParam: "Stock_K_Period=8&Stock_D_Period=3&Stock_Slow=3",
  topicThumbMaxWidth: 300,
  topicThumbMaxHeight: 200,
  educatorThumbMaxWidth: 200,
  educatorThumbMaxHeight: 300,
  avatarMaxSize: 72,

  SIGNAL_STATUS: {
    CONTINUATION: {
      LONG: "Long Continuation Setup",
      SHORT: "Short Continuation Setup",
    },
    MIDDLE: {
      LONG: "Long Continuation Trade",
      SHORT: "Short Continuation Trade",
    },
    CONSECUTIVE: {
      MESSAGE:
        "Probabilities indicate that this trade may not be appropriate even though the conditions are met for a short - traders discretion should be used.",
    },
  },

  defaultTopicThumbnailUrl:
    "https://images.unsplash.com/photo-1498758536662-35b82cd15e29?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60",

  linkTypes: [
    {
      label: "Sense",
      value: "sense",
    },
    {
      label: "Telegram",
      value: "telegram",
    },
  ],
  toolsPairCounts: [
    {
      label: 'Cosmic Cloud',
      value: 22
    },
    {
      label: 'GoldZone',
      value: 1
    },
    {
      label: 'EuroTitan',
      value: 10
    },
    {
      label: 'Butterfly',
      value: 60
    },
    {
      label: 'WallStreet30,',
      value: 1
    },
    {
      label: 'PrecisionScanner,',
      value: 30
    },
    {
      label: 'NFXTurbo,',
      value: 10
    }
  ],
  defaultTP: 20,
  defaultSL: 50,

  wowURL: "https://wow.berules.com/"
  //isStaging : config.featuresInDev
};
