import React, {useEffect, useState, useRef} from 'react';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';

import {getChartUrl, getLineTools, updateLineTools} from '../../services/addon';
import AddonToolbar from './AddonToolbar';
import OpenIconButton from "../../components/Tools/OpenIconButton";
import ToolsLeftPanelWrapper from "../../hoc/ToolsLeftPanelWrapper";
import SignalsCard from "../../components/SignalsCard";
import {getSignalsByAddonId} from "../../services/signal";
import SkuidWrapper from "../../hoc/SkuidWrapper";
import {userRoleList} from "../../utils/links";
import constants from '../../constants';
import {
  checkAdminKindRole,
  getQueryParam,
  is5PointsPattern, is6PointsPattern,
  saveAddonSettings,
  loadAddonSettings, parseHarmonicPattern,
} from "../../utils/utility";
import {
  getHarmonicPatternsList,
  getNewHarmonicPatternsCount,
  getPatternByOCTId
} from "../../services/harmonic_pattern";
import {setLoadingStatus} from "../../actions/loading";
import {FadeLoader} from "react-spinners";
import {colors} from "../../utils/styles";
import Helmet from "react-helmet";
import HarmonicPatternsTable from "../../components/HarmonicPatternsTable";
import EagleViewToolbar from "./EagleViewToolbar";

const styles = () => ({
  chartContent: {
    display: 'flex',
    flexDirection: 'row',
    height: '95%',
    position: 'relative',
  },
  chartWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  chartIframe: {
    width: '100%',
    height: '100%',
    border: 'none'
  },
  loaderBackdrop: {
    backgroundColor: '#000',
    opacity: 0.5,
    zIndex: 100,
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
});

const signalCanBeDisplayedOnChart = ({chartParams, signal}) => (
  chartParams.symbol === signal.symbol &&
  chartParams.broker === signal.broker &&
  chartParams.timeframe === signal.timeframe
);

const DEFAULT_HARMONIC_FILTERS = {
  names: ['engulfing'],
  types: constants.harmonicPatternTypes,
  // medium, large, xLarge
  sizes: [1, 2, 3],
  deviationTypes: [
    constants.harmonicPatternDeviationTypes[0],
    constants.harmonicPatternDeviationTypes[1],
  ],
  additionalIndicators: constants.harmonicPatternAdditionalIndicators,
  indicatorNotification: constants.harmonicPatternIndicatorNotification,
  deviationValue: null,
  ids: [],
};

const PAGE_SIZE = 20;

const getInitialHarmonicNotificationsFilters = addon => {
  const filters = (addon.harmonicConfig && addon.harmonicConfig.default_) || DEFAULT_HARMONIC_FILTERS;
  return {
    ...filters,
    symbol: addon.symbols,
    timeframe: addon.timeframes,
    page: 1,
    pageSize: PAGE_SIZE,
  };
};

const getInitialIndicationFilters = addon => {
  const filters = {};
  if (addon.indicators.includes('engulfing_and_op')) {
    filters.engulfingAndOP = addon.engulfingAndOPConfig ? addon.engulfingAndOPConfig.default_ : constants.addonEngulfingAndOPConfig.default_;
  }
  return filters;
};

const COMMON_DEVIATION_TYPES = ['Conservative', 'Aggressive'];

const testHarmonicPattern = ({parsedPattern, filters}) => {
  let nameCond = true;
  let typeCond = true;
  let sizeCond = true;
  let deviationValueCond = true;
  let deviationTypeCond = true;
  let MA200Cond = true;
  let symbolCond = true;
  let timeframeCond = true;
  let indicatorNotificationCond = true;
  if (filters.names) {
    nameCond = filters.names.includes(parsedPattern.patternname);
  }
  if (filters.types) {
    typeCond = filters.types.includes(parsedPattern.patterntype);
  }
  if (filters.sizes) {
    sizeCond = filters.sizes.includes(parsedPattern.patternsize);
  }
  if (filters.deviationValue) {
    deviationValueCond = parsedPattern.devation === filters.deviationValue;
  }
  if (filters.deviationTypes) {
    const common = filters.deviationTypes.filter(t => COMMON_DEVIATION_TYPES.includes(t));
    deviationTypeCond = common.includes(parsedPattern.signaltype);
    if (filters.deviationTypes.includes('MA200')) {
      MA200Cond = parsedPattern.mafilter === 'On';
    }
  }
  if (filters.indicatorNotification) {
    indicatorNotificationCond = filters.indicatorNotification.every(f => !!parsedPattern[f]);
  }
  if (filters.symbol) {
    symbolCond = filters.symbol.includes(parsedPattern.symbol);
  }
  if (filters.timeframe) {
    timeframeCond = filters.timeframe.includes(parsedPattern.timeframe);
  }
  return [
    nameCond, typeCond, sizeCond, deviationValueCond,
    deviationTypeCond, MA200Cond, symbolCond, timeframeCond, indicatorNotificationCond,
  ].every(v => v);
};

const Addon = (props) => {
  const addon = props.addon;
  const patternId = getQueryParam('pattern');

  if (!addon) {
    return null;
  }

  const isAdmin = checkAdminKindRole(props.userRole);

  const chartRef = useRef(null);
  const initialPatternRef = useRef(null);
  const eagleViewPagePointer = useRef(null);

  const [chartReady, setChartReady] = useState(false);
  const [chartUrl, setChartUrl] = useState(null);
  const [lastBar, setLastBar] = useState(null);
  const [signals, setSignals] = useState([]);
  const [technicalIndicator, setTechnicalIndicator] = useState(null);
  const [eagleView, setEagleView] = useState(false);
  const [selectedSignal, setSelectedSignal] = useState(null);
  const [harmonicFilters, setHarmonicFilters] = useState(
    loadAddonSettings({
      addonId: addon._id,
      field: 'harmonicFilters',
    }) || (addon.harmonicConfig && addon.harmonicConfig.default_) || DEFAULT_HARMONIC_FILTERS
  );
  const [hiddenPatterns, setHiddenPatterns] = useState([]);
  const [activePattern, setActivePattern] = useState(null);
  const [harmonicPatterns, setHarmonicPatterns] = useState([]);
  const [selectedHarmonicPatterns, setSelectedHarmonicPatterns] = useState([]);
  const [showSignalPanel, setShowSignalPanel] = useState(false);
  const [chartParams, setChartParams] = useState({});
  const [harmonicNotifications, setHarmonicNotifications] = useState([]);
  const [harmonicNotificationsFilters, setHarmonicNotificationsFilters] = useState(
    getInitialHarmonicNotificationsFilters(addon)
  )
  const [harmonicNotificationsCount, setHarmonicNotificationsCount] = useState(0);
  const [indicationFilters, setIndicationFilters] = useState(
    loadAddonSettings({
      addonId: addon._id,
      field: 'indicationFilters',
    }) || getInitialIndicationFilters(addon)
  );

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.contentWindow.postMessage({
        type: 'SET_HARMONIC_PATTERNS_STATE',
        payload: {
          filters: harmonicFilters,
          hiddenIds: hiddenPatterns,
          selectedId: activePattern,
        },
      }, '*');
    }
  }, [harmonicFilters, hiddenPatterns, activePattern, chartReady]);

  useEffect(() => {
    if (!addon) {
      return;
    }
    setEagleView(false);
    setHarmonicNotificationsFilters(getInitialHarmonicNotificationsFilters(addon));
    setIndicationFilters(
      loadAddonSettings({
        addonId: addon._id,
        field: 'indicationFilters'
      }) || getInitialIndicationFilters(addon)
    );
    const defaultParams = {
      symbol: addon.symbols[0],
      broker: addon.brokers[0],
      timeframe: addon.timeframes[0],
    };
    const saved = loadAddonSettings({
      addonId: addon._id,
      field: 'chartParams',
    });
    const default_ = saved || defaultParams;
    if (patternId) {
      getPatternByOCTId(patternId).then(pattern => {
        const info = pattern.points[0];
        initialPatternRef.current = {...info, _id: patternId};
        setChartParams({
          symbol: pattern.symbol,
          broker: 'OANDA',
          timeframe: pattern.timeframe,
        });
        const indicatorNotification = [];
        if (!!pattern.retracementPoint) {
          indicatorNotification.push('retracementPoint');
        }
        if (!!pattern.orderPartition) {
          indicatorNotification.push('orderPartition');
        }
        if (!!pattern.lowerTFEngulfing) {
          indicatorNotification.push('lowerTFEngulfing');
        }
        if (!!pattern.higherTFEngulfing) {
          indicatorNotification.push('higherTFEngulfing');
        }
        if (!!pattern.higherTFOrderPartition) {
          indicatorNotification.push('higherTFOrderPartition');
        }
        setHarmonicFilters(prev => ({
          ...prev,
          names: [info.patternname],
          types: [info.patterntype],
          sizes: [info.patternsize],
          deviationTypes: [info.signaltype],
          deviationValue: info.deviationValue,
          indicatorNotification,
        }));
      }).catch(() => {
        setChartParams(default_);
      });
    } else {
      setChartParams(default_);
    }
  }, [addon]);

  useEffect(() => {
    eagleViewPagePointer.current = null;
    getNewHarmonicPatternsCount().then(c => setHarmonicNotificationsCount(c.count));
    getHarmonicPatternsList(harmonicNotificationsFilters).then(
      res => setHarmonicNotifications(res.data.map(parseHarmonicPattern))
    )
  }, [harmonicNotificationsFilters]);

  useEffect(() => {
    // reset eagle view filters here.
    if (!eagleView) {
      setHarmonicNotificationsFilters(getInitialHarmonicNotificationsFilters(addon));
    }
  }, [eagleView]);

  useEffect(() => {
    window.addEventListener('realtime_update', handleRealtimeUpdate);
    return () => window.removeEventListener('realtime_update', handleRealtimeUpdate);
  }, [harmonicNotificationsFilters]);

  useEffect(() => {
    const ids = harmonicPatterns.map(p => p._id);
    if (activePattern && !ids.includes(activePattern)) {
      setActivePattern(null);
    }
    if (initialPatternRef.current) {
      if (harmonicPatterns.find(p => initialPatternRef.current._id === p._id)) {
        toggleActivePattern(initialPatternRef.current._id);
        initialPatternRef.current = null;
      }
    }

  }, [harmonicPatterns, activePattern]);

  useEffect(() => {
    setChartReady(false);
    const params = {...chartParams};
    if (!params.symbol || !params.broker || !params.timeframe) {
      return;
    }
    if (initialPatternRef.current) {
      params.selectedPattern = initialPatternRef.current._id;
      const {atime, btime, ctime, dtime, xtime, otime} = initialPatternRef.current;
      let patternPointsArr = [atime, btime, ctime, dtime];
      if (is5PointsPattern(initialPatternRef.current)) {
        patternPointsArr = [atime, btime, ctime, dtime, xtime];
      }
      if (is6PointsPattern(initialPatternRef.current)) {
        patternPointsArr = [atime, btime, ctime, dtime, xtime, otime];
      }
      params.initialFrom = Math.min(...patternPointsArr) - (params.timeframe * 60 * 15);
      params.initialTo = Math.max(...patternPointsArr) + (params.timeframe * 60 * 15);
    }
    getChartUrl({...params, addonId: addon._id}).then(url => {
      setChartUrl(url);
    });
    saveAddonSettings({
      addonId: addon._id,
      field: 'chartParams',
      value: chartParams,
    });
  }, [chartParams]);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.contentWindow.postMessage({
        type: 'UPDATE_HARMONIC_IDS_FILTER',
        filter: selectedHarmonicPatterns,
      }, '*');
    }
  }, [selectedHarmonicPatterns]);

  useEffect(() => {
    if (chartReady) {
      getLineTools(addon._id, chartParams).then(lineTools => {
        if (lineTools) {
          const {symbol, broker, timeframe} = chartParams;
          const lineToolsKey = `${symbol}_${broker}_${timeframe}`;
          const payload = lineTools[lineToolsKey];
          handleUpdateLineTools(payload);
        }
      });
      const updateHarmonicFiltersPayload = {
        type: 'UPDATE_HARMONIC_COMMON_FILTERS',
        filters: harmonicFilters,
      };
      const addonConfigPayload = {
        type: 'ADDON_CONFIG',
        addonConfig: {
          symbols: addon.symbols,
          timeframes: addon.timeframes,
        }
      };
      chartRef.current.contentWindow.postMessage(updateHarmonicFiltersPayload, '*');
      chartRef.current.contentWindow.postMessage(addonConfigPayload, '*');
      chartRef.current.contentWindow.postMessage({
        type: 'SET_ENGULFING_AND_OP_FILTERS',
        payload: indicationFilters.engulfingAndOP,
      }, '*');
    }
  }, [chartReady, addon]);

  useEffect(() => {
    getSignalsByAddonId(addon._id).then(signals => setSignals(signals));
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleOCTMessage);
    window.addEventListener('realtime_update', handleRealtimeUpdate);
    return () => {
      window.removeEventListener('message', handleOCTMessage);
      window.removeEventListener('realtime_update', handleRealtimeUpdate);
    }
  }, [chartParams, harmonicNotificationsFilters]);

  useEffect(() => {
    if (chartRef.current) {
      let payload = {
        type: 'SHOW_SIGNAL',
        signal: selectedSignal,
      }
      if (!selectedSignal) {
        payload = {type: 'HIDE_SIGNAL'}
      }
      chartRef.current.contentWindow.postMessage({
        type: 'SET_ACTIVE_SIGNAL',
        payload: {signal: selectedSignal},
      }, '*');
      chartRef.current.contentWindow.postMessage(payload, '*');
    }
  }, [selectedSignal]);

  useEffect(() => {
    if (chartRef.current) {
      const payload = {
        type: 'UPDATE_HARMONIC_COMMON_FILTERS',
        filters: harmonicFilters,
      };
      chartRef.current.contentWindow.postMessage(payload, '*');
    }
    saveAddonSettings({
      addonId: addon._id,
      field: 'harmonicFilters',
      value: harmonicFilters,
    });
  }, [harmonicFilters]);

  const updateSignals = () => {
    getSignalsByAddonId(addon._id).then(signals => setSignals(signals));
  };

  const handleChartParamsChange = (param, value) => setChartParams(
    prev => ({...prev, [param]: value})
  );

  const toggleSignalPanel = () => {
    setShowSignalPanel(!showSignalPanel);
  };

  const handleSignalSelect = signal => {
    if (selectedSignal && selectedSignal._id === signal._id) {
      setSelectedSignal(null);
      return;
    }
    setSelectedSignal(signal);
    // need to update chart in order to display symbols
    if (!signalCanBeDisplayedOnChart({chartParams, signal})) {
      setChartParams({
        symbol: signal.symbol,
        broker: signal.broker,
        timeframe: signal.timeframe,
      });
    }
  };

  const toggleHiddenPattern = patternId => {
    // user hides current active pattern
    if (!hiddenPatterns.includes(patternId) && patternId === activePattern) {
      setActivePattern(null);
    }
    setHiddenPatterns(
      hiddenPatterns.includes(patternId) ?
        hiddenPatterns.filter(p => p !== patternId) : [...hiddenPatterns, patternId]
    );
    if (chartRef.current) {
      chartRef.current.contentWindow.postMessage({
        type: 'TOGGLE_HIDDEN_PATTERN',
        patternId,
      }, '*');
    }
  };

  const toggleActivePattern = patternId => {
    let messagePayload;
    let newActivePattern;
    let newHiddenPatterns = [];
    if (patternId === activePattern) {
      messagePayload = {
        type: 'HIDE_PATTERN_INFO',
      }
      newActivePattern = null;
      newHiddenPatterns = [];
    } else {
      messagePayload = {
        type: 'SHOW_PATTERN_INFO',
        patternId,
      }
      newActivePattern = patternId;
      newHiddenPatterns = harmonicPatterns.reduce((acc, p) => {
        if (p._id !== patternId) {
          acc.push(p._id);
        }
        return acc;
      }, []);
      if (hiddenPatterns.includes(patternId)) {
        toggleHiddenPattern(patternId);
      }
    }
    setHiddenPatterns(newHiddenPatterns);
    setActivePattern(newActivePattern);
    if (window.history && window.location) {
      if (newActivePattern) {
        window.history.replaceState(null, null, `?pattern=${newActivePattern}`);
      } else {
        window.history.replaceState(null, null, window.location.pathname);
      }
    }
    if (chartRef.current) {
      chartRef.current.contentWindow.postMessage(messagePayload, '*');
      chartRef.current.contentWindow.postMessage({
        type: 'SET_HIDDEN_PATTERNS',
        patterns: newHiddenPatterns
      }, '*')
    }
  }

  const handleHarmonicFiltersChange = (newFilters) => {
    setHarmonicFilters(newFilters);
  };

  const handleSelectedPatternsChange = selectedPatterns => {
    setSelectedHarmonicPatterns(selectedPatterns);
  };

  const handlePublishLineTools = () => {
    if (chartRef.current) {
      chartRef.current.contentWindow.postMessage({type: 'GET_LINE_TOOLS'}, '*');
      chartRef.current.contentWindow.postMessage({type: 'GET_LINE_TOOLS_REQUEST', payload: '*'}, '*');
    }
  };

  const handleUpdateLineTools = lineTools => {
    if (chartRef.current) {
      chartRef.current.contentWindow.postMessage({type: 'SET_LINE_TOOLS', lineTools}, '*');
      chartRef.current.contentWindow.postMessage({type: 'SET_LINE_TOOLS', payload: lineTools}, '*');
    }
  };

  const handleOCTMessage = event => {
    if (typeof event.data === 'object') {
      if (event.data.type === 'LAST_BAR') {
        setLastBar(event.data.bar);
      }
      if (event.data.type === 'LINE_TOOLS') {
        if (!isAdmin) {
          return;
        }
        const payload = {
          addonId: addon._id,
          lineTools: event.data.lineTools,
          ...chartParams,
        };
        updateLineTools(payload);
      }
      if (event.data.type === 'CHART_READY') {
        setChartReady(true);
      }
      // backward capability with old chart
      if (event.data.type === 'ACTIVE_PATTERNS') {
        setHarmonicPatterns(event.data.patterns);
      }
      if (event.data.type === 'UPDATE_HARMONIC_PATTERNS') {
        setHarmonicPatterns(event.data.payload);
        //setHarmonicFilters({...harmonicFilters, ids: event.data.patterns.map(p => p._id)});
      }
      if (event.data.type === 'TECHNICAL_INDICATOR') {
        setTechnicalIndicator(event.data.indicator);
      }
      if (event.data.type === 'UPDATE_CHART_PARAMS') {
        setChartParams(prev => ({...prev, ...event.data.payload}));
      }
      if (event.data.type === 'GET_LINE_TOOLS_RESPONSE') {
        if (!isAdmin) {
          return;
        }
        const payload = {
          addonId: addon._id,
          lineTools: event.data.payload,
          ...chartParams,
        };
        updateLineTools(payload);
      }
    }
  };

  const handleRealtimeUpdate = event => {
    const detail = event.detail;
    if (detail && [constants.socketEvent.SEND_SIGNAL_EVENT, constants.socketEvent.UPDATE_SIGNAL_SIGNALS_EVENT].includes(detail.type)) {
      updateSignals();
    }
    if (detail && detail.type === constants.socketEvent.UPDATE_LINE_TOOLS_EVENT && detail.addonId === addon._id) {
      const {symbol, broker, timeframe} = chartParams;
      const lineToolsKey = `${symbol}_${broker}_${timeframe}`;
      const payload = detail.lineTools[lineToolsKey];
      if (payload) {
        handleUpdateLineTools(payload);
      }
    }
    if (detail && detail.type === constants.socketEvent.NEW_HARMONIC_PATTERN_EVENT) {
      setHarmonicNotificationsCount(p => p + 1);
      const newPattern = parseHarmonicPattern(JSON.parse(detail.data));
      if (!testHarmonicPattern({parsedPattern: newPattern, filters: harmonicNotificationsFilters})) {
        return;
      }
      setHarmonicNotifications(prev => {
        const new_ = [...prev];
        if (new_.length === PAGE_SIZE) {
          new_.pop();
        }
        new_.unshift(newPattern);
        return new_;
      })
    }
    if (detail && detail.type === constants.socketEvent.UPDATE_HARMONIC_PATTERN_EVENT) {
      const updatedPattern = parseHarmonicPattern(JSON.parse(detail.data));
      setHarmonicNotifications(prev => (
        prev.map(pattern => {
          if (pattern._id === updatedPattern._id) {
            return updatedPattern;
          }
          return pattern;
        })
      ));
    }
  };

  const handlePatternNotificationClick = octId => {
    if (octId) {
      props.history.push(`/addons/${addon._id}?pattern=${octId}`);
      window.location.reload();
    }
  };

  const handleFullScreen = () => {
    const target = eagleView ? document.documentElement : chartRef.current;
    // Do fullscreen
    if (target.requestFullscreen) {
      target.requestFullscreen();
    } else if (target.webkitRequestFullscreen) {
      target.webkitRequestFullscreen();
    } else if (target.mozRequestFullScreen) {
      target.mozRequestFullScreen();
    } else if (target.msRequestFullscreen) {
      target.msRequestFullscreen();
    }
  };

  const toggleEagleView = () => {
    setEagleView(!eagleView);
  };

  const syncEagleView = () => {
    setHarmonicFilters(harmonicNotificationsFilters);
  };

  const resetHarmonicNotificationsCount = () => {
    setHarmonicNotificationsCount(0);
  };

  const handleSelectMarketplaceItem = item => {
    switch (item.type) {
      case constants.MARKETPLACE_ITEM_CHART:
        handleSelectChart(item.data);
        break;
      case constants.MARKETPLACE_ITEM_STUDY_TEMPLATE:
        handleSelectStudyTemplate(item.data);
        break;
      default:
        return;
    }
  };

  const handleSelectStudyTemplate = data => {
    if (chartRef.current) {
      chartRef.current.contentWindow.postMessage({
        type: 'SET_STUDY_TEMPLATE',
        payload: data,
      }, '*');
    }
  };

  const handleSelectChart = data => {
    if (chartRef.current) {
      chartRef.current.contentWindow.postMessage({
        type: 'SET_CHART',
        payload: data,
      }, '*');
    }
  };

  const handleEagleViewRowClick = (octId, pattern) => {
    setChartReady(false);
    const indicatorNotification = [];
    if (!!pattern.retracementPoint) {
      indicatorNotification.push('retracementPoint');
    }
    if (!!pattern.orderPartition) {
      indicatorNotification.push('orderPartition');
    }
    if (!!pattern.lowerTFEngulfing) {
      indicatorNotification.push('lowerTFEngulfing');
    }
    if (!!pattern.higherTFEngulfing) {
      indicatorNotification.push('higherTFEngulfing');
    }
    if (!!pattern.higherTFOrderPartition) {
      indicatorNotification.push('higherTFOrderPartition');
    }
    setHarmonicFilters(prev => ({
      ...prev,
      names: [pattern.patternname],
      types: [pattern.patterntype],
      sizes: [pattern.patternsize],
      deviationTypes: [pattern.signaltype],
      deviationValue: pattern.deviationValue,
      indicatorNotification,
    }));
    initialPatternRef.current = {...pattern, _id: octId};
    setChartParams(prev => ({
      ...prev,
      symbol: pattern.symbol,
      timeframe: pattern.timeframe,
      selectedPattern: octId,
    }));
  };

  const handleLoadMoreHarmonicNotifications = async () => {
    if (eagleViewPagePointer.current) {
      eagleViewPagePointer.current += 1;
    } else {
      eagleViewPagePointer.current = harmonicNotificationsFilters.page + 1;
    }
    const page = eagleViewPagePointer.current;
    const res = await getHarmonicPatternsList({...harmonicNotificationsFilters, page});
    setHarmonicNotifications(prev => prev.concat(res.data.map(parseHarmonicPattern)));
  };

  const handleIndicationFiltersChange = (field, filters) => {
    setIndicationFilters(prev => ({...prev, [field]: filters}));
  };

  useEffect(() => {
    // TODO: refactor this for better performance
    if (indicationFilters.engulfingAndOP) {
      if (chartRef.current) {
        chartRef.current.contentWindow.postMessage({
          type: 'SET_ENGULFING_AND_OP_FILTERS',
          payload: indicationFilters.engulfingAndOP,
        }, '*');
        saveAddonSettings({
          addonId: addon._id,
          field: 'indicationFilters',
          value: indicationFilters,
        });
      }
    }
  }, [indicationFilters]);



  return (
    <SkuidWrapper
      roles={userRoleList}
      skuIds={addon.subscriptions}
      loggedInUser={props.loggedInUser}
      addonName={addon.name}
    >
      <Helmet defer={false} title={`${addon.name} Addon`} />
      <>
        {
          chartUrl && (
            <>
              {
                (eagleView && addon.indicators.includes('harmonic_pattern')) ?
                  <EagleViewToolbar
                    addon={addon}
                    notificationFilters={harmonicNotificationsFilters}
                    handleNotificationFiltersChange={setHarmonicNotificationsFilters}
                    toggleEagleView={toggleEagleView}
                    syncEagleView={syncEagleView}
                    onRequestFullScreen={handleFullScreen}
                    indicationFilters={indicationFilters}
                    onIndicationFiltersChange={handleIndicationFiltersChange}
                    userSubscriptions={props.loggedInUser.subscriptions}
                    {
                      ...addon.indicators.includes('harmonic_pattern') && {
                        harmonicPatterns,
                        hiddenPatterns,
                        toggleHiddenPattern,
                        activePattern,
                        toggleActivePattern,
                        technicalIndicator,
                      }
                    }
                  />
                  :
                  <AddonToolbar
                    symbols={addon.symbols}
                    timeframes={addon.timeframes}
                    selectedSymbol={chartParams.symbol}
                    selectedTimeframe={chartParams.timeframe}
                    onChartParamChange={handleChartParamsChange}
                    rules={addon.rules}
                    links={addon.links}
                    addonId={addon._id}
                    eagleView={eagleView}
                    addon={addon}
                    indicationFilters={indicationFilters}
                    onIndicationFiltersChange={handleIndicationFiltersChange}
                    userSubscriptions={props.loggedInUser.subscriptions}
                    handleSelectMarketplaceItem={handleSelectMarketplaceItem}
                    setLoadingStatus={props.setLoadingStatus}
                    loggedInUserId={props.loggedInUser.id}
                    onRequestFullScreen={handleFullScreen}
                    {
                      ...addon.indicators.includes('harmonic_pattern') && {
                        harmonicFilters,
                        harmonicPatterns,
                        selectedHarmonicPatterns,
                        hiddenPatterns,
                        toggleHiddenPattern,
                        handleHarmonicFiltersChange,
                        handleSelectedPatternsChange,
                        activePattern,
                        toggleActivePattern,
                        handlePatternNotificationClick,
                        addonHarmonicFilters: addon.harmonicConfig.available,
                        addonHarmonicDefaultFilters: addon.harmonicConfig.default_,
                        technicalIndicator,
                        toggleEagleView,
                        harmonicNotifications,
                        harmonicNotificationsCount,
                        resetHarmonicNotificationsCount,
                      }
                    }
                    {
                      ...isAdmin && {
                        handlePublish: handlePublishLineTools,
                      }
                    }
                  />
              }
              <div className={props.classes.chartContent}>
                {
                  !chartReady && (
                    <div className={props.classes.loaderBackdrop}>
                      <FadeLoader
                        css={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                        }}
                        color={colors.blue500}
                      />
                    </div>
                  )
                }
                <div className={props.classes.chartWrapper}>
                  <iframe
                    src={chartUrl}
                    className={props.classes.chartIframe}
                    ref={chartRef}
                    title='oct'
                    {
                      ...eagleView && {style: {height: '70%'}}
                    }
                  />
                  {
                    eagleView && (
                      <HarmonicPatternsTable
                        patterns={harmonicNotifications}
                        onRowClick={handleEagleViewRowClick}
                        active={activePattern}
                        onLoadMore={handleLoadMoreHarmonicNotifications}
                        filters={harmonicNotificationsFilters}
                        eagleViewCtx
                      />
                    )
                  }
                </div>
                {
                  addon.signalsEnabled && (
                    <OpenIconButton
                      opened={showSignalPanel}
                      onToggle={toggleSignalPanel}
                    /> || null
                  )
                }
                {showSignalPanel && (
                  <ToolsLeftPanelWrapper>
                    <SignalsCard
                      title='Active Strategies'
                      lastBar={lastBar}
                      addon={addon}
                      chartParams={chartParams}
                      allSymbols={props.allSymbols}
                      signals={signals}
                      selectedSignal={selectedSignal}
                      onSignalSelect={handleSignalSelect}
                      onSignalAddedUpdated={updateSignals}



                      newSignals={[]}
                      selectedStrategy={{}}
                      selectedSymbolId={{}}
                      stratSymbols={[]}
                      symbols={addon.symbols}
                      strategies={[]}
                      newSignal={{}}
                    />
                  </ToolsLeftPanelWrapper>
                )}
              </div>
            </>
          )
        }
      </>
    </SkuidWrapper>
  );
};


const mapStateToProps = (state, ownProps) => {
  const addonId = ownProps.match.params.addonId;
  return {
    addon: state.addon.addons.find(a => a._id === addonId),
    userRole: state.auth.user.roles,
    allSymbols: state.symbol.data,
    loggedInUser: state.auth.user,
  };
};

const mapDispatchToProps = {
  setLoadingStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, {withTheme: true})(Addon));
