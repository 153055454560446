import { SET_CURRENT_THEME, SET_CURRENT_SIDEBAR } from "./types";

export const changeSideBar = (isDrawHandler) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_SIDEBAR,
    payload: isDrawHandler,
  });
};

export const changeTheme = (themeValue) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_THEME,
    payload: themeValue,
  });
};
