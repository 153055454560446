import { combineReducers } from "redux";
import authReducer from "./authReducer";
import themeReducer from "./themeReducer";
import errorReducer from "./errorReducer";
import loadingReducer from "./loadingReducer";
import chartReducer from "./chartReducer";
import brokerReducer from "./brokerReducer";
import symbolReducer from "./symbolReducer";
import notificationReducer from "./notificationReducer";
import strategyReducer from "./strategyReducer";
import toolsReducer from "./toolsReducer";
import addonReducer from './addonReducer';
import subscriptionReducer from './subscriptionReducer';
import configReducer from './configReducer';

export default combineReducers({
  auth: authReducer,
  theme: themeReducer,
  errors: errorReducer,
  loading: loadingReducer,
  chart: chartReducer,
  broker: brokerReducer,
  symbol: symbolReducer,
  notification: notificationReducer,
  strategy: strategyReducer,
  tools: toolsReducer,
  addon: addonReducer,
  subscription: subscriptionReducer,
  config: configReducer,
});
