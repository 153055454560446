import { SET_NOTIFICATIONS } from "./types";
import { getNotificationsByRole } from "../services/notification";

export const setNotifications = (newNotifications) => async (dispatch) => {
  let notifications;
  if (newNotifications) {
    notifications = newNotifications;
  } else {
    const { data: receivedNotifications } = await getNotificationsByRole();
    notifications = receivedNotifications;
  }
  dispatch({
    type: SET_NOTIFICATIONS,
    payload: notifications,
  });
};
