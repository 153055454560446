import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import {
  showErrorToast,
  removeItemWithSlice,
  getToolsTypesArray,
} from "../../../utils/utility";
import AddEditDialog from "./AddEditDialog";
import CustomMUIDataTable from "../../../components/CustomMUIDataTable";
import PlusAddButton from "../../../components/PlusAddButton";
import EditIconButton from "../../../components/EditIconButton";
import RemoveIconButton from "../../../components/RemoveIconButton";
import ConfirmDialog from "../../../components/ConfirmDialog";
import * as RULE_SERVICE from "../../../services/rule";
import { setLoadingStatus } from "../../../actions/loading";
import { tools, notifications, DISCLAIMER_RULE } from "../../../constants";
import { commonMUITableOptions } from "../../../utils/styles";
import { liveLanguages } from "../../../utils/languages";

class RuleManage extends Component {
  state = {
    addEditDialogOpened: false,
    confirmDialogOpened: false,
    dialogData: null,
    removeId: null,
    removeIdList: null,
    rules: [],
  };

  async componentDidMount() {
    const { setLoadingStatus } = this.props;
    setLoadingStatus({ loading: true, text: "Loading Rules..." });
    try {
      const { data: rules } = await RULE_SERVICE.getRules();
      setLoadingStatus({ loading: false });
      let availableRuleTypes = [];
      tools.forEach((tool) => availableRuleTypes.push(tool.value));
      availableRuleTypes.push(DISCLAIMER_RULE);

      const displayRules = rules.filter((rule) => {
        return availableRuleTypes.includes(rule.toolsType);
      });

      this.setState({ rules: displayRules });
    } catch (error) {
      console.log(
        "ray : [containers Admin RuleManage componentDidMount] error => ",
        error
      );
      setLoadingStatus({ loading: false });
      if (error.response) {
        const { message } = error.response.data;
        showErrorToast(message);
      }
    }
  }

  createTableData = (rules) => {
    const { defaultLanguage } = this.props;
    const displayLanguage = defaultLanguage || Object.keys(liveLanguages)[0];
    const tableData = rules.map((rule) => {
      const { _id, toolsType, description } = rule;
      const toolsTypeArray = getToolsTypesArray(tools, [], null);
      const { label: toolsName } = toolsTypeArray.find(
        (tools) => tools.value === toolsType
      );
      const row = [toolsName, description[displayLanguage], _id];
      return row;
    });
    return tableData;
  };

  columns = () => [
    { name: "Tools Type", options: { filter: false } },
    { name: "Description", options: { filter: false } },
    {
      name: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{ display: "flex" }}>
              <EditIconButton
                color="primary"
                onClick={() => this.openEditDialogHandler(value)}
              />
              <RemoveIconButton
                color="primary"
                onClick={() => this.openCloseConfirmDialogHandler(true, value)}
              />
            </div>
          );
        },
      },
    },
  ];

  options = {
    ...commonMUITableOptions,
    selectableRows: "multiple",
    onRowsDelete: (rowsDeleted) => {
      const { rules } = this.state;
      const idList = rules.map((item) => item._id);
      const removeIdList = idList.filter(
        (id, index) => rowsDeleted.lookup[index]
      );
      this.openCloseConfirmDialogHandler(true, null, removeIdList);
    },
    customToolbar: () => {
      return <PlusAddButton onClick={this.openAddDialogHandler} />;
    },
  };

  openAddDialogHandler = () => {
    const { rules } = this.state;
    if (rules.length === tools.length) {
      showErrorToast(notifications.NOT_ABLE_TO_ADD_RULE);
    } else {
      this.openCloseAddEditDialogHandler(true);
    }
  };

  openEditDialogHandler = (ruleId) => {
    const { rules } = this.state;
    const dialogData = rules.find((rule) => rule._id === ruleId);
    this.openCloseAddEditDialogHandler(true, dialogData);
  };

  closeAddEditDialogHandler = () => {
    this.openCloseAddEditDialogHandler(false);
  };

  openCloseAddEditDialogHandler = (opened, dialogData = null) => {
    this.setState({ addEditDialogOpened: opened, dialogData });
  };

  removeRuleHandler = async (removeId) => {
    try {
      const response = await RULE_SERVICE.removeRule(removeId);
      const { data: ruleFromBackend } = response;
      this.removeRule(ruleFromBackend);
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        showErrorToast(message);
      }
    }
  };

  removeMultiRulesHandler = async (removeIds) => {
    try {
      await RULE_SERVICE.removeRules(removeIds);
      this.removeMultiRules(removeIds);
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        showErrorToast(message);
      }
    }
  };

  addEditRuleHandler = (rule) => {
    let { rules } = this.state;
    rules = [...rules];
    const targetIndex = rules.findIndex((item) => item._id === rule._id);

    if (targetIndex >= 0) {
      rules[targetIndex] = rule;
    } else {
      rules = [...rules, rule];
    }
    this.setState({ rules });
  };

  removeRule = (rule) => {
    let { rules } = this.state;

    const targetIndex = rules.findIndex((item) => item._id === rule._id);
    rules = removeItemWithSlice(rules, targetIndex);
    this.setState({ rules });
  };

  removeMultiRules = (ruleIds) => {
    let { rules } = this.state;

    rules = rules.filter((item) => !ruleIds.includes(item._id));
    this.setState({ rules });
  };

  openCloseConfirmDialogHandler = (opened, removeId, removeIdList) => {
    this.setState({ confirmDialogOpened: opened, removeId, removeIdList });
  };

  confirmRemoveDialogHandler = () => {
    const { removeId, removeIdList } = this.state;
    if (removeId) {
      this.removeRuleHandler(removeId);
    } else if (removeIdList && removeIdList.length > 0) {
      this.removeMultiRulesHandler(removeIdList);
    }
  };

  render() {
    const {
      addEditDialogOpened,
      dialogData,
      confirmDialogOpened,
      removeIdList,
    } = this.state;
    const { rules } = this.state;
    const tableData = this.createTableData(rules);

    return (
      <Fragment>
        {addEditDialogOpened && (
          <AddEditDialog
            manageTitle="Rule"
            opened={addEditDialogOpened}
            isAddDialog={dialogData ? false : true}
            dialogData={dialogData}
            addEditRule={this.addEditRuleHandler}
            closed={this.closeAddEditDialogHandler}
            rules={rules}
            addons={this.props.addons}
          />
        )}
        <CustomMUIDataTable
          title="Rules"
          data={tableData}
          columns={this.columns()}
          options={this.options}
        />
        <ConfirmDialog
          isMultiple={!!removeIdList}
          opened={confirmDialogOpened}
          closed={() => this.openCloseConfirmDialogHandler(false)}
          confirmed={this.confirmRemoveDialogHandler}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  addons: state.addon.addons,
  defaultLanguage: state.auth.user.defaultLanguage,
});

const mapActionToProps = {
  setLoadingStatus,
};

export default connect(mapStateToProps, mapActionToProps)(RuleManage);
