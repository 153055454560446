import axios from 'axios';
import {getConfigUrl} from './endpoints';


export const getConfig = async () => {
  const res = await axios.get(getConfigUrl());
  return res.data;
};

export const updateConfig = async configData => {
  const res = await axios.put(getConfigUrl(), configData);
  return res.data;
};
