
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { showErrorToast } from '../../../utils/utility';
import AddEditDialog from './AddEditDialog';
import CustomMUIDataTable from '../../../components/CustomMUIDataTable';
import PlusAddButton from '../../../components/PlusAddButton';
import EditIconButton from '../../../components/EditIconButton';
import RemoveIconButton from '../../../components/RemoveIconButton';
import ConfirmDialog from '../../../components/ConfirmDialog';
import * as BROKER_SERVICE from '../../../services/broker';
import { setBrokers, addEditBroker, removeBroker } from '../../../actions/broker';
import { setLoadingStatus } from '../../../actions/loading';
import { commonMUITableOptions } from '../../../utils/styles';

class BrokerManage extends Component {
    state = {
        addEditDialogOpened: false,
        confirmDialogOpened: false,
        dialogData: null,
        removeId: null,
        removeIdList: null,
    };

    async componentDidMount() {
        const { setLoadingStatus, setBrokers } = this.props;
        setLoadingStatus({loading: true, text: 'Loading brokers...'});
        await setBrokers();
        setLoadingStatus({loading: false});
    }

    createTableData = data => {
        const tableData = data.map(broker => {
            const { name, code, isDemo, _id } = broker;
            const labels = ['Real', 'Demo'];
            const row = [
                name,
                code,
                labels[isDemo ? 0 : 1],
                _id
            ];
            return row;
        });
        return tableData;
    };

    columns = () => [
        {name: 'Name', options: {filter: false}},
        {name: 'Code', options: {filter: false}},
        {name: 'Type'},
        {
            name: 'Action',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <div style={{display: 'flex'}}>
                            <EditIconButton color='primary' onClick={() => this.openEditDialogHandler(value)} />
                            <RemoveIconButton color='primary' onClick={() => this.openCloseConfirmDialogHandler(true, value)} />
                        </div>
                    );
                }
            }
        }
    ];

    options = {
        ...commonMUITableOptions,
        selectableRows: 'multiple',
        onRowsDelete: rowsDeleted => {
            const { brokers } = this.props;
            const idList = brokers.map(item => item._id);
            const removeIdList = idList.filter((id, index) => rowsDeleted.lookup[index]);
            this.openCloseConfirmDialogHandler(true, null, removeIdList);
        },
        customToolbar: () => {
            return (
                <PlusAddButton onClick={this.openAddDialogHandler} />
            );
        }
    };

    openAddDialogHandler = () => {
        this.openCloseAddEditDialogHandler(true);
    };

    openEditDialogHandler = brokerId => {
        const { brokers } = this.props;
        const dialogData = brokers.find(broker => broker._id === brokerId);
        this.openCloseAddEditDialogHandler(true, dialogData);
    };
    
    closeAddEditDialogHandler = () => {
        this.openCloseAddEditDialogHandler(false);
    };

    openCloseAddEditDialogHandler = (opened, dialogData=null) => {
        this.setState({addEditDialogOpened: opened, dialogData});
    };

    removeBrokerHandler = async brokerId => {
        try {
            const response = await BROKER_SERVICE.removeBroker(brokerId);
            const { data: brokerFromBackend } = response;
            this.removeBrokerData(brokerFromBackend);
        } catch (error) {
            if (error.response) {
                const { message } = error.response.data;
                showErrorToast(message);
            }
        }
    };

    removeMultiBrokersHandler = async brokerIds => {
        try {
            await BROKER_SERVICE.removeBrokers(brokerIds);
            // TODO: redux update
        } catch (error) {
            if (error.response) {
                const { message } = error.response.data;
                showErrorToast(message);
            }
        }
    };

    addEditBrokerHandler = brokerFromBackend => {
        const { addEditBroker } = this.props;
        addEditBroker(brokerFromBackend);
    };

    removeBrokerData = brokerFromBackend => {
        const { removeBroker } = this.props;
        removeBroker(brokerFromBackend);
    };

    openCloseConfirmDialogHandler = (opened, removeId, removeIdList) => {
        this.setState({confirmDialogOpened: opened, removeId, removeIdList});
    };

    confirmRemoveDialogHandler = () => {
        const { removeId, removeIdList } = this.state;
        if (removeId) {
            this.removeBrokerHandler(removeId)
        } else if(removeIdList && removeIdList.length > 0) {
            this.removeMultiBrokersHandler(removeIdList);
        }
    }

    render() {
        const { addEditDialogOpened, dialogData, confirmDialogOpened, removeIdList } = this.state;
        const { brokers } = this.props;
        const tableData = this.createTableData(brokers);
        return (
            <Fragment>
                { addEditDialogOpened && (
                    <AddEditDialog
                        manageTitle='Broker'
                        opened={addEditDialogOpened}
                        isAddDialog={dialogData ? false : true}
                        dialogData={dialogData}
                        addEditBroker={this.addEditBrokerHandler}
                        closed={this.closeAddEditDialogHandler} />
                ) }
                <CustomMUIDataTable
                    title='Brokers'
                    data={tableData}
                    columns={this.columns()}
                    options={this.options} />
                <ConfirmDialog
                    isMultiple={!!removeIdList}
                    opened={confirmDialogOpened}
                    closed={() => this.openCloseConfirmDialogHandler(false)}
                    confirmed={this.confirmRemoveDialogHandler} />
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    brokers: state.broker.data
});

const mapActionToProps = {
    setLoadingStatus,
    setBrokers,
    addEditBroker,
    removeBroker
};

export default connect(
    mapStateToProps,
    mapActionToProps
)(BrokerManage);
