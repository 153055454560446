
import React, { Fragment } from 'react';
import classNames from 'classnames';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';

import { getSpacingUnit, getFontUnit } from '../../utils/utility';
import { boxShadow, robotoFont, isWidthWiderThan } from '../../utils/styles';
import { liveLanguages } from '../../utils/languages';

const styles = theme => {
	const fontSizeUnit = getFontUnit(theme);
	const spacingUnit = getSpacingUnit(theme);

    return {
        root: {
			color: theme.palette.primary[0],
			marginBottom: spacingUnit * 0.6,
			fontFamily: robotoFont,
			fontSize: fontSizeUnit * 2,
			'& .selected--flag--option': {
				// fontSize: `${fontSizeUnit * 1.8}px !important`,
				'& .arrow-down':{
					color: theme.palette.highlightColor,
					paddingLeft: spacingUnit * 0.7,
					paddingTop: spacingUnit * 2.5
				},
				'& .country-flag span': {
					fontSize: `${fontSizeUnit * 1.2}px !important`
				}
			},
			'& .flag-options': {
				padding: 0,
				border: 0,
				backgroundColor: theme.palette.selectorBgColor,
				boxShadow: boxShadow,
				
				'& .flag-option': {
					paddingTop: spacingUnit / 2,
					paddingBottom: spacingUnit * 1.5,
					paddingLeft: spacingUnit * 2,
					fontSize: fontSizeUnit * 1.8,
					margin: 0,
					'&:hover': {
						backgroundColor: theme.palette.selectorHoverBgColor,
						color: theme.palette.highlightColor
					}
				},
				'&::-webkit-scrollbar': {
					display: 'none'
				}
			}
		},
		// TODO: * typography default color set using theme -> more correct
		thumbnailTitle: {
			color: theme.palette.subForeColor3
		}
    };
};

const LanguageSelector = ({ classes, selectedLanguage, languageSelected, caption, passedClass, width }) => {
	const languages = Object.keys(liveLanguages);
	const customLabels = liveLanguages;

	const selectLanguageHandler = language => {
		languageSelected(language);
	};

	const languagePlaceholder = !isWidthWiderThan('sm', width) ? 'Language' : 'Select Language';
	return (
		<Fragment>
			{ caption && (
				<Typography variant='subtitle1' className={classes.thumbnailTitle}>
					Language
				</Typography>
            ) }
            {selectedLanguage === 'default' ? 
                <Fragment>
                    <ReactFlagsSelect
                        placeholder={languagePlaceholder}
                        countries={languages}
                        customLabels={customLabels}
                        showOptionFlag={false}
                        showSelectedLabel={true}
                        onSelect={selectLanguageHandler}
                        className={classNames(classes.root, passedClass)} />
                </Fragment>
				: <ReactFlagsSelect
                    value={selectedLanguage}
                    defaultCountry={selectedLanguage}
                    placeholder={languagePlaceholder}
                    countries={languages}
                    customLabels={customLabels}
                    showOptionFlag={false}
                    showSelectedLabel={true}
                    onSelect={selectLanguageHandler}
                    className={classNames(classes.root, passedClass)} />}
		</Fragment>
    );
};

export default withWidth()(withStyles(styles, {withTheme: true})(LanguageSelector));
