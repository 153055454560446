
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const HighChart = ({ options }) => (
    <HighchartsReact
        highcharts={Highcharts}
        options={options} />
);

export default HighChart;
