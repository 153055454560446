
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import AddEditDialog from './AddEditDialog';
import CustomMUIDataTable from '../../../components/CustomMUIDataTable';
import PlusAddButton from '../../../components/PlusAddButton';
import EditIconButton from '../../../components/EditIconButton';
import RemoveIconButton from '../../../components/RemoveIconButton';
import ConfirmDialog from '../../../components/ConfirmDialog';
import LinkOnIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';

import CustomSwitchButton from '../../../components/CustomSwitchButton';
import { getNotifications, removeNotification, removeNotifications, editNotification } from '../../../services/notification';
import { getRoleLabel, showErrorToast, removeItemWithSlice } from '../../../utils/utility';
import { commonMUITableOptions } from '../../../utils/styles';
import { setLoadingStatus } from '../../../actions/loading';

class NotificationManage extends Component {
    state = {
        addEditDialogOpened: false,
        confirmDialogOpened: false,
        notifications: [],
        dialogData: null,
        removeId: null,
        removeIdList: null,
        showHideButtonDisabled: false
    };

    async componentDidMount() {
        const { setLoadingStatus, loggedInUser } = this.props;
        setLoadingStatus({loading: true, text: 'Loading notifications...'});
        try {
            const { data: notifications } = await getNotifications(loggedInUser.id);
            setLoadingStatus({loading: false});
            this.setState({notifications});
        } catch (error) {
            console.log('ray : [containers Admin NotificatoinManage componentDidMount] error => ', error);
            setLoadingStatus({loading: false});
            if (error.response) {
                const { message } = error.response.data;
                showErrorToast(message);
            }
        }
    }

    createTableData = notifications => {
        const tableData = notifications.map(notification => {
            const { message, accessRoleValues, type, show, _id } = notification;
            const accessRoleLabels = accessRoleValues.map(accessRoleValue => {
                const accessRoleLabel = getRoleLabel(accessRoleValue);
                return accessRoleLabel;
            });
            const labels = ['Shown', 'Hidden'];
            const row = [
                message,
                accessRoleLabels.join(' | '),
                type,
                labels[show ? 0 : 1], //show,
                _id
            ];
            return row;
        });
        return tableData;
    };

    columns = () => [
        {name: 'Message', options: {filter: false}},
        {name: 'Access Roles', options: {filter: false}},
        {name: 'Type'},
        {name: 'Show'},
        // {
        //     name: 'Show',
        //     options: {
        //         customBodyRender: value => {
        //             const labels = ['Shown', 'Hidden'];
        //             return (
        //                 <CustomChip flag={value} labels={labels} />
        //             );
        //         }
        //     }
        // },
        {
            name: 'Action',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    const { showHideButtonDisabled, notifications } = this.state;
                    const notification = notifications.find(notification => notification._id === value);
                    return (
                        <div style={{display: 'flex'}}>
                            <CustomSwitchButton
                                color='primary'
                                flag={notification.show}
                                trueIcon={<LinkOnIcon />}
                                falseIcon={<LinkOffIcon />}
                                disabled={showHideButtonDisabled}
                                onClick={() => this.showHideNotificationHandler(value)} />
                            <EditIconButton color='primary' onClick={() => this.openEditDialogHandler(value)} />
                            <RemoveIconButton color='primary' onClick={() => this.openCloseConfirmDialogHandler(true, value)} />
                        </div>
                    );
                }
            }
        }
    ];
  
    options = {
        ...commonMUITableOptions,
        selectableRows: 'multiple',
        onRowsDelete: rowsDeleted => {
            const { notifications } = this.state;
            const idList = notifications.map(item => item._id);
            const removeIdList = idList.filter((id, index) => rowsDeleted.lookup[index]);
            this.openCloseConfirmDialogHandler(true, null, removeIdList);
        },
        customToolbar: () => {
            return (
                <PlusAddButton onClick={this.openAddDialogHandler} />
            );
        }
    };

    // open close dialog handlers
    openAddDialogHandler = () => {
        this.openCloseAddEditDialogHandler(true);
    };
    openEditDialogHandler = notificationId => {
        const { notifications } = this.state;
        const dialogData = notifications.find(notification => notification._id === notificationId);
        this.openCloseAddEditDialogHandler(true, dialogData);
    };
    closeAddEditDialogHandler = () => {
        this.openCloseAddEditDialogHandler(false);
    };
    openCloseAddEditDialogHandler = (opened, dialogData=null) => {
        this.setState({addEditDialogOpened: opened, dialogData});
    };
    openCloseConfirmDialogHandler = (opened, removeId, removeIdList) => {
        this.setState({confirmDialogOpened: opened, removeId, removeIdList});
    };

    removeNotificationHandler = async notificationId => {
        try {
            const response = await removeNotification(notificationId);
            const { data: notificationFromBackend } = response;
            this.removeNotificationData(notificationFromBackend);
        } catch (error) {
            if (error.response) {
                const { message } = error.response.data;
                showErrorToast(message);
            }
        }
    };

    removeMultiNotificationsHandler = async notificationIds => {
        try {
            await removeNotifications(notificationIds);
            this.removeMultiNotificationsData(notificationIds);
        } catch (error) {
            if (error.response) {
                const { message } = error.response.data;
                showErrorToast(message);
            }
        }
    };

    showHideNotificationHandler = async notificationId => {
        try {
            let { notifications } = this.state;
            notifications = [...notifications];
            const notification = notifications.find(notification => notification._id === notificationId);
            notification.show = !notification.show;

            this.setState({showHideButtonDisabled: true});
            const response = await editNotification(notification);
            const { data: notificationFromBackend } = response;

            const targetIndex = notifications.findIndex(user => (
                user._id === notificationFromBackend._id
            ));
            notifications[targetIndex] = notificationFromBackend;
            this.setState({notifications});
        } catch (error) {
            if (error.response) {
                const { message } = error.response.data;
                showErrorToast(message);
            }
        }
        this.setState({showHideButtonDisabled: false});
    };

    addEditNotificationHandler = notificationFromBackend => {
        let { notifications } = this.state;
        notifications = [...notifications];
        const targetIndex = notifications.findIndex(notification => (
            notification._id === notificationFromBackend._id
        ));
        if (targetIndex >= 0) {
            notifications[targetIndex] = notificationFromBackend;
        } else {
            notifications = [
                ...notifications,
                notificationFromBackend
            ];
        }
        this.setState({notifications});
    };

    removeMultiNotificationsData = notificationIds => {
        if (!notificationIds || !notificationIds.length) {
            return;
        }
        let { notifications } = this.state;

        notifications = notifications.filter(item => !notificationIds.includes(item._id));
        this.setState({notifications});
    };

    removeNotificationData = notificationFromBackend => {
        if (!notificationFromBackend) {
            return;
        }
        let { notifications } = this.state;

        const targetIndex = notifications.findIndex(notification => (
            notification._id === notificationFromBackend._id
        ));
        notifications = removeItemWithSlice(notifications, targetIndex);
        this.setState({notifications});
    };

    confirmRemoveDialogHandler = () => {
        const { removeId, removeIdList } = this.state;
        if (removeId) {
            this.removeNotificationHandler(removeId)
        } else if (removeIdList && removeIdList.length > 0) {
            this.removeMultiNotificationsHandler(removeIdList);
        }
    }

    render() {
        const { loggedInUser } = this.props;
        const { addEditDialogOpened, notifications, dialogData, confirmDialogOpened, removeIdList } = this.state;

        const tableData = this.createTableData(notifications);
        return (
            <Fragment>
                { addEditDialogOpened && (
                    <AddEditDialog
                        manageTitle='Notification'
                        opened={addEditDialogOpened}
                        loggedInUser={loggedInUser}
                        isAddDialog={dialogData ? false : true}
                        dialogData={dialogData}
                        addEditNotification={this.addEditNotificationHandler}
                        closed={this.closeAddEditDialogHandler} />
                ) }
                <CustomMUIDataTable
                    title='Notifications'
                    data={tableData}
                    columns={this.columns()}
                    options={this.options} />
                <ConfirmDialog
                    isMultiple={!!removeIdList}
                    opened={confirmDialogOpened}
                    closed={() => this.openCloseConfirmDialogHandler(false)}
                    confirmed={this.confirmRemoveDialogHandler} />
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    loggedInUser: state.auth.user
});

const mapActionToProps = {
    setLoadingStatus
};

export default connect(
    mapStateToProps,
    mapActionToProps
)(NotificationManage);
