import React, {useMemo} from 'react';
import {groupN} from "../../utils/utility";
import constants  from "../../constants";
import CustomSwitch from "../CustomSwitch";
import {isValidDarkTheme} from "../../utils/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Checkbox, MenuItem, Select} from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import {usePremiumLockInfo} from "../../hoc/PremiumLockInfo";

const capitalize = string => string[0].toUpperCase() + string.slice(1);

const PATTERN_NAME_SPECIAL = {
  order_partition: 'Order Partition',
  orderpartition: 'Order Partition',
  hangingman: 'Hanging Man',
  morningstar: 'Morning Star',
  incersehammer: 'Inverted Hammer',
  hammer: 'Hammer',
  inversehangingman: 'Inverted Hanging Man',
  '3blackvrows': '3 Black Crows',
  '3whitesoldiers': '3 White Soldiers',
  eveningstar: 'Evening Star',
};

const ensurePatternName = patternName => (
  PATTERN_NAME_SPECIAL[patternName] || capitalize(patternName.replace(/_/g, ' '))
);

const styles = theme => {
  return {
    container: {
      marginLeft: '10px',
    },
    selectedContainer: {
      marginLeft: '10px',
      backgroundColor: 'rgb(33, 150, 243)',
    },
    icon: {
      color: isValidDarkTheme(theme.palette.type) ? theme.palette.iconColor : `rgba(0, 0, 0, 0.54)`,
      paddingTop: 5
    },
    filtersBlock: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#1f272e7d',
      padding: '10px 0',
    },
    divider: {
      height: '1px',
      backgroundColor: 'aliceblue',
      margin: '10px 0',
    },
    switchesBlock: {
      display: 'flex',
      minWidth: '400px',
    },
    switchWrapper: {
      flex: 1,
      justifyContent: 'flex-start',
    },
    switchContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    defaultSelectedWrapper: {
      paddingLeft: '12px',
      marginTop: '-10px',
    },
    selectWrapper: {
      padding: '20px',
    },
    colorSwitchBase: {
      '&$colorChecked': {
        color: '#198ee8',
        '& + $colorBar': {
          backgroundColor: '#52a8ea',
        },
      },
    },
    colorBar: {},
    colorChecked: {},
  }
};

const FREE = 'Free';


const EngulfingAndOPList = props => {
  const {showPremiumLockInfo} = usePremiumLockInfo();
  const {
    classes,
    filters,
    defaultFilters,
    availableFilters,
    onChange,
    onChangeDefault,
    configMode,
  } = props;

  const handlePremiumLockClick = premiumLock => {
    showPremiumLockInfo(premiumLock);
  };

  const handlePremiumFiltersChange = (filterName, filterValue, subscriptionValue) => {
    if (subscriptionValue === FREE) {
      props.onChangePremium({
        ...props.premiumFilters,
        [filterName]: props.premiumFilters[filterName].filter(pf => pf.filterValue !== filterValue)
      });
      return;
    }
    const subscription = props.subscriptions.find(s => s.value === subscriptionValue);
    props.onChangePremium({
      ...props.premiumFilters,
      [filterName]: [
        ...props.premiumFilters[filterName].filter(pf => pf.filterValue !== filterValue),
        {filterValue, subscriptionValue, subscriptionName: subscription.name}
      ],
    });
  };

  const handleDefaultSelectedFilterChange = (field, value) => {
    const existing = defaultFilters[field];
    if (existing) {
      const active = existing.includes(value);
      const new_ = {
        ...defaultFilters,
        [field]: active ?
          existing.filter(f => f !== value) : [...existing, value]
      }
      onChangeDefault(new_);
    }
  };

  const handleMultiFilterChange = (field, value) => {
    const existing = filters[field];
    if (existing) {
      const active = existing.includes(value);
      const new_ = {
        ...filters,
        [field]: active ?
          existing.filter(f => f !== value) : [...existing, value]
      };
      onChange(new_);
    }
  };

  const final = useMemo(() => {
    if (!availableFilters || configMode) {
      return constants.addonEngulfingAndOPConfig.default_;
    }
    return {
      names: constants.addonEngulfingAndOPConfig.available.names.filter(
        v => availableFilters.names.includes(v),
      ),
      types: constants.addonEngulfingAndOPConfig.available.types.filter(
        v => availableFilters.types.includes(v),
      )
    }
  }, [availableFilters]);

  const names = groupN(final.names, 2);
  const types = groupN(final.types, 2);

  const subscriptionOptions = useMemo(() => {
    if (props.configMode) {
      return [{label: FREE, value: FREE}, ...props.subscriptions.map(sub => ({label: sub.name, value: sub.value}))]
    }
    return [];
  }, [props.subscriptions]);

  return (
    <div className={classes.filtersBlock}>
      {
        names.map(group => {
          return (
            <div className={classes.switchesBlock} key={group[0]}>
              {
                group.map(name => {
                  const checked = filters.names.includes(name);
                  let premiumLock;
                  if (!props.configMode) {
                    premiumLock = props.premiumLockedFilters.names.find(pf => pf.filterValue === name);
                  }
                  return (
                    <div
                      className={classes.switchWrapper}
                      key={name}
                    >
                      <div className={classes.switchContainer}>
                        <CustomSwitch
                          label={ensurePatternName(name)}
                          labelPlacement='end'
                          checked={checked}
                          switchHandler={
                            premiumLock ? () => handlePremiumLockClick(premiumLock) : () => handleMultiFilterChange('names', name)
                          }
                          overrideClasses={{
                            switchBase: classes.colorSwitchBase,
                            checked: classes.colorChecked,
                            bar: classes.colorBar,
                          }}
                        />
                        {
                          premiumLock && (
                            <LockIcon
                              style={{marginLeft: '-5px'}}
                              color='primary'
                            />
                          )
                        }
                      </div>
                      {
                        (configMode && checked) && (
                          <div>
                            <div className={classes.defaultSelectedWrapper}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color='primary'
                                    checked={defaultFilters.names.includes(name)}
                                    onChange={() => handleDefaultSelectedFilterChange('names', name)} />
                                }
                                label='Default Selected'
                              />
                            </div>
                            <div className={classes.selectWrapper}>
                              <Select
                                label='Select premium filter subscription'
                                value={(() => {
                                  const existing = props.premiumFilters.names.find(pf => pf.filterValue === name);
                                  return !!existing ? existing.subscriptionValue : FREE
                                })()}
                                style={{width: '100%'}}
                                onChange={e => handlePremiumFiltersChange('names', name, e.target.value)}
                              >
                                {
                                  subscriptionOptions.map(option => (
                                    <MenuItem
                                      key={option.label}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))
                                }
                              </Select>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  )
                })
              }
            </div>
          )
        })
      }
      {
        types.map(group => {
          return (
            <div className={classes.switchesBlock} key={group[0]}>
              {
                group.map(type => {
                  const checked = filters.types.includes(type);
                  return (
                    <div
                      className={classes.switchWrapper}
                      key={type}
                    >
                      <CustomSwitch
                        label={ensurePatternName(type)}
                        labelPlacement='end'
                        checked={checked}
                        switchHandler={() => handleMultiFilterChange('types', type)}
                        overrideClasses={{
                          switchBase: classes.colorSwitchBase,
                          checked: classes.colorChecked,
                          bar: classes.colorBar,
                        }}
                      />
                      {
                        (configMode && checked) && (
                          <div className={classes.defaultSelectedWrapper}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color='primary'
                                  checked={defaultFilters.types.includes(type)}
                                  onChange={() => handleDefaultSelectedFilterChange('types', type)} />
                              }
                              label='Default Selected'
                            />
                          </div>
                        )
                      }
                    </div>
                  )
                })
              }
            </div>
          )
        })
      }
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(EngulfingAndOPList);
