import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { isValidDarkTheme } from '../../utils/styles';

const styles = theme => {
	return {
		switchBase: {
			color: isValidDarkTheme(theme.palette.type)
                ? theme.palette.mainBackColor
                : theme.palette.subForeColor5,
			'&$colorChecked': {
				color: isValidDarkTheme(theme.palette.type)
			  		? theme.palette.subForeColor5
			  		: theme.palette.mainForeColor,
				'& + $colorBar': {
					backgroundColor: theme.palette.subForeColor3
				},
			},
		},
		colorChecked: {},
		colorBar: {},
        enableAlertSwitch: {
			marginLeft: 0,
			height: 40
        },
	};
};

const CustomSwitch = ({ switchHandler, classes, checked, label, overrideClasses = {}, labelPlacement='start', icon = null }) => {
  return (
    <FormControlLabel
      className={classes.enableAlertSwitch}
      labelPlacement={labelPlacement}
      label={label}
      control={
        <>
          <Switch
            classes={{
              switchBase: classes.switchBase,
              checked: classes.colorChecked,
              bar: classes.colorBar,
              ...overrideClasses,

            }}
            disableRipple
            checked={checked}
            onChange={switchHandler} />
          {icon}
        </>
      } />
  );
}

export default withStyles(styles, { withTheme: true })(CustomSwitch);
