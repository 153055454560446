import React, {useMemo} from 'react';
import {groupN} from "../../utils/utility";
import constants, {harmonicPatternDeviationValues} from "../../constants";
import CustomSwitch from "../CustomSwitch";
import {Checkbox, MenuItem, Select} from "@material-ui/core";
import {isValidDarkTheme} from "../../utils/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LockIcon from '@material-ui/icons/Lock';
import {usePremiumLockInfo} from "../../hoc/PremiumLockInfo";

const capitalize = string => string[0].toUpperCase() + string.slice(1);

const PATTERN_NAME_SPECIAL = {
  order_partition: 'Order Partition',
  lowerTFEngulfing: 'Lower TF Engulfing',
  orderPartition: 'Lower TF Order Partition',
  retracementPoint: 'Retracement Point',
  higherTFEngulfing: 'Higher TF Engulfing',
  higherTFOrderPartition: 'Higher TF Order Partition',
};

const ensurePatternName = patternName => (
  PATTERN_NAME_SPECIAL[patternName] || capitalize(patternName.replace(/_/g, ' '))
);

const FREE = 'Free';

const styles = theme => {
  return {
    container: {
      marginLeft: '10px',
    },
    selectedContainer: {
      marginLeft: '10px',
      backgroundColor: 'rgb(33, 150, 243)',
    },
    icon: {
      color: isValidDarkTheme(theme.palette.type) ? theme.palette.iconColor : `rgba(0, 0, 0, 0.54)`,
      paddingTop: 5
    },
    filtersBlock: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#1f272e7d',
      padding: '10px 0',
    },
    divider: {
      height: '1px',
      backgroundColor: 'aliceblue',
      margin: '10px 0',
    },
    switchesBlock: {
      display: 'flex',
      minWidth: '400px',
    },
    switchWrapper: {
      flex: 1,
      justifyContent: 'flex-start',
    },
    switchContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    defaultSelectedWrapper: {
      paddingLeft: '12px',
      marginTop: '-10px',
    },
    selectWrapper: {
      padding: '20px',
    },
    colorSwitchBase: {
      '&$colorChecked': {
        color: '#198ee8',
        '& + $colorBar': {
          backgroundColor: '#52a8ea',
        },
      },
    },
    colorBar: {},
    colorChecked: {},
  }
};


const HarmonicPatternsFiltersList = props => {

  const {showPremiumLockInfo} = usePremiumLockInfo();

  const handleDefaultSelectedFilterChange = (field, value) => {
    if (field === 'deviationValue') {
      props.onChangeDefault({...props.defaultFilters, deviationValue: value});
      return;
    }
    const existing = props.defaultFilters[field];
    if (existing) {
      const active = existing.includes(value);
      const new_ = {
        ...props.defaultFilters,
        [field]: active ?
          existing.filter(f => f !== value) : [...existing, value]
      }
      props.onChangeDefault(new_);
    }
  };

  const handlePremiumFiltersChange = (filterName, filterValue, subscriptionValue) => {
    if (subscriptionValue === FREE) {
      props.onChangePremium({
        ...props.premiumFilters,
        [filterName]: props.premiumFilters[filterName].filter(pf => pf.filterValue !== filterValue)
      });
      return;
    }
    const subscription = props.subscriptions.find(s => s.value === subscriptionValue);
    props.onChangePremium({
      ...props.premiumFilters,
      [filterName]: [
        ...props.premiumFilters[filterName].filter(pf => pf.filterValue !== filterValue),
        {filterValue, subscriptionValue, subscriptionName: subscription.name}
      ],
    });
  };

  const handlePremiumLockClick = premiumLock => {
    showPremiumLockInfo(premiumLock);
  }

  const handleMultiFilterChange = (field, value) => {
    if (field === 'deviationTypes' && !props.configMode) {
      handleDeviationTypesFilterChange(value);
      return;
    }
    const existing = props.filters[field];
    if (existing) {
      const active = existing.includes(value);
      const new_ = {
        ...props.filters,
        [field]: active ?
          existing.filter(f => f !== value) : [...existing, value]
      };
      props.onChange(new_);
      if (props.configMode && props.onChangeDefault && props.defaultFilters) {
        const newDefault = {
          ...props.defaultFilters,
          [field]: props.defaultFilters[field].filter(f => new_[field].includes(f))
        };
        props.onChangeDefault(newDefault);
      }
    }
  };

  const handleDeviationTypesFilterChange = value => {
    let new_;
    const current = props.filters.deviationTypes;
    const isActive = current.includes(value);
    // this logic is temporary ( https://mdmcc.slack.com/archives/C025CNZE7RQ/p1639086746012400 )
    // we just need to ensure that deviationValue set to null when custom deviation turn off
    if (value === 'Set Deviation' && isActive) {
      new_ = {
        ...props.filters,
        deviationTypes: current.filter(v => v !== value),
        deviationValue: null,
      }
    } else if (value === 'Set Deviation' && !isActive) {
      new_ = {
        ...props.filters,
        deviationTypes: [...current, value],
        deviationValue: harmonicPatternDeviationValues[0],
      }
    } else {
      new_ = {
        ...props.filters,
        deviationTypes: isActive ?
          current.filter(f => f !== value) : [...current, value]
      }
    }

    // TODO: THIS IS MAIN METHOD LOGIC.
    // // Turn on 'Set Deviation'. Need to remove aggressive
    // if (value === 'Set Deviation' && !isActive) {
    //   new_ = {
    //     ...props.filters,
    //     deviationTypes: [value, 'Conservative', ...current.filter(v => v !== 'Aggressive')],
    //     deviationValue: harmonicPatternDeviationValues[0],
    //   }
    //   // Turn on 'Aggressive'
    // } else if (
    //   (value === 'Aggressive' && !isActive)
    // ) {
    //   new_ = {
    //     ...props.filters,
    //     deviationTypes: [value, ...current.filter(v => v !== 'Set Deviation')],
    //     deviationValue: null,
    //   }
    //   // Turn off 'Conservative'
    // } else if (value === 'Conservative' && isActive) {
    //   new_ = {
    //     ...props.filters,
    //     deviationTypes: current.filter(v => v !== 'Set Deviation' && v !== 'Conservative'),
    //     deviationValue: null,
    //   }
    // } else {
    //   new_ = {
    //     ...props.filters,
    //     deviationTypes: isActive ?
    //       current.filter(f => f !== value) : [...current, value]
    //   }
    // }
    // ensure that MA200 can be turned on
    const ma200Available = new_.deviationTypes.includes('Aggressive') ||
      new_.deviationTypes.includes('Conservative');
    if (!ma200Available) {
      new_ = {
        ...new_,
        deviationTypes: new_.deviationTypes.filter(v => v !== 'MA200')
      }
    }
    props.onChange(new_);
  };

  const handleSingleFilterChange = (field, value) => {
    let newValue = value;
    // ignore clicks on Set Deviation switch if it's already selected
    if (
      field === 'deviation' &&
      newValue === 'Set Deviation' &&
      constants.harmonicPatternDeviationValues.includes(props.filters.deviation)
    ) {
      return;
    }
    // set default deviation value
    if (
      field === 'deviation' &&
      newValue === 'Set Deviation'
    ) {
      newValue = constants.harmonicPatternDeviationValues[0];
    }
    props.onChange({
      ...props.filters,
      [field]: newValue,
    });
  };

  const showDeviationValueSelect = props.filters.deviationTypes.includes('Set Deviation');

  const {classes} = props;

  const finalFilters = useMemo(() => {
    const {availableFilters} = props;

    if (!availableFilters) {
      return {
        harmonicPatternSizes: constants.harmonicPatternSizes,
        harmonicPatternNames: constants.harmonicPatternNames,
        harmonicPatternTypes: constants.harmonicPatternTypes,
        harmonicPatternDeviationTypes: constants.harmonicPatternDeviationTypes,
        harmonicPatternIndicatorNotifications: constants.harmonicPatternIndicatorNotification,
      }
    }
    return {
      harmonicPatternSizes: constants.harmonicPatternSizes.filter(
        (_, i) => availableFilters.sizes.includes(i)
      ),
      harmonicPatternNames: constants.harmonicPatternNames.filter(
        v => availableFilters.names.includes(v),
      ),
      harmonicPatternTypes: constants.harmonicPatternTypes.filter(
        v => availableFilters.types.includes(v),
      ),
      harmonicPatternDeviationTypes: constants.harmonicPatternDeviationTypes.filter(
        v => availableFilters.deviationTypes.includes(v),
      ),
      harmonicPatternIndicatorNotifications: constants.harmonicPatternIndicatorNotification.filter(
        v => availableFilters.indicatorNotification.includes(v),
      ),
    };
  }, [props.availableFilters]);

  const sizes = groupN(finalFilters.harmonicPatternSizes, 2);
  const names = groupN(finalFilters.harmonicPatternNames, 2);
  const types = finalFilters.harmonicPatternTypes
  const deviationTypes = groupN(finalFilters.harmonicPatternDeviationTypes, 2);
  const indicatorNotification = groupN(finalFilters.harmonicPatternIndicatorNotifications, 2);

  const subscriptionOptions = useMemo(() => {
    if (props.configMode) {
      return [{label: FREE, value: FREE}, ...props.subscriptions.map(sub => ({label: sub.name, value: sub.value}))]
    }
    return [];
  }, [props.subscriptions]);

  return (
    <div className={classes.filtersBlock}>
      {
        sizes.length ? (
          <>
            {
              sizes.map(group => {
                return (
                  <div key={group[0]} className={classes.switchesBlock}>
                    {
                      group.map(patternSize => {
                        const filterValue = constants.harmonicPatternSizes.indexOf(patternSize);
                        const checked = props.filters.sizes.includes(filterValue);
                        return (
                          <div
                            key={patternSize}
                            className={classes.switchWrapper}
                          >
                            <CustomSwitch
                              label={ensurePatternName(patternSize)}
                              labelPlacement='end'
                              checked={checked}
                              switchHandler={() => handleMultiFilterChange('sizes', filterValue)}
                              overrideClasses={{
                                switchBase: classes.colorSwitchBase,
                                checked: classes.colorChecked,
                                bar: classes.colorBar,
                              }}
                            />
                            {
                              (props.configMode && checked) && (
                                <div>
                                  <div className={classes.defaultSelectedWrapper}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          color='primary'
                                          checked={props.defaultFilters.sizes.includes(filterValue)}
                                          onChange={() => handleDefaultSelectedFilterChange('sizes', filterValue)} />
                                      }
                                      label='Default Selected'
                                    />
                                  </div>
                                </div>
                              )
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                )
              })
            }
            <div className={classes.divider}/>
          </>
        ) : null
      }
      {
        indicatorNotification.length ? (
          <>
            {
              indicatorNotification.map(group => {
                return (
                  <div key={group[0]} className={classes.switchesBlock}>
                    {
                      group.map(indicatorNotification => {
                        const checked = props.filters.indicatorNotification.includes(indicatorNotification);
                        return (
                          <div
                            key={indicatorNotification}
                            className={classes.switchWrapper}
                          >
                            <CustomSwitch
                              label={ensurePatternName(indicatorNotification)}
                              labelPlacement='end'
                              checked={checked}
                              switchHandler={() => handleMultiFilterChange('indicatorNotification', indicatorNotification)}
                              overrideClasses={{
                                switchBase: classes.colorSwitchBase,
                                checked: classes.colorChecked,
                                bar: classes.colorBar,
                              }}
                            />
                            {
                              (props.configMode && checked) && (
                                <div className={classes.defaultSelectedWrapper}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color='primary'
                                        checked={props.defaultFilters.indicatorNotification.includes(indicatorNotification)}
                                        onChange={() => handleDefaultSelectedFilterChange('indicatorNotification', indicatorNotification)} />
                                    }
                                    label='Default Selected'
                                  />
                                </div>
                              )
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                )
              })
            }
            <div className={classes.divider}/>
          </>
        ) : null
      }
      {
        names.length ? (
          <>
            {
              names.map(group => {
                return (
                  <div key={group[0]} className={classes.switchesBlock}>
                    {
                      group.map(patternName => {
                        const checked = props.filters.names.includes(patternName);
                        let premiumLock;
                        if (!props.configMode) {
                          premiumLock = props.premiumLockedFilters.names.find(pf => pf.filterValue === patternName);
                        }
                        return (
                          <div
                            key={patternName}
                            className={classes.switchWrapper}
                          >
                            <div className={classes.switchContainer}>
                              <CustomSwitch
                                label={ensurePatternName(patternName)}
                                labelPlacement='end'
                                checked={checked}
                                switchHandler={
                                  premiumLock ? () => handlePremiumLockClick(premiumLock): () => handleMultiFilterChange('names', patternName)
                                }
                                overrideClasses={{
                                  switchBase: classes.colorSwitchBase,
                                  checked: classes.colorChecked,
                                  bar: classes.colorBar,
                                }}
                              />
                              {
                                premiumLock && (
                                  <LockIcon
                                    style={{marginLeft: '-5px'}}
                                    color='primary'
                                  />
                                )
                              }
                            </div>
                            {
                              (props.configMode && checked) && (
                                <div>
                                  <div className={classes.defaultSelectedWrapper}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          color='primary'
                                          checked={props.defaultFilters.names.includes(patternName)}
                                          onChange={() => handleDefaultSelectedFilterChange('names', patternName)} />
                                      }
                                      label='Default Selected'
                                    />
                                  </div>
                                  <div className={classes.selectWrapper}>
                                    <Select
                                      label='Select premium filter subscription'
                                      value={(() => {
                                        const existing = props.premiumFilters.names.find(pf => pf.filterValue === patternName);
                                        return !!existing ? existing.subscriptionValue : FREE
                                      })()}
                                      style={{width: '100%'}}
                                      onChange={e => handlePremiumFiltersChange('names', patternName, e.target.value)}
                                    >
                                      {
                                        subscriptionOptions.map(option => (
                                          <MenuItem
                                            key={option.label}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </MenuItem>
                                        ))
                                      }
                                    </Select>
                                  </div>
                                </div>
                              )
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                )
              })
            }
            <div className={classes.divider}/>
          </>
        ) : null
      }
      {
        types.length ? (
          <>
            {
              <div className={classes.switchesBlock}>
                {
                  types.map(patternType => {
                    const checked = props.filters.types.includes(patternType);
                    return (
                      <div
                        className={classes.switchWrapper}
                        key={patternType}
                      >
                        <CustomSwitch
                          label={patternType}
                          labelPlacement='end'
                          checked={checked}
                          switchHandler={() => handleMultiFilterChange('types', patternType)}
                          overrideClasses={{
                            switchBase: classes.colorSwitchBase,
                            checked: classes.colorChecked,
                            bar: classes.colorBar,
                          }}
                        />
                        {
                          (props.configMode && checked) && (
                            <div className={classes.defaultSelectedWrapper}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color='primary'
                                    checked={props.defaultFilters.types.includes(patternType)}
                                    onChange={() => handleDefaultSelectedFilterChange('types', patternType)} />
                                }
                                label='Default Selected'
                              />
                            </div>
                          )
                        }
                      </div>
                    )
                  })
                }
              </div>
            }
            <div className={classes.divider}/>
          </>
        ) : null
      }
      {
        deviationTypes.length ? (
          <>
            {
              deviationTypes.map(group => {
                return (
                  <div key={group[0]} className={classes.switchesBlock}>
                    {
                      group.map(deviationType => {
                        const checked = props.filters.deviationTypes.includes(deviationType);
                        return (
                          <div
                            key={deviationType}
                            className={classes.switchWrapper}
                          >
                            <CustomSwitch
                              label={ensurePatternName(deviationType)}
                              labelPlacement='end'
                              checked={checked}
                              switchHandler={() => handleMultiFilterChange('deviationTypes', deviationType)}
                              overrideClasses={{
                                switchBase: classes.colorSwitchBase,
                                checked: classes.colorChecked,
                                bar: classes.colorBar,
                              }}
                            />
                            {
                              (props.configMode && checked) && (
                                <div className={classes.defaultSelectedWrapper}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color='primary'
                                        checked={props.defaultFilters.deviationTypes.includes(deviationType)}
                                        onChange={() => handleDefaultSelectedFilterChange('deviationTypes', deviationType)} />
                                    }
                                    label='Default Selected'
                                  />
                                </div>
                              )
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                )
              })
            }
            <div className={classes.divider}/>
          </>
        ) : null
      }
      {
        showDeviationValueSelect && (
          <div className={classes.selectWrapper}>
            <Select
              label='Select'
              value={
                props.configMode ? props.defaultFilters.deviationValue : props.filters.deviationValue
              }
              style={{width: '100%'}}
              onChange={e => {
                if (props.configMode) {
                  handleDefaultSelectedFilterChange('deviationValue', e.target.value);
                } else {
                  handleSingleFilterChange('deviationValue', e.target.value);
                }
              }}
            >
              {
                constants.harmonicPatternDeviationValues.map((value, index) => (
                  <MenuItem
                    key={value}
                    value={index}
                  >
                    {
                      value === 0 ? 'Perfect Pattern' : `${value}%`
                    }
                  </MenuItem>
                ))
              }
            </Select>
          </div>
        )
      }
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(HarmonicPatternsFiltersList);
