import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

import { isValidDarkTheme } from '../../utils/styles';

const styles = theme => {
    return {
        root: {
            width: 222,
            height: 81,
            color: isValidDarkTheme(theme.palette.type)
            ? theme.palette.mainForeColor
            : theme.palette.highlightColor
        }
    }
};
const Logo = ({ classes, className, viewBox, color, ...rest }) => {
    return (
        <SvgIcon id="Icons" viewBox={viewBox || "0 0 222 81"} {...rest} className={clsx(classes.root, className)}>
            <defs>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
                    <stop stopColor="#FA224A" offset="0%"></stop>
                    <stop stopColor="#DB1C47" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="94.3426552%" y1="35.8103483%" x2="5.32835079%" y2="60.6274346%" id="linearGradient-2">
                    <stop stopColor="#1A7D85" offset="0%"></stop>
                    <stop stopColor="#3DFDFE" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-3">
                    <stop stopColor="#1E9091" offset="0%"></stop>
                    <stop stopColor="#3DFDFE" offset="100%"></stop>
                </linearGradient>
                <radialGradient cx="50%" cy="50%" fx="50%" fy="50%" r="100.043033%" gradientTransform="translate(0.500000,0.500000),scale(1.000000,0.999570),rotate(90.000000),translate(-0.500000,-0.500000)" id="radialGradient-4">
                    <stop stopColor="#FA2249" offset="0%"></stop>
                    <stop stopColor="#DB1C47" offset="100%"></stop>
                </radialGradient>
            </defs>
            <g id="oracle">
                <g id="Artboard-1" fillRule="nonzero">
                    <path d="M187.07,11.78 L187.07,66.61 L160.97,66.61 L160.97,11.78 L187.07,11.78 Z M185.45,29 L171.88,38 L162.84,29.39 L162.57,29.39 L162.57,36.69 L178.17,52.3 L186.17,52.3 L176,42.1 L185.25,35.94 L185.45,35.8 L185.45,29 Z M162.54,39.3 L162.54,52.3 L168.21,52.3 L168.21,45 L162.54,39.3 Z" id="Combined-Shape" fill="url(#linearGradient-1)"></path>
                    <path d="M102.62,52.83 C100.902382,52.8431807 99.2004239,52.5027891 97.62,51.83 C96.1218626,51.2249578 94.7613366,50.3236094 93.62,49.18 C92.4950238,48.0295974 91.6050937,46.6709256 91,45.18 C89.7296967,42.0472077 89.7296967,38.5427923 91,35.41 C91.6060013,33.9195502 92.4958226,32.5610444 93.62,31.41 C94.7637537,30.2693482 96.1236435,29.3684211 97.62,28.76 C100.796726,27.4665941 104.353274,27.4665941 107.53,28.76 C110.519258,29.9927307 112.884536,32.3796422 114.09,35.38 C115.360303,38.5127923 115.360303,42.0172077 114.09,45.15 C112.882305,48.1465787 110.517322,50.5295871 107.53,51.76 C105.949407,52.4322419 104.247564,52.7726105 102.53,52.76 M102.53,48.49 C103.599484,48.4987233 104.660034,48.2947713 105.65,47.89 C106.595625,47.5053194 107.458077,46.9416694 108.19,46.23 C108.924569,45.4893642 109.502696,44.6085705 109.89,43.64 C110.703678,41.4807792 110.703678,39.0992208 109.89,36.94 C109.502732,35.9743593 108.924517,35.0968328 108.19,34.36 C107.457847,33.646114 106.595618,33.0792151 105.65,32.69 C103.650301,31.8904086 101.419699,31.8904086 99.42,32.69 C98.5094888,33.109914 97.6879786,33.7005866 97,34.43 C96.2525514,35.1584292 95.6635854,36.0333672 95.27,37 C94.4563217,39.1592208 94.4563217,41.5407792 95.27,43.7 C95.6612383,44.6766744 96.2502405,45.5618801 97,46.3 C97.7177679,47.0249663 98.5704539,47.6024827 99.51,48 C100.496319,48.40527 101.553706,48.6092675 102.62,48.6" id="Shape"></path>
                    <path d="M118.73,28.43 L127.43,28.43 C128.544947,28.4167459 129.652998,28.606504 130.7,28.99 C131.631728,29.3432665 132.484661,29.8767741 133.21,30.56 C133.911574,31.2304017 134.463624,32.0414384 134.83,32.94 C135.213797,33.893267 135.407432,34.9123995 135.4,35.94 C135.402517,36.7079051 135.26703,37.4700144 135,38.19 C134.737242,38.8780558 134.365862,39.5195309 133.9,40.09 C133.427437,40.665436 132.875209,41.1705229 132.26,41.59 C131.642509,42.0137493 130.969494,42.3502568 130.26,42.59 L130.26,42.82 L136.66,51.99 L136.66,52.26 L131.5,52.26 L125.33,43.26 L123.23,43.26 L123.23,52.26 L118.73,52.26 L118.73,28.43 Z M127.4,39.2 C128.328249,39.2321215 129.231201,38.8939642 129.91,38.26 C130.569734,37.6513308 130.934307,36.7872941 130.91,35.89 C130.906734,35.4705106 130.832346,35.0546133 130.69,34.66 C130.548105,34.2629594 130.326958,33.8989177 130.04,33.59 C129.734677,33.2692408 129.367204,33.014051 128.96,32.84 C128.486577,32.6495963 127.980247,32.5544469 127.47,32.56 L123.24,32.56 L123.24,39.2 L127.4,39.2 Z" id="Shape"></path>
                    <path d="M146.37,28.43 L151.5,28.43 L160.5,52.3 L155.5,52.3 L153.5,46.6 L144.29,46.6 L142.29,52.3 L137.29,52.3 L146.37,28.43 Z M152.07,42.43 L149.93,36.43 L149.07,33.53 L148.8,33.53 L147.93,36.43 L145.8,42.43 L152.07,42.43 Z" id="Shape"></path>
                    <polygon id="Path" points="188.08 28.43 192.58 28.43 192.58 48.03 202.68 48.03 202.68 52.3 188.08 52.3"></polygon>
                    <polygon id="Path" points="210.38 32.69 210.38 38.23 219.95 38.23 219.95 42.5 210.38 42.5 210.38 48.03 221.02 48.03 221.02 52.3 205.88 52.3 205.88 28.43 221.02 28.43 221.02 32.69"></polygon>
                    <path d="M63,31.56 C67.7551717,43.9394627 61.5744627,57.8298283 49.195,62.585 C36.8155373,67.3401717 22.9251717,61.1594627 18.17,48.78 L6.17,53.38 C13.4546361,72.3868794 34.7681203,81.8896353 53.7749998,74.6049995 C72.7818793,67.3203637 82.2846355,46.0068796 75,27 L63,31.56 Z" id="Path" fill="url(#linearGradient-2)"></path>
                    <path d="M27.56,6.17 C8.56878541,13.4624176 -0.923914377,34.7616907 6.35,53.76 L18.35,49.16 C15.1075586,41.1145698 16.4587553,31.9456139 21.8842103,25.177541 C27.3096654,18.4094681 35.9649619,15.0956598 44.5231343,16.5098941 C53.0813067,17.9241284 60.2103625,23.8462941 63.17,32 L75.17,27.4 C71.6763275,18.2677067 64.6955227,10.8988853 55.7653752,6.91680103 C46.8352278,2.9347168 36.688357,2.66605547 27.56,6.17" id="Path" fill="url(#linearGradient-3)"></path>
                    <path d="M50.44,42 C49.483368,41.0220385 49.4948825,39.4554618 50.4657865,38.4916678 C51.4366905,37.5278737 53.0033095,37.5278737 53.9742135,38.4916678 C54.9451175,39.4554618 54.956632,41.0220385 54,42 C53.0277539,42.971478 51.4522461,42.971478 50.48,42 M27.6,27.2 C20.430011,34.3547056 20.3777052,45.9547481 27.4828814,53.1738218 C34.5880576,60.3928956 46.1874651,60.5250575 53.4552815,53.4697474 C60.7230979,46.4144373 60.9351096,34.8162145 53.93,27.5 C54.3915055,31.0000562 52.1289588,34.2814113 48.6935046,35.0944517 C45.2580503,35.9074921 41.76474,33.9883278 40.6084984,30.652689 C39.4522567,27.3170503 41.0083672,23.6475914 44.21,22.16 C43.0316854,21.920733 41.8323619,21.8 40.63,21.8 C35.7513231,21.8013081 31.0737719,23.7442909 27.63,27.2" id="Shape" fill="url(#radialGradient-4)"></path>
                    <path d="M3.64,24.73 C0.634755806,31.9049641 -0.2131826,39.8005217 1.2,47.45 L5.57,45.66 C3.48325388,32.4534735 9.01963048,19.1986683 19.88,11.4 L18.09,7 C11.6502757,11.3775452 6.62826229,17.5395035 3.64,24.73" id="Path" fill="#FA224A"></path>
                    <path d="M33.51,0.7 L35.31,5.07 C48.5160707,2.98222237 61.7700421,8.52373687 69.56,19.39 L73.94,17.59 C65.0195189,4.42307472 49.1460206,-2.20822348 33.51,0.7" id="Path" fill="#FA224A"></path>
                    <path d="M7.53,62.88 C16.4842308,76.0284174 32.3554483,82.6522094 48,79.77 L46.21,75.39 C32.9868823,77.4967815 19.7073619,71.9581649 11.9,61.08 L7.53,62.88 Z" id="Path" fill="#FA224A"></path>
                    <path d="M75.89,34.81 C77.9751468,48.0134959 72.4387087,61.2645793 61.58,69.06 L63.38,73.44 C76.5130263,64.4871789 83.1341432,48.6341315 80.27,33 L75.89,34.81 Z" id="Path" fill="#FA224A"></path>
                </g>
            </g>
        </SvgIcon>
    )
};

export default (withStyles(styles, { withTheme: true })(Logo));