
import React from 'react';
import IconButton from '@material-ui/core/IconButton';

const CustomSwitchButton = ({ flag, trueIcon, falseIcon, ...rest }) => {
    return (
        <IconButton {...rest}>
            { flag? trueIcon: falseIcon }
        </IconButton>
    );
};

export default CustomSwitchButton;
