import React, {useState} from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import {Checkbox, Popover} from "@material-ui/core";
import SettingsIcon from '@material-ui/icons/Settings';
import TimelapseIcon from "@material-ui/icons/Timelapse";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ContainedButton from "../../components/ContainedButton";
import HarmonicPatternsFiltersList
  from "../../components/HarmonicPatternsFiltersList";
import AutoCompleteInput from "../../components/AutocompleteInput";
import {formatTimeFrame} from "../../utils/utility";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const symbolToSelectValue = s => ({label: s, value: s});
const timeframeToSelectValue = v => ({label: formatTimeFrame(v), value: v});

const styles = () => {
  return {
    container: {
      marginLeft: '10px',
    },
    selectedContainer: {
      marginLeft: '10px',
      backgroundColor: 'rgb(33, 150, 243)',
    },
    selectWrapper: {
      padding: '10px 15px',
      background: '#1f272e7d',
      maxWidth: '400px',
      minWidth: '400px',
      minHeight: '300px',
    },
  };
};

const Filter = (props) => {
  const { classes } = props;
  const [symbolsAnchor, setSymbolsAnchor] = useState(null);
  const [timeframesAnchor, setTimeframesAnchor] = useState(null);
  const [filtersAnchor, setFiltersAnchor] = useState(null);

  const handleSymbolsClick = e => {
    setSymbolsAnchor(e.currentTarget);
  };

  const handleTimeframesClick = e => {
    setTimeframesAnchor(e.currentTarget);
  };

  const handleFiltersClick = e => {
    setFiltersAnchor(e.currentTarget);
  };

  const handleClose = () => {
    setSymbolsAnchor(null)
    setTimeframesAnchor(null);
    setFiltersAnchor(null);
  };

  const handleMultiFieldChange = field => value => {
    props.onChange({...props.filters, [field]: value.map(v => v.value)});
  };

  const handleSelectAllSymbols = e => {
    if (e.target.checked) {
      props.onChange({
        ...props.filters, symbol: props.symbolOptions.map(v => v.value),
      });
    } else {
      props.onChange({
        ...props.filters, symbol: [],
      });
    }
  };

  const handleSelectAllTimeframes = e => {
    if (e.target.checked) {
      props.onChange({
        ...props.filters, timeframe: props.timeframeOptions.map(v => v.value),
      });
    } else {
      props.onChange({
        ...props.filters, timeframe: [],
      });
    }
  };

  return (
    <>
      <div>
        <ContainedButton
          onClick={handleSymbolsClick}
          customClasses={symbolsAnchor !== null ? classes.selectedContainer : classes.container}
        >
          <AttachMoneyIcon />
          <span>&nbsp;Symbols</span>
        </ContainedButton>
        <ContainedButton
          onClick={handleTimeframesClick}
          customClasses={timeframesAnchor !== null ? classes.selectedContainer : classes.container}
        >
          <TimelapseIcon />
          <span>&nbsp;Timeframes</span>
        </ContainedButton>
        <ContainedButton
          onClick={handleFiltersClick}
          customClasses={filtersAnchor !== null ? classes.selectedContainer : classes.container}
        >
          <SettingsIcon />
          <span>&nbsp;Filter</span>
        </ContainedButton>
      </div>
      <Popover
        anchorEl={symbolsAnchor}
        keepMounted
        open={Boolean(symbolsAnchor)}
        onClose={handleClose}
        elevation={20}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.selectWrapper}>
          <AutoCompleteInput
            multi
            options={props.symbolOptions}
            onChange={handleMultiFieldChange('symbol')}
            value={props.filters.symbol.map(symbolToSelectValue)}
            inputLabel='Symbols'
            placeholder='Please select at least one symbol'
            inputLabelStyle={{color: '#fff'}}
            hideIndicators
          />
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                onChange={handleSelectAllSymbols}
                checked={props.filters.symbol.length === props.symbolOptions.length}
              />
            }
            label='Select all'
          />
        </div>
      </Popover>

      <Popover
        anchorEl={timeframesAnchor}
        keepMounted
        open={Boolean(timeframesAnchor)}
        onClose={handleClose}
        elevation={20}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.selectWrapper}>
          <AutoCompleteInput
            multi
            options={props.timeframeOptions}
            onChange={handleMultiFieldChange('timeframe')}
            value={props.filters.timeframe.map(timeframeToSelectValue)}
            inputLabel='Timeframes'
            placeholder='Please select at least one timeframe'
            inputLabelStyle={{color: '#fff'}}
            hideIndicators
            disableSearch
          />
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                onChange={handleSelectAllTimeframes}
                checked={props.filters.timeframe.length === props.timeframeOptions.length}
              />
            }
            label='Select all'
          />
        </div>
      </Popover>

      <Popover
        anchorEl={filtersAnchor}
        keepMounted
        open={Boolean(filtersAnchor)}
        onClose={handleClose}
        elevation={20}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <HarmonicPatternsFiltersList
          filters={props.filters}
          onChange={props.onChange}
          availableFilters={props.availableFilters}
          premiumLockedFilters={props.premiumLockedFilters}
          notificationsContext
        />
      </Popover>
    </>
  )
}

export default withStyles(styles, { withTheme: true })(Filter);
