import { seperatorChar } from "../constants";

const isValidStrategyParameter = ({ parameters }) => {
  if (!parameters) {
    return true;
  }
  const equalChar = "=";
  const rows = parameters.split(seperatorChar);
  for (let i = 0; i < rows.length; i++) {
    if (!rows[i].includes(equalChar)) {
      return false;
    }
    const array = rows[i].split(equalChar);
    if (array.length !== 2 || !array[0]) {
      return false;
    }
  }
  return true;
};

export {
  isValidStrategyParameter
};
