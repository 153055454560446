import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import CloseIconButton from "../../components/CloseIconButton";

const styles = theme => {
  return {
    paper: {
      backgroundColor: theme.palette.subBackColor3
    },
    content: {
      diplay: "flex",
      alignItems: "center",
      paddingBottom: 0
    },
    closeButton: {
      float: "right",
      padding: 0
    },
    scrollbar: {
      "& .ps__rail-y": {
        "&:hover": {
          backgroundColor: "transparent"
        }
      },
      "& .ps__rail-x": {
        display: "hidden"
      }
    }
  };
};

const DialogWrapper = ({
  classes,
  opened,
  dialogTitle,
  dialogDescription,
  dialogActions,
  children,
  onClose,
  fullScreen,
  closeIcon,
  ...rest
}) => {
  const scrollBarClasses = "ps ps--active-y";

  return (
    <Dialog
      {...rest}
      onClose={onClose}
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      classes={{ paper: classes.paper }}
      open={opened}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {dialogTitle}
        {(fullScreen || onClose) && (
          <CloseIconButton
            className={classes.closeButton}
            onClick={onClose}
          />
        )}
      </DialogTitle>

      <PerfectScrollbar
        className={[classes.scrollbar, scrollBarClasses].join(" ")}
      >
        <DialogContent className={classes.content}>
          <DialogContentText>{dialogDescription}</DialogContentText>
          {children}
        </DialogContent>
      </PerfectScrollbar>
      {dialogActions && dialogActions}
    </Dialog>
  );
};

export default withStyles(styles, { withTheme: true })(DialogWrapper);
