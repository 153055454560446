
import React, { PureComponent } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import RoleMultiSelect from '../../../../components/RoleMultiSelect';
// import CustomSelectValidator from '../../../../components/CustomSelectValidator';
import OutlinedButton from '../../../../components/OutlinedButton';
import CustomTextButton from '../../../../components/CustomTextButton';
import DialogWrapper from '../../../../hoc/DialogWrapper';
import DialogActionsWrapper from '../../../../hoc/DialogActionsWrapper';
import { getAddEditDialogMetadata, showErrorToast } from '../../../../utils/utility';
import { editUser, addUser } from '../../../../services/user';

class AddEditDialog extends PureComponent {
    state = {
        name: '',
        email: '',
        role: '',
        roles: [],
        password: '',
        submitButtonDisabled: false,
        roleValidation: true,
        validation: {
            role: {
                valid: true,
                message: 'Must select more than one role.'
            }
        }
    };

    componentDidMount() {
        const { isAddDialog, dialogData } = this.props;
        // edit
        if (!isAddDialog) {
            const { name, email, role, roles } = dialogData;
            this.setState({ name, email, role, roles });
        }
    }

    inputChangeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    roleChangeHandler = roles => {
        this.setState({ roles, roleValidation: true });
    };

    onCloseHandler = () => {
        const { closed } = this.props;
        closed && closed();
    };

    submitHandler = async () => {
        const { name, email, role, roles, password } = this.state;
        if(roles === undefined || roles.length === 0) {
            this.setState({roleValidation: false});
            return;
        }
        const { isAddDialog, dialogData, addEditUser } = this.props;
        const user = {
            _id: !isAddDialog ? dialogData._id : null,
            name,
            email,
            role,
            roles,
            password
        };
        try {
            this.setState({ submitButtonDisabled: true });
            let response;
            if (isAddDialog) {
                response = await addUser(user);
            } else {
                response = await editUser(user);
            }
            const { data: userFromBackend } = response;
            addEditUser(userFromBackend);
        } catch (error) {
            // TODO: * axios handling module
            console.log('sergey : [containers Admin UserManage AddEditDialog submitHandler] error => ', error);
            if (error.response) {
                const { message: errorMessage } = error.response.data;
                showErrorToast(errorMessage);
            }
        }
        this.onCloseHandler();
    };

    triggerSubmitHandler = () => {
        this.form.isFormValid(false).then((isValid) => {
            if (isValid) {
                this.submitHandler();
            }
        });
    };

    render() {
        const { opened, isAddDialog, manageTitle } = this.props;
        const { name, email, roles, password, submitButtonDisabled } = this.state;
        const { dialogTitle, dialogDescription, submitButtonName, cancelButtonName } = getAddEditDialogMetadata(isAddDialog, manageTitle);

        return (
            <DialogWrapper 
                opened={opened} 
                dialogTitle={dialogTitle} 
                dialogDescription={dialogDescription} 
                onClose={this.onCloseHandler}
                dialogActions={
                    <DialogActionsWrapper>
                        <CustomTextButton onClick={this.onCloseHandler}>
                            {cancelButtonName}
                        </CustomTextButton>
                        <OutlinedButton type='button' disabled={submitButtonDisabled} onClick={this.triggerSubmitHandler}>
                            {submitButtonName}
                        </OutlinedButton>
                    </DialogActionsWrapper>
                }>
                <ValidatorForm
                    ref={(ref) => { this.form = ref; }}
                    onSubmit={this.submitHandler}
                    onError={errors => console.log(errors)}>
                    <TextValidator
                        fullWidth
                        name='name'
                        label='Name'
                        value={name}
                        margin='normal'
                        onChange={this.inputChangeHandler}
                        validators={['required']}
                        errorMessages={['Name cannot be empty']} />
                    <TextValidator
                        fullWidth
                        name='email'
                        label='Email'
                        margin='normal'
                        value={email}
                        onChange={this.inputChangeHandler}
                        validators={['isEmail', 'required']}
                        errorMessages={['Email is not valid', 'Email cannot be empty']} />
                    {/* <CustomSelectValidator
                        label='Role'
                        multiple={true}
                        value={roles}
                        changed={this.roleChangeHandler}
                        items={rolePairs} /> */}
                    <RoleMultiSelect
                        label='Role'
                        selectedRoleValues={roles}
                        validation={this.state.roleValidation}
                        changed={this.roleChangeHandler} />
                    {isAddDialog ?
                        <TextValidator
                            fullWidth
                            name='password'
                            label='Password'
                            margin='normal'
                            type='password'
                            value={password}
                            onChange={this.inputChangeHandler}
                            validators={['required']}
                            errorMessages={['Password cannot be empty']} /> :
                        <TextValidator
                            fullWidth
                            name='password'
                            label='Password'
                            margin='normal'
                            type='password'
                            value={password}
                            onChange={this.inputChangeHandler} />}
                    <DialogActionsWrapper />
                </ValidatorForm>
            </DialogWrapper>
        );
    }
}

export default AddEditDialog;
