import axios from "axios";

import {
  getNotificationsUrl,
  getNotificationsByRoleUrl,
  getCloseNotificationUrl,
} from "./endpoints";

const getNotifications = async (creatorId) => {
  const url = getNotificationsUrl();
  const notifcations = await axios.get(url, {
    params: { creatorId },
  });
  return notifcations;
};

const addNotification = async (data) => {
  const url = getNotificationsUrl();
  const result = await axios.post(url, data);
  return result;
};

const editNotification = async (data) => {
  const url = getNotificationsUrl();
  const result = await axios.put(url, data);
  return result;
};

const removeNotification = async (_id) => {
  const url = getNotificationsUrl();
  const result = await axios.delete(url, {
    params: { _id },
  });
  return result;
};

const removeNotifications = async (ids) => {
  const url = getNotificationsUrl();
  const result = await axios.delete(url, { data: { ids } });
  return result;
};

const getNotificationsByRole = async () => {
  const url = getNotificationsByRoleUrl();
  const result = await axios.get(url);
  return result;
};

const closeNotification = async (_id) => {
  const url = getCloseNotificationUrl();
  const result = await axios.put(url, null, {
    params: { _id },
  });
  return result;
};

export {
  getNotifications,
  addNotification,
  editNotification,
  removeNotification,
  removeNotifications,
  getNotificationsByRole,
  closeNotification,
};
