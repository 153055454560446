
import React, { PureComponent } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import CustomSelectValidator from '../../../../components/CustomSelectValidator';
import OutlinedButton from '../../../../components/OutlinedButton';
import CustomTextButton from '../../../../components/CustomTextButton';
import DialogWrapper from '../../../../hoc/DialogWrapper';
import DialogActionsWrapper from '../../../../hoc/DialogActionsWrapper';
import { currencyTypes } from '../../../../constants';
import { getAddEditDialogMetadata, showErrorToast } from '../../../../utils/utility';
import { addSymbol, editSymbol } from '../../../../services/symbol';
import CustomSwitch from "../../../../components/CustomSwitch";

class AddEditDialog extends PureComponent {
    state = {
        brokerId: '',
        name: '',
        code: '',
        currency: '',
        digits: '',
        tick: '',
        incrementStep: 1,
        shouldSubscribeToMetaAPI: false,
        submitButtonDisabled: false
    };

    componentDidMount() {
        const { isAddDialog, dialogData } = this.props;
        // edit
        if (!isAddDialog) {
            const { brokerId, name, code, currency, digits, tick, shouldSubscribeToMetaAPI } = dialogData;
            const incrementStep = 1 / Math.pow(10, digits);
            this.setState({brokerId, name, code, currency, digits, tick, incrementStep, shouldSubscribeToMetaAPI});
        }
    }

    inputChangeHandler = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    subscribeToMetaChangeHandler = shouldSubscribeToMetaAPI => {
      this.setState({shouldSubscribeToMetaAPI});
    }

    brokerChangeHandler = brokerId => {
        this.setState({brokerId});
    };

    currencyChangeHandler = currency => {
        this.setState({currency});
    };

    digitsChangeHandler = event => {
        const incrementStep = 1 / Math.pow(10, event.target.value);
        this.setState({digits: event.target.value, tick: incrementStep, incrementStep: incrementStep});
    };

    onCloseHandler = () => {
        const { closed } = this.props;
        closed && closed();
    };

    submitHandler = async () => {
        const { brokerId, name, code, currency, digits, tick, shouldSubscribeToMetaAPI } = this.state;
        const { isAddDialog, dialogData, addEditSymbol } = this.props;
        const symbol = {
            _id: !isAddDialog ? dialogData._id : null,
            brokerId, 
            name, 
            code, 
            currency, 
            digits, 
            tick,
            shouldSubscribeToMetaAPI,
        };
        try {
            this.setState({submitButtonDisabled: true});
            let response;
            if (isAddDialog) {
                response = await addSymbol(symbol);
            } else {
                response = await editSymbol(symbol);
            }
            const { data: symbolFromBackend } = response;
            addEditSymbol(symbolFromBackend);
        } catch (error) {
            // TODO: * axios handling module
            console.log('sergey : [containers Admin SymbolManage AddEditDialog submitHandler] error => ', error);
            if (error.response) {
                const { message: errorMessage } = error.response.data;
                showErrorToast(errorMessage);
            }
        }
        this.onCloseHandler();
    };

    triggerSubmitHandler = () => {
        this.form.isFormValid(false).then((isValid) => {
            if (isValid) {
                this.submitHandler();
            }
        });
    };

    render() {
        const { opened, isAddDialog, manageTitle, brokerData } = this.props;
        const { brokerId, name, code, currency, digits, tick, incrementStep, submitButtonDisabled, shouldSubscribeToMetaAPI } = this.state;
        const { dialogTitle, dialogDescription, submitButtonName, cancelButtonName } = getAddEditDialogMetadata(isAddDialog, manageTitle);

        const brokerList = brokerData.map(broker => ({
            label: broker.name,
            value: broker._id
        }));

        return (
            <DialogWrapper 
                opened={opened} 
                dialogTitle={dialogTitle} 
                dialogDescription={dialogDescription} 
                onClose={this.onCloseHandler}
                dialogActions={
                    <DialogActionsWrapper>
                        <CustomTextButton onClick={this.onCloseHandler}>
                            {cancelButtonName}
                        </CustomTextButton>
                        <OutlinedButton type='button' disabled={submitButtonDisabled} onClick={this.triggerSubmitHandler}>
                            {submitButtonName}
                        </OutlinedButton>
                    </DialogActionsWrapper>
                }>
                <ValidatorForm
                    ref={(ref) => { this.form = ref; }}
                    onSubmit={this.submitHandler}
                    onError={errors => console.log(errors)}>
                    <CustomSelectValidator
                        label="Broker"
                        value={brokerId}
                        changed={this.brokerChangeHandler}
                        items={brokerList} />
                    <TextValidator
                        fullWidth
                        name='name'
                        label='Name'
                        value={name}
                        margin='normal'
                        onChange={this.inputChangeHandler}
                        validators={['required']}
                        errorMessages={['Name cannot be empty']} />
                    <TextValidator
                        fullWidth
                        name='code'
                        label='Code'
                        value={code}
                        margin='normal'
                        onChange={this.inputChangeHandler}
                        validators={['required']}
                        errorMessages={['Code cannot be empty']} />
                    <CustomSelectValidator
                        label="Currency"
                        value={currency}
                        changed={this.currencyChangeHandler}
                        items={currencyTypes} />
                    <TextValidator
                        fullWidth
                        name='digit'
                        label='Digit'
                        value={digits}
                        type='number'
                        inputProps={{min: '0', max: '10'}}
                        margin='normal'
                        onChange={this.digitsChangeHandler}
                        validators={['isNumber', 'required']}
                        errorMessages={['Digit should be number', 'Digit cannot be empty']} />
                    <TextValidator
                        fullWidth
                        name='tick'
                        label='Tick'
                        value={tick}
                        type='number'
                        inputProps={{min: '0', step: incrementStep}}
                        margin='normal'
                        onChange={this.inputChangeHandler}
                        validators={['required']}
                        errorMessages={['Tick cannot be empty']} />
                    <CustomSwitch
                        label='MetaAPI subscribe'
                        checked={shouldSubscribeToMetaAPI}
                        switchHandler={e => this.subscribeToMetaChangeHandler(e.target.checked)}
                    />
                    <DialogActionsWrapper />
                </ValidatorForm>
            </DialogWrapper>
        );
    }
}

export default AddEditDialog;
