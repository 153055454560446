import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import SendIcon from "@material-ui/icons/Send";
import Typography from "@material-ui/core/Typography";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import OutlinedButton from "../../../components/OutlinedButton";
import { showErrorToast, showInfoToast } from "../../../utils/utility";
import constants from "../../../constants";
import { addFeedback } from "../../../services/feedback";
import { setLoadingStatus } from "../../../actions/loading";

const styles = (theme) => {
  return {
    paper: {
      backgroundColor: theme.palette.subBackColor3,
      paddingTop: 60,
      paddingBottom: 60,
    },
    sendButton: {
      marginTop: 20,
      marginBottom: 20,
    },
    sendIcon: {
      fontSize: 18,
      marginRight: 4,
    },
  };
};

class Feedback extends Component {
  state = {
    message: "",
    email: "",
    phoneNumber: "",
  };

  componentDidMount() {
    const { loggedInUser } = this.props;
    this.setState({ email: loggedInUser.email });
  }
  inputChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  sendFeedback = async () => {
    const { loggedInUser, setLoadingStatus } = this.props;
    const data = {
      feedback: {
        ...this.state,
        userId: loggedInUser.id,
        username: loggedInUser.name,
      },
    };

    setLoadingStatus({ loading: true, text: "Loading..." });
    try {
      const result = await addFeedback(data);
      const { message } = result.data;

      showInfoToast(message);
      setLoadingStatus({ loading: false });
      this.setState({
        message: "",
        email: loggedInUser.email,
        phoneNumber: "",
      });
    } catch (error) {
      console.log("ser : [containers Feedback sendFeedback] error => ", error);
      setLoadingStatus({ loading: false });

      if (error.response) {
        const { message } = error.response.data;

        showErrorToast(message);
      }
    }
  };

  render() {
    const { classes } = this.props;
    const { message, email, phoneNumber } = this.state;

    return (
      <Fragment>
        <Paper className={classes.paper}>
          <Grid container spacing={16} alignItems="center" justify="center">
            <Grid item xs={10} sm={8} md={6} lg={4}>
              <Grid container alignItems="center" justify="center">
                <Typography variant="h4">Send Us Feedback</Typography>
              </Grid>
              <ValidatorForm ref="form" onSubmit={this.sendFeedback}>
                <Grid container alignItems="center" justify="center">
                  <TextValidator
                    fullWidth
                    margin="normal"
                    multiline={true}
                    rows={8}
                    rowsMax={8}
                    name="message"
                    label="Message*"
                    value={message}
                    onChange={this.inputChangeHandler}
                    validators={["required"]}
                    errorMessages={[constants.validation.MESSAGE_NOT_EMPTY]}
                  />
                </Grid>
                <Grid container alignItems="center" justify="center">
                  <TextValidator
                    fullWidth
                    margin="normal"
                    name="email"
                    label="Email"
                    value={email}
                    onChange={this.inputChangeHandler}
                    validators={["isEmail"]}
                    errorMessages={[constants.validation.EMAIL_VALID_ERROR]}
                  />
                </Grid>
                <Grid container alignItems="center" justify="center">
                  <TextValidator
                    fullWidth
                    margin="normal"
                    name="phoneNumber"
                    label="Phone Number"
                    value={phoneNumber}
                    onChange={this.inputChangeHandler}
                    validators={["isNumber"]}
                    errorMessages={[
                      constants.validation.PHONENUMBER_VALID_ERROR,
                    ]}
                  />
                </Grid>
                <Grid container alignItems="center" justify="flex-end">
                  <div className={classes.sendButton}>
                    <OutlinedButton fullWidth type="submit">
                      <SendIcon className={classes.sendIcon} />
                      SEND
                    </OutlinedButton>
                  </div>
                </Grid>
              </ValidatorForm>
            </Grid>
          </Grid>
        </Paper>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.auth.user,
});

const mapActionToProps = {
  setLoadingStatus,
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(withStyles(styles, { withTheme: true })(Feedback));
