import React, {useEffect, useState} from 'react';
import DialogWrapper from "../../../../hoc/DialogWrapper";
import DialogActionsWrapper from "../../../../hoc/DialogActionsWrapper";
import CustomTextButton from "../../../../components/CustomTextButton";
import OutlinedButton from "../../../../components/OutlinedButton";
import CreateTgBotForm from "./create";
import UpdateTgBotForm from "./update";
import {showInfoToast} from "../../../../utils/utility";


const TgBotConfig = props => {
  const {
    addon,
    onClose,
    setLoadingStatus,
    createTgBot,
    updateTgBot,
    getTgBot,
    removeTgBot,
  } = props;

  const isCreateMode = !addon.tgBotId;

  const [newBotToken, setNewBotToken] = useState('');
  const [bot, setBot] = useState(null);

  useEffect(() => {
    if (!isCreateMode) {
      setLoadingStatus({loading: true});
      getTgBot(addon.tgBotId).then(bot => {
        setBot(bot);
        setLoadingStatus({loading: false});
      });
    }
  }, []);

  const handleSubmit = async () => {
    setLoadingStatus({loading: true});
    if (isCreateMode) {
      await createTgBot({addonId: addon._id, token: newBotToken});
      showInfoToast('Bot created');
    } else {
      await updateTgBot(bot);
      showInfoToast('Bot updated');
    }
    onClose();
    setLoadingStatus({loading: false});
  };

  const handleFieldChange = (field, value) => {
    setBot(prev => ({...prev, [field]: value}));
  };

  const handleConfigureFromAddon = () => {
    setBot(
      prev => ({
        ...prev,
        symbols: addon.symbols,
        timeframes: addon.timeframes,
        patterns: addon.harmonicConfig.available.names,
      })
    )
  };

  const handleRemoveTgBot = () => {
    removeTgBot({addonId: addon._id, botId: addon.tgBotId});
    onClose();
    showInfoToast('Bot removed');
  }

  let title = `Create telegram bot for ${addon.name}`;
  if (!isCreateMode) {
    title = `Update telegram bot for ${addon.name}`;
  }

  return (
    <DialogWrapper
      opened={true}
      dialogTitle={title}
      dialogActions={
        <DialogActionsWrapper>
          <CustomTextButton onClick={onClose}>
            Cancel
          </CustomTextButton>
          <OutlinedButton
            type='button'
            disabled={false}
            onClick={handleSubmit}
          >
            Save
          </OutlinedButton>
          {
            !isCreateMode && (
              <OutlinedButton
                type='button'
                disabled={false}
                onClick={handleRemoveTgBot}
              >
                Remove bot
              </OutlinedButton>
            )
          }
        </DialogActionsWrapper>
      }
    >
      {
        isCreateMode ? (
          <CreateTgBotForm
            token={newBotToken}
            setToken={setNewBotToken}
          />
        ) : (
          <UpdateTgBotForm
            bot={bot}
            addon={addon}
            handleFieldChange={handleFieldChange}
            handleConfigureFromAddon={handleConfigureFromAddon}
          />
        )
      }
    </DialogWrapper>
  );
};

export default TgBotConfig;
