import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import SideDrawer from '../../components/Navigation/SideDrawer';
import NotificationPanelList from '../../components/NotificationPanelList';
import { isWidthWiderThan, isValidDarkTheme } from '../../utils/styles';
import { changeSideBar } from '../../actions/theme';

const styles = theme => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.subBackColor2,
    },
    toolbar: {
        ...theme.mixins.toolbar
    },
    fab: {
        position: 'absolute',
        opacity: 1,
        bottom: 16,
        left: 16,
        zIndex: 10,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 2, //`${getSpacingUnit(theme) * 2}px ${getSpacingUnit(theme) * 4}px ${getSpacingUnit(theme) * 2}px ${getSpacingUnit(theme) * 4}px`,
        minHeight: '100vh',
        color: theme.palette.mainForeColor,
        backgroundColor: isValidDarkTheme(theme.palette.type) ? theme.palette.subBackColor2 : theme.palette.grayColor[10],
        width: 0,
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing.unit * 2//`${getSpacingUnit(theme) * 2}px ${getSpacingUnit(theme) * 4}px ${getSpacingUnit(theme) * 2}px ${getSpacingUnit(theme) * 4}px`,
        }
    },
    mobileMode: {
        display: 'none'
    },
});

class Layout extends React.Component {
    state = {
        openSideDrawer: false,
        expandingIndex: -1
    };

    navigationHandler = (event, index) => {
        this.setState({ openSideDrawer: true });
        if (this.state.expandingIndex === index) {
            this.setState({ expandingIndex: -1 });
        } else {
            this.setState({ expandingIndex: index });
        }
    };

    setExpandingIndex = index => {
        this.setState({ expandingIndex: index });
    }

    drawOpen = () => {
        const { changeSideBar, isSideDrawer } = this.props;
        changeSideBar(!isSideDrawer);
    };

    drawCloseHandler = () => {
        this.setState({ openSideDrawer: false });
        this.setState({ expandingIndex: -1 });
    };

    render() {
        const { classes, isSideDrawer, width } = this.props;
        const mobileMode = (isSideDrawer && !isWidthWiderThan('md', width)) ? classes.mobileMode : null;
        return (
            <div className={classes.root}>
                {!isWidthWiderThan('md', width) &&
                    <Fab className={classes.fab} onClick={this.drawOpen} >
                        <AddIcon />
                    </Fab>}
                <SideDrawer
                    drawOpenHandler={this.drawOpenHandler}
                    openSideDrawer={this.state.openSideDrawer}
                    expandingIndex={this.state.expandingIndex}
                    drawCloseHandler={this.drawCloseHandler}
                    navigationHandler={this.navigationHandler}
                    setExpandingIndex={this.setExpandingIndex} />
                <main className={classNames(classes.content, mobileMode)}>
                    {/* <div className={classes.toolbar} /> */}
                    <NotificationPanelList />
                    {this.props.children}
                </main>
            </div>
        );
    }
}

Layout.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    isSideDrawer: state.theme.isSideDrawer,

});

const mapActionToProps = {
    changeSideBar
};

export default connect(
    mapStateToProps,
    mapActionToProps,
    null
)(withWidth()(withStyles(styles, { withTheme: true })(Layout)));
