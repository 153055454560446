
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import { getSpacingUnit } from '../../utils/utility';


const styles = theme => {
    const spacingUnit = getSpacingUnit(theme);

    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            marginLeft: spacingUnit * 2,
            [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
                marginTop: spacingUnit * 2,
                width: '100%'
            }
        },
    };
};

const ToolsLeftPanelWrapper = ({ classes, children }) => {
    return (
        <div className={classes.root}>
            {children}
        </div>
    );
};

export default withStyles(styles, {withTheme: true})(ToolsLeftPanelWrapper);
