import ReactDOM from "react-dom";
import jwt_decode from "jwt-decode";
import moment from "moment";
import constants from "../constants";
import { toast } from "react-toastify";
import i18n from "./i18n";

// TODO: replace all hardcoded theme.spacing.unit with this
const getSpacingUnit = (theme) => theme.spacing.unit;

const getFontUnit = (theme) => theme.spacing.unit * 1.25;

const isEmpty = (value) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

const hasValidToken = () => {
  if (!localStorage.jwtToken) {
    return false;
  }

  const decoded = jwt_decode(localStorage.jwtToken);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    return false;
  }
  return true;
};

const isBuyByTitle = title => {
  return title.toLowerCase().includes("buy");
};

const isBuyByType = type => {
  return constants.signalsTitle[type].toLowerCase().includes("buy");
};

const timeDifference = (serverTime, previous) => {
  if (!serverTime) {
    return moment.utc(previous).format(constants.minuteAPFormat);
  }

  const currentTime = new Date(serverTime).getTime();
  const previousTime = new Date(previous).getTime();
  var msJustNow = 30 * 1000;
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;

  var elapsed = currentTime - previousTime;
  if (elapsed < msJustNow) {
    return "Just Now";
  } else if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + " seconds ago";
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + " minutes ago";
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + " hours ago";
  } else {
    return moment.utc(previous).format(constants.minuteAPFormat);
  }
};

const isUpdatedInTime = (currentDate, lastDate) => {
  let dateTime = currentDate;
  if (!currentDate) {
    dateTime = new Date().toISOString();
  }
  const fourHourTime = 60000 * 60 * 4;
  if (
    new Date(dateTime).getTime() - new Date(lastDate).getTime() <=
    fourHourTime
  ) {
    return true;
  }
  return false;
};

// TODO: * should be go to config
const getTimeframeName = (timeframe) => {
  switch (timeframe) {
    case 1:
      return "1m";
    case 5:
      return "5m";
    case 15:
      return "15m";
    case 30:
      return "30m";
    case 60:
      return "1h";
    case 120:
      return "2h";
    case 240:
      return "4h";
    case 1440:
      return "1D";
    default:
      return timeframe;
  }
};

const getMomentTime = (date, timeFormat) => {
  return moment.utc(date).format(timeFormat);
};

// TODO: * should be go to config
const timeframeList = [
  {
    value: 1,
    label: "1m",
  },
  {
    value: 5,
    label: "5m",
  },
  {
    value: 15,
    label: "15m",
  },
  {
    value: 30,
    label: "30m",
  },
  {
    value: 60,
    label: "1h",
  },
  {
    value: 240,
    label: "4h",
  },
  {
    value: 1440,
    label: "1D",
  },
];

// TODO: * group and migrate tool specific utility
// convert signal to be adapted to strat
const convertToToolStratSignal = (signal) => {
  return {
    id: signal._id,
    comment: signal.comment,
    title: getSignalTypeLabel(signal.type),
    lots: signal.lots,
    price: signal.price,
    time: signal.time,
    type: signal.type,
    ticket: signal.ticket,
    symbol: signal.symbol,
    symbolId: signal.symbolId,
    tp: signal.tp,
    sl: signal.sl,
    tp1: signal.tp1,
    signalKind: newSignalStatus(signal), //.signalKind,
    openClose: signal.openClose,
    closedPrice: signal.closedPrice,
    closedTime: signal.closedTime,
    pips: signal.pips,
    strategyId: signal.strategyId,
  };
};

// convert signal to be adapted to strat table
// TODO: * expensive method -> use the following array handling method intead
const convertToToolStratTableSignal = (signal) => {
  const date = new Date(signal.time);
  return [
    getMomentTime(date, constants.timeFormat),
    getSignalTypeLabel(signal.type),
    signal.symbol,
    signal.openClose ? constants.openClose.OPEN : constants.openClose.CLOSE,
    signal.price,
    signal.tp,
    signal.sl,
    signal._id || signal.id,
  ];
};

const getStatusTextWithSignalKind = (kind) => {
  switch (kind) {
    case constants.signalKinds.PENDING_SELL:
      return "PENDING SELL";
    case constants.signalKinds.PENDING_BUY:
      return "PENDING BUY";
    case constants.signalKinds.TP1_HIT:
      return "TP1 HIT";
    case constants.signalKinds.TP2_HIT:
      return "TP HIT";
    case constants.signalKinds.SL_HIT:
      return "SL HIT";
    case constants.signalKinds.ACTIVATED:
      return "ACTIVATED";
    default:
      return "CANCELLED";
  }
};

const newSignalStatus = (signal) => {
  const { signalKind, type, price, tp1, closedPrice } = signal;
  const {
    OP_SELL,
    OP_SELLLIMIT,
    OP_SELLSTOP,
    OP_BUY,
    OP_BUYLIMIT,
    OP_BUYSTOP,
  } = constants.signalKind;
  const {
    // UPCOMING_SIGNAL,
    PENDING_SIGNAL,
    // EXCUTED_SIGNAL,
    TP1_REACHED_SIGNAL,
    CLOSED_SIGNAL,

    PENDING_SELL,
    PENDING_BUY,
    TP1_HIT,
    TP2_HIT,
    SL_HIT,
    ACTIVATED,
    CANCELLED,
  } = constants.signalKinds;
  let t = 0;
  if (signalKind >= PENDING_SELL) {
    if (signalKind === TP1_HIT) {
      return ACTIVATED;
    }
    return signalKind;
  }
  if (signalKind < PENDING_SELL) {
    if (tp1 === 0 || !tp1) t = price;
    else t = tp1;
  }

  if (
    signalKind === PENDING_SIGNAL &&
    [OP_SELL, OP_SELLLIMIT, OP_SELLSTOP].includes(type)
  ) {
    return PENDING_SELL;
  } else if (
    signalKind === PENDING_SIGNAL &&
    [OP_BUY, OP_BUYLIMIT, OP_BUYSTOP].includes(type)
  ) {
    return PENDING_BUY;
  } else if (signalKind === TP1_REACHED_SIGNAL) {
    return ACTIVATED;
  } else if (
    signalKind === CLOSED_SIGNAL &&
    ((closedPrice > t && type === OP_BUY) ||
      (closedPrice < t && type === OP_SELL))
  ) {
    return TP2_HIT;
  } else if (
    signalKind === CLOSED_SIGNAL &&
    ((closedPrice < t && type === OP_BUY) ||
      (closedPrice > t && type === OP_SELL))
  ) {
    return SL_HIT;
  } else if (!closedPrice) {
    return ACTIVATED;
  } else {
    return CANCELLED;
  }
};

// TODO: remove later: old status calc
// const signalStatus = signal => {
//     const { signalKind, type, price, sl, tp, closedPrice } = signal;
//     if (signalKind === constants.signalKinds.EXCUTED_SIGNAL) {
//         return constants.signalStatus.active;
//     } else if (signalKind === constants.signalKinds.PENDING_SIGNAL ) {
//         return constants.signalStatus.pending;
//     } else if (signalKind === constants.signalKinds.CLOSED_SIGNAL ) {
//         if ((type === constants.signalKind.OP_BUY && price - closedPrice >= sl) || (type === constants.signalKind.OP_SELL && closedPrice - price >= sl)) {
//             return constants.signalStatus.slHit;
//         } else if((type === constants.signalKind.OP_BUY && closedPrice - price >= tp) || (type === constants.signalKind.OP_SELL && price - closedPrice >= tp)) {
//             return constants.signalStatus.won;
//         } else {
//             return constants.signalStatus.cancelled;
//         }
//     }
//     return '';
// };

const convertToToolStratTableSignals = (signals) => {
  const tableSignals = signals.map((signal) => {
    const date = new Date(signal.time);
    return [
      getMomentTime(date, constants.timeFormat),
      signal.symbol,
      getSignalTypeLabel(signal.type),
      // signal.openClose
      //     ? constants.openClose.OPEN
      //     : constants.openClose.CLOSE,
      signal.price,
      signal.tp,
      signal.sl,
      signal.closedPrice || "",
      signal._id || signal.id,
      getStatusTextWithSignalKind(newSignalStatus(signal)),
      signal.pips || 0,
    ];
  });

  return tableSignals;
};

const convertToReportTableSignals = (signals) => {
  const tableSignals = signals.map((signal) => {
    const date = new Date(signal.time);
    const endDate = new Date(signal.closedTime);
    return [
      getMomentTime(date, constants.timeFormat),
      signal.closedTime ? getMomentTime(endDate, constants.timeFormat) : "",
      signal.symbol,
      getSignalTypeLabel(signal.type),
      // signal.openClose
      //     ? constants.openClose.OPEN
      //     : constants.openClose.CLOSE,
      signal.price,
      signal.tp,
      signal.sl,
      signal.closedPrice || "",
      signal._id || signal.id,
      getStatusTextWithSignalKind(newSignalStatus(signal)),
      signal.pips || 0,
    ];
  });

  return tableSignals;
};

const rolePairs = [
  {
    label: constants.roleName.ADMIN_ROLE,
    value: constants.roles.ADMIN_ROLE,
  },
  {
    label: constants.roleName.CHART_ADMIN_ROLE,
    value: constants.roles.CHART_ADMIN_ROLE,
  },
  {
    label: constants.roleName.PROVIDER_ROLE,
    value: constants.roles.PROVIDER_ROLE,
  },
  {
    label: constants.roleName.USER_ROLE,
    value: constants.roles.USER_ROLE,
  },
  {
    label: constants.roleName.SCHEDULE_ADMIN_ROLE,
    value: constants.roles.SCHEDULE_ADMIN_ROLE,
  },
];

const getRoleLabel = (targetRoleValue) => {
  const targetRolePair = rolePairs.find(
    (rolePair) => rolePair.value === targetRoleValue
  );
  const roleLabel = targetRolePair.label;

  return roleLabel;
};

const getRoleLabels = (targetRoleValues) => {
  let result = [];
  if (targetRoleValues === undefined || targetRoleValues === null)
    return result;
  for (let value of targetRoleValues) {
    const targetRolePair = rolePairs.find(
      (rolePair) => rolePair.value === value
    );
    if (targetRolePair !== undefined) {
      result.push(targetRolePair.label);
    }
  }
  return result;
};

const getSignalTypeLabel = (targetSignalTypeValue) => {
  const targetSignalTypePair = constants.signalTypeList.find(
    (signalTypePair) => signalTypePair.value === targetSignalTypeValue
  );
  const targetSignalTypeLabel = targetSignalTypePair.label;

  return targetSignalTypeLabel;
};

const getStrategyTypeLabel = (targetStrategyTypeValue) => {
  const targetStrategyTypePair = constants.tools.find(
    (strategyTypePair) => strategyTypePair.value === targetStrategyTypeValue
  );
  const targetStrategyTypeLabel = targetStrategyTypePair
    ? targetStrategyTypePair.label
    : "";

  return targetStrategyTypeLabel;
};

const getSignalKindElementColor = (signal) => {
  const found = constants.signalKindList.find(
    (signalKindElement) => signalKindElement.value === signal.signalKind
  );
  if (typeof found === "undefined") {
    return constants.signalKindList[constants.signalKindList.length - 1];
  }
  if (
    signal.signalKind === constants.signalKinds.ACTIVATED &&
    !isBuyByType(signal.type)
  )
    return "red500";
  return found.colorType;
};

const generateRandomDigitString = () => {
  return Math.random().toString().slice(2, 11);
};

const getAPIToken = () => {
  try {
    const decoded = jwt_decode(localStorage.jwtToken);
    return decoded.apiToken;
  } catch (error) {
    console.log("ray : [utils utility getAPIToken] error => ", error);
    return "";
  }
};

const compareObject = (object1, object2) => {
  if (JSON.stringify(object1) !== JSON.stringify(object2)) {
    return false;
  }
  return true;
};

const getAddEditDialogMetadata = (isAddDialog, manageTitle) => {
  let dialogTitlePrefix;
  let dialogDescriptionPrefix;
  let submitButtonName;
  const cancelButtonName = "Cancel";
  if (isAddDialog) {
    dialogTitlePrefix = "Add";
    dialogDescriptionPrefix = "Create new";
    submitButtonName = "Add";
  } else {
    dialogTitlePrefix = "Edit";
    dialogDescriptionPrefix = "Update current";
    submitButtonName = "Update";
  }
  const dialogTitle = `${dialogTitlePrefix} ${manageTitle}`;
  const dialogDescription = `${dialogDescriptionPrefix} ${manageTitle} data`;

  return { dialogTitle, dialogDescription, submitButtonName, cancelButtonName };
};

const validateRoleSkuAccess = (pageRoles, pageSkuIds, loggedInUser) => {
  let valid = false;
  if (
    loggedInUser.roles &&
    loggedInUser.roles.includes(constants.roles.USER_ROLE) &&
    pageSkuIds
  ) {
    for (const loggedInUserSkuId of loggedInUser.skuIds) {
      if (pageSkuIds.includes(loggedInUserSkuId)) {
        valid = true;
        break;
      }
    }
  } else if (loggedInUser.roles) {
    for (let role of loggedInUser.roles) {
      if (pageRoles.includes(role)) valid = true;
      break;
    }
  }

  return valid;
};

const validatePageAccessByRole = (pageRoles, loggedInUser) => {
  if (loggedInUser.roles === undefined || loggedInUser.roles === null) {
    return false;
  }
  for (let role of loggedInUser.roles)
    if (pageRoles.includes(role)) {
      return true;
    }
  return false;
};

const dateTimeToUTCString = (dateTime) => {
  const dateString = moment(dateTime).format(constants.timeFormat);
  return dateString + " UTC";
};

const getUTCDateTimeString = (date) => {
  return moment.utc(date).format(constants.utcDateTimeFormat);
};

const getUTCDateString = (date) => {
  return moment.utc(date).format(constants.utcDateFormat);
};

const getUTCDateTime = (date) => {
  return moment.utc(date);
};

const getUTCTimeString = (date) => {
  return moment.utc(date).format(constants.utcTimeFormat);
};

const getDateFromUTCDateTime = (day, time) => {
  return new Date(
    day.getFullYear(),
    day.getMonth(),
    day.getDate(),
    time.getUTCHours(),
    time.getUTCMinutes(),
    time.getUTCSeconds()
  );
};

const getConvertedUTCDate = (date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

const extractTimeStamp = (dateString) => {
  return new Date(dateString).getTime() % constants.DAY_TIMESTAMP;
};

const extractUTCTimeStamp = (dateString) => {
  return getUTCDateTime(dateString) % constants.DAY_TIMESTAMP;
};

const extractDateStamp = (date) => {
  return new Date(date).getTime() - extractTimeStamp(date);
};

const extractUTCDateStamp = (date) => {
  return getUTCDateTime(date) - extractUTCTimeStamp(date);
};

// remove item with index and return the rest as array
const removeItemWithSlice = (items, index) => {
  return [...items.slice(0, index), ...items.slice(index + 1)];
};

const checkAdminKindRole = (userRoles) => {
  return (
    userRoles &&
    (userRoles.includes(constants.roles.CHART_ADMIN_ROLE) ||
      userRoles.includes(constants.roles.ADMIN_ROLE))
  );
};

const checkScheduleAdminKindRole = (userRoles) => {
  if (userRoles === undefined) {
    return false;
  }
  for (let role of userRoles) {
    if (
      [
        constants.roles.ADMIN_ROLE,
        constants.roles.CHART_ADMIN_ROLE,
        constants.roles.SCHEDULE_ADMIN_ROLE,
      ].includes(role)
    ) {
      return true;
    }
  }
  return false;
};

const checkAdminRole = (userRoles) => {
  if (userRoles === undefined || userRoles === null || userRoles.length === 0) {
    return false;
  }
  return userRoles.includes(constants.roles.ADMIN_ROLE);
};

const checkUserRole = (userRoles) => {
  if (userRoles === undefined || userRoles === null || userRoles.length === 0) {
    return false;
  }
  return userRoles.includes(constants.roles.USER_ROLE);
};

const showErrorToast = (message) => {
  toast.error(message, {
    position: toast.POSITION.BOTTOM_RIGHT,
  });
};

const showInfoToast = (message) => {
  toast.info(message, {
    position: toast.POSITION.BOTTOM_RIGHT,
  });
};

const isInCurrentWeek = (serverTime, time) => {
  let lastDate = null;
  if (serverTime) {
    lastDate = new Date(serverTime);
  } else {
    lastDate = new Date();
  }
  const weekfirstDay = lastDate.getDate() - lastDate.getDay();
  const firstDate = new Date(lastDate.setDate(weekfirstDay));
  const signalDate = new Date(time);
  return signalDate > firstDate;
};

// compare date within diffWeekThreshold weeks. e.g. diff = 2 => within last week
const checkWeekDiff = (serverTime, date, diffWeekThreshold) => {
  let lastDate = null;
  if (serverTime) {
    lastDate = new Date(serverTime);
  } else {
    lastDate = new Date();
  }
  const compareWeekFirstDay =
    lastDate.getDate() - lastDate.getDay() - (diffWeekThreshold - 1) * 7;
  const firstDate = new Date(lastDate.setDate(compareWeekFirstDay));
  const targetDate = new Date(date);
  return targetDate > firstDate;
};

const measureElement = (element) => {
  if (!element) {
    return { width: 0, height: 0 };
  }
  const DOMNode = ReactDOM.findDOMNode(element);
  const width = DOMNode.offsetWidth;
  const height = DOMNode.offsetHeight;
  const measuredSize = {
    width,
    height,
  };
  return measuredSize;
};

const onSafariBrowser = () => {
  if (
    navigator.userAgent.indexOf("Safari") !== -1 &&
    navigator.userAgent.indexOf("Chrome") === -1
  ) {
    return true;
  } else {
    return false;
  }
};

const getKeyFromValue = (obj, value) => {
  const targetIndex = Object.values(obj).findIndex(
    (valueElement) => valueElement === value
  );
  const targetKey = Object.keys(obj)[targetIndex];
  return targetKey;
};

const getToolsTypesArray = (tools, currentRules, currentToolsType) => {
  let extendedTools = [...tools];

  extendedTools.push({
    label: 'Disclaimer Content',
    value: constants.DISCLAIMER_RULE
  });

  const toolsTypeArray = [];
  extendedTools.forEach(tool => {
    const rule = currentRules.find(
      (rule) => rule.toolsType === tool.value
    );
    if (!rule) {
      toolsTypeArray.push({
        label: tool.label,
        value: tool.value,
      });
    }
  });

  if (currentToolsType !== null) {
    const tool = extendedTools.find(tool => tool.value === currentToolsType);
    if (tool) {
      toolsTypeArray.push({
        label: tool.label,
        value: tool.value,
      });
    }
  }

  return toolsTypeArray;
};

const getSchedulerUTCTimeRange = (selectedDate) => {
  const startDate = new Date();
  const endDate = new Date();
  if (selectedDate) {
    startDate.setYear(selectedDate.getFullYear());
    startDate.setMonth(selectedDate.getMonth());
    startDate.setDate(selectedDate.getDate());
    endDate.setYear(selectedDate.getFullYear());
    endDate.setMonth(selectedDate.getMonth());
    endDate.setDate(selectedDate.getDate());
  }
  startDate.setHours(startDate.getHours() - 3);
  endDate.setHours(endDate.getHours() + 3);
  return {
    startDate: startDate,
    endDate: endDate,
  };
};

const getFirstOfCurrentWeek = (currentDate) => {
  const day = currentDate.getDay();
  return new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - day
  );
};

const getLastOfCurrentWeek = (currentDate) => {
  const day = currentDate.getDay();
  return new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + (day === 0 ? -1 : 6) - day
  );
};

const getWeekDates = (date) => {
  let weekDates = [];
  for (let i = 0; i < 7; i++) {
    let iDate = new Date(moment.utc(date).format(constants.utcDateTimeFormat));
    iDate.setDate(date.getDate() + i);
    weekDates.push(iDate);
  }
  return weekDates;
};

const isSameDate = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

const isSameTimeline = (date1, date2, timeline) => {
  const utcDate = new Date(
    moment.utc(date1).format(constants.utcDateTimeFormat)
  );
  if (
    utcDate.getFullYear() === date2.getFullYear() &&
    utcDate.getMonth() === date2.getMonth() &&
    utcDate.getDate() === date2.getDate()
  ) {
    const dateHours = utcDate.getHours();
    if (timeline === "Morning" && dateHours < 12) {
      return true;
    } else if (timeline === "Afternoon" && dateHours < 18 && dateHours >= 12) {
      return true;
    } else if (timeline === "Evening" && dateHours >= 18) {
      return true;
    }
  }
  return false;
};

const getPassedDays = (date) => {
  return moment.duration(moment().diff(date)).humanize() + " ago";
};

const checkPendingSignal = (signal) => {
  const { PENDING_SELL, PENDING_BUY } = constants.signalKinds;
  return [PENDING_SELL, PENDING_BUY].includes(signal.signalKind);
};

const getSignalMainPoints = (signals) => {
  let lastSameTypeIndex = 0;
  let firstSignalIndex = -1;
  for (let i = 0; i < signals.length; i++) {
    if (!checkPendingSignal(signals[i])) {
      firstSignalIndex = i;
      break;
    }
  }
  if (firstSignalIndex === -1) {
    return {
      activated: null,
      continuationIndex: null,
      consecutiveIndex: null,
    };
  }

  for (let i = firstSignalIndex + 1; i < signals.length; i++) {
    if (
      signals[firstSignalIndex].type === signals[i].type ||
      checkPendingSignal(signals[i])
    ) {
      if (!checkPendingSignal(signals[i])) {
        lastSameTypeIndex = i;
      }
    } else {
      break;
    }
  }

  let activatedIndex = null;
  if (
    lastSameTypeIndex + 1 < signals.length &&
    checkPendingSignal(signals[lastSameTypeIndex + 1])
  ) {
    activatedIndex = lastSameTypeIndex;
  }
  let continuationIndex = null;
  let thirdTrade = null;
  let fourthTrade = null;
  let consecutiveIndex = null;
  let count = 0;
  for (let i = lastSameTypeIndex - 1; i >= 0; i--) {
    if (!checkPendingSignal(signals[i])) {
      if (count === 0) {
        continuationIndex = i;
      } else if (count === 1) {
        thirdTrade = i;
      } else if (count === 2) {
        fourthTrade = i;
      } else if (count === 3) {
        consecutiveIndex = i;
      } else if (count >= 4) {
        break;
      }
      count++;
    }
  }

  return {
    activatedIndex: activatedIndex,
    continuationIndex: continuationIndex,
    thirdTrade: thirdTrade,
    fourthTrade: fourthTrade,
    consecutiveIndex: consecutiveIndex,
  };
};

const getSignalStatus = (signalPoints, signalIndex, signalType, signal, strategyType, symbolName) => {
  let signalText = `\nType: ${
    isBuyByType(signal.type)
      ? constants.signalType.BUY
      : constants.signalType.SELL
    }`;
  signalText += `\nStatus: ${getStatusTextWithSignalKind(signal.signalKind)}`;
  signalText += `\nPrice: ${signal.price}\n TP: ${signal.tp}\n SL: ${signal.sl}\n`;
  if (!signal.openClose) {
    signalText += `Closed Price: ${signal.closedPrice}\n ${getProfitUnitString(symbolName)} : ${signal.pips}\n`;
  }
  if (!isEmpty(signal.comment)) {
    signalText += `Comment: ${signal.comment}\n`;
  }
  if (signalPoints.activatedIndex === signalIndex) {
    return {
      label: "Activated",
      index: 1,
      SignalInfo: signalText,
    };
  } else if (signalPoints.continuationIndex === signalIndex) {
    return {
      label:
        signalType === 0 && strategyType === constants.COSMICCLOUD
          ? constants.SIGNAL_STATUS.CONTINUATION.LONG
          : constants.SIGNAL_STATUS.CONTINUATION.SHORT,
      index: 2,
      SignalInfo: signalText,
    };
  } else if (signalPoints.thirdTrade === signalIndex) {
    return {
      label:
        signalType === 0
          ? constants.SIGNAL_STATUS.MIDDLE.LONG
          : constants.SIGNAL_STATUS.MIDDLE.SHORT,
      index: 3,
      SignalInfo: signalText,
    };
  } else if (signalPoints.fourthTrade === signalIndex) {
    return {
      label:
        signalType === 0
          ? constants.SIGNAL_STATUS.MIDDLE.LONG
          : constants.SIGNAL_STATUS.MIDDLE.SHORT,
      index: 4,
      SignalInfo: signalText,
    };
  } else {
    if (signalPoints.consecutiveIndex === signalIndex) {
      return {
        label: constants.SIGNAL_STATUS.CONSECUTIVE.MESSAGE,
        index: 5,
        SignalInfo: signalText,
      };
    }
    return {
      SignalInfo: signalText,
    };
  }
};

const getScheduleDate = (selectedDate) => {
  const currentUTCDate = new Date(moment());
  const yesterday = new Date(moment());
  const tomorrow = new Date(moment());
  yesterday.setDate(currentUTCDate.getDate() - 1);
  tomorrow.setDate(currentUTCDate.getDate() + 1);
  if (isSameDate(selectedDate, currentUTCDate)) {
    return i18n.t("calendar.Today");
  } else if (isSameDate(selectedDate, yesterday)) {
    return i18n.t("calendar.Yesterday");
  } else if (isSameDate(selectedDate, tomorrow)) {
    return i18n.t("calendar.Tomorrow");
  } else {
    const monthName = `calendar.${moment(selectedDate)
      .format("MMMM")
      .toUpperCase()}`;
    return `${selectedDate.getFullYear()} ${i18n.t(
      monthName
    )} ${selectedDate.getDate()}`;
  }
};

const checkVimeoLink = (link) => {
  if (link.includes("vimeo")) {
    return true;
  }
  return false;
};

const checkYoutubeLink = (link) => {
  if (link.includes("youtu.be") || link.includes("youtube.com")) {
    return true;
  }
  return false;
};

// const getTypeWithBackend = type => {
//     switch (type) {
//         case: constants.
//     }
// }

const getAnalyzedSignalReport = (
  winPips,
  lossPips,
  bestPips,
  worstPips,
  countWinPips,
  countLossPips
) => {
  const add = (sum, value) => sum + value;
  const countWin = countWinPips.reduce(add, 0);
  const countLoss = countLossPips.reduce(add, 0);
  const pipsWinSum = winPips.reduce(add, 0);
  const pipsLossSum = lossPips.reduce(add, 0);

  return {
    signalCount: countWin + countLoss,
    pipsSum: pipsWinSum + pipsLossSum,
    aveWin: countWin > 0 ? pipsWinSum / countWin : 0,
    aveLoss: countLoss > 0 ? pipsLossSum / countLoss : 0,
    bestStrategyPips: bestPips,
    worstStrategyPips: -worstPips,
  };
};

const getHighChartSeries = (winPips, lossPips, year) => {
  let months = Array(12).fill(null);
  for (let i = 0; i < 12; i++) {
    months[i] = new Date(`1/1/${year}`).setMonth(i);
  }
  const result = months.map((month, index) => {
    const monthNumber = index;
    return {
      name: moment(new Date(month)).format(constants.monthFormat),
      y: winPips[monthNumber] + lossPips[monthNumber],
    };
  });
  return result;
};

const hexToRgb = (input) => {
  input = input + "";
  input = input.replace("#", "");
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.");
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase(input);
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return (
    parseInt(first, 16) +
    ", " +
    parseInt(second, 16) +
    ", " +
    parseInt(last, 16)
  );
};

const getAccountId = (iframe) => {
  if (iframe.indexOf("accounts/") > 0 && iframe.indexOf("/events") > 0) {
    return iframe.split("accounts/")[1].split("/events")[0];
  }
  return "";
};

const getEventId = (iframe) => {
  if (iframe.indexOf("events/") > 0 && iframe.indexOf("/player") > 0) {
    return iframe.split("events/")[1].split("/player")[0];
  }

  return "";
};

const getEmbedId = (iframe) => {
  if (iframe.indexOf("title") > 0) {
    return iframe.split("title=")[0].split("ls_embed_")[1].replace("'", "");
  }
  return "";
};

const convertTimefromSecond = (milisecond) => {
  var pad = function (num, size) {
    return ("000" + num).slice(size * -1);
  };
  const sec = Math.floor(milisecond / 1000);
  const hours = Math.floor(sec / 60 / 60);
  const minutes = Math.floor(sec / 60) % 60;
  const seconds = Math.floor(sec - hours * 3600 - minutes * 60);
  if (hours > 0)
    return `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`;
  else return `${pad(minutes, 2)}:${pad(seconds, 2)}`;
};

const getDateYear = (date) => {
  return moment(date, "DD/MM/YYYY").year();
};

const isSignalClosed = (signalKind) => {
  return [
    constants.signalKinds.TP1_HIT,
    constants.signalKinds.TP2_HIT,
    constants.signalKinds.SL_HIT,
  ].includes(signalKind);
};

const calcPips = (signal, tick, digits) => {
  if ((signal.openClose || signal.closedPrice !== undefined) === true) {
    return 0;
  }

  const divider = (digits === 1 || digits === 3 || digits === 5) ? 10 : 1;

  if (signal.type === constants.signalKind.OP_BUY)
    return Math.round((signal.closedPrice - signal.price) / tick / divider);
  else
    return Math.round((signal.price - signal.closedPrice) / tick / divider);
};

const debounce = (func, wait) => {
  let timeout;
  return (args) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func(args);
    }, wait);
  };
};

const getToolKey = index => {
  const tool = constants.tools.find(tool => tool.value === index);

  if (!tool) {
    console.error('mars: invalid strategy index', index);
    return "unknown";
  }

  return tool.key;
};

const getProfitUnitString = symbolName => {
  if (symbolName.toLowerCase().includes('us30')) {
    return 'Points';
  }

  return 'Pips';
}

const formatTimeFrame = tf => {
  if (tf < 60) {
    return `${tf}m`;
  }
  if (tf >= 60 && tf < 1440) {
    return `${tf/60}h`;
  }
  return `${tf/1440}d`;
};

const groupN = (arr, n) => {
  const tempResult = arr.reduce((acc, v) => {
    if (acc.curr.length < n) {
      acc.curr.push(v);
    } else {
      acc.res.push(acc.curr);
      acc.curr = [v];
    }
    return acc;
  }, {res: [], curr: []});
  if (tempResult.curr.length) {
    tempResult.res.push(tempResult.curr);
  }
  return tempResult.res;
};

const saveAddonSettings = ({addonId, field, value}) => {
  try {
    const key = `${constants.addonSettingsStorageKeyPrefix}_${addonId}`;
    const saved = window.localStorage.getItem(key);
    let parsed = {};
    if (saved) {
      parsed = JSON.parse(saved);
    }
    parsed[field] = value;
    window.localStorage.setItem(key, JSON.stringify(parsed));
  } catch (e) {
    console.warn('Error saving addon settings');
  }
};

const loadAddonSettings = ({addonId, field}) => {
  try {
    const key = `${constants.addonSettingsStorageKeyPrefix}_${addonId}`;
    const saved = window.localStorage.getItem(key);
    let parsed = {};
    if (saved) {
      parsed = JSON.parse(saved);
    }
    return parsed[field] || null;
  } catch (e) {
    console.warn('Error loading addon settings');
  }
}

const saveHarmonicPatternFilters = (addonId, filters) => {
  try {
    const saved = window.localStorage.getItem(constants.harmonicFiltersStorageKey);
    let parsed = {};
    if (saved) {
      parsed = JSON.parse(saved);
    }
    parsed[addonId] = filters;
    window.localStorage.setItem(
      constants.harmonicFiltersStorageKey,
      JSON.stringify(parsed)
    );
  } catch (e) {
    console.warn('Error saving harmonic filters');
  }
};

const loadHarmonicPatternFilters = addonId => {
  try {
    const saved = window.localStorage.getItem(constants.harmonicFiltersStorageKey);
    let parsed = {};
    if (saved) {
      parsed = JSON.parse(saved);
    }
    return parsed[addonId] || null;
  } catch (e) {
    console.warn('Error saving harmonic filters');
  }
};

const parseHarmonicPattern = pattern => {
  return {
    ...pattern.points[0],
    symbol: pattern.symbol,
    timeframe: pattern.timeframe,
    _id: pattern._id,
    octId: pattern.octId,
    rr: pattern.rr,
    lowerTFEngulfing: pattern.lowerTFEngulfing,
    orderPartition: pattern.orderPartition,
    retracementPoint: pattern.retracementPoint,
    higherTFOrderPartition: pattern.higherTFOrderPartition,
    higherTFEngulfing: pattern.higherTFEngulfing,
    createdAt: pattern.createdAt,
    completed: pattern.completed,
  };
};

const getQueryParam = param => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param) || null;
};

const ALL_PATTERNS = {
  ABCD: 'ABCD',
  Alt_ABCD: 'Alt_ABCD',
  Golden_Pattern: 'Golden_Pattern',
  Gartley: 'Gartley',
  Bat: 'Bat',
  Alt_Bat: 'Alt_Bat',
  Butterfly: 'Butterfly',
  Crab: 'Crab',
  DeepCrab: 'DeepCrab',
  Shark: 'Shark',
  Cypher: 'Cypher',
  CP: 'CP',
  '5-O': '5-O',
  ThreeDrives: 'ThreeDrives',
}

const _4_POINT_PATTERNS = [
  ALL_PATTERNS.ABCD,
  ALL_PATTERNS.Alt_ABCD,
  ALL_PATTERNS.Golden_Pattern,
];

const _5_POINT_PATTERNS = [
  ALL_PATTERNS.Gartley,
  ALL_PATTERNS.Bat,
  ALL_PATTERNS.Alt_Bat,
  ALL_PATTERNS.Butterfly,
  ALL_PATTERNS.Crab,
  ALL_PATTERNS.DeepCrab,
  ALL_PATTERNS.Shark,
  ALL_PATTERNS.Cypher,
  ALL_PATTERNS.CP,
];

const _6_POINT_PATTERNS = [
  '5-O',
  'ThreeDrives',
];

const is4PointsPattern = pattern => _4_POINT_PATTERNS.includes(pattern.patternname);
const is5PointsPattern = pattern => _5_POINT_PATTERNS.includes(pattern.patternname);
const is6PointsPattern = pattern => _6_POINT_PATTERNS.includes(pattern.patternname);

const patternRRIsValid = rr => {
  if (!rr) {
    return false;
  }
  const fields = ['riskMin', 'riskMax', 'rewardMin', 'rewardMax'];
  return fields.every(f => !Number.isNaN(rr[f]));
};

const getPremiumLockedFilters = (premiumFilters, userSubscriptions) => {
  if (!premiumFilters) {
    return {};
  }
  return Object.keys(premiumFilters).reduce((acc, k) => {
    if (Array.isArray(premiumFilters[k])) {
      acc[k] = premiumFilters[k].filter(pf => !userSubscriptions.includes(pf.subscriptionValue))
    }
    return acc;
  }, {});
};

export {
  getSpacingUnit,
  isEmpty,
  hasValidToken,
  getFontUnit,
  isBuyByTitle,
  isBuyByType,
  timeDifference,
  getTimeframeName,
  timeframeList,
  getMomentTime,
  convertToToolStratSignal,
  convertToToolStratTableSignal,
  getRoleLabel,
  getRoleLabels,
  generateRandomDigitString,
  getAPIToken,
  isUpdatedInTime,
  compareObject,
  rolePairs,
  getAddEditDialogMetadata,
  validateRoleSkuAccess,
  extractTimeStamp,
  extractUTCTimeStamp,
  extractDateStamp,
  extractUTCDateStamp,
  getUTCDateTime,
  dateTimeToUTCString,
  getUTCDateTimeString,
  getUTCDateString,
  getUTCTimeString,
  getConvertedUTCDate,
  getDateFromUTCDateTime,
  getPassedDays,
  removeItemWithSlice,
  getSignalTypeLabel,
  checkAdminKindRole,
  checkScheduleAdminKindRole,
  checkUserRole,
  getStrategyTypeLabel,
  getSignalKindElementColor,
  convertToToolStratTableSignals,
  convertToReportTableSignals,
  showErrorToast,
  showInfoToast,
  checkAdminRole,
  isInCurrentWeek,
  checkWeekDiff,
  measureElement,
  onSafariBrowser,
  getKeyFromValue,
  getToolsTypesArray,
  getSchedulerUTCTimeRange,
  getFirstOfCurrentWeek,
  getLastOfCurrentWeek,
  getWeekDates,
  isSameDate,
  isSameTimeline,
  getSignalStatus,
  getSignalMainPoints,
  validatePageAccessByRole,
  getScheduleDate,
  checkYoutubeLink,
  checkVimeoLink,
  getAnalyzedSignalReport,
  getHighChartSeries,
  newSignalStatus,
  getStatusTextWithSignalKind,
  hexToRgb,
  getAccountId,
  getEventId,
  getEmbedId,
  convertTimefromSecond,
  getDateYear,
  isSignalClosed,
  calcPips,
  debounce,
  getToolKey,
  getProfitUnitString,
  formatTimeFrame,
  groupN,
  saveHarmonicPatternFilters,
  loadHarmonicPatternFilters,
  parseHarmonicPattern,
  getQueryParam,
  is4PointsPattern,
  is5PointsPattern,
  is6PointsPattern,
  saveAddonSettings,
  loadAddonSettings,
  patternRRIsValid,
  getPremiumLockedFilters,
};
