
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { showInfoToast, showErrorToast } from '../../../utils/utility';
import AddEditDialog from './AddEditDialog';
import CustomMUIDataTable from '../../../components/CustomMUIDataTable';
import PlusAddButton from '../../../components/PlusAddButton';
import EditIconButton from '../../../components/EditIconButton';
import RemoveIconButton from '../../../components/RemoveIconButton';
import ConfirmDialog from '../../../components/ConfirmDialog';
import * as VIDEO_SERVICE from '../../../services/video';
import { setLoadingStatus } from '../../../actions/loading';
import { checkYoutubeLink, checkVimeoLink, removeItemWithSlice } from '../../../utils/utility';
import constants from '../../../constants';
import { commonMUITableOptions } from '../../../utils/styles';
import youtubeIcon from '../../../assets/imgs/youtube.png';
import vimeoIcon from '../../../assets/imgs/vimeo.png';

class VideoManage extends Component {
    state = {
        videos: [],
        addEditDialogOpened: false,
        confirmDialogOpened: false,
        dialogData: null,
        removeId: null,
        removeIdList: null,
    };

    async componentDidMount() {
        const { setLoadingStatus } = this.props;
        setLoadingStatus({loading: true, text: 'Loading videos...'});
        try {
            let {data: videos} = await VIDEO_SERVICE.getVideos();
            this.setState({videos});
        } catch (error) {
            console.log(error);
        }
        setLoadingStatus({loading: false});
    }

    createTableData = data => {
        const tableData = data.map(video => {
            const { title, topic, link, _id } = video;
            const row = [
                title,
                topic,
                link,
                _id
            ];
            return row;
        });
        return tableData;
    };

    columns = () => [
        //{name: 'Title', options: {filter: true}},
        {
            name: 'Title',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    // TODO: hardcoded 2
                    const isYoutube = checkYoutubeLink(tableMeta.rowData[2]);
                    const isVimeo = checkVimeoLink(tableMeta.rowData[2]);
                    return (
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
                            { isYoutube && <img style={{width: '30px', height: '24px',}} src={youtubeIcon} alt='youtube' /> }
                            { isVimeo && <img style={{width: '30px', height: '12px', marginBottom: '4px'}} src={vimeoIcon} alt='youtube' /> }
                            {value}
                        </div>
                    );
                }
            }
        },
        {name: 'Topic', options: {filter: true}},
        {name: 'Link', options: {filter: false}},
        {
            name: 'Action',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {

                    return (
                        <div style={{display: 'flex'}}>
                            <EditIconButton color='primary' onClick={() => this.openEditDialogHandler(value)} />
                            <RemoveIconButton color='primary' onClick={() => this.openCloseConfirmDialogHandler(true, value)} />
                        </div>
                    );
                }
            }
        }
    ];

    options = {
        ...commonMUITableOptions,
        selectableRows: 'multiple',
        onRowsDelete: rowsDeleted => {
            const { videos } = this.state;
            const idList = videos.map(item => item._id);
            const removeIdList = idList.filter((id, index) => rowsDeleted.lookup[index]);
            this.openCloseConfirmDialogHandler(true, null, removeIdList);
        },
        customToolbar: () => {
            return (
                <PlusAddButton onClick={this.openAddDialogHandler} />
            );
        }
    };

    openAddDialogHandler = () => {
        this.openCloseAddEditDialogHandler(true);
    };

    openEditDialogHandler = videoId => {
        const { videos } = this.state;
        const dialogData = videos.find(video => video._id === videoId);
        this.openCloseAddEditDialogHandler(true, dialogData);
    };
    
    closeAddEditDialogHandler = () => {
        this.openCloseAddEditDialogHandler(false);
    };

    openCloseAddEditDialogHandler = (opened, dialogData=null) => {
        this.setState({addEditDialogOpened: opened, dialogData});
    };

    removeVideoHandler = async videoId => {
        try {
            const {data: videoFromBackend} = await VIDEO_SERVICE.removeVideo(videoId);
            showInfoToast(constants.notifications.SUCCESS_REMOVE_VIDEO);
            this.removeVideoData(videoFromBackend);
        } catch (error) {
            if (error.response) {
                const { message } = error.response.data;
                showErrorToast(message);
            }
        }
    };

    removeMultiVideosHandler = async videoIds => {
        try {
            await VIDEO_SERVICE.removeVideos(videoIds);
            showInfoToast(constants.notifications.SUCCESS_REMOVE_VIDEO);
            this.removeMultiVideosData(videoIds);
        } catch (error) {
            if (error.response) {
                const { message } = error.response.data;
                showErrorToast(message);
            }
        }
    };

    addEditVideoHandler = videoFromBackend => {
        let { videos } = this.state;
		videos = [...videos];
		const targetIndex = videos.findIndex(item => (
			item._id === videoFromBackend._id
		));

		if (targetIndex >= 0) {
            videos[targetIndex] = {...videos[targetIndex], ...videoFromBackend};
            showInfoToast(constants.notifications.SUCCESS_EDIT_VIDEO);
		} else {
            videos = [
				...videos,
                {...videoFromBackend}
            ];
            showInfoToast(constants.notifications.SUCCESS_ADD_VIDEO);
		}
        this.setState({videos});
        
    };

    removeVideoData = videoFromBackend => {
        let { videos } = this.state;
		
		const targetIndex = videos.findIndex(item => (
			item._id === videoFromBackend._id
		));
		videos = removeItemWithSlice(videos, targetIndex);
		this.setState({videos});
    };

    removeMultiVideosData = videoIds => {
        let { videos } = this.state;
		
		videos = videos.filter(item => !videoIds.includes(item._id));
		this.setState({videos});
    };

    openCloseConfirmDialogHandler = (opened, removeId, removeIdList) => {
        this.setState({confirmDialogOpened: opened, removeId, removeIdList});
    };

    confirmRemoveDialogHandler = () => {
        const { removeId, removeIdList } = this.state;
        if (removeId) {
            this.removeVideoHandler(removeId)
        } else if (removeIdList && removeIdList.length > 0) {
            this.removeMultiVideosHandler(removeIdList);
        }
    }

    render() {
        const { addEditDialogOpened, dialogData, confirmDialogOpened, videos, removeIdList } = this.state;
        const tableData = this.createTableData(videos);
        return (
            <Fragment>
                { addEditDialogOpened && (
                    <AddEditDialog
                        manageTitle='Video'
                        opened={addEditDialogOpened}
                        isAddDialog={dialogData ? false : true}
                        dialogData={dialogData}
                        addEditVideo={this.addEditVideoHandler}
                        closed={this.closeAddEditDialogHandler} />
                ) }
                <CustomMUIDataTable
                    title='Videos'
                    data={tableData}
                    columns={this.columns()}
                    options={this.options} />
                <ConfirmDialog
                    isMultiple={!!removeIdList}
                    opened={confirmDialogOpened}
                    closed={() => this.openCloseConfirmDialogHandler(false)}
                    confirmed={this.confirmRemoveDialogHandler} />
            </Fragment>
        );
    }
}

const mapActionToProps = {
    setLoadingStatus,
};

export default connect(
    null, //mapStateToProps,
    mapActionToProps
)(VideoManage);
