import { SET_LOADING_STATUS } from "../actions/types";

const initialState = {
  loadingStatus: {
    loading: false,
    text: "Please wait...",
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_STATUS:
      return {
        ...state,
        loadingStatus: action.payload,
      };
    default:
      return state;
  }
}
