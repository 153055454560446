
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';

const Transition = (props) => {
    return <Slide direction='up' {...props} />;
};

const styles = theme => {
    return {
        dialogContent: {
            padding: 0,
			'&:first-child': {
                padding: 0
			}
        }
    };
};

const TransitionDialogWrapper = ({ classes, children, ...rest }) => {
    return (
        <Dialog
            keepMounted
            maxWidth={false}
            TransitionComponent={Transition}
            { ...rest }
            aria-labelledby='alert-dialog-slide-title'
            aria-describedby='alert-dialog-slide-description'>
            <DialogContent className={classes.dialogContent}>
                {children}
            </DialogContent>
        </Dialog>
    );
};

export default withStyles(styles, {withTheme: true})(TransitionDialogWrapper);
