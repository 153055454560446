import axios from "axios";

import { getStrategiesUrl } from "./endpoints";

const addStrategy = async (data) => {
  const url = getStrategiesUrl();
  const result = await axios.post(url, data);
  return result;
};

const editStrategy = async (data) => {
  const url = getStrategiesUrl();
  const result = await axios.put(url, data);
  return result;
};

const removeStrategy = async (_id) => {
  const url = getStrategiesUrl();
  const result = await axios.delete(url, {
    params: { _id },
  });
  return result;
};

const removeStrategies = async (ids) => {
  const url = getStrategiesUrl();
  const result = await axios.delete(url, { data: { ids } });
  return result;
};

const getStrategies = async () => {
  const url = getStrategiesUrl();
  const result = await axios.get(url);
  return result;
};

export {
  addStrategy,
  editStrategy,
  removeStrategy,
  removeStrategies,
  getStrategies,
};
