
import React from 'react';
import { connect } from 'react-redux';

import SubscriptionPlan from '../../components/SubscriptionPlan';
import { validateRoleSkuAccess } from '../../utils/utility';

const SkuidWrapper = ({ children, roles, skuIds, loggedInUser, addonName }) => {
    return (
        validateRoleSkuAccess(roles, skuIds, loggedInUser) ? children : <SubscriptionPlan addonName={addonName} />
    )
}

const mapStateToProps = state => ({
    loggedInUser: state.auth.user
});

export default connect(mapStateToProps)(SkuidWrapper);
