import axios from "axios";

import { getSymbolDataUrl, geLastTickUrl } from "./endpoints";

const getSymbols = async () => {
  const url = getSymbolDataUrl("");
  const Symbols = await axios.get(url);
  return Symbols;
};

const addSymbol = async (data) => {
  const url = getSymbolDataUrl();
  const result = await axios.post(url, data);
  return result;
};

const editSymbol = async (data) => {
  const url = getSymbolDataUrl();
  const result = await axios.put(url, data);
  return result;
};

const removeSymbol = async (_id) => {
  const url = getSymbolDataUrl();
  const result = await axios.delete(url, {
    params: { _id },
  });
  return result;
};

const removeSymbols = async (ids) => {
  const url = getSymbolDataUrl();
  const result = await axios.delete(url, { data: { ids } });
  return result;
};

const getLastTick = async symbolId => {
  const url = geLastTickUrl(symbolId);
  return await axios.get(url);
}

export { getSymbols, addSymbol, editSymbol, removeSymbol, removeSymbols, getLastTick };
