
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import { robotoFont } from '../../utils/styles';

const styles = theme => {
	return {
		editorPreview: {
			fontFamily: robotoFont,
			color: theme.palette.highlightColor
		}
	}
}

const EditorPreview = ({ classes, editorData }) => {
	return (
		<div className={classes.editorPreview}>
			<div className={classes.editorPreview} dangerouslySetInnerHTML={{ __html: editorData }}></div>
		</div>
	);
}

export default withStyles(styles, {withTheme: true})(EditorPreview);