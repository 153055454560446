import axios from "axios";

import { getBrokerDataUrl } from "./endpoints";

const getBrokers = async () => {
  const url = getBrokerDataUrl();
  const brokers = await axios.get(url);
  return brokers;
};

const addBroker = async (data) => {
  const url = getBrokerDataUrl();
  const result = await axios.post(url, data);
  return result;
};

const editBroker = async (data) => {
  const url = getBrokerDataUrl();
  const result = await axios.put(url, data);
  return result;
};

const removeBroker = async (_id) => {
  const url = getBrokerDataUrl();
  const result = await axios.delete(url, {
    params: { _id },
  });
  return result;
};

const removeBrokers = async (ids) => {
  const url = getBrokerDataUrl();
  const result = await axios.delete(url, { data: { ids } });
  return result;
};

export { getBrokers, addBroker, editBroker, removeBroker, removeBrokers };
