import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { SelectValidator } from "react-material-ui-form-validator";

const styles = (theme) => {
  return {
    selectMenu: {
      backgroundColor: theme.palette.mainBackColor,
    },
  };
};

const CustomSelectValidator = ({
  classes,
  label,
  value,
  changed,
  items,
  variant,
  validators = ["required"]
}) => {
  const onChangeHandler = (event) => {
    changed(event.target.value);
  };
  return (

    <SelectValidator
      fullWidth
      select
      multiple
      SelectProps={{
        MenuProps: {
          classes: { paper: classes.selectMenu },
        },
      }}
      label={label}
      value={value}
      margin="normal"
      onChange={onChangeHandler}
      validators={validators}
      errorMessages={[`Please select ${label}`]}
      variant={variant}
    >
      {items.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </SelectValidator>
  );
};

export default withStyles(styles, { withTheme: true })(CustomSelectValidator);
