
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';

import { getSpacingUnit, getFontUnit } from '../../utils/utility';
import { boxShadow, colors, isValidDarkTheme } from '../../utils/styles';

const styles = theme => {
	const fontSizeUnit = getFontUnit(theme);
	const spacingUnit = getSpacingUnit(theme);

    return {
        radiusButton: {
			color: colors.white,
			fontSize: fontSizeUnit * 1.2,
			borderRadius: 24,
			background: isValidDarkTheme(theme.palette.type) ? colors.dark : theme.palette.primaryColor[1],
			paddingTop: spacingUnit / 4,
			paddingBottom: spacingUnit / 2,
			paddingLeft: spacingUnit * 2,
			paddingRight: spacingUnit * 2,
			'&:hover': {
				backgroundColor: theme.palette.primaryColor[0],
				boxShadow: boxShadow
			}
		}
    };
};

const RadiusButton = ({ classes, children, passedClasses, customClasses = {}, ...rest }) => {
	 return (
        <Button
			{...rest}
            className={classNames(classes.radiusButton, passedClasses, customClasses)}>
		    {children}
        </Button>
    );
};

export default withStyles(styles, {withTheme: true})(RadiusButton);
