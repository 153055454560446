import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import OutlinedButton from "../../../../components/OutlinedButton";
import LanguageSelector from "../../../../components/LanguageSelector";
import constants from "../../../../constants";
import {
  getRoleLabels,
  showErrorToast,
  showInfoToast,
} from "../../../../utils/utility";
import { updateMyInfo } from "../../../../services/user";
import { editCurrentUserInfo } from "../../../../actions/auth";
import { setLoadingStatus } from "../../../../actions/loading";

const styles = (theme) => {
  return {
    root: {
      marginTop: 32,
      backgroundColor: theme.palette.subBackColor3,
    },
    paper: {
      backgroundColor: theme.palette.subBackColor3,
    },
    updateButton: {
      marginTop: 20,
      marginBottom: 20,
    },
    language: {
      marginTop: 20,
      marginLeft: -10,
    },
  };
};

class ProfilePanel extends Component {
  state = {
    name: "",
    language: "",
  };

  inputChangeHandler = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  updateUserInfo = async () => {
    const { loggedInUser, editCurrentUserInfo, setLoadingStatus } = this.props;
    const { name, language } = this.state;

    if (
      (name === loggedInUser.name || name === "") &&
      (language === loggedInUser.defaultLanguage || language === "")
    ) {
      return;
    }
    const data = {
      user: { name, _id: loggedInUser.id, defaultLanguage: language },
    };

    setLoadingStatus({ loading: true, text: "Updating ..." });
    try {
      const result = await updateMyInfo(loggedInUser.id, data);
      if (result.status === constants.response.SUCCESS) {
        editCurrentUserInfo(result.data);
        showInfoToast(constants.notifications.SUCCESS_UPDATE_PROFILE);
      }
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        showErrorToast(message);
      } else {
        showErrorToast(constants.notifications.FAIL_UPDATE_PASSWORD);
      }
    }
    setLoadingStatus({ loading: false });
  };

  componentDidMount() {
    const { loggedInUser } = this.props;
    this.setState({
      name: loggedInUser.name,
      language: loggedInUser.defaultLanguage,
    });
  }

  languageSelectHandler = (language) => {
    this.setState({ language });
  };

  render() {
    const { classes, loggedInUser } = this.props;
    const { name, language } = this.state;
    const userRoleName = getRoleLabels(loggedInUser.roles);

    return (
      <div className={classes.root}>
        <Grid container spacing={16} alignItems="center" justify="center">
          <Grid item xs={10} sm={8} md={6}>
            <Grid container alignItems="center" justify="center">
              <TextField
                label="User Name"
                value={name}
                onChange={this.inputChangeHandler("name")}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid container alignItems="center" justify="center">
              <TextField
                disabled
                label="Email"
                value={loggedInUser.email}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid container alignItems="center" justify="center">
              <TextField
                disabled
                label="User Type"
                value={userRoleName}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid container alignItems="center" justify="flex-start">
              <LanguageSelector
                passedClass={classes.language}
                selectedLanguage={language}
                languageSelected={this.languageSelectHandler}
              />
            </Grid>
            <Grid container alignItems="center" justify="flex-end">
              <div className={classes.updateButton}>
                <OutlinedButton fullWidth onClick={this.updateUserInfo}>
                  Update
                </OutlinedButton>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.auth.user,
});

const mapActionToProps = {
  editCurrentUserInfo,
  setLoadingStatus,
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(withStyles(styles, { withTheme: true })(ProfilePanel));
