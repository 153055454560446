import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { ToastContainer } from "react-toastify";
import { Helmet } from 'react-helmet';
import "react-toastify/dist/ReactToastify.css";
import './index.css';

import { lightTheme, darkTheme } from "./theme/muiTheme";
import Login from "./containers/Authentification/Login";
import ForgotPassword from "./containers/Authentification/ForgotPassword";
import ResetPassword from "./containers/Authentification/ResetPassword";
// TODO: remove register for now
// import Register from './containers/Authentification/Register';
import Verification from "./containers/Authentification/Verification";
import Logout from "./containers/Authentification/Logout";
import Layout from "./hoc/Layout";
import Spinner from "./components/Spinner";
import {
  pageLinks,
  sidebarItems,
  getDefaultRedirectedComponent,
} from "./utils/links";
import {
  checkAdminRole,
  hasValidToken,
  validatePageAccessByRole
} from "./utils/utility";
import constants from "./constants";
import {getAddons} from "./actions/addon";
import {setSymbols} from './actions/symbol';
import {getConfig} from './actions/config';
import Addon from "./containers/Tools/Addon";
import HarmonicPatterns from "./containers/HarmonicPatterns";
import MaintenancePage from "./containers/MaintenancePage";
import Pattern from './containers/Pattern';

class App extends Component {

  componentDidMount() {
    this.props.getConfig();
    if (this.props.loggedInUser) {
      this.props.getAddons();
      this.props.setSymbols();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.loggedInUser && this.props.loggedInUser) {
      this.props.getAddons();
      this.props.setSymbols();
    }
  }

  render() {
    const { theme, loggedInUser, loadingStatus, config } = this.props;
    // merge sidebar subitems to spread routes
    let sidebarSubItems = [];
    // TODO: hide tools / 2 subitems
    sidebarItems().forEach((sidebarItem, index) => {
      if (sidebarItem.subItems) {
        sidebarSubItems = [...sidebarSubItems, ...sidebarItem.subItems];
      } else {
        sidebarSubItems = [...sidebarSubItems, sidebarItem];
      }
    });

    if (config.maintenanceMode && loggedInUser && !checkAdminRole(loggedInUser.roles)) {
      return (
        <MuiThemeProvider
          theme={
            theme.themeType === constants.themeType.dark ? darkTheme : lightTheme
          }
        >
          <MaintenancePage />
        </MuiThemeProvider>
      );
    }

    return (
      // <I18nextProvider i18n={i18n}>
      <React.Fragment>
        <Helmet
          titleTemplate=' Orakle | %s'
          defaultTitle='Orakle'
          defer={false}
        />
        <MuiThemeProvider
          theme={
            theme.themeType === constants.themeType.dark ? darkTheme : lightTheme
          }
        >
          <ToastContainer />
          <Spinner loading={loadingStatus.loading} />
          <Switch>
            {/* TODO: disabled for now */}
            {/* <Route path={pageLinks.Register.url} component={Register} /> */}
            <Route path={pageLinks.Login.url} component={Login} />
            <Route
              path={pageLinks.ForgotPassword.url}
              component={ForgotPassword}
            />
            <Route path={pageLinks.ResetPassword.url} component={ResetPassword} />
            <Route path={pageLinks.Verification.url} component={Verification} />
            <Route path={pageLinks.Logout.url} component={Logout} />
            <Route
              render={() =>
                hasValidToken() ? (
                  <Layout>
                    <Switch>
                      <Route
                        path="/"
                        exact
                        component={getDefaultRedirectedComponent(loggedInUser)}
                      />
                      <Route
                        path='/addons/:addonId'
                        component={Addon}
                      />
                      <Route
                        path='/pattern/:patternId'
                        component={Pattern}
                      />
                      <Route
                        path='/hp'
                        component={HarmonicPatterns}
                      />
                      {sidebarSubItems
                        .filter((sidebarSubItem) =>
                          validatePageAccessByRole(
                            sidebarSubItem.roles,
                            loggedInUser
                          )
                        )
                        .map((sidebarSubItem) => (
                          <Route
                            key={
                              sidebarSubItem.subTitle
                                ? sidebarSubItem.subTitle
                                : sidebarSubItem.title
                            }
                            path={sidebarSubItem.link}
                            component={sidebarSubItem.component}
                          />
                        ))}
                    </Switch>
                  </Layout>
                ) : (
                  <Redirect
                    to={{
                      pathname: pageLinks.Login.url,
                      state: {
                        from: `${window.location.pathname}${window.location.search}`,
                      },
                    }}
                  />
                )
              }
            />
          </Switch>
        </MuiThemeProvider>
      </React.Fragment >
    );
  }
}

const mapStateToProps = (state) => ({
  theme: state.theme,
  loadingStatus: state.loading.loadingStatus,
  loggedInUser: state.auth.user,
  addons: state.addon.addons,
  config: state.config,
});

const mapDispatchToProps = {
  getAddons,
  setSymbols,
  getConfig,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
