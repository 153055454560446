import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// import { makeStyles, withStyles } from '@material-ui/core/styles';
import { hexToRgb } from '../../utils/utility';
import { getSpacingUnit } from '../../utils/utility';

// core components
const styles = theme => {

  return {
    card: {
      border: '0',
      marginBottom: getSpacingUnit(theme) * 2,
      marginTop: getSpacingUnit(theme) * 3,
      borderRadius: '6px',
      color: theme.palette.mainForeColor,
      background: theme.palette.mainBackColor,
      width: '100%',
      boxShadow: '0 1px 4px 0 rgba(' + hexToRgb(theme.palette.mainForeColor) + ', 0.14)',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      minWidth: '0',
      wordWrap: 'break-word',
      fontSize: '.875rem'
    },
    signalListCard: {
      border: '0',
      marginBottom: getSpacingUnit(theme) * 2,
      marginTop: getSpacingUnit(theme) * 3,
      borderRadius: '6px',
      color: theme.palette.mainForeColor,
      background: theme.palette.subBackColor2,
      width: '100%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      minWidth: '0',
      wordWrap: 'break-word',
      fontSize: '.875rem'
    },
    cardPlain: {
      background: 'transparent',
      boxShadow: 'none'
    },
    cardProfile: {
      marginTop: getSpacingUnit(theme) * 4,
      textAlign: 'center'
    },
    cardChart: {
      '& p': {
        marginTop: '0px',
        paddingTop: '0px'
      }
    },
    customMarginTop: {
      marginTop: 0
    }
  }
}

// const useStyles = makeStyles(styles);
const CardWithHeader = (props) => {
  const { classes, children, plain, profile, chart, displayCardHeader, cardWrapperType, ...rest } = props;
  const cardClasses = classNames(cardWrapperType === 'signalList' ? classes.signalListCard : classes.card, {
    [classes.cardPlain]: plain,
    [classes.cardProfile]: profile,
    [classes.cardChart]: chart,
    [classes.customMarginTop]: displayCardHeader === 'none'
  })
  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  );
}

CardWithHeader.propTypes = {
  // className: PropTypes.string,
  plain: PropTypes.bool,
  profile: PropTypes.bool,
  chart: PropTypes.bool,
  children: PropTypes.node
};


export default withStyles(styles, { withTheme: true })(CardWithHeader);