
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import AddEditDialog from './AddEditDialog';
import CustomMUIDataTable from '../../../components/CustomMUIDataTable';
import PlusAddButton from '../../../components/PlusAddButton';
import EditIconButton from '../../../components/EditIconButton';
import RemoveIconButton from '../../../components/RemoveIconButton';
import ConfirmDialog from '../../../components/ConfirmDialog';
import * as SYMBOL_SERVICE from '../../../services/symbol';
import { setSymbols, addEditSymbol, removeSymbol, removeSymbols } from '../../../actions/symbol';
import { setBrokers } from '../../../actions/broker';
import { setLoadingStatus } from '../../../actions/loading';
import { showErrorToast } from '../../../utils/utility';
import { commonMUITableOptions } from '../../../utils/styles';

class SymbolManage extends Component {
    state = {
        addEditDialogOpened: false,
        confirmDialogOpened: false,
        dialogData: null,
        removeId: null,
        removeIdList: null
    };

    async componentDidMount() {
        const { setLoadingStatus, setSymbols, setBrokers } = this.props;
        setLoadingStatus({loading: true, text: 'Loading symbols...'});
        await setBrokers();
        await setSymbols();
        setLoadingStatus({loading: false});
    }
    
    createTableData = (symbols) => {
        const { brokers } = this.props;
        const tableData = symbols.map(symbol => {
            const { brokerId, name, code, currency, digits, tick, _id } = symbol;
            const broker = brokers.find(broker => broker._id === brokerId);
            const row = [
                broker && broker.name,
                name,
                code,
                currency,
                digits,
                tick,
                _id
            ];

            return row;
        });
        return tableData;
    };

    columns = () => [
        {name: 'Broker'},
        {name: 'Name', options: {filter: false}},
        {name: 'Code', options: {filter: false}},
        {name: 'Currency'},
        {name: 'Digit'},
        {name: 'Tick', options: {filter: false}},
        {
            name: 'Action',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <div style={{display: 'flex'}}>
                            <EditIconButton color='primary' onClick={() => this.openEditDialogHandler(value)} />
                            <RemoveIconButton color='primary' onClick={() => this.openCloseConfirmDialogHandler(true, value)} />
                        </div>
                    );
                }
            }
        }
    ];

    options = {
        ...commonMUITableOptions,
        selectableRows: 'multiple',
        onRowsDelete: rowsDeleted => {
            const { symbols } = this.props;
            const idList = symbols.map(item => item._id);
            const removeIdList = idList.filter((id, index) => rowsDeleted.lookup[index]);
            this.openCloseConfirmDialogHandler(true, null, removeIdList);
        },
        customToolbar: () => {
            return (
                <PlusAddButton onClick={this.openAddDialogHandler} />
            );
        }
    };

    openAddDialogHandler = () => {
        this.openCloseAddEditDialogHandler(true);
    };
    openEditDialogHandler = symbolId => {
        const { symbols } = this.props;
        const dialogData = symbols.find(symbol => symbol._id === symbolId);
        this.openCloseAddEditDialogHandler(true, dialogData);
    };
    closeAddEditDialogHandler = () => {
        this.openCloseAddEditDialogHandler(false);
    };
    openCloseAddEditDialogHandler = (opened, dialogData=null) => {
        this.setState({addEditDialogOpened: opened, dialogData});
    };

    removeSymbolHandler = async symbolId => {
        try {
            const response = await SYMBOL_SERVICE.removeSymbol(symbolId);
            const { data: symbolFromBackend } = response;
            this.removeSymbolData(symbolFromBackend);
        } catch (error) {
            if (error.response) {
                const { message } = error.response.data;
                showErrorToast(message);
            }
        }
    };

    removeMultiSymbolsHandler = async symbolIds => {
        try {
            await SYMBOL_SERVICE.removeSymbols(symbolIds);
            this.removeMultiSymbolsData(symbolIds);
        } catch (error) {
            if (error.response) {
                const { message } = error.response.data;
                showErrorToast(message);
            }
        }
    };

    addEditSymbolHandler = symbolFromBackend => {
        const { addEditSymbol } = this.props;
        addEditSymbol(symbolFromBackend);
    };

    removeSymbolData = symbolFromBackend => {
        const { removeSymbol } = this.props;
        removeSymbol(symbolFromBackend);
    };

    removeMultiSymbolsData = symbolIds => {
        const { removeSymbols } = this.props;
        removeSymbols(symbolIds);
    };

    openCloseConfirmDialogHandler = (opened, removeId, removeIdList) => {
        this.setState({confirmDialogOpened: opened, removeId, removeIdList});
    };

    confirmRemoveDialogHandler = () => {
        const { removeId, removeIdList } = this.state;
        if (removeId) {
            this.removeSymbolHandler(removeId)
        } else if (removeIdList && removeIdList.length > 0) {
            this.removeMultiSymbolsHandler(removeIdList);
        }
    }

    render() {
        const { addEditDialogOpened, dialogData, confirmDialogOpened, removeIdList } = this.state;
        const { symbols, brokers } = this.props;

        if (symbols.length !== 0 && brokers.length === 0) {
            return null;
        }
        const tableData = this.createTableData(symbols);

        return (
            <Fragment>
                { addEditDialogOpened && (
                    <AddEditDialog
                        manageTitle='Symbol'
                        opened={addEditDialogOpened}
                        isAddDialog={dialogData ? false : true}
                        dialogData={dialogData}
                        brokerData={brokers}
                        addEditSymbol={this.addEditSymbolHandler}
                        closed={this.closeAddEditDialogHandler} />
                ) }
                <CustomMUIDataTable
                    title='Symbols'
                    data={tableData}
                    columns={this.columns()}
                    options={this.options} />
                <ConfirmDialog
                    isMultiple={!!removeIdList}
                    opened={confirmDialogOpened}
                    closed={() => this.openCloseConfirmDialogHandler(false)}
                    confirmed={this.confirmRemoveDialogHandler} />
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    symbols: state.symbol.data,
    brokers: state.broker.data
});

const mapActionToProps = {
    setLoadingStatus,
    setSymbols,
    setBrokers,
    addEditSymbol,
    removeSymbol,
    removeSymbols
};

export default connect(
    mapStateToProps,
    mapActionToProps
)(SymbolManage);
