
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import { getSpacingUnit } from '../../utils/utility';

const styles = theme => {
    const spacingUnit = getSpacingUnit(theme);
    return {
        actions: {
            paddingTop: spacingUnit * 2,
            paddingBottom: spacingUnit * 2,
            paddingRight: spacingUnit * 2,
            margin: 0
        }
    };
};

const DialogActionsWrapper = ({ classes, children }) => {
    return (
        <DialogActions className={classes.actions}>
            {children}
        </DialogActions>
    );
};

export default withStyles(styles, {withTheme: true})(DialogActionsWrapper);
