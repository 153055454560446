
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

// import OutlinedButton from '../OutlinedButton';
import { getSpacingUnit, getFontUnit } from '../../utils/utility';
import RadiusButton from '../RadiusButton';

const styles = theme => {
    const spacingUnit = getSpacingUnit(theme);;
    const fontSizeUnit = getFontUnit(theme);

    return {
        passedClasses: {
            fontSize: fontSizeUnit,
            marginRight: spacingUnit,
            marginBottom: spacingUnit,
            [theme.breakpoints.down('md')]: {
                paddingLeft: spacingUnit / 2,
                paddingRight: spacingUnit / 2,
                '& > span span': {
                    display: 'none'
                },
                '& > span svg': {
                    width: spacingUnit * 2,
                    // height: spacingUnit * 2,
                }
            },
			[theme.breakpoints.down('sm')]: {
                // fontSize: fontSizeUnit * 0.8,
                width: spacingUnit * 4,
                minWidth: spacingUnit * 4,
                '& > span span': {
                    display: 'none'
                }
			}
        }
    };
};

const ContainedButton = ({ classes, children, customClasses = {}, ...rest }) => {
    return (
        <RadiusButton
            passedClasses={classes.passedClasses}
            customClasses={customClasses}
            {...rest}>
            {children}
        </RadiusButton>
    );
};

export default withStyles(styles, {withTheme: true})(ContainedButton);
