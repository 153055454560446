
import React from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Logo from '../Logo';
import {boxShadow, colors, robotoFont} from "../../utils/styles";
import {getFontUnit, getSpacingUnit} from "../../utils/utility";
import Button from "@material-ui/core/Button";

const styles = theme => {
  const spacingUnit = getSpacingUnit(theme);
  const fontUnit = getFontUnit(theme);
  return {
    root: {
      color: theme.palette.mainForeColor,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    logo: {
      width: 200,
      height: 75,
      marginBottom: '30px',
    },
    subscribe: {
      fontFamily: robotoFont,
      fontSize: fontUnit * 3,
      background: colors.red500,
      color: 'white',
      padding: `${spacingUnit}px ${spacingUnit*5}px`,
      textDecoration: 'none',
      borderRadius: spacingUnit * 2.5,
      marginBottom: spacingUnit * 2,
      marginTop: spacingUnit * 2,
      [theme.breakpoints.down('sm')]: {
        padding: `${spacingUnit}px ${spacingUnit*2}px`,
      },
      '&:hover': {
        boxShadow: boxShadow,
        background: colors.red500
      }
    },
  };
};

const SubscriptionPlan = ({ classes, addonName = 'Addon' }) => {
  return (
    <div className={classes.root}>
      <Logo className={classes.logo} />
      <Typography variant='h5' align='center'>
        You do not have an ACTIVE <b>{addonName}</b> subscription, please purchase the addon from your shop
      </Typography>
      <Button className={classes.subscribe} onClick={() => {window.open('https://umustlook.com/', '_blank')}}>
        SUBSCRIBE NOW
      </Button>
    </div>
  );
}

export default withStyles(styles, {withTheme: true})(SubscriptionPlan);
