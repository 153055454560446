
import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

const styles = theme => {
  return {
    root: {
      width: 24,
      height: 24,
    }
  }
}


const LoadingSpinnerIcon = ({ className, classes, viewBox, color, ...rest }) => {

  return (
    <SvgIcon version="1.0" width="64px" height="64px" viewBox="0 0 128 128"  {...rest} className={clsx(classes.root, className)}>
      <g><path d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z" fill="#fff" fillOpacity="1" /><path d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z" fill="#c0c0c0" fillOpacity="0.25" transform="rotate(45 64 64)" /><path d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z" fill="#c0c0c0" fillOpacity="0.25" transform="rotate(90 64 64)" /><path d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z" fill="#c0c0c0" fillOpacity="0.25" transform="rotate(135 64 64)" /><path d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z" fill="#c0c0c0" fillOpacity="0.25" transform="rotate(180 64 64)" /><path d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z" fill="#c0c0c0" fillOpacity="0.25" transform="rotate(225 64 64)" /><path d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z" fill="#c0c0c0" fillOpacity="0.25" transform="rotate(270 64 64)" /><path d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z" fill="#c0c0c0" fillOpacity="0.25" transform="rotate(315 64 64)" /><animateTransform attributeName="transform" type="rotate" values="0 64 64;45 64 64;90 64 64;135 64 64;180 64 64;225 64 64;270 64 64;315 64 64" calcMode="discrete" dur="720ms" repeatCount="indefinite"></animateTransform></g></SvgIcon>
  )
};

export default withStyles(styles, { withTheme: true })(LoadingSpinnerIcon);
