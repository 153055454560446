import axios from "axios";
import urlJoin from "url-join";

import { getVideoUrl } from "./endpoints";

const addVideo = async (data) => {
  const url = getVideoUrl();
  const result = await axios.post(url, data);
  return result;
};

const editVideo = async (data) => {
  const url = getVideoUrl();
  const result = await axios.put(url, data);
  return result;
};

const removeVideo = async (_id) => {
  const url = getVideoUrl();
  const result = await axios.delete(url, {
    params: { _id },
  });
  return result;
};

const removeVideos = async (ids) => {
  const url = getVideoUrl();
  const result = await axios.delete(url, { data: { ids } });
  return result;
};

const getVideo = async (_id) => {
  const url = getVideoUrl();
  const result = await axios.get(url, {
    params: { _id },
  });
  return result;
};

const getVideos = async () => {
  const url = getVideoUrl();
  const result = await axios.get(url);
  return result;
};

const getPageVideos = async (pageNumber) => {
  const url = urlJoin(getVideoUrl(), pageNumber.toString());
  const result = await axios.get(url);
  return result;
};

const getVideosByTopicId = async (topicId) => {
  const url = getVideoUrl(topicId);
  const result = await axios.get(url, {
    params: { topicId },
  });
  return result;
};

export {
  addVideo,
  editVideo,
  removeVideo,
  removeVideos,
  getVideo,
  getVideos,
  getPageVideos,
  getVideosByTopicId,
};
