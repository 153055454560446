import React, {Fragment, useEffect, useState, useRef} from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import * as marketplaceItemsService from '../../../services/marketplace_items';
import {Popover, TextField, Typography} from "@material-ui/core";
import ContainedButton from "../../../components/ContainedButton";
import Icon from '@material-ui/icons/Backup'
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import constants from '../../../constants';

const styles = () => {
  return {
    container: {
      marginLeft: '10px',
    },
    selectedContainer: {
      marginLeft: '10px',
      backgroundColor: 'rgb(33, 150, 243)',
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#1f272e7d',
      padding: '10px',
      minWidth: '600px',
    },
    searchWrapper: {
      padding: '10px',
    },
    templatesList: {
      padding: '10px 0',
      maxHeight: '400px',
      overflow: 'auto',
    },
    templateWrapper: {
      display: 'flex',
      padding: '5px 0',
      flexDirection: 'row',
      alignItems: 'center',
    },
    templateInfoBlock: {
      display: 'flex',
      flex: 1,
    },
    templateActionsBlock: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      paddingRight: '10px',
    },
    buttonsBlock: {
      padding: '5px 0',
    },
    button: {
      border: '1px solid #ffffff',
      borderRadius: '5px',
      padding: '5px 10px',
      width: 'fit-content',
      cursor: 'pointer',
    },
    buttonActive: {
      background: 'rgb(33, 150, 243)',
      borderRadius: '5px',
      padding: '5px 10px',
      width: 'fit-content',
      cursor: 'pointer',
    },
    featuredIcon: {
      color: '#fff',
      marginRight: '5px',
    },
    horizontalDivider: {
      width: '10px',
    },
    itemAuthorWrapper: {
      fontSize: '12px',
    },
    itemAuthorName: {
      color: 'rgb(33, 150, 243)'
    },
    itemTypeBadge: {
      border: '1px solid rgb(33, 150, 243)',
      borderRadius: '5px',
      backgroundColor: 'transparent',
      color: 'rgb(33, 150, 243)',
      fontSize: '12px',
      padding: '5px 10px',
    },
  }
};

const PER_PAGE = 20;

const Marketplace = ({classes, addonId, handleSelectItem, loggedInUserId, setLoadingStatus}) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState({
    name: '',
    addonId: addonId,
    featured: false,
  });
  const [items, setItems] = useState([]);

  const itemsListContainer = useRef(null);
  const scrolledToBottom = useRef(false);
  const currentPage = useRef(1);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getItems = async options => {
    if (!options.featured) {
      delete options.featured;
    }
    return await marketplaceItemsService.getMarketplaceItems(options);
  };

  const handleLoadMore = async e => {
    const element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      if (!scrolledToBottom.current) {
        scrolledToBottom.current = true;
        currentPage.current += 1;
        const {data, total} = await getItems({
          ...filters,
          page: currentPage.current,
        });
        setItems(prev => prev.concat(data));
        scrolledToBottom.current = currentPage.current * PER_PAGE > total;
      }
    }
  };

  useEffect(() => {
    setFilters({
      name: '',
      addonId: addonId,
      featured: false,
    });
  }, [addonId]);

  useEffect(() => {
    if (itemsListContainer.current) {
      itemsListContainer.current.addEventListener('scroll', handleLoadMore);
      return () => itemsListContainer.current.removeEventListener('scroll', handleLoadMore);
    }
  }, [filters, itemsListContainer.current]);

  useEffect(() => {
    currentPage.current = 1;
    scrolledToBottom.current = false;
    getItems({...filters, page: currentPage.current}).then(res => setItems(res.data));
  }, [filters]);

  const handleNameFilterChange = name => {
    setFilters(prev => ({...prev, page: 1, name}));
  };

  const handleAddItem = async itemId => {
    setLoadingStatus({loading: true});
    try {
      await marketplaceItemsService.addMarketplaceItem(itemId);
      setItems(prev => prev.map(item => {
        if (item._id === itemId) {
          return {...item, usedBy: [...item.usedBy, loggedInUserId]}
        }
        return item;
      }))
    } catch (e) {
      alert('Error adding item.')
    }
    setLoadingStatus({loading: false});
  };

  return (
    <Fragment>
      <ContainedButton
        onClick={handleClick}
        customClasses={anchorEl !== null ? classes.selectedContainer : classes.container}
      >
        <Icon />
        <span>&nbsp;Marketplace</span>
      </ContainedButton>
      <Popover
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={20}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.root}>
          <TextField
            label='Search charts or indicator templates'
            onChange={e => handleNameFilterChange(e.target.value)}
            fullWidth
          />
          <div className={classes.templatesList} ref={itemsListContainer}>
            {
              items.map(item => (
                <div
                  key={item._id}
                  className={classes.templateWrapper}
                >
                  <div className={classes.templateInfoBlock}>
                    {
                      item.featured ? <TrendingUpIcon className={classes.featuredIcon}/> : null
                    }
                    <Typography>
                      {item.data.name}&nbsp;
                      {
                        !!item.authorName ? <div className={classes.itemAuthorWrapper}>by <span className={classes.itemAuthorName}>{item.authorName}</span></div>: null
                      }
                    </Typography>
                  </div>
                  <div className={classes.templateActionsBlock}>
                    <div
                      className={classes.itemTypeBadge}
                    >
                      <Typography style={{color: 'rgb(33, 150, 243)'}}>
                        {item.type === constants.MARKETPLACE_ITEM_CHART ? 'Chart' : 'Indicators'}
                      </Typography>
                    </div>
                    <div className={classes.horizontalDivider}/>
                    <div className={classes.buttonActive} onClick={() => handleSelectItem(item)}>
                      <Typography>Activate</Typography>
                    </div>
                    <div className={classes.horizontalDivider}/>
                    {
                      !item.usedBy.includes(loggedInUserId) ? (
                        <div className={classes.buttonActive} onClick={() => handleAddItem(item._id)}>
                          <Typography>Add</Typography>
                        </div>
                      ) : null
                    }
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </Popover>
    </Fragment>
  )
}

export default withStyles(styles, { withTheme: true })(Marketplace);
