
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/DeleteOutline';

const RemoveIconButton = (props) => {
    return (
        <IconButton {...props}>
            <RemoveIcon />
        </IconButton>
    );
};

export default RemoveIconButton;
