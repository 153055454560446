
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

import CloseIconButton from '../../CloseIconButton';
import { NotificationLabels } from '../../../utils/styles';
import { getSpacingUnit } from '../../../utils/utility';
import { closeNotification } from '../../../services/notification';

const styles = theme => {
    const spacingUnit = getSpacingUnit(theme);
    return {
        root: {
            display: 'flex',
            backgroundColor: theme.palette.subBackColor3,
            marginBottom: spacingUnit
        },
        label: {
            minWidth: spacingUnit / 2
        },
        messageBox: {
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            color: theme.palette.subForeColor3,
            padding: spacingUnit
        },
        closeButton: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: spacingUnit / 2 * 3
		}
    };
};

const NotificationPanel = ({ classes, _id, type, message, reflectClosedNotification }) => {
    const notificationLabel = NotificationLabels[type];
    const labelStyle = {
        backgroundColor: notificationLabel.backgroundColor
    };
    const closeHandler = async () => {
        const { data: closedOne } = await closeNotification(_id);
        reflectClosedNotification(closedOne);
    };
    return (
        <Paper square className={classes.root}>
            <div className={classNames(classes.label)} style={labelStyle} />
            <div className={classes.messageBox}>
                <Typography>{message}</Typography>
            </div>
            <div className={classes.closeButton}>
                <CloseIconButton onClick={closeHandler} />
            </div>
        </Paper>
    );
};

export default withStyles(styles, {withTheme: true})(NotificationPanel);
