
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import NotificationPanel from './NotificationPanel';
import { setNotifications } from '../../actions/notification';
import { removeItemWithSlice } from '../../utils/utility';

class NotificationPanelList extends Component {
    async componentDidMount() {
        const { setNotifications } = this.props;
        setNotifications();
    }

    reflectClosedNotification = closedOne => {
        if (!closedOne) {
            return;
        }
        let { notifications, setNotifications } = this.props;
    
        const targetIndex = notifications.findIndex(notification => (
            notification._id === closedOne._id
        ));
        const newNotifications = removeItemWithSlice(notifications, targetIndex);
        setNotifications(newNotifications);
    };

    render () {
        const { notifications } = this.props;
        if (notifications.length <= 0) {
            return null;
        }
        return (
            <Fragment>
                { notifications.map(notification => {
                    const { message, type } = notification;
                    return (
                        <NotificationPanel
                            key={notification._id}
                            _id={notification._id}
                            type={type}
                            message={message}
                            reflectClosedNotification={this.reflectClosedNotification} />
                    );
                }) }
            </Fragment>
        );
    };
};

const mapStateToProps = state => ({
    notifications: state.notification.data
});

const mapActionToProps = {
    setNotifications
};

export default connect(mapStateToProps, mapActionToProps)(NotificationPanelList);
