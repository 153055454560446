
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import DashboardIcon from '@material-ui/icons/Dashboard';

const styles = theme => {

    return {
        root: {
			cursor: 'pointer',
            color: theme.palette.subForeColor5,
            margin: 4
		}
    };
};

const DashboardIconButton = ({ classes, ...rest }) => {
    return (
        <DashboardIcon
            className={classes.root}
            {...rest} />
    );
};

export default withStyles(styles, {withTheme: true})(DashboardIconButton);
