import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import { PersistGate } from "redux-persist/integration/react";
import "typeface-roboto";

import store, { persistor } from "./store";
import CacheBuster from "./hoc/CacheBuster";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {NotConnectedSpinner} from "./components/Spinner";
import constants from "./constants";
import PremiumLockInfo from './hoc/PremiumLockInfo';

const loadingScreenStyle = {
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  position: 'fixed',
  background: '#000',
};

const LoadingScreen = () => (
  <div style={loadingScreenStyle}>
    <NotConnectedSpinner loading={true} themeType={constants.themeType.dark}/>;
  </div>
);

const app = (
  <CacheBuster>
    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
      if (loading) {
        return <LoadingScreen />
      }
      if (!loading && !isLatestVersion) {
        refreshCacheAndReload();
      }
      return (
        <Fragment>
          <CssBaseline />
          <BrowserRouter>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <PremiumLockInfo>
                  <App />
                </PremiumLockInfo>
              </PersistGate>
            </Provider>
          </BrowserRouter>
        </Fragment>
      );
    }}
  </CacheBuster>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
