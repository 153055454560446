
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import MenuItem from "@material-ui/core/MenuItem";
import { Typography } from '@material-ui/core';

import { getSpacingUnit, getRoleLabel, rolePairs } from '../../utils/utility';

const styles = theme => {
    const spacingUnit = getSpacingUnit(theme);
    return {
        chips: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        chip: {
            margin: spacingUnit / 4,
        },
        normalMessage: {
            display: 'none',
            color: ''
        },
        errorMessage: {
            display: 'inline',
            color: '#f44336'
        }
    };
};

// TODO: * validation required
const RoleMultiSelect = ({classes, theme, label, selectedRoleValues, validation, changed}) => {
    if( validation === undefined ) {
        validation = true;
    }
    const getStyles = roleValue => {
        return {
            fontWeight:
                selectedRoleValues.includes(roleValue) 
                    ? theme.typography.fontWeightMedium
                    : theme.typography.fontWeightRegular
        };
    };

    const onChangeHander = event => {
        changed(event.target.value);
    };

    return (
        <FormControl margin='normal' fullWidth>
            <InputLabel htmlFor='role-multi-select'>{label}</InputLabel>
            <Select
                autoWidth
                multiple
                name='role-multi-select'
                value={selectedRoleValues}
                onChange={onChangeHander}
                input={<Input id='role-multi-select' />}
                renderValue={ selected => (
                    <div className={classes.chips}>
                        { selected.map(value => (
                            <Chip key={value} label={getRoleLabel(value)} className={classes.chip} />
                        )) }
                    </div>
                ) }>
                    { rolePairs.map(rolePair => (
                        <MenuItem key={rolePair.label} value={rolePair.value} style={getStyles(rolePair.value)}>
                            {rolePair.label}
                        </MenuItem>
                    )) }
            </Select>
            <Typography className={validation ? classes.normalMessage : classes.errorMessage}>{'Must select at least one role.'}</Typography>
        </FormControl>
    );
};

export default withStyles(styles, {withTheme: true})(RoleMultiSelect);
