import { GET_ERRORS } from "./types";

export const setErrors = (error) => (dispatch) => {
  dispatch({
    type: GET_ERRORS,
    payload: error,
  });
};

export const clearErrors = () => (dispatch) => {
  dispatch(setErrors(null));
};
