import "date-fns";
/* eslint-disable react/no-unused-prop-types */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import DialogWrapper from "../../../hoc/DialogWrapper";
import DialogActionsWrapper from "../../../hoc/DialogActionsWrapper";
import CustomSelectValidator from "../../CustomSelectValidator";
import { addSignal, editSignal } from "../../../services/signal";
import RoundAddIconButton from '../../../components/RoundAddIconButton';
import {
  getSpacingUnit,
  generateRandomDigitString,
  getAddEditDialogMetadata,
  showErrorToast,
  isSignalClosed,
  isBuyByType,
  calcPips,
  getProfitUnitString, formatTimeFrame
} from "../../../utils/utility";
import OutlinedButton from "../../OutlinedButton";
import constants from "../../../constants";

const styles = (theme) => {
  const spacingUnit = getSpacingUnit(theme);
  return {
    openClose: {
      display: "flex",
      flexDirection: "row",
      marginBottom: spacingUnit * 2,
      marginLeft: spacingUnit * 2,
    },
    textarea: {
      resize: "both"
    },
    addeditButton: {
      borderRadius: '5px',
      backgroundColor: theme.palette.shortLabelBack,
      border: 'none',
      marginRight: spacingUnit,
      textTransform: 'none',
      fontSize: 18
    },
    textValidatorCustom: {
      marginRight: spacingUnit * 2
    },
    tpslContainer: {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      },
      display: 'flex',
      justifyContent: 'space-between'
    },
    tpGrid: {
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        marginRight: 0,
        alignItems: 'stretch',
      },
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginRight: 10
    },
    slGrid: {
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        marginLeft: 0,
        alignItems: 'stretch',
      },
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginLeft: 10
    }
  };
};

class AddEditSignalDialog extends PureComponent {
  state = {
    symbolId: "",
    signalKind: constants.signalKinds.ACTIVATED,
    type: 0,
    price: 0,
    lots: 0.01,
    pips: 0,
    tp: 0,
    tp1: 0,
    sl: 0,
    openClose: true,
    closedPrice: 0,
    time: this.props.serverTime,
    closedTime: null,
    comment: "",
    submitButtonDisabled: false,
    strategyId: "",
    isTPPips: true,
    isSLPips: true,
    inputTP: constants.defaultTP,
    inputSL: constants.defaultSL,
    tp1ErrorMessage: "",
    tp2ErrorMessage: "",
    slErrorMessage: "",
  };

  componentDidMount() {
    const {
      isAddDialog,
      signalDialogData,
      selectedSymbolId,
      lastBar,
    } = this.props;

    if (!isAddDialog) { // edit
      this.setState({
        symbolId: signalDialogData.symbolId,
        signalKind: signalDialogData.signalKind,
        type: signalDialogData.type,
        price: signalDialogData.price,
        lots: signalDialogData.lots,
        pips: signalDialogData.pips || 0,
        tp: signalDialogData.tp,
        tp1: signalDialogData.tp1,
        sl: signalDialogData.sl,
        openClose: signalDialogData.openClose,
        closedPrice: signalDialogData.closedPrice,
        time: signalDialogData.time,
        closedTime: signalDialogData.closedTime,
        comment: signalDialogData.comment,
        strategyId: signalDialogData.strategyId,
        symbol: signalDialogData.symbol,
        timeframe: signalDialogData.timeframe,
      });
      this.updateDefaultTPSL(signalDialogData.price, signalDialogData.type, signalDialogData.symbolId, this.state.isTPPips, this.state.isSLPips);
    } else { // add
      const lastPrice = parseFloat((lastBar && lastBar.close) || 0);
      this.setState({
        symbol: this.props.chartParams.symbol,
        timeframe: this.props.chartParams.timeframe,
        price: lastPrice,
      });
      this.updateDefaultTPSL(lastPrice, this.state.type, selectedSymbolId, this.state.isTPPips, this.state.isSLPips);
    }


    ValidatorForm.addValidationRule("positiveNumber", (value) => {
      return value >= 0;
    });
  }

  componentDidUpdate(prevProps) {
  }

  signalKindChangeHandler = signalKind => {
    this.setState({
      signalKind,
      openClose: !isSignalClosed(signalKind)
     });
  };

  typeChangeHandler = (type) => {
    this.setState({ type });
    this.updateDefaultTPSL(this.state.price, type, this.state.symbolId, this.state.isTPPips, this.state.isSLPips);
  };

  inputChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  checkTPPipsHandler = event => {
    this.setState({ [event.target.name]: event.target.checked });
    this.updateDefaultTPSL(this.state.price, this.state.type, this.state.symbolId, event.target.checked, this.state.isSLPips);
  };

  checkSLPipsHandler = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
    this.updateDefaultTPSL(this.state.price, this.state.type, this.state.symbolId, this.state.isTPPips, event.target.checked);
  };

  removeTP1Handler = () => {
    if (this.state.tp1 === 0) { // if have only tp1
      this.setState({ tp: 0 });
    } else { // if have tp1 and tp2
      this.setState({ tp1: 0 });
    }

    this.setState({ tp1ErrorMessage: '' });
  }

  removeTP2Handler = () => {
    this.setState({ tp: this.state.tp1, tp1: 0 });
    this.setState({ tp2ErrorMessage: '' });
  }

  removeSLHandler = () => {
    this.setState({ sl: 0 });
    this.setState({ slErrorMessage: '' });
  }

  getSymbol = () => {
    console.log({
      all: this.props.allSymbols,
      curr: this.state.symbol,
    })
    return this.props.allSymbols.find(
      symbol => symbol.code === this.state.symbol
    );
  };

  addTPHandler = () => {
    const symbol = this.getSymbol();

    if (this.state.tp === 0) { // is adding first TP
      const tp = this.state.isTPPips
        ? this.getTPPriceByPips(this.state.price, this.state.inputTP, this.state.type, symbol)
        : this.state.inputTP;
      this.setState({ tp });
    } else if (this.state.tp1 === 0) { // is adding second TP
      const tp = this.state.isTPPips
        ? this.getTPPriceByPips(this.state.price, this.state.inputTP, this.state.type, symbol)
        : this.state.inputTP;
      this.setState({ tp1: this.state.tp, tp });
    }

    this.setState({ tp1ErrorMessage: '' });
    this.setState({ tp2ErrorMessage: '' });
  }

  addSLHandler = () => {
    const symbol = this.getSymbol();

    if (this.state.sl === 0) { // is adding first SL
      const sl = this.state.isSLPips
        ? this.getSLPriceByPips(this.state.price, this.state.inputSL, this.state.type, symbol)
        : this.state.inputSL;
      this.setState({ sl });
    }

    this.setState({ slErrorMessage: '' });
  }

  timeframeChangeHandler = timeframe => {
    this.setState({timeframe});
  };

  symbolChangeHandler = symbol => {
    this.setState({
      price: 0,
      symbol,
    })
  };

  submitHandler = async () => {
    const symbol = this.getSymbol();
    const { isAddDialog, signalDialogData } = this.props;
    const signal = {
      _id: !isAddDialog ? signalDialogData._id : null,
      symbol: this.state.symbol,
      timeframe: this.state.timeframe,
      signalKind: this.state.signalKind,
      broker: this.props.chartParams.broker,
      type: this.state.type,
      price: this.state.price,
      closedPrice: this.state.closedPrice,
      lots: this.state.lots,
      tp: this.state.tp,
      tp1: this.state.tp1,
      sl: this.state.sl,
      openClose: this.state.openClose,
      time: this.state.openClose ? this.props.serverTime : this.state.time,
      closedTime: !this.state.openClose ? this.props.serverTime : null,
      comment: this.state.comment,
      ticket: generateRandomDigitString(),
      strategyId: this.state.strategyId,
      addonId: this.props.addon._id,
    };

    signal.pips = calcPips(signal, symbol.tick, symbol.digits);

    const data = { signal };

    try {
      this.setState({ submitButtonDisabled: true });
      if (isAddDialog) {
        await addSignal(data);
      } else {
        await editSignal(data);
      }
      this.props.onSignalAddedUpdated();
      //onUpdateSignal(signalFromBackend);
    } catch (error) {
      console.log(
        "ray : [components Tools AddEditSignalDialog] error => ",
        error
      );
      if (error.response) {
        const { message: errorMessage } = error.response.data;
        showErrorToast(errorMessage);
      }
    }
    this.onCloseHandler();
  };

  onCloseHandler = () => {
    const { closed } = this.props;
    closed && closed();
  };

  dateChangeHandler = (date, name) => {
    this.setState({
      [name]: date,
    });
  };

  triggerSubmitHandler = () => {
    this.form.isFormValid(false).then((isValid) => {
      if (isValid && this.checkCustomValidate()) {
        this.submitHandler();
      }
    });
  };

  checkCustomValidate = () => {
    const { tp, tp1, sl, price, type } = this.state;
    const isBuy = isBuyByType(type);
    let result = true;
    // eslint-disable-next-line
    if (tp === 0) { // if does not have any tp
      this.setState({ tp1ErrorMessage: 'Please input at least one TP.' });
      result = false;
    } else if (tp1 === 0) { // if have only tp1
      // eslint-disable-next-line
      if (isBuy && tp <= price || !isBuy && tp >= price) {
        this.setState({ tp1ErrorMessage: `TP1 must be ${isBuy ? 'greater' : 'lesser'} than price.` });
        result = false;
      }
    } else { // if have tp1 and tp2
      // eslint-disable-next-line
      if (isBuy && tp1 <= price || !isBuy && tp1 >= price) {
        this.setState({ tp1ErrorMessage: `TP1 must be ${isBuy ? 'greater' : 'lesser'} than price.` });
        result = false;
      }
      // eslint-disable-next-line
      if (isBuy && tp <= price || !isBuy && tp >= price) {
        this.setState({ tp2ErrorMessage: `TP2 must be ${isBuy ? 'greater' : 'lesser'} than price.` });
        result = false;
      }
      // eslint-disable-next-line
      if (isBuy && tp < tp1 || !isBuy && tp > tp1) {
        this.setState({ tp2ErrorMessage: 'TP2 must be greater than TP1.' });
        result = false;
      }
    }
      // eslint-disable-next-line
    if (sl === 0) { // if does not have sl
      this.setState({ slErrorMessage: 'Please input SL.' });
      result = false;
    } else {
      // eslint-disable-next-line
      if (isBuy && sl >= price || !isBuy && sl <= price) {
        this.setState({ slErrorMessage: `SL must be ${!isBuy ? 'greater' : 'lesser'} than price.` });
        result = false;
      }
    }
    return result;
  }

  getTPPriceByPips = (price, pips, type, symbol) => {
    const multiplier = (symbol.digits === 1 || symbol.digits === 3 || symbol.digits === 5) ? 10 : 1;

    return isBuyByType(type)
      ? parseFloat(price + pips * symbol.tick * multiplier).toFixed(symbol.digits)
      : parseFloat(price - pips * symbol.tick * multiplier).toFixed(symbol.digits);
  };

  getSLPriceByPips = (price, pips, type, symbol) => {
    const multiplier = (symbol.digits === 1 || symbol.digits === 3 || symbol.digits === 5) ? 10 : 1;

    return isBuyByType(type)
      ? parseFloat(price - pips * symbol.tick * multiplier).toFixed(symbol.digits)
      : parseFloat(price + pips * symbol.tick * multiplier).toFixed(symbol.digits);
  };

  updateDefaultTPSL = (price, type, symbolId, isTPPips, isSLPips) => {
    const symbol = this.getSymbol();
    if (!symbol) {
      return;
    }

    const inputTP = isTPPips ? constants.defaultTP : this.getTPPriceByPips(price, constants.defaultTP, type, symbol);
    const inputSL = isSLPips ? constants.defaultSL : this.getSLPriceByPips(price, constants.defaultSL, type, symbol);

    this.setState({ inputTP, inputSL });
  }

  render() {
    const {
      classes,
      opened,
      isAddDialog,
      manageTitle,
    } = this.props;
    const {
      signalKind,
      type,
      price,
      tp,
      sl,
      comment,
      closedPrice,
      submitButtonDisabled,
    } = this.state;
    const {
      dialogTitle,
      submitButtonName
    } = getAddEditDialogMetadata(isAddDialog, manageTitle);
    const signalKindList = constants.signalKindList.filter(
      (item) => item.value >= constants.signalKinds.TP2_HIT && item.value <= constants.signalKinds.ACTIVATED
    );
    const signalTypeList = constants.signalTypeList;
    const currentSymbol = this.getSymbol();
    const symbolName = (currentSymbol && currentSymbol.name) || '';
    const incrementStep = currentSymbol
      ? Math.pow(0.1, currentSymbol.digits)
      : 0.00001; // TODO: * hardcoded
    const isClosed = isSignalClosed(signalKind);

    return (
      <DialogWrapper
        opened={opened}
        dialogTitle={dialogTitle}
        closeIcon
        onClose={this.onCloseHandler}
        dialogActions={
          <DialogActionsWrapper>
            <OutlinedButton
              type="button"
              classes={{ root: classes.addeditButton }}
              disabled={submitButtonDisabled}
              onClick={this.triggerSubmitHandler}>
              {submitButtonName}
            </OutlinedButton>
          </DialogActionsWrapper>
        }
      >
        <ValidatorForm
          ref={(ref) => {
            this.form = ref;
          }}
          onSubmit={this.submitHandler}
          onError={(errors) => console.log(errors)}
        >
          <Grid container spacing={16} alignItems="flex-end">
            <Grid item xs={12} sm={6}>
              <CustomSelectValidator
                label="Currency pair: "
                variant='outlined'
                value={this.state.symbol}
                changed={this.symbolChangeHandler}
                items={
                  this.props.addon.symbols.map(symbol => ({
                    label: symbol,
                    value: symbol,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomSelectValidator
                label="Order type:"
                variant='outlined'
                value={type}
                changed={this.typeChangeHandler}
                items={signalTypeList}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator
                variant='outlined'
                fullWidth
                name="price"
                label={isBuyByType(type) ? "Buy value" : "Sell value"}
                value={price}
                margin="normal"
                type="number"
                InputProps={{ inputProps: { min: 0, step: incrementStep } }}
                onChange={this.inputChangeHandler}
                validators={["required", "positiveNumber"]}
                errorMessages={[
                  "Open price cannot be empty",
                  "Open price must be greater than 0",
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomSelectValidator
                label="Signal Status"
                variant='outlined'
                value={signalKind}
                changed={this.signalKindChangeHandler}
                items={signalKindList}
              />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.tpslContainer}>
              <Grid item xs={12} sm={6} className={classes.tpGrid} >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextValidator
                    className={classes.textValidatorCustom}
                    fullWidth
                    variant='outlined'
                    name="inputTP"
                    label="Take profit:"
                    value={this.state.inputTP}
                    margin="normal"
                    type="number"
                    InputProps={{ inputProps: { min: 0, step: this.state.isTPPips ? 1 : incrementStep } }}
                    onChange={this.inputChangeHandler}
                    errorMessages={[
                      "TP must be greater than price",
                      "TP must be lesser than price",
                    ]}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.isTPPips}
                        name='isTPPips'
                        color='primary'
                        onChange={this.checkTPPipsHandler} />
                    }
                    label= {getProfitUnitString(symbolName)} />
                  <RoundAddIconButton style={{ color: this.state.tp && this.state.tp1 ? '#3F434E' : null }}
                    onClick={() => this.state.tp && this.state.tp1 ? null : this.addTPHandler()} />
                </div>
                {this.state.tp !== 0 ?
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Typography>TP1 : {this.state.tp1 === 0 ? this.state.tp : this.state.tp1}</Typography>
                    <Typography
                      onClick={this.removeTP1Handler}
                      variant='body1' color='secondary'
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                      Remove
                </Typography>
                  </div>
                  : null}
                <Typography variant='subtitle2' style={{ width: '100%', color: 'red' }}>
                  {this.state.tp1ErrorMessage}
                </Typography>
                {this.state.tp1 !== 0 ?
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Typography>TP2 : {this.state.tp}</Typography>
                    <Typography
                      onClick={this.removeTP2Handler}
                      variant='body1' color='secondary'
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                      Remove
                </Typography>
                  </div>
                  : null}
                <Typography variant='subtitle2' style={{ width: '100%', color: 'red' }}>
                  {this.state.tp2ErrorMessage}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.slGrid}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextValidator
                    variant='outlined'
                    className={classes.textValidatorCustom}
                    fullWidth
                    name="inputSL"
                    label="Stop tip:"
                    value={this.state.inputSL}
                    margin="normal"
                    type="number"
                    InputProps={{ inputProps: { min: 0, step: this.state.isSLPips ? 1 : incrementStep } }}
                    onChange={this.inputChangeHandler}
                    errorMessages={[
                      "SL must be lesser than price",
                      "SL must be greater than price",
                    ]}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.isSLPips}
                        name='isSLPips'
                        color='primary'
                        onChange={this.checkSLPipsHandler} />
                    }
                    label={getProfitUnitString(symbolName)} />
                  <RoundAddIconButton style={{ color: this.state.sl ? '#3F434E' : null }} onClick={() => this.state.sl ? null : this.addSLHandler()} />
                </div>
                {this.state.sl !== 0 ?
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Typography variant='body1'> SL : {this.state.sl} </Typography>
                    <Typography
                      onClick={this.removeSLHandler}
                      variant='body1' color='secondary'
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                      Remove
                </Typography>
                  </div>
                  : null
                }
                <Typography variant='subtitle2' style={{ width: '100%', color: 'red' }}>
                  {this.state.slErrorMessage}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomSelectValidator
                variant='outlined'
                label="Timeframe:"
                value={this.state.timeframe}
                changed={this.timeframeChangeHandler}
                items={
                  this.props.addon.timeframes.map(timeframe => ({
                    value: timeframe,
                    label: formatTimeFrame(timeframe),
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {!isAddDialog && <TextValidator
                fullWidth
                variant='outlined'
                disabled={!isClosed}
                name="closedPrice"
                label="Close Price"
                value={signalKind === 13 ? tp : signalKind === 14 ? sl : closedPrice}
                margin="normal"
                type="number"
                InputProps={{ inputProps: { step: 0.01 } }} // TODO: * 0.01 hardcoded
                onChange={this.inputChangeHandler}
                validators={["positiveNumber"]}
                errorMessages={["Close Price must be greater than 0"]}
              />}
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextValidator
                fullWidth
                name="comment"
                inputProps={{ className: classes.textarea }}
                multiline
                rows={3}
                variant='outlined'
                label="Comment:"
                placeholder='comment'
                value={comment || ''}
                margin="normal"
                onChange={this.inputChangeHandler}
              />
            </Grid>
          </Grid>
          <DialogActionsWrapper />
        </ValidatorForm>
      </DialogWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  serverTime: state.tools.serverTime
});

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(AddEditSignalDialog)
);
