import React from 'react';
import AutoCompleteInput from "../../../../components/AutocompleteInput";
import CustomMultiSelect from "../../../../components/CustomMultiSelect";
import CustomSwitch from "../../../../components/CustomSwitch";
import Button from "@material-ui/core/Button";

const toMultiSelectItem = item => ({
  value: item,
  label: item,
});


const UpdateTgBotForm = ({addon, bot, handleFieldChange, handleConfigureFromAddon}) => {

  if (!bot) {
    return null;
  }

  return (
    <div>
      <CustomSwitch
        label='Bot active'
        checked={bot.active}
        switchHandler={e => handleFieldChange('isActive', e.target.checked)}
      />
      <div>
        <Button onClick={handleConfigureFromAddon}>Configure from addon</Button>
      </div>
      <div>
        <AutoCompleteInput
          multi
          options={addon.symbols.map(toMultiSelectItem)}
          onChange={selected => handleFieldChange('symbols', selected.map(e => e.value))}
          value={bot.symbols.map(toMultiSelectItem)}
          inputLabel='Symbols'
          placeholder='Select symbols'
        />
      </div>
      <CustomMultiSelect
        label='Timeframes'
        selected={bot.timeframes}
        changed={selected => handleFieldChange('timeframes', selected)}
        items={
          addon.timeframes.map(toMultiSelectItem)
        }
      />
      <CustomMultiSelect
        label='Patterns'
        selected={bot.patterns}
        changed={selected => handleFieldChange('patterns', selected)}
        items={
          addon.harmonicConfig.available.names.map(toMultiSelectItem)
        }
      />
    </div>
  )
};


export default UpdateTgBotForm;
