
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Link from '@material-ui/core/Link';

import { sendResetLink } from '../../../actions/auth';
import { clearErrors } from '../../../actions/error';
import { setLoadingStatus } from '../../../actions/loading';
import { getSpacingUnit, showErrorToast, showInfoToast } from '../../../utils/utility';
import { pageLinks } from '../../../utils/links';
import constants from '../../../constants';

const styles = theme => {
    const spacingUnit = getSpacingUnit(theme);
    return {
        main: {
            width: 'auto',
            display: 'block',
            marginLeft: spacingUnit * 3,
            marginRight: spacingUnit * 3,
            [theme.breakpoints.up(400 + spacingUnit * 3 * 2)]: {
                width: 400,
                marginLeft: 'auto',
                marginRight: 'auto'
            }
        },
        paper: {
            marginTop: spacingUnit * 8,
            marginBottom: spacingUnit * 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: `${spacingUnit * 2}px ${spacingUnit * 3}px ${spacingUnit * 3}px`
        },
        avatar: {
            margin: '1rem',
            backgroundColor: theme.palette.mainRedColor,
            height: '3.5rem',
            width: '3.5rem'
        },
        lockIcon: {
            fontSize: '2rem'
        },
        form: {
            width: '100%',
            marginTop: spacingUnit
        },
        submit: {
            marginTop: spacingUnit * 2,
            marginBottom: spacingUnit * 2
        },
        link: {
            padding: spacingUnit * 2
        }
    };
};

class ForgotPassword extends Component {
    state = {
        email: ''
    };

    inputChangeHandler = event => {
        this.setState({
            [event.target.name]:
                event.target.value === 'remember'
                    ? event.target.checked
                    : event.target.value
        });
    };

    submitHandler = event => {
        event.preventDefault();
        const { clearErrors, setLoadingStatus, sendResetLink } = this.props;
        const { email } = this.state;
        setLoadingStatus({
            loading: true,
            text: 'Sending Reset Email...'
        });
        clearErrors();
        const user = {
            email: email,
            host: window.location.origin
        };

        sendResetLink(user, this.successCallback, this.errorCallback);
    };

    navToLoginHandler = () => {
        this.props.history.push(pageLinks.Login.url);
    };

    navToSignUpHandler = () => {
        this.props.history.push(pageLinks.Register.url);
    };

    successCallback = message => {
        showInfoToast(message);
        this.props.setLoadingStatus({loading: false});
    };

    errorCallback = (response, message) => {
        this.props.setLoadingStatus({loading: false});
        if (!response) {
            showErrorToast(message || constants.notifications.NO_RESPONSE);
        }
    };

    componentDidMount() {
        const { clearErrors } = this.props;
        clearErrors();
    }

    render() {
        const { classes, errorStatus } = this.props;
        const { email } = this.state;

        return (
            <main className={classes.main}>
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon className={classes.lockIcon} />
                    </Avatar>
                    <Typography component='h1' variant='h5'>
                        Forgot Password
                    </Typography>
                    <form
                        className={classes.form}
                        onSubmit={this.submitHandler}>
                        <FormControl margin='normal' required fullWidth>
                            <InputLabel htmlFor='email'>
                                Email Address
                            </InputLabel>
                            <Input
                                id='email'
                                name='email'
                                type='email'
                                autoComplete='email'
                                autoFocus
                                onChange={this.inputChangeHandler}
                                value={email} />
                            <Typography color='error'>
                                {errorStatus && errorStatus.email}
                            </Typography>
                        </FormControl>
                        <Button
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.submit}>
                            Send Email
                        </Button>
                        <Grid
                            container
                            direction='row'
                            justify='space-between'
                            alignItems='center'>
                            <Link
                                component='button'
                                className={classes.link}
                                onClick={this.navToLoginHandler}>
                                Login
                            </Link>
                            <Link
                                component='button'
                                className={classes.link}
                                onClick={this.navToSignUpHandler}>
                                Register
                            </Link>
                        </Grid>
                    </form>
                </Paper>
            </main>
        );
    }
}

ForgotPassword.propTypes = {
    classes: PropTypes.object.isRequired,
    sendResetLink: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    errorStatus: state.errors.errorStatus
});

const mapActionToProps = {
    sendResetLink,
    clearErrors,
    setLoadingStatus
};

export default connect(
    mapStateToProps,
    mapActionToProps
)(withStyles(styles)(ForgotPassword));
