import {
  SET_TIME_FRAME,
  SET_TV_HEIGHT,
} from "./types";
import { getCharts, getDetailedChart } from "../services/chart";
import { setChartDetailedData, setChartsInfoData } from "./tools";

// tradingview resolution
export const setTimeFrame = (timeFrame) => (dispatch) => {
  dispatch({
    type: SET_TIME_FRAME,
    payload: timeFrame,
  });
};

// tradingview chart operation result
export const setChartData = (strategy, symbolId) => async (dispatch) => {
  if (strategy) {
    try {
      const result = await getCharts(strategy._id);
      const chartsInfoData = result.data;

      dispatch(
        setChartsInfoData(strategy.type, chartsInfoData)
      );

      let isValidChart = false;
      for (const dataItem of chartsInfoData) {
        if (
          dataItem.strategyId === strategy._id &&
          dataItem.symbolId === symbolId
        ) {
          isValidChart = true;
          break;
        }
      }
      let detailedChartResult = null;
      if (isValidChart) {
        detailedChartResult = await getDetailedChart(strategy._id, symbolId);
      }

      dispatch(
        setChartDetailedData(
          strategy.type,
          isValidChart ? detailedChartResult.data : null
        )
      );
    } catch (error) {
      console.log("ray : [actions chart] error => ", error);
    }
  }
};

// TODO: * in future we could make layout based on screen size like webtrader
export const setTVHeight = (height) => (dispatch) => {
  dispatch({
    type: SET_TV_HEIGHT,
    payload: height,
  });
};
