
import React, { useState, useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import List from '@material-ui/core/List';
import 'react-perfect-scrollbar/dist/css/styles.css'
import InfiniteScroll from "react-infinite-scroll-component";
import PerfectScrollbar from 'react-perfect-scrollbar'

import constants from "../../../constants";
import LoadingSpinnerIcon from '../../Icons/LoadingSpinnerIcon';
import SignalListItem from './SignalListItem';
import { isEmpty } from '../../../utils/utility';

const styles = theme => {
  return {
    root: {
      padding: 0,
      // height: 'calc(100vh - 328px)',
      // overflowY: 'auto',
      [theme.breakpoints.down('sm')]: {
        height: 400
      }
    },
    scrollbar: {
      height: 'calc(100vh - 328px)',
      '& .ps__rail-y': {
        '&:hover': {
          backgroundColor: 'transparent'
        }
      },
      '& .ps__rail-x': {
        display: 'hidden'
      }
    },
    loadingContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    },
    loading: { marginLeft: 10 }
  };
};

const SignalList = ({ classes, allSymbols, signals, symbols, strategies, selectedSymbolId, focusedSignalCategory, serverTime, height, ...rest }) => {
  const pageCount = 10;
  const scrollBarClasses = 'ps ps--active-y';
  signals.sort((a, b) => (a.signalKind > b.signalKind) ? -1 : 1)
  const [state, setState] = useState({ items: [] })
  const [page, setPage] = useState(10);
  useEffect(() => {
    setState({
      items: signals.slice(0, page)
    });
  }, [signals.length])

  const fetchMoreData = () => {
    setPage(signals.legth <= page + pageCount ? page : page + pageCount)
    setTimeout(() => {
      setState({
        items: state.items.concat(signals.slice(page, signals.legth < page + pageCount ? signals.legth - page : page + pageCount))
      });
    }, 1000);
  };

  const getPips = signal => {
    const symbol = allSymbols.find(symbol => symbol.code === signal.symbol);
    const divider = (symbol.digits === 1 || symbol.digits === 3 || symbol.digits === 5) ? 10 : 1;
    return !symbol || signal.tp === 0 ? 0 : parseInt(Math.round(Math.abs(signal.price - signal.tp) / symbol.tick / divider));
  }

  const getTP1Pips = signal => {
    const symbol = allSymbols.find(symbol => symbol.code === signal.symbol);
    const divider = (symbol.digits === 1 || symbol.digits === 3 || symbol.digits === 5) ? 10 : 1;
    return !symbol || signal.tp1 === 0 || isEmpty(signal.tp1) ?
      0 : parseInt(Math.round(Math.abs(signal.price - signal.tp1) / symbol.tick / divider));
  }

  return (
    <>
      {
        focusedSignalCategory === constants.signalCategory.ACTIVE ?
          <List className={classes.root}
            style={{ height: window.innerWidth > 960 && `calc(100vh - 356px)` }} >
            <PerfectScrollbar className={[classes.scrollbar, scrollBarClasses].join(' ')}>
              {signals.map(signal => {
                return (
                  <SignalListItem key={signal.id} strategies={strategies} signal={signal} selectedSymbolId={selectedSymbolId} tpPips={getPips(signal)} tp1Pips={getTP1Pips(signal)} {...rest} />
                );
              })}

            </PerfectScrollbar>
          </List>
          :
          <List className={classes.root}
            style={{ height: window.innerWidth > 960 && `calc(100vh - 356px)` }} >
            <InfiniteScroll
              height={window.innerWidth > 960 && `calc(100vh - 328px)`}
              dataLength={state.items.length}
              next={fetchMoreData}
              hasMore={true}
              loader={signals.length === state.items.length ? null : <div className={classes.loadingContainer}><LoadingSpinnerIcon /> <h4 className={classes.loading}>   Loading...</h4></div>}>
              {state.items.map(signal => {
                return (
                  <SignalListItem key={signal.id} strategies={strategies} signal={signal} selectedSymbolId={selectedSymbolId} tpPips={getPips(signal)} tp1Pips={getTP1Pips(signal)} {...rest} />
                );
              })}
            </InfiniteScroll>
          </List>
      }
    </>
  );
};

export default withStyles(styles, { withTheme: true })(SignalList);
