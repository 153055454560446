import axios from "axios";

import { getFeedbacksUrl } from "./endpoints";

const addFeedback = async (data) => {
  const url = getFeedbacksUrl();
  const result = await axios.post(url, data);
  return result;
};

export { addFeedback };
