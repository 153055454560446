import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PerfectScrollbar from "react-perfect-scrollbar";

import CardWithHeader from '../../components/CardWithHeader';
import CardHeader from '../../components/CardWithHeader/CardHeader';
import CardBody from '../../components/CardWithHeader/CardBody';

const styles = theme => {
  return {
    scrollbar: {
      "& .ps__rail-y": {
        "&:hover": {
          backgroundColor: "transparent"
        }
      },
      "& .ps__rail-x": {
        display: "hidden"
      }
    }
  }
}

const CardWrapper = ({ classes, displayCardHeader, headerColor, headerHeight, title, subTitle, children, headerAction, titleFontSize, plain, scroll, cardWrapperType }) => {
  const scrollBarClasses = "ps ps--active-y";
  return (
    <Fragment>
      <CardWithHeader cardWrapperType={cardWrapperType} displayCardHeader={displayCardHeader}>
        <CardHeader
          plain={plain && plain}
          color={headerColor}
          height={headerHeight}
          title={title}
          subTitle={subTitle}
          action={headerAction}
          display={displayCardHeader}
          fontSize={titleFontSize}>
        </CardHeader>
        {scroll ?
          (
            <PerfectScrollbar
              className={[classes.scrollbar, scrollBarClasses].join(" ")}
            >
              <CardBody>
                {children}
              </CardBody>
            </PerfectScrollbar>
          ) :
          (
            <CardBody>
              {children}
            </CardBody>

          )
        }
      </CardWithHeader>
    </Fragment>
  )
}

export default withStyles(styles, { withTheme: true })(CardWrapper);
