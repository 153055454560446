import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

import EditIconButton from "../../../EditIconButton";
import RemoveIconButton from "../../../RemoveIconButton";
import {
  isBuyByTitle,
  timeDifference,
  getSpacingUnit,
  getSignalTypeLabel,
  isSignalClosed,
  getProfitUnitString
} from "../../../../utils/utility";
import ListItemLablel from "./ListItemLablel";

const styles = (theme) => {
  const spacingUnit = getSpacingUnit(theme);

  return {
    root: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.mainBackColor,
      padding: 0,
      marginTop: spacingUnit * 0.5,
      marginBottom: spacingUnit * 0.5,
      borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
      "&:hover": {
        transform: "translateY(-2px)",
        transition: `ease-out 0.4s `,
        opacity: "100%",
      },
      transition: "ease-out 0.4s",
    },
    selectedBorder: {
      border: "1px solid",
      borderColor: theme.palette.borderColor,
    },
    labelContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
    },
    roundLabel: {
      backgroundColor: "red",
      padding: "4px 4px 4px 4px",
      borderRadius: "0px 0px 20px",
    },
    ListItemContent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "100%",
      padding: "0px 8px 4px 8px",
    },
    symbolField: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: "4px",
    },
    moreDetail: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    pipsField: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    labelField: {
      display: "flex",
      flexDirection: "row",
      borderRadius: 4,
    },
    priceLabel: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "4px 0px 0px 4px",
      padding: "2px 5px 2px 5px",
    },
    signalLabel: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "0px 4px 4px 0px",
      padding: "2px 5px 2px 5px",
    },
  };
};

const SignalListItem = ({
  theme,
  classes,
  signal,
  tpPips,
  tp1Pips,
  strategies,
  selectedSignal,
  signalSelected,
  showActionButtons,
  serverTime,
  onOpenEditSignal,
  onOpenCloseRemoveSignalConfirm,
  onSignalSelect
}) => {
  const signalTypeLabel = getSignalTypeLabel(signal.type);
  const isClosed = isSignalClosed(signal.signalKind);
  const signalKindLabel = isClosed ? "CLOSED" : "ACTIVE";

  return (
    <ListItem
      button
      className={classNames(
        classes.root,
        selectedSignal &&
          selectedSignal._id === signal._id &&
          classes.selectedBorder
      )}
      onClick={() => onSignalSelect(signal, true)}
    >
      <ListItemLablel
        marketStatus={"MARKET EXECUTION"}
        signalStatus={signalKindLabel}
      />
      <div className={classes.ListItemContent}>
        <div className={classes.symbolField}>
          <Typography
            style={{ fontSize: 13, fontWeight: 400 }}
            variant="subtitle1"
          >
            {" "}
            {`${signal.symbol} `}{" "}
          </Typography>
          <div className={classes.labelField}>
            <div
              style={{
                backgroundColor: isBuyByTitle(signalTypeLabel)
                  ? theme.palette.longPriceLabel
                  : theme.palette.shortPriceLabel,
              }}
              className={classes.priceLabel}
            >
              <Typography style={{ fontSize: 10 }}>{signal.price}</Typography>
            </div>
            <div
              className={classes.signalLabel}
              style={{
                backgroundColor: isBuyByTitle(signalTypeLabel)
                  ? theme.palette.longLable
                  : theme.palette.shortLabelBack,
              }}
            >
              <Typography style={{ fontSize: 10 }}>
                {isBuyByTitle(signalTypeLabel) ? "LONG" : "SHORT"}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.moreDetail}>
          {tp1Pips !== 0 && (
            <div className={classes.pipsField}>
              <Typography
                style={{ fontSize: 12, fontWeight: 300, paddingRight: 6 }}
                color="textSecondary"
              >
                {`Target ${signal.tp1} `}
              </Typography>
              <Typography
                style={{
                  fontSize: 12,
                  color: 0,
                  fontWeight: 600,
                }}
              >
                {` +${tp1Pips} ${getProfitUnitString(signal.symbol)}`}
              </Typography>
            </div>
          )}
          <div className={classes.pipsField}>
            <Typography
              style={{ fontSize: 12, fontWeight: 300, paddingRight: 6 }}
              color="textSecondary"
            >
              {`Target ${signal.tp} `}
            </Typography>
            <Typography
              style={{
                fontSize: 12,
                color: 0,
                fontWeight: 600,
              }}
            >
              {` +${tpPips} ${getProfitUnitString(signal.symbol)}`}
            </Typography>
          </div>
          <Typography
            component="div"
            style={{
              fontSize: 12,
              fontWeight: 300,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
            color="textSecondary"
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <AccessTimeIcon style={{ paddingRight: 4 }} fontSize="small" />
              {timeDifference(
                serverTime,
                isClosed ? signal.closedTime : signal.time
              )}
              {/*({getTimeframeName(strategy.timeframe)})*/}
            </div>
            {showActionButtons && (
              <div>
                <EditIconButton
                  style={{ height: 20, width: 20 }}
                  fontSize="small"
                  onClick={() => onOpenEditSignal(signal)}
                />
                <RemoveIconButton
                  style={{ height: 20, width: 20 }}
                  fontSize="small"
                  onClick={() =>
                    onOpenCloseRemoveSignalConfirm(true, signal._id)
                  }
                />
              </div>
            )}
          </Typography>
        </div>
      </div>
    </ListItem>
  );
};

const mapStateToProps = (state) => ({
  serverTime: state.tools.serverTime,
});

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(SignalListItem)
);
