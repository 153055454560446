
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import CardContent from '@material-ui/core/CardContent';

const styles = theme => {
    return {
        root: {
			padding: 0,
            '&:last-child': {
            	padding: 0
            }
		}
    };
};

const ToolsCardContentWrapper = ({ classes, children }) => {
    return (
        <CardContent className={classes.root}>
            {children}
        </CardContent>
    );
};

export default withStyles(styles, {withTheme: true})(ToolsCardContentWrapper);
