import React, {useEffect, useRef} from 'react';
import Typography from "@material-ui/core/Typography";
import ArrowDown from "../../assets/imgs/arrow-down.svg";
import ArrowUp from "../../assets/imgs/arrow-up.svg";
import withStyles from "@material-ui/core/styles/withStyles";
import constants from '../../constants';
import moment from "moment";
import {formatTimeFrame, patternRRIsValid} from "../../utils/utility";

const COLUMNS = [
  'Symbol',
  'Pattern',
  'Size',
  'Type',
  'Timeframe',
  'Risk Reward (Min - Max)',
  'Deviation',
  'Status',
  'Created At',
];

const styles = theme => {
  return {
    eagleView: {
      maxHeight: '40%',
      overflow: 'auto',
      height: '400px',
    },
    tableRoot: {
      textAlign: 'center',
      width: '100%',
    },
    symbolColumnItem: {
      display: 'flex',
      justifyContent: 'center',
    },
    patternTypeIcon: {
      marginLeft: '5px',
    },
  };
};

const ensurePatternName = patternName => patternName.replace(/_/g, ' ');
const getDeviationDisplayValue = pattern => {
  const percent = constants.harmonicPatternDeviationValues[pattern.deviation];
  if (percent) {
    return `${percent}%`
  }
  return 'Perfect Pattern';
};
const getStatusDisplayValue = pattern => {
  if (typeof pattern.completed === 'boolean') {
    return pattern.completed ? 'Complete' : 'Incomplete';
  }
  return 'Complete';
};


const HarmonicPatternsTable = props => {

  const {
    classes,
    patterns = [],
    onRowClick = null,
    eagleViewCtx = false,
    active = null,
    filters,
    onLoadMore
  } = props;

  const container = useRef(null);
  const scrolledToBottom = useRef(false);

  const handleRowClick = (octId, pattern) => {
    if (onRowClick) {
      onRowClick(octId, pattern);
    }
  };

  const handleLoadMore = async e => {
    const element = e.target;
    const threshold = element.scrollHeight * 0.95;
    if ((element.clientHeight + element.scrollTop) > threshold) {
      if (!scrolledToBottom.current) {
        scrolledToBottom.current = true;
        await onLoadMore();
        scrolledToBottom.current = false;
      }
    }
  };

  useEffect(() => {
    if (eagleViewCtx && container.current) {
      container.current.addEventListener('scroll', handleLoadMore);
      return () => container.current.removeEventListener('scroll', handleLoadMore);
    }
  }, [filters]);

  return (
    <div {...eagleViewCtx && {className: classes.eagleView}} ref={container}>
      <table className={classes.tableRoot}>
        <thead>
          <tr>
            {
              COLUMNS.map(column => (<td key={column}><Typography>{column}</Typography></td>))
            }
          </tr>
        </thead>
        <tbody>
        {
          patterns.map(pattern => (
            <tr
              key={pattern._id}
              onClick={() => handleRowClick(pattern.octId, pattern)}
              style={{
                cursor: !!pattern.octId ? 'pointer': 'not-allowed',
                ...(pattern.octId && active === pattern.octId) && {backgroundColor: 'blue'},
              }}
            >
              <td className={classes.symbolColumnItem}>
                <Typography>{pattern.symbol}</Typography>
                <img
                  src={pattern.patterntype === 'Bearish' ? ArrowDown : ArrowUp}
                  alt='Pattern type'
                  className={classes.patternTypeIcon}
                />
              </td>
              <td><Typography>{ensurePatternName(pattern.patternname)}</Typography></td>
              <td><Typography>{constants.harmonicPatternSizes[pattern.patternsize]}</Typography></td>
              <td><Typography>{pattern.signaltype}</Typography></td>
              <td><Typography>{formatTimeFrame(pattern.timeframe)}</Typography></td>
              <td>
                {
                  patternRRIsValid(pattern.rr) && (
                    <Typography>
                      {(pattern.rr.rewardMin / pattern.rr.riskMin).toFixed(2)} - {(pattern.rr.rewardMax / pattern.rr.riskMax).toFixed(2)}
                    </Typography>
                  )
                }
              </td>
              <td>
                <Typography>
                  {getDeviationDisplayValue(pattern)}
                </Typography>
              </td>
              <td><Typography>{getStatusDisplayValue(pattern)}</Typography></td>
              <td>
                <Typography>
                  {moment(pattern.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                </Typography>
              </td>
            </tr>
          ))
        }
        </tbody>
      </table>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(HarmonicPatternsTable);
