import React, { Fragment } from 'react';
import AlarmIcon from '@material-ui/icons/AccessAlarm';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';

import { isValidDarkTheme } from '../../../utils/styles';
import ContainedButton from "../../ContainedButton";

const styles = theme => {
  return {
    menu: {
      border: '1px solid #d3d4d5',
    },
    menuItem: {
      textTransform: 'uppercase'
    },
    container: {
      marginLeft: '10px',
    },
    selectedContainer: {
      marginLeft: '10px',
      backgroundColor: 'rgb(33, 150, 243)',
    },
    icon: {
      color: isValidDarkTheme(theme.palette.type) ? theme.palette.iconColor : `rgba(0, 0, 0, 0.54)`,
      paddingTop: 5
    },
    iconButton: {
      display: 'flex',
      justifyContent: 'center',
      padding: 5
    },
    selectedIconButton: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'rgb(33, 150, 243)',
      padding: 5
    },
  }
}

const LinksSelector = (props) => {
  const { classes, links } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }
  const validURL = (str) => {
    var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!]))?/;
    if (!regex.test(str)) {
      alert("Please enter valid URL.");
      return false;
    } else {
      return true;
    }
  }
  const handleTypeClick = link => () => {
    if (validURL(link.link)) {
      window.open(link.link, '_blank');
    }
    setAnchorEl(null);
  }

  return (
    <Fragment>
      <ContainedButton
        className={anchorEl !== null ? classes.selectedContainer : classes.container}
        onClick={handleClick}
      >
        <AlarmIcon />
        <span>&nbsp;Setup alerts</span>
      </ContainedButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={10}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {links.map(link => (
          <MenuItem className={classes.menuItem} key={link._id} onClick={handleTypeClick(link)}>
            {link.type}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  )
}

export default withStyles(styles, { withTheme: true })(LinksSelector);
