import React, {useState, useContext, createContext} from 'react';
import {Modal, Typography} from "@material-ui/core";


const PremiumLockInfoContext = createContext();

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: '#181D26',
  padding: '30px',
};


export default ({children}) => {
  const [premiumLockInfo, setPremiumLockInfo] = useState(null);

  const showPremiumLockInfo = premiumLockInfo => {
    setPremiumLockInfo(premiumLockInfo);
  };

  return (
    <PremiumLockInfoContext.Provider
      value={{showPremiumLockInfo}}
    >
      <Modal
        open={!!premiumLockInfo}
        onClose={() => setPremiumLockInfo(null)}
      >
        {
          !!premiumLockInfo ?
            <div style={style}>
              <Typography style={{color: '#fff'}}>
                In order to use this filter you need to buy {premiumLockInfo.subscriptionName}<br /><br />
                <a href='https://addonmkt.com/' target='_blank' rel='noopener noreferrer'>Go to shop</a>
              </Typography>
            </div>
            :
            null
        }
      </Modal>
      {children}
    </PremiumLockInfoContext.Provider>
  )
};

export const usePremiumLockInfo = () => {
  const {showPremiumLockInfo} = useContext(PremiumLockInfoContext);
  return {showPremiumLockInfo};
};
