import React, { PureComponent } from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import withStyles from "@material-ui/core/styles/withStyles";

import CustomSelectValidator from "../../../../components/CustomSelectValidator";
import OutlinedButton from "../../../../components/OutlinedButton";
import CustomTextButton from "../../../../components/CustomTextButton";
import DialogWrapper from "../../../../hoc/DialogWrapper";
import DialogActionsWrapper from "../../../../hoc/DialogActionsWrapper";
import {
  getAddEditDialogMetadata,
  showErrorToast,
} from "../../../../utils/utility";
import { addLink, editLink } from "../../../../services/link";
import { tools, linkTypes } from "../../../../constants";

const styles = (theme) => {
  return {
    row: {
      display: "flex",
      alignItems: "flex-end",
    },
    languageSelector: {
      minWidth: 100,
      marginBottom: 10,
    },
  };
};

class AddEditDialog extends PureComponent {
  state = {
    toolsType: 2,
    type: "sense",
    link: "",
    submitButtonDisabled: false,
    toolsTypeArray: [],
  };

  componentDidMount() {
    const { dialogData } = this.props;
    if (dialogData) {
      const { toolsType, type, link } = dialogData;
      this.setState({ toolsType, type, link });
    }

    let toolsTypeArray = [];
    let availableLinkTypes = [];
    tools.forEach(tool => availableLinkTypes.push(tool));

    for (const linkType of availableLinkTypes) {
      toolsTypeArray.push({
        label: linkType.label,
        value: linkType.value,
      });
    }
    this.setState({ toolsTypeArray: toolsTypeArray });
  }

  onCloseHandler = () => {
    const { closed } = this.props;
    closed && closed();
  };

  submitHandler = async () => {
    const { toolsType, type, link } = this.state;
    const { isAddDialog, dialogData, addEditLink } = this.props;
    const linkItem = {
      _id: !isAddDialog ? dialogData._id : null,
      toolsType,
      type,
      link,
    };
    try {
      this.setState({ submitButtonDisabled: true });
      let response;
      if (isAddDialog) {
        response = await addLink(linkItem);
      } else {
        response = await editLink(linkItem);
      }
      const { data: linkFromBackend } = response;
      addEditLink(linkFromBackend);
    } catch (error) {
      // TODO: * axios handling module
      console.log(
        "ant : [containers Admin LinkManage AddEditDialog submitHandler] error => ",
        error
      );
      if (error.response) {
        const { message: errorMessage } = error.response.data;
        showErrorToast(errorMessage);
      }
    }
    this.onCloseHandler();
  };

  toolsTypeChangeHandler = (toolsType) => {
    this.setState({ toolsType });
  };

  updateLinkHandler = (event) => {
    this.setState({ link: event.target.value });
  };

  updateTypeHandler = (type) => {
    this.setState({ type });
  };

  triggerSubmitHandler = () => {
    this.form.isFormValid(false).then((isValid) => {
      if (isValid) {
        this.submitHandler();
      }
    });
  };

  render() {
    const { opened, isAddDialog, manageTitle, classes } = this.props;
    const {
      toolsType,
      type,
      link,
      submitButtonDisabled,
      toolsTypeArray,
    } = this.state;
    const {
      dialogTitle,
      dialogDescription,
      submitButtonName,
      cancelButtonName,
    } = getAddEditDialogMetadata(isAddDialog, manageTitle);

    return (
      <DialogWrapper
        opened={opened}
        dialogTitle={dialogTitle}
        dialogDescription={dialogDescription}
        onClose={this.onCloseHandler}
        disableEnforceFocus
        dialogActions={
          <DialogActionsWrapper>
            <CustomTextButton onClick={this.onCloseHandler}>
              {cancelButtonName}
            </CustomTextButton>
            <OutlinedButton
              type="button"
              disabled={submitButtonDisabled}
              onClick={this.triggerSubmitHandler}
            >
              {submitButtonName}
            </OutlinedButton>
          </DialogActionsWrapper>
        }
      >
        <ValidatorForm
          ref={(ref) => {
            this.form = ref;
          }}
          onSubmit={this.submitHandler}
          onError={(errors) => console.log(errors)}
        >
          <div className={classes.row}>
            <CustomSelectValidator
              label="Type"
              value={toolsType}
              changed={this.toolsTypeChangeHandler}
              items={toolsTypeArray}
            />
          </div>
          <CustomSelectValidator
            label="Type"
            value={type}
            changed={this.updateTypeHandler}
            items={linkTypes}
          />
          <TextValidator
            fullWidth
            name="link"
            label="Link"
            value={link}
            margin="normal"
            onChange={this.updateLinkHandler}
            validators={["required"]}
            errorMessages={["Link cannot be empty"]}
          />
          <DialogActionsWrapper />
        </ValidatorForm>
      </DialogWrapper>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AddEditDialog);
