import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';

import AddEditDialog from './AddEditDialog';
import HPConfig from "./HPConfig";
import CustomMUIDataTable from '../../../components/CustomMUIDataTable';
import PlusAddButton from '../../../components/PlusAddButton';
import EditIconButton from '../../../components/EditIconButton';
import RemoveIconButton from '../../../components/RemoveIconButton';
import ConfirmDialog from '../../../components/ConfirmDialog';
import {
  getAddons,
  getOCTConfig,
  createAddon,
  updateAddon,
  removeAddon,
  createAddonTgBot,
  removeAddonTgBot,
} from '../../../actions/addon';
import {getSubscriptions} from '../../../actions/subscription';
import {setLoadingStatus} from '../../../actions/loading';
import {commonMUITableOptions} from '../../../utils/styles';
import Avatar from "@material-ui/core/Avatar";
import {Chip} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {Menu, MenuItem} from "@material-ui/core";
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import {generateSignalToken, getChartUrl} from "../../../services/addon";
import OutlinedButton from "../../../components/OutlinedButton";
import {showErrorToast, showInfoToast} from "../../../utils/utility";
import EngulfingAndOPConfig from "./EngulfingAndOPConfig";
import TgBotConfig from "./TgBotConfig";
import {getTgBot, updateTgBot} from '../../../services/addon';

class AddonsManage extends Component {
  state = {
    addEditDialogOpened: false,
    confirmDialogOpened: false,
    dialogData: null,
    addonToManageHP: false,
    addonToManageEngulfingAndOP: false,
    addonToManageTgBot: false,
  };

  async componentDidMount() {
    const {setLoadingStatus, getSubscriptions, getAddons, getOCTConfig} = this.props;
    setLoadingStatus({loading: true, text: 'Loading addons...'});
    await getAddons();
    await getOCTConfig();
    await getSubscriptions();
    setLoadingStatus({loading: false});
  };

  handleGenerateSignalToken = addonId => {
    this.props.setLoadingStatus({loading: true});
    generateSignalToken(addonId).then(
      () => {
        showInfoToast('Generated new signal token');
        this.props.getAddons();
      }
    ).catch(() => {
      showErrorToast('Error while generating signal token');
    }).finally(() => {
      this.props.setLoadingStatus({loading: false});
    });
  };

  handleCopySignalToken = async token => {
    try {
      await window.navigator.clipboard.writeText(token);
      showInfoToast('Signal token copied to clipboard');
    } catch (e) {
      showErrorToast('Error while copying signal token to clipboard');
    }
  };

  onManageChartAppearances = async () => {
    const url = await getChartUrl({
      octAdmin: 1,
    });
    window.open(url, '_blank');
  };

  createTableData = () => {
    return this.props.addons.map(addon => ([
      addon.image,
      addon.name,
      addon.comingSoon,
      addon.hidden,
      addon.signalsEnabled,
      addon.symbols.length,
      addon.signalToken,
      addon,
    ]));
  };

  columns = () => [
    {
      name: '',
      options: {
        filter: false,
        sort: false,
        customBodyRender: image => {
          return (
            <Avatar variant="square" src={image} style={{ height: 22, width: 24, borderRadius: 0 }} />
          );
        },
      },
    },
    {name: 'Name'},
    {
      name: 'Status',
      options: {
        filter: false,
        sort: false,
        customBodyRender: comingSoon => {
          return (
            <Chip label={comingSoon ? 'Coming soon' : 'Live'}/>
          )
        }
      }
    },
    {
      name: 'Visibility',
      options: {
        filter: false,
        sort: false,
        customBodyRender: hidden => {
          return (
            <Chip label={hidden ? 'Hidden' : 'Visible'}/>
          )
        }
      }
    },
    {
      name: 'Signals',
      options: {
        filter: false,
        sort: false,
        customBodyRender: signalsEnabled => {
          return (
            <Chip label={signalsEnabled ? 'Enabled' : 'Disabled'}/>
          )
        }
      }
    },
    {name: 'Symbols'},
    {
      name: 'Signal token',
      options: {
        filter: false,
        sort: false,
        customBodyRender: signalToken => {
          if (signalToken) {
            return (
              <OutlinedButton
                onClick={() => this.handleCopySignalToken(signalToken)}
              >
                Copy
              </OutlinedButton>
            )
          }
          return null;
        }
      }
    },
    {
      name: 'Action',
      options: {
        filter: false,
        sort: false,
        customBodyRender: addon => {
          return (
            <div style={{display: 'flex'}}>
              <EditIconButton
                color='primary'
                onClick={() => this.openEditDialogHandler(addon._id)}
              />
              <RemoveIconButton
                color='primary'
                onClick={() => this.openCloseConfirmDialogHandler(true, addon._id)}
              />
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <React.Fragment>
                    <Button {...bindTrigger(popupState)}>
                      More
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        onClick={
                          () => {
                            popupState.close();
                            this.handleGenerateSignalToken(addon._id)
                          }
                        }
                      >
                        Generate signal token
                      </MenuItem>
                      {
                        addon.indicators.includes('harmonic_pattern') && (
                          <>
                            <MenuItem
                              onClick={
                                () => {
                                  popupState.close();
                                  this.setAddonToManageHarmonicPatterns(addon);
                                }
                              }
                            >
                              Manage addon patterns
                            </MenuItem>
                            <MenuItem
                              onClick={
                                () => {
                                  popupState.close();
                                  this.setAddonToManageTgBot(addon);
                                }
                              }
                            >
                              {!!addon.tgBotId ? 'Manage telegram bot' : 'Create telegram bot'}
                            </MenuItem>
                          </>
                        )
                      }
                      {
                        addon.indicators.includes('engulfing_and_op') && (
                          <MenuItem
                            onClick={
                              () => {
                                popupState.close();
                                this.setAddonToManageEngulfingAndOP(addon);
                              }
                            }
                          >
                            Manage addon indicators
                          </MenuItem>
                        )
                      }
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>

            </div>
          );
        }
      }
    }
  ];

  options = {
    ...commonMUITableOptions,
    customToolbar: () => {
      return (
        <>
          <PlusAddButton onClick={this.openAddDialogHandler}/>
          <Button onClick={this.onManageChartAppearances}>Manage chart appearances</Button>
        </>
      );
    }
  };

  openAddDialogHandler = () => {
    this.openCloseAddEditDialogHandler(true);
  };

  openEditDialogHandler = addonId => {
    const {addons} = this.props;
    const dialogData = addons.find(addon => addon._id === addonId);
    this.openCloseAddEditDialogHandler(true, dialogData);
  };

  closeAddEditDialogHandler = () => {
    this.openCloseAddEditDialogHandler(false);
  };

  openCloseAddEditDialogHandler = (opened, dialogData = null) => {
    this.setState({addEditDialogOpened: opened, dialogData});
  };

  removeAddonHandler = async _id => {
    await this.props.removeAddon(_id);
  };

  openCloseConfirmDialogHandler = (opened, removeId, removeIdList) => {
    this.setState({confirmDialogOpened: opened, removeId, removeIdList});
  };

  confirmRemoveDialogHandler = async () => {
    const {removeId} = this.state;
    if (removeId) {
      await this.removeAddonHandler(removeId);
    }
  }

  setAddonToManageHarmonicPatterns = addon => {
    this.setState({addonToManageHP: addon});
  };

  setAddonToManageEngulfingAndOP = addon => {
    this.setState({addonToManageEngulfingAndOP: addon});
  };

  setAddonToManageTgBot = addon => {
    this.setState({addonToManageTgBot: addon});
  };

  render() {
    const {
      addEditDialogOpened,
      dialogData,
      confirmDialogOpened,
      removeIdList,
      addonToManageHP,
      addonToManageEngulfingAndOP,
      addonToManageTgBot,
    } = this.state;
    const tableData = this.createTableData();

    return (
      <Fragment>
        {
          !!addonToManageHP && (
            <HPConfig
              addon={addonToManageHP}
              onClose={() => this.setAddonToManageHarmonicPatterns(null)}
              onSubmit={this.props.updateAddon}
              subscriptions={this.props.subscriptions}
            />
          )
        }
        {
          !!addonToManageEngulfingAndOP && (
            <EngulfingAndOPConfig
              addon={addonToManageEngulfingAndOP}
              onClose={() => this.setAddonToManageEngulfingAndOP(null)}
              onSubmit={this.props.updateAddon}
              subscriptions={this.props.subscriptions}
            />
          )
        }
        {
          !!addonToManageTgBot && (
            <TgBotConfig
              addon={addonToManageTgBot}
              onClose={() => this.setAddonToManageTgBot(null)}
              createTgBot={this.props.createAddonTgBot}
              removeTgBot={this.props.removeAddonTgBot}
              setLoadingStatus={this.props.setLoadingStatus}
              updateTgBot={updateTgBot}
              getTgBot={getTgBot}
            />
          )
        }
        {addEditDialogOpened && (
          <AddEditDialog
            manageTitle='Addon'
            opened={addEditDialogOpened}
            isAddDialog={!dialogData}
            dialogData={dialogData}
            closed={this.closeAddEditDialogHandler}
            octConfig={this.props.octConfig}
            createAddon={this.props.createAddon}
            updateAddon={this.props.updateAddon}
            subscriptions={this.props.subscriptions}
          />
        )}
        <CustomMUIDataTable
          title='Addons'
          data={tableData}
          columns={this.columns()}
          options={this.options}
        />
        <ConfirmDialog
          isMultiple={!!removeIdList}
          opened={confirmDialogOpened}
          closed={() => this.openCloseConfirmDialogHandler(false)}
          confirmed={this.confirmRemoveDialogHandler}/>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  addons: state.addon.addons,
  octConfig: state.addon.octConfig,
  subscriptions: state.subscription.subscriptions,
});

const mapActionToProps = {
  setLoadingStatus,
  getAddons,
  createAddon,
  updateAddon,
  removeAddon,
  getOCTConfig,
  getSubscriptions,
  createAddonTgBot,
  removeAddonTgBot,
};

export default connect(
  mapStateToProps,
  mapActionToProps,
)(AddonsManage);
