import React, {PureComponent} from 'react';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import OutlinedButton from '../../../../components/OutlinedButton';
import CustomTextButton from '../../../../components/CustomTextButton';
import DialogWrapper from '../../../../hoc/DialogWrapper';
import DialogActionsWrapper from '../../../../hoc/DialogActionsWrapper';
import {
  getAddEditDialogMetadata,
} from '../../../../utils/utility';

class AddEditDialog extends PureComponent {
  state = {
    name: '',
    value: '',
  };

  componentDidMount() {
    const {isAddDialog, dialogData} = this.props;
    // edit
    if (!isAddDialog) {
      this.setState(dialogData);
    }
  }

  inputChangeHandler = event => {
    this.setState({[event.target.name]: event.target.value});
  };

  handleSubmit = async () => {
    const {createSubscription, updateSubscription} = this.props;
    let method = createSubscription;
    if (!this.props.isAddDialog) {
      method = updateSubscription;
    }
    const payload = {...this.state, value: parseInt(this.state.value)};
    await method(payload);
    this.onCloseHandler();
  };

  onCloseHandler = () => {
    const {closed} = this.props;
    closed && closed();
  };

  triggerSubmitHandler = () => {
    this.form.isFormValid(false).then((isValid) => {
      if (isValid) {
        this.handleSubmit();
      }
    });
  };

  render() {
    const {opened, isAddDialog, manageTitle} = this.props;
    const {
      submitButtonDisabled
    } = this.state;
    const {
      dialogTitle,
      dialogDescription,
      submitButtonName,
      cancelButtonName
    } = getAddEditDialogMetadata(isAddDialog, manageTitle);

    return (
      <DialogWrapper
        opened={opened}
        dialogTitle={dialogTitle}
        dialogDescription={dialogDescription}
        onClose={this.onCloseHandler}
        dialogActions={
          <DialogActionsWrapper>
            <CustomTextButton onClick={this.onCloseHandler}>
              {cancelButtonName}
            </CustomTextButton>
            <OutlinedButton type='button' disabled={submitButtonDisabled}
                            onClick={this.triggerSubmitHandler}>
              {submitButtonName}
            </OutlinedButton>
          </DialogActionsWrapper>
        }>
        <ValidatorForm
          ref={(ref) => {
            this.form = ref;
          }}
          onSubmit={this.handleSubmit}
          onError={errors => console.log(errors)}
        >
          <TextValidator
            fullWidth
            name='name'
            label='Name'
            value={this.state.name}
            margin='normal'
            onChange={this.inputChangeHandler}
            validators={['required']}
            errorMessages={['Name cannot be empty']}
          />
          <TextValidator
            fullWidth
            name='value'
            label='Value'
            value={this.state.value}
            margin='normal'
            onChange={this.inputChangeHandler}
            validators={['required', 'isNumber']}
            errorMessages={['Value cannot be empty', 'Value must be number']}
          />
          <DialogActionsWrapper/>
        </ValidatorForm>
      </DialogWrapper>
    );
  }
}

export default AddEditDialog;
