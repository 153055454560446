import React from 'react';
import {Input} from "@material-ui/core";


const CreateTgBotForm = ({token, setToken}) => {

  return (
    <div>
      <Input
        fullWidth
        placeholder='Enter bot token'
        value={token}
        onChange={e => setToken(e.target.value)}
      />
    </div>
  )
};


export default CreateTgBotForm;
