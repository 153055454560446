import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Particles from 'react-particles-js';

import setAuthToken from '../../../services/security/setAuthToken';
import store from '../../../store';
import { setCurrentUser } from '../../../actions/auth'; 
import { clearBrokers } from '../../../actions/broker';
import { clearSymbols } from '../../../actions/symbol';
import { clearTools } from '../../../actions/tools';
import { clearStrategies } from '../../../actions/strategy';
import { loginUser } from '../../../actions/auth';
import { changeTheme } from '../../../actions/theme';
import { clearErrors } from '../../../actions/error';
import { setLoadingStatus } from '../../../actions/loading';
import { getSpacingUnit, showErrorToast } from '../../../utils/utility';
import { pageLinks } from '../../../utils/links';
import constants from '../../../constants';
import config from '../../../config';
import Logo from '../../../components/Logo';
import TextField from '@material-ui/core/TextField';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PasswordIcon from '@material-ui/icons/VpnKey';
import param from  '../../../assets/particles.json';

const styles = theme => {
    const spacingUnit = getSpacingUnit(theme);
    return {
        background: {
            display: 'flex',
            flexDirection: 'column',
            width: '100vw',
            height: '100vh',
            // background: '#232741',
            background: 'url(https://cdn.pixabay.com/photo/2018/03/29/19/34/northern-lights-3273425_1280.jpg) no-repeat 50%',
            // background: 'url(https://image.freepik.com/free-photo/popular-abstract-background-shining-blue-dust-particles-stars-sparks_75775-385.jpg) no-repeat 50%',
            backgroundSize: 'cover',
            backgroundPositionX: 'center',
            '& > div': {
                height: '100vh'
            }

        },
        main: {
            transform: 'translate(-50%, -50%)',
            left: '50%',
            top: '50%',
            position: 'absolute',
            width: `calc(100% - ${spacingUnit * 4}px);`,
            display: 'block',
            [theme.breakpoints.up(440 + spacingUnit * 3 * 2)]: {
                width: 440,
            }
        },
        paper: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: spacingUnit * 3,
            paddingTop: spacingUnit * 5,
            background: 'rgba(3, 12, 26, 0.6);'
        },
        userIcon: {
            fontSize: '3.5rem',
            color: theme.palette.mainRedColor,
            margin: '1rem'
        },
        form: {
            width: '100%',
            marginTop: spacingUnit
        },
        submit: {
            marginTop: spacingUnit * 3,
            marginBottom: spacingUnit * 1.5,
            height: 42,
            letterSpacing: 2,
        },
        signUp: {
            width: '100%',
            padding: spacingUnit * 2
        },
        white: {
            color: 'white',
        },
        opacityHalf: {
            opacity: 0.6
        },
        forgotPasswordText: {
            color: 'white',
            opacity: 0.6,
            '&:hover': {
                opacity: 1
            }
        },
        textField: {
            border: '1px solid #384a66',
            marginTop: spacingUnit,
            background: 'transparent', //'#0b182b',
            width: '100%',
            '& input': {
                color: 'white',
                '-webkit-text-fill-color' : 'white',
                '-webkit-box-shadow': '0 0 0px 1000px #17305540 inset',
                border: 'none',
                padding: '12px 14px 12px 56px',
                '&:-webkit-autofill': {
                    'transition': 'background-color 5000s ease-in-out 0s',
                }
            },
            '& fieldset': {
                border: 'none'
            },
        },
        copyRight: {
            color: 'white',
            padding: spacingUnit,
            textAlign: 'center'
        },
        iconLabel: {
            color: 'white',
            position: 'absolute',
            zIndex: 1,
            margin: '18px 4px 4px 16px'
        },
        oneRow: {
            display: 'flex',
            width: '100%',
            alignItems: 'center'
        },
        halfLine: {
            borderBottom: '1px solid #384a66',
            height: 0,
            width: '100%',
            margin: spacingUnit
        },
        forgotPassword: {
            textAlign: 'center',
            color: 'white',
            display: 'flex',
            justifyContent: 'center'
        },
        logo: {
            width: 160,
            height: 60,
            color: theme.palette.subForeColor5
        }
    };
};

class Login extends Component {
    state = {
        userIdentifier: '',
        password: '',
        rememberMe: false,
        thinkMeliusInvalidSubscriptionMessage: '',
        thinkMeliusInvalidCredentialsMessage: ''
    };

    inputChangeHandler = event => {
        this.setState({
            [event.target.name]:
                event.target.value === 'remember' // TODO: hardcoded
                    ? event.target.checked
                    : event.target.value
        });
    };
    
    submitHandler = event => {
        event.preventDefault();
        const { setLoadingStatus, loginUser, history } = this.props;
        const { userIdentifier, password, rememberMe } = this.state;
        setLoadingStatus({
            loading: true,
            text: 'Signing In...'
        });
        const user = {
            userIdentifier,
            password,
            rememberMe
        };

        this.setState({thinkMeliusInvalidSubscriptionMessage: '', thinkMeliusInvalidCredentialsMessage: ''});

        const successCallback = response => {        
            const { changeTheme } = this.props;
            setLoadingStatus({loading: false});
            changeTheme(constants.themeType.dark);
        };
        const errorCallback = error => {
            setLoadingStatus({loading: false});
            if (!error.response) {
                showErrorToast(constants.notifications.NO_RESPONSE);
            } else if (error.response.status === 500) {
                const { data: { message } } = error.response;
                showErrorToast(message);
            }
            else if (error.response.status === 401) {
                const { data: { message } } = error.response;
                this.setState({thinkMeliusInvalidCredentialsMessage: message});
            } else if (error.response.status === 403) {
                const { data: { message } } = error.response;
                this.setState({thinkMeliusInvalidSubscriptionMessage: message});
            }
        };
        const from = this.props.location.state && this.props.location.state.from;
        loginUser(user, history, successCallback, errorCallback, from);
    };

    signUpHandler = () => {
        this.props.history.push(pageLinks.Register.url);
    };

    forgotHandler = () => {
        this.props.history.push(pageLinks.ForgotPassword.url);
    };

    componentDidMount() {
        const { clearErrors, changeTheme } = this.props;
        changeTheme(constants.themeType.light);
        clearErrors();
        
        // clear data
        localStorage.clear();
        setAuthToken(false);
    
        store.dispatch(clearTools());
        store.dispatch(setCurrentUser(null));
        store.dispatch(clearSymbols());
        store.dispatch(clearBrokers());
        store.dispatch(clearStrategies());
    }

    render() {
        const { classes, errorStatus } = this.props;
        const { userIdentifier, password, thinkMeliusInvalidSubscriptionMessage, thinkMeliusInvalidCredentialsMessage } = this.state;

        return (
            <div className={classes.background}>
                <Particles
                    params={param} />
                <main className={classes.main}>
                    <Paper className={classes.paper}>
                        <div className={classes.oneRow}>
                            <div className={classes.halfLine} />
                                <Logo className={classes.logo} />
                            <div className={classes.halfLine} />
                        </div>
                        <form
                            className={classes.form}
                            onSubmit={this.submitHandler}>
                            <FormControl margin='normal' required fullWidth>
                                <Typography className={classNames(classes.white, classes.opacityHalf)}>
                                    Username
                                </Typography>
                                <div style={{position: 'relative'}}>
                                    <MailOutlineIcon className={classes.iconLabel}/>
                                    <TextField
                                        id='email-thinkmeliusUsername'
                                        name='userIdentifier'
                                        placeholder='Username'
                                        type='text'
                                        variant='outlined'
                                        autoComplete='email'
                                        autoFocus
                                        className={classNames(
                                            'form-control form-control-lg',
                                            classes.textField
                                        )}
                                        onChange={this.inputChangeHandler}
                                        value={userIdentifier} />
                                </div>
                                <Typography color='error'>
                                    {errorStatus && errorStatus.userIdentifier}
                                </Typography>
                            </FormControl>
                            <FormControl margin='normal' required fullWidth>
                                <Typography className={classNames(classes.white, classes.opacityHalf)}>Password</Typography>
                                <div style={{position: 'relative'}}>
                                    <PasswordIcon className={classes.iconLabel} />
                                    <TextField
                                        id='password'
                                        name='password'
                                        type='password'
                                        variant='outlined'
                                        autoComplete='current-password'
                                        className={classNames(
                                            'form-control form-control-lg',
                                            classes.textField
                                        )}
                                        onChange={this.inputChangeHandler}
                                        value={password}
                                        />
                                </div>
                                <Typography color='error'>
                                    {errorStatus && errorStatus.password}
                                </Typography>
                            </FormControl>
                            <Grid
                                container
                                direction='row'
                                justify='space-between'
                                alignItems='center'>
                                <FormControlLabel
                                    style={{display: 'none'}}
                                    control={
                                        <Checkbox
                                            value='remember'
                                            name='rememberMe'
                                            color='primary'
                                            onChange={this.inputChangeHandler} />
                                    }
                                    label='Remember me' />
                                { /* TODO: modified by mars */ }

                                {/* <FormControlLabel
                                    control={
                                        <Link
                                            component='label'
                                            variant='body2'
                                            to={pageLinks.ForgotPassword.url}
                                            onClick={this.forgotHandler}>
                                            Forgot Password?
                                        </Link>
                                    } /> */}
                                { thinkMeliusInvalidSubscriptionMessage && (
                                    <Typography color='error'>
                                        {`${thinkMeliusInvalidSubscriptionMessage}, `}
                                        <Link
                                            href={config.shopThinkmelius}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            color='error'
                                            underline='always'>
                                            click here to upgrade
                                        </Link>
                                    </Typography>
                                ) }
                                { thinkMeliusInvalidCredentialsMessage && (
                                    <Typography color='error'>
                                        {thinkMeliusInvalidCredentialsMessage}
                                    </Typography>
                                ) }
                            </Grid>
                            <Button
                                type='submit'
                                fullWidth
                                variant='contained'
                                color='primary'
                                className={classes.submit}>
                                Sign In
                            </Button>
                            <a className={classes.forgotPassword} href={pageLinks.ForgotPassword.meliusUrl}
                                rel='noopener noreferrer'
                                target='_blank'>
                                <Typography component='h1' variant='body2' className={classes.forgotPasswordText}>Forgot Password?</Typography>
                            </a>
                            {
                            // TODO: disabled for now by mars
                            /* <Link
                                component='button'
                                variant='body2'
                                className={classes.signUp}
                                onClick={this.signUpHandler}>
                                Sign Up
                            </Link> */
                            }
                        </form>
                    </Paper>
                    <Typography className={classes.copyRight}>© CopyRight 2021 Orakle. All Rights Reserved</Typography>
                </main>
            </div>
        );
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
    loginUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    errorStatus: state.errors.errorStatus,
    loggedInUser: state.auth.user
});

const mapActionToProps = {
    loginUser,
    clearErrors,
    setLoadingStatus,
    changeTheme
};

export default connect(
    mapStateToProps,
    mapActionToProps
)(withStyles(styles)(Login));
