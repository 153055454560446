
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyIcon from '@material-ui/icons/FileCopy';
import OutlinedButton from '../OutlinedButton';

const styles = theme => {
    return {
        copyIcon: {
			marginLeft: 4
		}
    };
};

const CopyButton = ({ value, label='Copy', passedClasses, icon, classes }) => {
	const copyButtonClickHandler = event => {
		event.stopPropagation();
	}

	return (
		<CopyToClipboard text={value}>
			<OutlinedButton
				passedClasses={passedClasses}
				buttonType='small' 
				variant='contained'
				onClick={copyButtonClickHandler} >
				{label}
				{icon && <CopyIcon className={classes.copyIcon} />}
			</OutlinedButton>
		</CopyToClipboard>
	)
};

export default withStyles(styles, {withTheme: true})(CopyButton);