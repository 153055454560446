import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/DeleteOutline';

import { indicatorNameList } from '../../../../utils/indicator';

const styles = theme => {
  return {
    selectMenu: {
      backgroundColor: theme.palette.mainBackColor
    },
    indicatorSelect: {
      width: 200,
      marginRight: 8,
      [theme.breakpoints.down('xs')]: {
        width: 100
      },
    },
    addButton: {
      padding: 4,
      marginLeft: 16,
      marginBottom: 8,
      border: `1px solid ${
        theme.palette.type === 'dark'
          ? theme.palette.subForeColor2
          : theme.palette.mainForeColor
        }`,
      '&:hover': {
        backgroundColor:
          theme.palette.type === 'dark'
            ? theme.palette.mainBackColor
            : theme.palette.mainForeColor,
        color: 'white'
      },
      background: 'transparent',
      color: theme.palette.mainForeColor
    }
  };
};

class IndicatorRow extends Component {
  constructor(props) {
    super(props);
    this._paramRef = React.createRef();
    this._overlayRef = React.createRef();
  }

  onAddHandler = () => {
    const { addIndicatorHandler } = this.props;
    addIndicatorHandler();
    this._paramRef.current.focus();
  };

  render() {
    const {
      classes,
      plus,
      name,
      param,
      overlay,
      removeIndicatorHandler
    } = this.props;

    return (
      <Grid
        style={{ display: 700 }}
        container
        direction='row'
        alignItems='flex-end'>
        <TextField
          className={classes.indicatorSelect}
          select
          label='Indicator'
          value={name}
          onChange={this.props.onIndexChange}
          SelectProps={{
            MenuProps: {
              classes: { paper: classes.selectMenu }
            }
          }}
          margin='normal'
          fullWidth>
          {indicatorNameList.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          inputRef={this._paramRef}
          style={{ flex: 3 }}
          margin='normal'
          label='Parameters'
          type='text'
          value={param}
          onChange={this.props.onParamChange} />
        <TextField
          style={{ flex: 1 }}
          className={classes.indicatorSelect}
          margin='normal'
          select
          label='Overlay'
          value={overlay}
          onChange={this.props.onOverlayChange}
          SelectProps={{
            MenuProps: {
              classes: { paper: classes.selectMenu }
            }
          }}
          fullWidth>
          <MenuItem key="true" value={true}>Yes</MenuItem>         
          <MenuItem key="false" value={false}>No</MenuItem>
        </TextField>
        {plus ? (
          <IconButton
            className={classes.addButton}
            onClick={this.onAddHandler}
            color='primary'>
            <AddIcon />
          </IconButton>
        ) : (
            <IconButton
              className={classes.addButton}
              onClick={removeIndicatorHandler}
              color='primary'>
              <RemoveIcon />
            </IconButton>
          )}
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(IndicatorRow);
