
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import CustomMUIDataTable from '../../CustomMUIDataTable';
import OutlinedButton from '../../OutlinedButton';
import { isBuyByTitle, convertToToolStratTableSignals } from '../../../utils/utility';
import { colorLinks, commonMUITableOptions } from '../../../utils/styles';

const styles = theme => {
    return {
		highlight: {
			color: theme.palette.highlightColor
		}
	}
};

const SignalsTable = ({ classes, signals, selectedSymbolId, signalSelected, title, closeSignalsTableDialog }) => {
	const options = {
        ...commonMUITableOptions
	};

	const selectSignalHandler = signalId => {
		const targetIndex = signals.findIndex(signal => signal.id === signalId);
		if (targetIndex >= 0) {
			signalSelected(signals[targetIndex], true);
			closeSignalsTableDialog();
		}
    };
	
	const hasSelectedSymbol = (signalId, symbolId) => {
		const signal = signals.find(signal => signalId === signal.id);
		if (signal && signal.symbolId === symbolId) {
			return true;
		}
		return false;
	};

	const columnOption = filter => {
		return {
			filter,
			customBodyRender: (value, tableMeta) => {
				// TODO: * hardcoded 7 means signal id
				const isSelectedSymbolRow = hasSelectedSymbol(tableMeta.rowData[7], selectedSymbolId);
				return (
					<div className={isSelectedSymbolRow ? classes.highlight : null}>
						{value}
					</div>
				);
			}
		}
	};

	const columns = () => ([
        {
            name: 'Time',
			options: columnOption(false)
        },
        {
            name: 'Symbol',
            options: columnOption(false)
        },
        {
            name: 'Type',
            options: {
                filter: true,
                customBodyRender: value => {
                    return (
                        <div style={{color: isBuyByTitle(value) ? colorLinks.buy : colorLinks.sell}}>
                            {value}
                        </div>
                    );
                }
            }
        },
        // {
        //     name: 'OpenClose',
        //     options: columnOption(true)
        // },
        {
            name: 'Open Price',
            options: columnOption(false)
		},
        {
            name: 'TP',
            options: columnOption(false)
        },
        {
            name: 'SL',
            options: columnOption(false)
        },
        {
            name: 'Close Price',
            options: columnOption(false)
        },
        {
            name: 'id',
            options: {
                display: false,
                filter: false
            }
        },
        {
            name: 'Status',
            options: columnOption(true)
        },
        {
            name: 'Action',
            options: {
				filter: false,
				sort: false,
                customBodyRender: (value, tableMeta) => {
                    return (
						<OutlinedButton
							variant='contained'
							// TODO: * hardcoded 7 means signal id
							onClick={() => selectSignalHandler(tableMeta.rowData[7])}
							buttonType='small'>
                            Select
						</OutlinedButton>
					);
                }
            }
        }
    ]);

	const tableData = convertToToolStratTableSignals(signals);

	return (
		<CustomMUIDataTable
			title={title}
			data={tableData}
			columns={columns()}
			options={options} />
	);
};

SignalsTable.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

export default withStyles(styles, {withTheme: true})(SignalsTable);
