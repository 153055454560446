import * as configService from '../services/config';
import {SET_CONFIG} from './types';


export const getConfig = () => async (dispatch) => {
  const config = await configService.getConfig();
  dispatch({
    type: SET_CONFIG,
    payload: config,
  });
};
