import io from "socket.io-client";

import config from "../config";
import constants from "../constants";
import { pageLinks } from "../utils/links";
import { getAPIToken } from "../utils/utility";
import setAuthToken from "../services/security/setAuthToken";
import store from "../store";
import { setBrokers, clearBrokers } from "../actions/broker";
import { setSymbols, clearSymbols } from "../actions/symbol";
import { setChartData } from "../actions/chart";
import { setCurrentUser } from "../actions/auth";
import { changeTheme } from "../actions/theme";
import {
  // addNewTick,
  addTickData,
} from "../components/TVChartContainer/api/stream";
import { setNotifications } from "../actions/notification";
import {
  setChainedStrategies,
  setStratAllSignals,
  setServerTime,
  setNewSignal,
  clearTools,
  setTickData,
} from "../actions/tools";
import { setStrategies, clearStrategies } from "../actions/strategy";

const sendSubscribeEvent = (symbolId) => {
  socket.emit(
    constants.socketEvent.SUBSCRIBE_EVENT,
    JSON.stringify({ symbolId: symbolId })
  );
};

const sendUnsubscribeEvent = () => {
  socket.emit(constants.socketEvent.UNSUBSCRIBE_EVENT);
};

const sendAuthenticateEvent = (token) => {
  socket.emit(
    constants.socketEvent.AUTHENTICATE_EVENT,
    JSON.stringify({ apiToken: token })
  );
};

let socket = null;
socket = io(config.socketUrl);

const apiToken = getAPIToken();
if (apiToken) {
  sendAuthenticateEvent(apiToken);
}

socket.on(constants.socketEvent.RELOGIN_EVENT, () => {
  const token = getAPIToken();
  sendAuthenticateEvent(token);
});

socket.on(constants.socketEvent.AUTH_RESPONSE_EVENT, (data) => {
  if (!data.success) {
    logout();
    return;
  }

  const { selectedToolKey } = store.getState().tools;
  const selectedToolSymbolId = store.getState().tools.selectedToolSymbolId[selectedToolKey];
  if (selectedToolSymbolId) {
    sendSubscribeEvent(selectedToolSymbolId);
  }

  socket.on(constants.socketEvent.SEND_TICK_EVENT, (data) => {
    const tickData = addTickData(data);

    if (tickData == null) {
      return;
    }
    try {
      const {
        selectedToolKey,
        selectedToolSymbolId
      } = store.getState().tools;
      const { symbolId } = JSON.parse(data);

      if (selectedToolSymbolId[selectedToolKey] === symbolId) {
        //addNewTick(tickData);
        store.dispatch(setTickData(data));
      }
    } catch (error) {
      console.log("ant : [socket-api/SendTickEvent] error => ", error);
    }
  });

  socket.on(constants.socketEvent.SEND_SIGNAL_EVENT, (data) => {
    const ev = new CustomEvent('realtime_update', {
      detail: {
        type: constants.socketEvent.SEND_SIGNAL_EVENT,
      }
    });
    window.dispatchEvent(ev);
    store.dispatch(setNewSignal(JSON.parse(data)));
  });

  socket.on(constants.socketEvent.UPDATE_BROKERS_EVENT, async () => {
    store.dispatch(setBrokers(true));
  });

  socket.on(constants.socketEvent.UPDATE_SYMBOLS_EVENT, async () => {
    store.dispatch(setSymbols(true));
  });

  socket.on(constants.socketEvent.UPDATE_STRATEGIES_EVENT, async () => {
    // TODO: * merge them to one for easy maintaining
    await store.dispatch(setStrategies(true));
    await store.dispatch(setChainedStrategies());
  });

  socket.on(constants.socketEvent.UPDATE_SIGNAL_SIGNALS_EVENT, () => {
    try {
      const {
        selectedToolKey,
        selectedToolStrategy,
        selectedToolSymbolId,
      } = store.getState().tools;

      store.dispatch(
        setStratAllSignals(
          selectedToolStrategy[selectedToolKey],
          selectedToolSymbolId[selectedToolKey]
        )
      );
      const ev = new CustomEvent('realtime_update', {
        detail: {
          type: constants.socketEvent.UPDATE_SIGNAL_SIGNALS_EVENT,
        }
      });
      window.dispatchEvent(ev);
    } catch (error) {
      console.log(
        "ant : [socket-api/UPDATE_SIGNAL_SIGNALS_EVENT] error => ",
        error
      );
    }
  });

  socket.on(constants.socketEvent.UPDATE_CHART, () => {
    const {
      selectedToolIndex,
      selectedToolStrategy,
      selectedToolSymbolId,
    } = store.getState().tools;

    store.dispatch(
      setChartData(selectedToolStrategy, selectedToolSymbolId, selectedToolIndex),
    );
  });

  socket.on(constants.socketEvent.LOGOUT_EVENT, (data) => {
    const token = getAPIToken();
    if (token === "" || token === data.token) {
      return;
    }
    logout();
  });

  socket.on(constants.socketEvent.RECV_SERVERTIME_EVENT, (timeData) => {
    if (timeData) {
      const serverTime = JSON.parse(timeData);
      store.dispatch(setServerTime(serverTime.time));
    }
  });

  socket.on(constants.socketEvent.UPDATE_NOTIFICATIONS_EVENT, async () => {
    store.dispatch(setNotifications());
  });

  socket.on(constants.socketEvent.NEW_HARMONIC_PATTERN_EVENT, harmonicPattern => {
    const ev = new CustomEvent('realtime_update', {
      detail: {
        type: constants.socketEvent.NEW_HARMONIC_PATTERN_EVENT,
        data: harmonicPattern,
      },
    });
    window.dispatchEvent(ev);
  });
  socket.on(constants.socketEvent.UPDATE_LINE_TOOLS_EVENT, data => {
    try {
      const parsed = JSON.parse(data);
      const ev = new CustomEvent('realtime_update', {
        detail: {
          type: constants.socketEvent.UPDATE_LINE_TOOLS_EVENT,
          addonId: parsed.addonId,
          lineTools: parsed.lineTools,
        },
      });
      window.dispatchEvent(ev);
    } catch (e) {
      console.warn('Error updating line tools');
    }
  });
});

// TODO: * everything should be initialized including localstorage -> we could initialize the whole redux state not individually
const logout = () => {
  localStorage.clear();
  setAuthToken(false);

  store.dispatch(clearTools());
  store.dispatch(setCurrentUser(null));
  store.dispatch(changeTheme(constants.themeType.light));
  store.dispatch(clearSymbols());
  store.dispatch(clearBrokers());
  store.dispatch(clearStrategies());
  window.location.href = window.location.origin + pageLinks.Login.url;
};

export { sendSubscribeEvent, sendUnsubscribeEvent, sendAuthenticateEvent };
