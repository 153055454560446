import { SET_STRATEGIES } from "./types";
import { getStrategies } from "../services/strategy";
import { showErrorToast } from "../utils/utility";
import constants from "../constants";
import { setSymbols } from "./symbol";
import { setBrokers } from "./broker";

const addFieldsToStrategies = async (strategies, symbols, brokers) => {
  const fieldsAddedStrategies = strategies.map((strategy) => {
    const symbol = symbols.find(
      (symbol) => symbol._id === strategy.defaultSymbolId
    );
    const broker = brokers.find((broker) => broker._id === strategy.brokerId);

    const updatedStrategy = {
      ...strategy,
      symbol,
      broker,
      userName: "",
    };
    return updatedStrategy;
  });
  return fieldsAddedStrategies;
};

export const setStrategies = (refresh) => async (dispatch, getState) => {
  let fieldsAddedStrategies;
  try {
    const { strategy } = getState();
    const { strategies } = strategy; // TODO: * convention broken -> data
    const brokers = await dispatch(setBrokers());
    const symbols = await dispatch(setSymbols());
    if (refresh || strategies.length === 0) {
      const { data: strategies } = await getStrategies();
      fieldsAddedStrategies = await addFieldsToStrategies(
        strategies,
        symbols,
        brokers
      );
      dispatch({
        type: SET_STRATEGIES,
        payload: fieldsAddedStrategies,
      });
    }
  } catch (error) {
    console.log("ray : [actions strategy setStrategies] error => ", error);
    showErrorToast(constants.notifications.FAIL_CONNECT);
  }
  return fieldsAddedStrategies;
};

export const clearStrategies = () => (dispatch) => {
  dispatch({
    type: SET_STRATEGIES,
    payload: [],
  });
};
