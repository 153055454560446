import {
  SET_SUBSCRIPTIONS,
  ADD_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  REMOVE_SUBSCRIPTION,
} from '../actions/types';

const initialState = {
  subscriptions: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.payload,
      };
    case ADD_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: [...state.subscriptions, action.payload],
      };
    case UPDATE_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: state.subscriptions.map(sub => {
          if (sub._id === action.payload._id) {
            return action.payload;
          }
          return sub;
        })
      }
    case REMOVE_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: state.subscriptions.filter(sub => sub._id !== action.payload._id)
      }
    default:
      return state;
  }
}
