import {
  SET_SELECTED_STRATEGY,
  SET_SELECTED_SYMBOL_ID,
  UPDATE_SIGNALS,
  SET_STRATEGY_SYMBOLS,
  CLEAR_TOOLS,
  INITIALIZE_SIGNALS,
  SET_CHART_DETAILED_DATA,
  SET_CHART_INFO_DATA,

  SET_STRATEGY_TYPE,
  SET_SERVER_TIME,
  SET_NEW_SIGNAL,
  SET_TICK_DATA,
  SET_UPDATE_SIGNAL,
  SET_REMOVE_SIGNAL,
  SET_REMOVE_SIGNALS,

} from "./types";
import constants from "../constants";
import { getSignalsByStrategyId } from "../services/signal";
import {
  convertToToolStratSignal,
  convertToToolStratTableSignal,
  calcPips,
  getToolKey
} from "../utils/utility";

export const setNewSignal = newSignal => dispatch => {
  dispatch({
    type: SET_NEW_SIGNAL,
    payload: newSignal
  });
};

export const updateSignal = (signal, toolIndex) => dispatch => {
  dispatch({
    type: SET_UPDATE_SIGNAL,
    payload: {
      toolIndex,
      signal
    },
  });
};

export const removeStratSignal = (signalId, toolIndex) => (
  dispatch
) => {
  dispatch({
    type: SET_REMOVE_SIGNAL,
    payload: {
      toolIndex,
      signalId
    },
  });
};

export const removeStratSignals = (signalIds, toolIndex) => (
  dispatch
) => {
  dispatch({
    type: SET_REMOVE_SIGNALS,
    payload: {
      toolIndex,
      signalIds
    },
  });
};

export const setSelectedStrategy = (toolIndex, strategy) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_STRATEGY,
    payload: { toolIndex, strategy }
  });
};

export const setSelectedSymbolId = (toolIndex, selectedSymbolId) => (
  dispatch
) => {
  dispatch({
    type: SET_SELECTED_SYMBOL_ID,
    payload: { toolIndex, selectedSymbolId },
  });
};

export const setStrategyType = toolIndex => dispatch => {
  dispatch({
    type: SET_STRATEGY_TYPE,
    payload: toolIndex,
  });
};

export const initializeSignals = () => (dispatch) => {
  dispatch({
    type: INITIALIZE_SIGNALS,
  });
};

export const clearTools = () => (dispatch) => {
  dispatch({
    type: CLEAR_TOOLS,
  });
};

export const updateStratSignalData = (toolIndex, updatedData) => (
  dispatch
) => {
  dispatch({
    type: UPDATE_SIGNALS,
    payload: { toolIndex, updatedData },
  });
};

export const setChartDetailedData = (toolIndex, chartData) => (dispatch) => {
  dispatch({
    type: SET_CHART_DETAILED_DATA,
    payload: { toolIndex, chartData },
  });
};

export const setChartsInfoData = (toolIndex, chartsInfoData) => (
  dispatch
) => {
  dispatch({
    type: SET_CHART_INFO_DATA,
    payload: { toolIndex, chartsInfoData },
  });
};

export const setStrategySymbols = (toolIndex, strategySymbols) => (
  dispatch
) => {
  dispatch({
    type: SET_STRATEGY_SYMBOLS,
    payload: { toolIndex, strategySymbols },
  });
};

export const setTickData = tickData => (dispatch) => {
  dispatch({
    type: SET_TICK_DATA,
    payload: tickData,
  });
};

export const setChainedStrategies = (selectedStrategy, toolIndex) => async (
  dispatch,
  getState
) => {
  if (selectedStrategy) {
    return;
  }

  try {
    const { tools, strategy } = getState();
    const { strategies } = strategy; // TODO: * convention broken -> data
    const {
      selectedToolStrategy,
      selectedToolSymbolId,
    } = tools;

    const key = getToolKey(toolIndex);

    dispatch(
      setStrategyRelatedData(
        strategies,
        selectedToolStrategy[key],
        selectedToolSymbolId[key],
        toolIndex
      )
    );
  } catch (error) {
    console.log(
      "ray : [actions tools setChainedStrategies] error => ",
      error
    );
  }
};

const setStrategyRelatedData = (
  strategies,
  selectedToolStrategy,
  selectedToolSymbolId,
  toolIndex
) => (dispatch) => {
  let newSelectedStrategy = null;
  let newSelectedSymbolId = null;

  // in case frontend selectedStrategy might be changed so update with one from backend
  if (selectedToolStrategy) {
    newSelectedStrategy = strategies.find(
      (strategy) => strategy._id === selectedToolStrategy._id
    );
  }

  // selected strategy is set
  if (newSelectedStrategy) {
    newSelectedSymbolId =
      selectedToolSymbolId || newSelectedStrategy.symbol._id;
    // selected strategy is not set
  } else {
    newSelectedStrategy = strategies.find(
      (strategy) => strategy.type === toolIndex
    );

    newSelectedSymbolId =
      newSelectedStrategy &&
      newSelectedStrategy.symbol &&
      newSelectedStrategy.symbol._id;
  }

  dispatch(
    setSelectedStrategy(
      toolIndex,
      newSelectedStrategy
    )
  );

  dispatch(setStratAllSignals(newSelectedStrategy, newSelectedSymbolId));
};

export const setStratAllSignals = (
  newSelectedStrategy,
  newSelectedSymbolId,
  archiveWeeks,
  sltponly
) => async (dispatch, getState) => {
  if (!newSelectedStrategy) {
    return;
  }
  const { symbol } = getState();
  const symbols = [...symbol.data];
  const strategySymbols = symbols
    .filter(symbol => newSelectedStrategy.symbolIds.includes(symbol._id))
    .map(symbol => ({
      label: symbol.name,
      value: symbol._id,
      signalKinds: {},
    }));

  try {
    const { data: allSignals = [] } = await getSignalsByStrategyId(
      newSelectedStrategy._id,
      archiveWeeks,
      sltponly
    );
    await dispatch(analyseAllSignals(newSelectedStrategy._id, allSignals));

    let selectedSymbolId = newSelectedSymbolId;
    if (allSignals && allSignals.length > 0) {
      selectedSymbolId = allSignals[0].symbolId;
    }

    dispatch(
      setSelectedSymbolId(
        newSelectedStrategy.type,
        selectedSymbolId
      )
    );

    for (const signal of allSignals) {
      if (signal.openClose) {
        const symbol = symbols.find((symbol) => symbol._id === signal.symbolId);
        if (symbol) {
          for (const strategySymbol of strategySymbols) {
            if (strategySymbol.value === symbol._id) {
              const signalType = constants.signalTypeList
                .find((item) => item.value === signal.type)
                .label.includes("BUY")
                ? "BUY"
                : "SELL";
              strategySymbol.signalKinds[signalType] = true;
              // const signalKindItem = constants.signalKindList.find(signalKindItem => signalKindItem.value === signal.signalKind);
              // if (signalKindItem) {
              //     strategySymbol.signalKinds[signalKindItem.mobileLabel] = true;
              // }
            }
          }
        }
      }
    }
  } catch (error) {
    console.log("ray : [actions tools setStratAllSignals] error => ", error);
    return;
  }

  dispatch(
    setStrategySymbols(newSelectedStrategy.type, strategySymbols)
  );
};

const analyseAllSignals = (strategyId, signals) => async (
  dispatch,
  getState
) => {
  let lastSignals = [];
  const { symbol } = getState();
  const symbols = [...symbol.data];
  const {
    strategy: { strategies },
  } = getState();
  const strategy = strategies.find((strategy) => strategy._id === strategyId);

  if (strategy) {
    const key = getToolKey(strategy.type);

    lastSignals = getState().tools.strategySignals[key] || [];

    let newSignals = [];
    let signalsTableData = [];
    const updatedSignals = signals.map((signal) => {
      const symbol = symbols.find((symbol) => symbol._id === signal.symbolId);
      signal = {
        ...signal,
        symbol: symbol && symbol.name,
      };
      if (signal.pips === null) {
        signal = {
          ...signal,
          pips: calcPips(signal, symbol.tick, symbol.digits),
        };
      }
      const newSignal = convertToToolStratSignal(signal);
      const newDetailedSignal = convertToToolStratTableSignal(signal);
      signalsTableData = [...signalsTableData, newDetailedSignal];

      // prepare new signals for displaying on chart
      let isNewSignal = false;

      for (let i = 0; i < lastSignals.length; i++) {
        if (lastSignals[i].id === newSignal.id &&
          lastSignals[i].openClose === newSignal.openClose
          && lastSignals[i].sl === newSignal.sl
          && lastSignals[i].tp === newSignal.tp) {
          isNewSignal = true;
          break;
        }
      }
      if (!isNewSignal) {
        newSignals = [...newSignals, newSignal];
      }
      return newSignal;
    });
    const updatedSignalsData = {
      newSignals: newSignals,
      signals: updatedSignals,
      signalsTableData: signalsTableData,
    };
    dispatch(
      updateStratSignalData(strategy.type, updatedSignalsData)
    );
  }
};

export const setServerTime = (serverTime) => (dispatch) => {
  dispatch({
    type: SET_SERVER_TIME,
    payload: serverTime,
  });
};
