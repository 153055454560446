import React, {useState} from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import {Popover} from "@material-ui/core";
import ContainedButton from "../../../components/ContainedButton";
import {ShowChart} from "@material-ui/icons";
import EngulfingAndOPList from "../../../components/EngulfingOrOPList";
import constants from '../../../constants';

const styles = () => {
  return {
    container: {
      marginLeft: '10px',
    },
    selectedContainer: {
      marginLeft: '10px',
      backgroundColor: 'rgb(33, 150, 243)',
    },
  };
};

const AddonIndication = (props) => {
  const {
    classes,
    addon,
    filters,
    onFiltersChange,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const engulfingAndOPAvailable = addon.indicators.includes('engulfing_and_op');

  if (!engulfingAndOPAvailable) {
    return null;
  }

  return (
    <>
      <ContainedButton
        onClick={handleClick}
        customClasses={anchorEl !== null ? classes.selectedContainer : classes.container}
      >
        <ShowChart />
        <span>&nbsp;Indication</span>
      </ContainedButton>
      <Popover
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={20}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {
          engulfingAndOPAvailable && (
            <EngulfingAndOPList
              filters={filters.engulfingAndOP}
              availableFilters={
                addon.engulfingAndOPConfig ?
                  addon.engulfingAndOPConfig.available
                  :
                  constants.addonEngulfingAndOPConfig.available
              }
              onChange={filters => onFiltersChange('engulfingAndOP', filters)}
              premiumLockedFilters={props.premiumLockedFilters}
            />
          )
        }
      </Popover>
    </>
  )
}

export default withStyles(styles, { withTheme: true })(AddonIndication);
