
import React, { PureComponent } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import OutlinedButton from '../../../../components/OutlinedButton';
import CustomTextButton from '../../../../components/CustomTextButton';
import DialogWrapper from '../../../../hoc/DialogWrapper';
import DialogActionsWrapper from '../../../../hoc/DialogActionsWrapper';
import { getAddEditDialogMetadata, showErrorToast } from '../../../../utils/utility';
import * as VIDEO_SERVICE from '../../../../services/video';

class AddEditDialog extends PureComponent {
    state = {
        title: '',
        topic: '',
        link: '',
        description: '',
        submitButtonDisabled: false,
    };

    componentDidMount() {
        const { isAddDialog, dialogData } = this.props;
        // edit
        if (!isAddDialog) {
            const { title, topic, link, description } = dialogData;
            this.setState({title, topic, link, description});
        }
    }

    inputChangeHandler = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    onCloseHandler = () => {
        const { closed } = this.props;
        closed && closed();
    };

    submitHandler = async () => {
        const { title, topic, link, description } = this.state;
        const { isAddDialog, dialogData, addEditVideo } = this.props;
        const video = {
            _id: !isAddDialog ? dialogData._id : null,
            title, 
            topic, 
            link,
            description
        };
        try {
            this.setState({submitButtonDisabled: true});
            let response;
            if (isAddDialog) {
                response = await VIDEO_SERVICE.addVideo(video);
            } else {
                response = await VIDEO_SERVICE.editVideo(video);
            }
            const { data: videoFromBackend } = response;
            addEditVideo(videoFromBackend);
        } catch (error) {
            // TODO: * axios handling module
            console.log('vlad : [containers Admin VideoManage AddEditDialog submitHandler] error => ', error);
            if (error.response) {
                const { message: errorMessage } = error.response.data;
                showErrorToast(errorMessage);
            }
        }
        this.onCloseHandler();
    };

    triggerSubmitHandler = () => {
        this.form.isFormValid(false).then((isValid) => {
            if (isValid) {
                this.submitHandler();
            }
        });
    };

    render() {
        const { opened, isAddDialog, manageTitle } = this.props;
        const { title, topic, link, description, submitButtonDisabled } = this.state;
        const { dialogTitle, dialogDescription, submitButtonName, cancelButtonName } = getAddEditDialogMetadata(isAddDialog, manageTitle);
        
        return (
            <DialogWrapper 
                opened={opened} 
                dialogTitle={dialogTitle} 
                dialogDescription={dialogDescription} 
                onClose={this.onCloseHandler}
                dialogActions={
                    <DialogActionsWrapper>
                        <CustomTextButton onClick={this.onCloseHandler}>
                            {cancelButtonName}
                        </CustomTextButton>
                        <OutlinedButton type='button' disabled={submitButtonDisabled} onClick={this.triggerSubmitHandler}>
                            {submitButtonName}
                        </OutlinedButton>
                    </DialogActionsWrapper>
                }>
                <ValidatorForm
                    ref={(ref) => { this.form = ref; }}
                    onSubmit={this.submitHandler}
                    onError={errors => console.log(errors)}>
                    <TextValidator
                        fullWidth
                        name='title'
                        label='Title'
                        value={title}
                        margin='normal'
                        onChange={this.inputChangeHandler}
                        validators={['required']}
                        errorMessages={['Title cannot be empty']} />
                    <TextValidator
                        fullWidth
                        name='link'
                        label='Link'
                        value={link}
                        margin='normal'
                        onChange={this.inputChangeHandler}
                        validators={['required']}
                        errorMessages={['Link cannot be empty']} />
                    <TextValidator
                        fullWidth
                        name='topic'
                        label='Topic'
                        value={topic}
                        margin='normal'
                        onChange={this.inputChangeHandler}
                        validators={['required']}
                        errorMessages={['Topic cannot be empty']} />
                    <TextValidator
                        fullWidth
                        name='description'
                        label='Description'
                        value={description}
                        margin='normal'
                        onChange={this.inputChangeHandler} />
                    <DialogActionsWrapper />
                </ValidatorForm>
            </DialogWrapper>
        );
    }
}

export default AddEditDialog;
