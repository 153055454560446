import { SET_CURRENT_THEME, SET_CURRENT_SIDEBAR } from "../actions/types";
import constants from "../constants";

const initialState = {
  themeType: constants.themeType.light,
  isSideDrawer: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_THEME:
      return {
        ...state,
        themeType: action.payload,
      };
    case SET_CURRENT_SIDEBAR:
      return {
        ...state,
        isSideDrawer: action.payload,
      };
    default:
      return state;
  }
}
