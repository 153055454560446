import { Component } from "react";
import { connect } from "react-redux";

import { logoutUser } from "../../../actions/auth";
import { changeTheme } from "../../../actions/theme";
import { clearTools } from "../../../actions/tools";
import { clearSymbols } from "../../../actions/symbol";
import { clearBrokers } from "../../../actions/broker";
import { clearStrategies } from "../../../actions/strategy";

class Logout extends Component {
  componentDidMount() {
    const {
      logoutUser,
      changeTheme,
      clearTools,
      clearSymbols,
      clearBrokers,
      clearStrategies,
      history,
    } = this.props;

    // TODO: * two cases of logout should be combined not individual logics since it would be easier to manage
    clearTools();
    logoutUser(history);
    changeTheme("light");
    clearSymbols();
    clearBrokers();
    clearStrategies();
  }

  render() {
    return null;
  }
}

const mapActionToProps = {
  logoutUser: logoutUser,
  changeTheme,
  clearTools,
  clearSymbols,
  clearBrokers,
  clearStrategies,
};

export default connect(null, mapActionToProps)(Logout);
