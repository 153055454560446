
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import withWidth from '@material-ui/core/withWidth';
import MenuIcon from '@material-ui/icons/Menu';
import PerfectScrollbar from 'react-perfect-scrollbar'
import LanguageIcon from '@material-ui/icons/Language';
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Link } from 'react-router-dom';
import ExitIcon from '@material-ui/icons/ExitToApp';

import Logo from '../../Logo';
import i18n from '../../../utils/i18n';
import { getLanguageFromI18n } from '../../../utils/languages';
import LanguageSelector from '../../LanguageSelector';
import { pageLinks } from '../../../utils/links';
import { withTranslation } from 'react-i18next';
import config from '../../../config';
import { getFontUnit, validatePageAccessByRole } from '../../../utils/utility';
import { sidebarItems, defaultAvatarLink } from '../../../utils/links';
import { getSpacingUnit } from '../../../utils/utility';
import { changeSideBar } from '../../../actions/theme';
import { isValidDarkTheme, isWidthWiderThan, boxShadow } from '../../../utils/styles';

const styles = theme => {
  const fontSizeUnit = getFontUnit(theme);
  const spacingUnit = getSpacingUnit(theme);
  return {
    drawer: {
      width: config.drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: config.drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      backgroundColor: theme.palette.subNavBackColor,
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      },
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      backgroundColor: theme.palette.subNavBackColor,
      overflow: 'hidden',
      width: theme.spacing.unit * 7 + 1,
      [theme.breakpoints.down('md')]: {
        display: 'none'
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }    // remove the sidebar when run mobile device
    },
    textTitle: {
      paddingLeft: theme.spacing.unit,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    listContainer: {
      boxShadow: boxShadow
    },
    userName: {
      paddingLeft: '4px',
      fontSize: fontSizeUnit * 1.6,
      color: theme.palette.highlightColor
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '0 8px',
      ...theme.mixins.toolbar
    },
    sidebarItemIcon: {
      marginRight: 0,
      color: theme.palette.mainForeColor
    },
    sidebarItem: {
      width: 'unset'
    },
    nested: {
      paddingLeft: theme.spacing.unit * 7 + 1
    },
    noLeftPadding: {
      paddingLeft: 0
    },
    arrowIcon: {
      color: theme.palette.mainForeColor
    },
    bigAvatar: {
      width: 32,
      height: 32,
      margin: 8
    },
    bottomItem: {
      marginTop: 'auto',
      '&:hover': {
        backgroundColor: 'transparent',
        cursor: 'default'
      }
      // marginBottom: 12
    },
    bottomtextTitle: {
      fontSize: 12,
      // color: isValidDarkTheme(theme.palette.type) ? theme.palette.subForeColor5 : theme.palette.primary.main,
      // marginLeft: theme.spacing.unit * 2,
    },
    bottomtextTitleOpen: {
      // fontSize: 12,
      marginLeft: -theme.spacing.unit
    },
    bottomtextTitleClose: {
      marginLeft: theme.spacing.unit
    },
    bottomLogoOpen: {
      marginLeft: theme.spacing.unit * 2,
    },
    bottomLogoClose: {

    },
    darkLogo: {
      display: isValidDarkTheme(theme.palette.type) ? 'block' : 'none'
    },
    whiteLogo: {
      display: isValidDarkTheme(theme.palette.type) ? 'none' : 'block'
    },
    sideDrawButton: {
      // marginLeft: 'auto'
      padding: 6
    },
    menuButton: {
      // marginLeft: spacingUnit * 1.5,
      // marginRight: spacingUnit * 4.5,
      color: theme.palette.mainForeColor,
      [theme.breakpoints.down('sm')]: {
        marginRight: spacingUnit
      },
    },
    logo: {
      width: 80,
      height: 30
    },
    themeSwitchIcon: {
      width: spacingUnit * 2.5,
      height: spacingUnit * 2.5,
      margin: spacingUnit,
      color: theme.palette.mainForeColor,
      cursor: 'pointer',
    },
    hoverEffect: {
      '&:hover': {
        transform: 'scale(1.15)'
      },
      '&:active': {
        transform: 'scale(1)'
      }
    },
    signOutLabel: {
      color: theme.palette.mainForeColor,
      // marginLeft: spacingUnit,
      marginRight: spacingUnit,
      fontSize: '0.75rem',
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    noDecorationLink: {
      textDecoration: 'none'
    },
    signOut: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.mainForeColor,
      textDecoration: 'none',
      marginRight: spacingUnit * 2,
      // padding: spacingUnit
    },
    languageSelectorContainer: {
      padding: 0,
      marginRight: 8,
      marginBottom: 0,
      '& > .selected--flag--option': {
        padding: 0,
        marginLeft: -spacingUnit / 2,
        color: theme.palette.mainForeColor,
        '&:hover': {
          transform: 'scale(1.15)'
        },
        '&:active': {
          transform: 'scale(1)'
        }
      },
      '& > .selected--flag--option span img': {
        display: 'none'
      },
      '& > .selected--flag--option .country-flag span': {
        fontSize: 14
      },
      '& .flag-options .flag-option': {
        paddingBottom: spacingUnit,
        '& > span > span': {
          fontSize: 14
        }
      }
    },
    languageIcon: {
      marginLeft: 'auto',
      marginRight: 0,
      width: spacingUnit * 2.5,
      height: spacingUnit * 2.5,
      color: theme.palette.mainForeColor,
    },
    sidebarFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 12
    },
    scrollbar: {
      '& .ps__rail-y': {
        '&:hover': {
          backgroundColor: 'transparent'
        }
      },
      '& .ps__rail-x': {
        display: 'hidden'
      }
    },
    link: {
      textDecoration: 'none'
    }
  };
};

class SideDrawer extends Component {

  state = {
    language: getLanguageFromI18n()
  };

  componentDidMount() {
    const { history, setExpandingIndex } = this.props;
    const sidebarItemsValue = sidebarItems();
    for (let i = 0; i < sidebarItemsValue.length; i++) {
      const item = sidebarItemsValue[i].subItems && sidebarItemsValue[i].subItems.find(subItem => subItem.link === history.location.pathname);
      if (item) {
        setExpandingIndex(i);
        break;
      }
    }
  }

  drawCloseHandler = () => {
    const { changeSideBar } = this.props;
    changeSideBar(false);
  };

  drawOpenHandler = () => {
    const { changeSideBar, isSideDrawer } = this.props;
    changeSideBar(!isSideDrawer);
  };


  openSidebarHandler = (event, index) => {
    const { changeSideBar, navigationHandler } = this.props;
    navigationHandler(event, index);
    changeSideBar(true);
  };

  moveToLink = (link, blank = false) => {
    if (blank) {
      window.open(link, '_blank').focus();
      return;
    }
    const { history, changeSideBar, width } = this.props;
    history.push(link);
    if (!isWidthWiderThan('sm', width)) {
      changeSideBar(false);
    }
  }

  languageSelectHandler = language => {
    this.setState({ language });
    const lang = language === 'GB' ? 'en' : language.toLowerCase();
    i18n.changeLanguage(lang);
  }

  renderSubItem = (subItem, subIndex) => {
    const { classes, history } = this.props;

    return (
      <ListItem
        button
        selected={history.location.pathname === subItem.link}
        className={classes.nested}
        key={subIndex}
        onClick={() => { this.moveToLink(subItem.link) }}
      // component={itemProps => (
      //     <Link to={subItem.link} {...itemProps} />
      // )}
      >
        <ListItemText
          primary={(
            <React.Fragment>
              <Typography
                component='span'
                className={classes.textTitle}
                color='textPrimary'>
                {subItem.subTitle}
              </Typography>
            </React.Fragment>
          )}
          className={classes.noLeftPadding} />
      </ListItem>
    );
  }

  renderSidebarItem = (sidebarItem, index) => {
    const { classes, expandingIndex, isSideDrawer, loggedInUser } = this.props;
    return (sidebarItem.subItems ?
      <Fragment key={index}>
        <ListItem
          button
          onClick={event => this.openSidebarHandler(event, index)}
          className={classes.sidebarItem}>
          <ListItemIcon className={classes.sidebarItemIcon}>
            {sidebarItem.Icon}
          </ListItemIcon>
          <ListItemText
            inset
            primary={
              <Fragment>
                <Typography
                  component='span'
                  className={classes.textTitle}
                  color='textPrimary'>
                  {sidebarItem.title}
                </Typography>
              </Fragment>
            } />
          {expandingIndex === index ? (
            <ExpandLess className={classes.arrowIcon} />
          ) : (
            <ExpandMore className={classes.arrowIcon} />
          )}
        </ListItem>
        <Collapse
          in={expandingIndex === index && isSideDrawer === true}
          timeout='auto'
          unmountOnExit>
          <List component='div' disablePadding>
            {sidebarItem.subItems.map((subItem, subIndex) => {
              if (validatePageAccessByRole(subItem.roles, loggedInUser)) {
                // TODO: vlad : hide tool/ 2 sub items <
                if (index === 1 && subItem.hide) {
                  return null;
                }
                // TODO: vlad : hide tool/ 2 sub items >
                return (
                  <Fragment key={subIndex}>
                    {this.renderSubItem(subItem, subIndex)}
                  </Fragment>
                );
              } else {
                return null;
              }
            })}
          </List>
        </Collapse>
      </Fragment>
      :
      sidebarItem.hide ?
        null
        :
        // eslint-disable-next-line react/jsx-no-target-blank
        validatePageAccessByRole(sidebarItem.roles, loggedInUser) ?
            <ListItem
              button
              className={classes.sidebarItem}
              onClick={() => this.moveToLink(sidebarItem.link, sidebarItem.targetBlank)}
            >
              <ListItemIcon className={classes.sidebarItemIcon}>
                {sidebarItem.Icon}
              </ListItemIcon>
              <ListItemText
                inset
                primary={
                  <Fragment>
                    <Typography
                      component='span'
                      className={classes.textTitle}
                      color='textPrimary'>
                      {sidebarItem.title}
                    </Typography>
                  </Fragment>
                } />
            </ListItem>
          : null
    );
  };

  render() {
    const {
      classes,
      theme,
      loggedInUser,
      isSideDrawer
    } = this.props;

    const { language } = this.state;

    const scrollBarClasses = 'ps ps--active-y';

    return (
      <Drawer
        variant='permanent'
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: isSideDrawer,
          [classes.drawerClose]: !isSideDrawer,
        })}
        classes={{
          paper: classNames(classes.listContainer, {
            [classes.drawerOpen]: isSideDrawer,
            [classes.drawerClose]: !isSideDrawer
          })
        }}
        open={isSideDrawer}>
        <div className={classes.toolbar}>
          {isSideDrawer
            ?
            <Avatar
              alt={loggedInUser.name}
              src={loggedInUser.avatar || defaultAvatarLink}
              className={classes.bigAvatar} />
            :
            <IconButton
              color='inherit'
              aria-label='Open drawer'
              onClick={this.drawOpenHandler}
              className={classNames(classes.menuButton, {
                [classes.hide]: isSideDrawer
              })}>
              <MenuIcon />
            </IconButton>}
          <Typography
            component='div'
            className={classes.userName}
          //color='textPrimary'
          >
            {loggedInUser.name}
          </Typography>
          {isSideDrawer &&
            <>
              <LanguageIcon className={classes.languageIcon} />
              <LanguageSelector
                passedClass={classes.languageSelectorContainer}
                selectedLanguage={language}
                languageSelected={this.languageSelectHandler} />
            </>}
          <IconButton
            className={classes.sideDrawButton}
            onClick={this.drawCloseHandler}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon className={classes.sidebarItemIcon} />
            ) : (
              <ChevronLeftIcon className={classes.sidebarItemIcon} />
            )}
          </IconButton>
        </div>
        <Divider />
        <PerfectScrollbar className={[classes.scrollbar, scrollBarClasses].join(' ')}>
          <List style={{ minHeight: 'calc(100% - 50px)' }}>
            {sidebarItems(this.props.addons).map((sidebarItem, index) => {
              if (validatePageAccessByRole(sidebarItem.roles, loggedInUser)) {
                return (
                  <Fragment key={index}>
                    {this.renderSidebarItem(sidebarItem, index)}
                  </Fragment>
                );
              } else {
                return null;
              }
            })}
          </List>
        </PerfectScrollbar>
        {isSideDrawer &&
          <div className={classes.sidebarFooter}>
            <Link
              to={pageLinks.CloudAnalyser.url}
              className={classes.noDecorationLink}>
              <Logo className={classes.logo} />
            </Link>
            <Link
              to={pageLinks.Logout.url}
              className={classNames(classes.signOut, classes.hoverEffect)}>
              <ExitIcon className={classes.themeSwitchIcon} />
              <Typography noWrap className={classes.signOutLabel}>SIGN OUT</Typography>
            </Link>
          </div>}
      </Drawer>
    );
  }
}

SideDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  errorStatus: state.auth.errorStatus,
  loggedInUser: state.auth.user,
  isSideDrawer: state.theme.isSideDrawer,
  addons: state.addon.addons.filter(a => !a.hidden && !a.comingSoon),
});

const mapActionToProps = {
  changeSideBar
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(withWidth()(withStyles(styles, { withTheme: true })(withRouter(withTranslation()(SideDrawer)))));
