import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import Typography from '@material-ui/core/Typography';
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import { getSpacingUnit, getFontUnit, onSafariBrowser } from '../../utils/utility';
import { 
  getCardHeaderStyle, 
  getCardHeaderStatsStyle, 
  getCardHeaderIconStyle,
  getWarningCardHeaderStyle,
  getSuccessCardHeaderStyle,
  getDangerCardHeaderStyle,
  getInfoCardHeaderStyle,
  getPrimaryCardHeaderStyle,
  getRoseCardHeaderStyle
} from '../../utils/styles';

import withStyles from '@material-ui/core/styles/withStyles';
import { getCardTitleStyle, getCardCategoryStyle } from '../../utils/styles';

// core components
const styles = (theme) => {
  const spacingUnit = getSpacingUnit(theme);
  const fontSizeUnit = getFontUnit(theme);
  const cardHeaderStyle = getCardHeaderStyle(theme);
  const cardHeaderStatsStyle = getCardHeaderStatsStyle();
  const cardHeaderIconStyle = getCardHeaderIconStyle();
  const warningCardHeaderStyle = getWarningCardHeaderStyle(theme);
  const successCardHeaderStyle = getSuccessCardHeaderStyle(theme);
  const dangerCardHeaderStyle = getDangerCardHeaderStyle(theme);
  const infoCardHeaderStyle = getInfoCardHeaderStyle(theme);
  const primaryCardHeaderStyle = getPrimaryCardHeaderStyle(theme);
  const roseCardHeaderStyle = getRoseCardHeaderStyle(theme);
  const cardTitleStyle = getCardTitleStyle(theme);
  const cardCategoryStyle = getCardCategoryStyle(theme);

  return {
    cardHeader: {
      ...cardHeaderStyle
    },
    cardHeaderPlain: {
      marginLeft: "0px !important",
      marginRight: "0px !important"
    },
    cardHeaderStats: {
      ...cardHeaderStatsStyle
    },
    cardHeaderIcon: {
      ...cardHeaderIconStyle
    },
    warningCardHeader: {
      ...warningCardHeaderStyle
    },
    successCardHeader: {
      ...successCardHeaderStyle
    },
    dangerCardHeader: {
      ...dangerCardHeaderStyle
    },
    infoCardHeader: {
      ...infoCardHeaderStyle
    },
    primaryCardHeader: {
      ...primaryCardHeaderStyle
    },
    roseCardHeader: {
      ...roseCardHeaderStyle
    },
    
    root: {
      position: onSafariBrowser() ? '-webkit-sticky' : 'sticky',
      top: 0,
      zIndex: 1, // TODO: * not sure why it has effect
    },
    header: {
      paddingBottom: spacingUnit,
      paddingTop: spacingUnit,
      paddingLeft: spacingUnit * 2,
      paddingRight: spacingUnit * 2,
      background: theme.palette.subBackColor1
    },
    title: {
      fontSize: fontSizeUnit * 10,
      color: theme.palette.subForeColor5
    },
    flexBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        marginLeft: spacingUnit * 1.5,
        overflowX: 'auto',
        overflowY: 'hidden'
      }
    },
    cardTitle: {
      ...cardTitleStyle
    },
    cardCategory: {
      ...cardCategoryStyle
    },
    noneDisplay: {
      display: "none"
    },
    inlineDisplay: {
      display: "inline"
    },
    fontSizeSpecTitle: {
      fontSize: "25px"
    }
  }  
};

// const useStyles = makeStyles(styles);

const CardHeader = (props) => {
  const { classes, children, display, color, height, plain, title, subTitle, stats, action, icon, fontSize, ...rest } = props;
  const cardHeaderClasses = classNames({
    [classes.cardHeader]: true,
    [classes.flexBox]: true,
    [classes[display + "Display"]]: display,
    [classes[color + "CardHeader"]]: color,
    [classes.cardHeaderPlain]: plain,
    [classes.cardHeaderStats]: stats,
    [classes.cardHeaderIcon]: icon,
    // [classes.cardHeaderHeight]: height
  });
  // console.log(height);
  return (
    <div className={cardHeaderClasses} {...rest} style={{height}}>
      <div>
        <Typography className={classes.cardTitle} style={{fontSize}}>{title}</Typography>
        <Typography className={classes.cardCategory}>
          {subTitle}
        </Typography>
      </div>
      <div className={classes.flexBox}>{action}</div>
    </div>
    );
  }

  CardHeader.propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOf([
      "warning",
      "success",
      "danger",
      "info",
      "primary",
      "rose"
    ]),
    display: PropTypes.oneOf([
      "none",
      "inline"
    ]),
    plain: PropTypes.bool,
    stats: PropTypes.bool,
    icon: PropTypes.bool,
    height: PropTypes.string,
    fontSize: PropTypes.string,
    children: PropTypes.node
  };

export default withStyles(styles, {withTheme: true})(CardHeader);