import axios from "axios";
import urlJoin from "url-join";

import { getUsersUrl, getPageUsersUrl } from "./endpoints";

const getUsers = async () => {
  const url = getUsersUrl();
  const result = await axios.get(url);
  return result;
};

const getPageUsers = async (pageNumber, searchQuery) => {
  let url = getPageUsersUrl(pageNumber);
  if (searchQuery) {
    url = urlJoin(url, searchQuery);
  }
  const pageUsers = await axios.get(url);
  return pageUsers;
};

const editUser = async (data) => {
  const url = getUsersUrl();
  const result = await axios.put(url, data);
  return result;
};

const removeUser = async (_id) => {
  const url = getUsersUrl();
  const result = await axios.delete(url, {
    params: { _id },
  });
  return result;
};

const removeUsers = async (ids) => {
  const url = getUsersUrl();
  const result = await axios.delete(url, { data: { ids } });
  return result;
};

const addUser = async (data) => {
  const url = getUsersUrl();
  const result = await axios.post(url, data);
  return result;
};

const updateMyInfo = async (id, data) => {
  const url = urlJoin(getUsersUrl(), id);
  const result = await axios.put(url, data);
  return result;
};

const subscribeUser = async (data) => {
  const url = urlJoin(getUsersUrl(), "subscribe");
  const result = await axios.post(url, data);
  return result;
};

export {
  getUsers,
  getPageUsers,
  editUser,
  removeUser,
  removeUsers,
  addUser,
  updateMyInfo,
  subscribeUser,
};
