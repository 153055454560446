import {useEffect} from 'react';
import * as harmonicPatternService from '../../services/harmonic_pattern';


const Pattern = props => {

  useEffect(() => {
    const patternId = props.match.params.patternId;
    harmonicPatternService.getPatternAddonByOCTId(patternId).then(addon => {
      props.history.push(`/addons/${addon._id}?pattern=${patternId}`)
    }).catch(e => props.history.push('/'));
  }, []);

  return null;
};


export default Pattern;
