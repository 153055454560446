import * as addonsService from '../services/addon';
import {SET_ADDONS, SET_OCT_CONFIG} from './types';


export const getAddons = () => async (dispatch) => {
  const addons = await addonsService.getAddons();
  dispatch({
    type: SET_ADDONS,
    payload: addons,
  });
};

export const createAddon = (addonData) => async (dispatch) => {
  await addonsService.createAddon(addonData);
  const addons = await addonsService.getAddons();
  dispatch({
    type: SET_ADDONS,
    payload: addons,
  });
};

export const updateAddon = (addonData) => async (dispatch) => {
  await addonsService.updateAddon(addonData);
  const addons = await addonsService.getAddons();
  dispatch({
    type: SET_ADDONS,
    payload: addons,
  });
};

export const createAddonTgBot = ({addonId, token}) => async dispatch => {
  await addonsService.createTgBot({addonId, token});
  const addons = await addonsService.getAddons();
  dispatch({
    type: SET_ADDONS,
    payload: addons,
  });
};

export const removeAddonTgBot = ({addonId, botId}) => async dispatch => {
  await addonsService.removeTgBot({addonId, botId});
  const addons = await addonsService.getAddons();
  dispatch({
    type: SET_ADDONS,
    payload: addons,
  });
};

export const removeAddon = _id => async (dispatch) => {
  await addonsService.removeAddon(_id);
  const addons = await addonsService.getAddons();
  dispatch({
    type: SET_ADDONS,
    payload: addons,
  });
};

export const getOCTConfig = () => async (dispatch) => {
  const config = await addonsService.getOCTConfig();
  dispatch({
    type: SET_OCT_CONFIG,
    payload: config,
  });
};
