import { seperatorChar } from "../constants";

// TODO: * indicators are managed as one string not as array, separated with semicolon and ampersand -> must be refined
const indicatorParser = (indicators) => {
  if (!indicators) {
    return [];
  }
  const indicatorStrings = indicators.split(seperatorChar);
  const result = indicatorStrings.map((item) => {
    const oneIndicatorName = item.split("IndicatorName=");
    if (oneIndicatorName.length !== 2) {
      return null;
    }
    const segments = oneIndicatorName[1].split(";");
    return {
      name: segments[0],
      param: segments[1],
      overlay: segments[2] === 'true'
    };
  });
  return result;
};

const indicatorGenerator = (indicatorList) => {
  const individualList = indicatorList.map(
    (item) => `IndicatorName=${item.name};${item.param};${item.overlay}`
  );
  return individualList.join(seperatorChar);
};

const indicatorParamParser = (param) => {
  const indicatorParams = param.replace(/ /g, "").split(",");
  const result = indicatorParams.map((paramItem) => {
    return isNaN(paramItem) ? paramItem : parseFloat(paramItem);
  });
  return result;
};

const indicatorNameList = [
  "Accumulation/Distribution",
  "Accumulative Swing Index",
  "Advance/Decline",
  "Arnaud Legoux Moving Average",
  "Aroon",
  "Average Directional Index",
  "Average True Range",
  "Awesome Oscillator",
  "Balance of Power",
  "Bollinger Bands %B",
  "Bollinger Bands Width",
  "Bollinger Bands",
  "Chaikin Money Flow",
  "Chaikin Oscillator",
  "Chande Kroll Stop",
  "Chande Momentum Oscillator",
  "Chop Zone",
  "Choppiness Index",
  "Commodity Channel Index",
  "Connors RSI",
  "Coppock Curve",
  "CRSI",
  "Detrended Price Oscillator",
  "Directional Movement",
  "Donchian Channels",
  "Double EMA",
  "Ease Of Movement",
  "Elder's Force Index",
  "EMA Cross",
  "Envelope",
  "Fisher Transform",
  "Historical Volatility",
  "Hull Moving Average",
  "Ichimoku Cloud",
  "Keltner Channels",
  "Klinger Oscillator",
  "Know Sure Thing",
  "Least Squares Moving Average",
  "Linear Regression Curve",
  "MA Cross",
  "MA with EMA Cross",
  "MA/EMA Cross",
  "MACD",
  "Mass Index",
  "McGinley Dynamic",
  "Momentum",
  "Money Flow",
  "Moving Average Channel",
  "Moving Average Exponential",
  "Moving Average Weighted",
  "Moving Average",
  "Net Volume",
  "On Balance Volume",
  "Parabolic SAR",
  "Pivot Points Standard",
  "Price Channel",
  "Price Oscillator",
  "Price Volume Trend",
  "Rate Of Change",
  "Relative Strength Index",
  "Relative Vigor Index",
  "Relative Volatility Index",
  "Session Volume",
  "SMI Ergodic Indicator/Oscillator",
  "Smoothed Moving Average",
  "Stochastic RSI",
  "Stochastic",
  "Triple EMA",
  "TRIX",
  "True Strength Indicator",
  "Ultimate Oscillator",
  "Volume Oscillator",
  "Volume",
  "Vortex Indicator",
  "VWAP",
  "VWMA",
  "Williams %R",
  "Williams Alligator",
  "Williams Fractal",
  "Zig Zag",
];

const indicatorWithDefaultParam = {
  "Accumulation/Distribution": "",
  "Accumulative Swing Index": "10",
  "Advance/Decline": "10",
  "Arnaud Legoux Moving Average": "9, 0.85, 6",
  Aroon: "14",
  "Average Directional Index": "14, 14",
  "Average True Range": "14",
  "Awesome Oscillator": "",
  "Balance of Power": "",
  "Bollinger Bands %B": "20, 2",
  "Bollinger Bands Width": "20, 2",
  "Bollinger Bands": "20, 2",
  "Chaikin Money Flow": "20",
  "Chaikin Oscillator": "3, 10",
  "Chande Kroll Stop": "10, 1, 9",
  "Chande Momentum Oscillator": "9",
  "Chop Zone": "",
  "Choppiness Index": "14",
  "Commodity Channel Index": "20",
  "Connors RSI": "3, 2, 100",
  "Coppock Curve": "10, 14, 11",
  CRSI: "3, 2, 100",
  "Detrended Price Oscillator": "21",
  "Directional Movement": "14, 14",
  "Donchian Channels": "20",
  "Double EMA": "9",
  "Ease Of Movement": "10000, 14",
  "Elder's Force Index": "13",
  "EMA Cross": "9, 26",
  Envelope: "20, 10",
  "Fisher Transform": "9",
  "Historical Volatility": "10",
  "Hull Moving Average": "9",
  "Ichimoku Cloud": "9, 26, 52, 26",
  "Keltner Channels": "20, 1",
  "Klinger Oscillator": "",
  "Know Sure Thing": "10, 15, 20, 30, 10, 10, 10, 15, 9",
  "Least Squares Moving Average": "25, 0",
  "Linear Regression Curve": "9",
  "MA Cross": "9, 26",
  "MA with EMA Cross": "10, 10",
  "MA/EMA Cross": "10, 10",
  MACD: "12, 26, close, 9",
  "Mass Index": "10",
  "McGinley Dynamic": "14",
  Momentum: "10, close",
  "Money Flow": "14",
  "Moving Average Channel": "9, close, 0",
  "Moving Average Exponential": "9, close, 0",
  "Moving Average Weighted": "9, close, 0",
  "Moving Average": "9, close, 0",
  "Net Volume": "",
  "On Balance Volume": "",
  "Parabolic SAR": "0.02, 0.02, 0.2",
  "Pivot Points Standard": "Traditional, Auto, 15",
  "Price Channel": "20, 0",
  "Price Oscillator": "10, 21",
  "Price Volume Trend": "",
  "Rate Of Change": "9",
  "Relative Strength Index": "",
  "Relative Vigor Index": "10",
  "Relative Volatility Index": "10",
  "Session Volume": "",
  "SMI Ergodic Indicator/Oscillator": "5, 20, 5",
  "Smoothed Moving Average": "7, close",
  "Stochastic RSI": "14, 14, 3, 3",
  Stochastic: "14, 1, 3",
  "Triple EMA": "9",
  TRIX: "18",
  "True Strength Indicator": "25, 13, 13",
  "Ultimate Oscillator": "7, 14, 28",
  "Volume Oscillator": "5, 10",
  Volume: "20",
  "Vortex Indicator": "14",
  VWAP: "",
  VWMA: "20",
  "Williams %R": "14",
  "Williams Alligator": "21, 13, 8",
  "Williams Fractal": "2",
  "Zig Zag": "5, 10",
};

export {
  indicatorParser,
  indicatorParamParser,
  indicatorNameList,
  indicatorGenerator,
  indicatorWithDefaultParam,
};
