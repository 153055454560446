import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { Typography } from '@material-ui/core';

import OutlinedButton from "../../components/OutlinedButton";

const styles = (theme) => {
  return {
    root: {},
    card: {
      display: 'flex',
      height: 173,
      margin: 16,
      flexDirection: 'column',
      justifyContent: 'space-between',
      cursor: 'pointer',
      borderRadius: 20,
      '&:hover': {
        transform: 'translateY(-5px)',
        transition: `ease-out 0.4s `,
        opacity: '100%'
      },
      transition: 'ease-out 0.4s',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'row',
      height: '50%',
      paddingBottom: '0px !important'
    },
    iconContainer: {
      width: '30%'
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      paddingBottom: 0,
      width: '70%'
    },
    subtitle: {
      color: theme.palette.subForeColor4
    },
    cardAction: {
      display: 'flex',
      height: '50%',
      justifyContent: 'center'
    },
    cardActionButton: {
      width: 130,
      height: 50,
      borderRadius: 25,
      border: 'none',
      backgroundColor: theme.palette.buttonColor6,
      textTransform: "capitalize"
    },
    cardDisibleButton: {
      width: 130,
      height: 50,
      borderRadius: 25,
      border: 'none',
      backgroundColor: theme.palette.primaryColor[0],
      textTransform: "capitalize"
    },
    noDecorationLink: {
      textDecoration: 'none'
    },
  };
};

const DashboardCard = (props) => {
  const { classes } = props;
  const {addon} = props;

  return (
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div className={classes.iconContainer}>
            <img style={{ width: '80px', height: '75px', }} src={addon.image} alt='Logo' />
          </div>
          <div className={classes.titleContainer}>
            <Typography variant='h6'>
              {addon.name}
            </Typography>
            <Typography variant='body1' className={classes.subtitle}>
              {addon.symbols.length} Pair
            </Typography>
          </div>
        </CardContent>
        <CardActions className={classes.cardAction}>
          <Link
            to={props.comingSoon ? '/' : `/addons/${addon._id}`}
            className={classes.noDecorationLink}
          >
            <OutlinedButton disible='true'
              passedClasses={props.comingSoon ? classes.cardDisibleButton : classes.cardActionButton} >
              <Typography variant='subtitle1'>
                Go
              </Typography>
            </OutlinedButton>
          </Link>
        </CardActions>
      </Card>
    </Grid >
  );
}

export default withStyles(styles, { withTheme: true })(DashboardCard);
