import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';

import AddEditDialog from './AddEditDialog';
import CustomMUIDataTable from '../../../components/CustomMUIDataTable';
import PlusAddButton from '../../../components/PlusAddButton';
import EditIconButton from '../../../components/EditIconButton';
import RemoveIconButton from '../../../components/RemoveIconButton';
import ConfirmDialog from '../../../components/ConfirmDialog';
import {
  getSubscriptions,
  createSubscription,
  updateSubscription,
  removeSubscription,
} from '../../../actions/subscription';
import {setLoadingStatus} from '../../../actions/loading';
import {commonMUITableOptions} from '../../../utils/styles';

class SubscriptionsManage extends Component {
  state = {
    addEditDialogOpened: false,
    confirmDialogOpened: false,
    dialogData: null,
  };

  async componentDidMount() {
    const {setLoadingStatus, getSubscriptions} = this.props;
    setLoadingStatus({loading: true, text: 'Loading addons...'});
    await getSubscriptions();
    setLoadingStatus({loading: false});
  };

  createTableData = () => {
    return this.props.subscriptions.map(subscription => ([
      subscription.name,
      subscription.value,
      subscription._id,
    ]));
  };

  columns = () => [
    {name: 'Name'},
    {name: 'Value'},
    {
      name: 'Action',
      options: {
        filter: false,
        sort: false,
        customBodyRender: _id => {
          return (
            <div style={{display: 'flex'}}>
              <EditIconButton
                color='primary'
                onClick={() => this.openEditDialogHandler(_id)}
              />
              <RemoveIconButton
                color='primary'
                onClick={() => this.openCloseConfirmDialogHandler(true, _id)}
              />
            </div>
          );
        }
      }
    }
  ];

  options = {
    ...commonMUITableOptions,
    customToolbar: () => {
      return (
        <PlusAddButton onClick={this.openAddDialogHandler}/>
      );
    }
  };

  openAddDialogHandler = () => {
    this.openCloseAddEditDialogHandler(true);
  };

  openEditDialogHandler = subscriptionId => {
    const {subscriptions} = this.props;
    const dialogData = subscriptions.find(addon => addon._id === subscriptionId);
    this.openCloseAddEditDialogHandler(true, dialogData);
  };

  closeAddEditDialogHandler = () => {
    this.openCloseAddEditDialogHandler(false);
  };

  openCloseAddEditDialogHandler = (opened, dialogData = null) => {
    this.setState({addEditDialogOpened: opened, dialogData});
  };

  removeSubscriptionHandler = async _id => {
    await this.props.removeSubscription(_id);
  };

  openCloseConfirmDialogHandler = (opened, removeId, removeIdList) => {
    this.setState({confirmDialogOpened: opened, removeId, removeIdList});
  };

  confirmRemoveDialogHandler = async () => {
    const {removeId} = this.state;
    if (removeId) {
      await this.removeSubscriptionHandler(removeId);
    }
  }

  render() {
    const {
      addEditDialogOpened,
      dialogData,
      confirmDialogOpened,
      removeIdList
    } = this.state;
    const tableData = this.createTableData();

    return (
      <Fragment>
        {addEditDialogOpened && (
          <AddEditDialog
            manageTitle='Subscription'
            opened={addEditDialogOpened}
            isAddDialog={!dialogData}
            dialogData={dialogData}
            closed={this.closeAddEditDialogHandler}
            octConfig={this.props.octConfig}
            createSubscription={this.props.createSubscription}
            updateSubscription={this.props.updateSubscription}
          />
        )}
        <CustomMUIDataTable
          title='Subscriptions'
          data={tableData}
          columns={this.columns()}
          options={this.options}
        />
        <ConfirmDialog
          isMultiple={!!removeIdList}
          opened={confirmDialogOpened}
          closed={() => this.openCloseConfirmDialogHandler(false)}
          confirmed={this.confirmRemoveDialogHandler}/>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  subscriptions: state.subscription.subscriptions,
});

const mapActionToProps = {
  setLoadingStatus,
  getSubscriptions,
  createSubscription,
  updateSubscription,
  removeSubscription,
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(SubscriptionsManage);
