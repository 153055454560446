
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';

import { getSpacingUnit, getFontUnit } from '../../utils/utility'; 
import { getOutlinedStyle } from '../../utils/styles';

const styles = theme => {
	const fontSizeUnit = getFontUnit(theme);
	const spacingUnit = getSpacingUnit(theme);
	const outlinedStyle = getOutlinedStyle(theme);

    return {
        root: {
			borderRadius: 2,
			...outlinedStyle
		},
		smallButton: {
			fontSize: fontSizeUnit * 1.1,
			paddingTop: spacingUnit / 2,
            paddingBottom: spacingUnit / 2,
            paddingLeft: spacingUnit,
            paddingRight: spacingUnit
		}
    };
};

const OutlinedButton = ({ classes, children, buttonType, passedClasses, ...rest }) => {
	const styleclasses = buttonType === 'small' && classes.smallButton;
	
    return (
        <Button
			{...rest}
            className={classNames(classes.root, styleclasses, passedClasses)}>
		    {children}
        </Button>
    ); 
};

export default withStyles(styles, {withTheme: true})(OutlinedButton);
