import i18n from "i18next";

const liveLanguages = {
  GB: "EN",
  ES: "ES",
  FR: "FR",
  TH: "TH",
};

const getLanguageFromI18n = () =>
  i18n.language.split("-")[0] === "en"
    ? "GB"
    : i18n.language.split("-")[0].toUpperCase();

export { liveLanguages, getLanguageFromI18n };
