import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import PerfectScrollbar from "react-perfect-scrollbar";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

import EditorPreview from "../../../components/EditorPreview";
import { getSpacingUnit } from "../../../utils/utility";
import RadiusButton from "../../RadiusButton";

import "react-perfect-scrollbar/dist/css/styles.css";

const styles = (theme) => {
  const spacingUnit = getSpacingUnit(theme);

  return {
    paper: {
      backgroundColor: theme.palette.subBackColor3,
    },
    dialogContent: {
      padding: spacingUnit,
      paddingTop: 0,
      paddingLeft: spacingUnit * 4,
      paddingRight: spacingUnit * 4,
      "&:first-child": {
        paddingTop: spacingUnit,
      },
    },
    dialogActions: {
      margin: 0,
      padding: spacingUnit,
      paddingTop: spacingUnit * 2,
      paddingRight: spacingUnit,
    },
    languageSelector: {
      float: "right",
      "& .flag-options": {
        position: "fixed",
      },
    },
    dialogTitle: {
      padding: spacingUnit,
    },
    closeButton: {
      marginRight: spacingUnit * 1.5,
      marginBottom: spacingUnit,
      width: spacingUnit * 10,
    },
    noDescription: {
      width: 200,
      paddingTop: 100,
      paddingBottom: 100,
      textAlign: "center",
      fontSize: 16,
    },
    scrollbar: {
      "& .ps__rail-y": {
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
      "& .ps__rail-x": {
        display: "hidden",
      },
    },
  };
};

const RuleDialog = ({
  classes,
  closed,
  opened,
  rules,
}) => {
  const onCloseHandler = () => {
    closed();
  };
  const scrollBarClasses = "ps ps--active-y";

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      onClose={onCloseHandler}
      aria-labelledby="customized-dialog-title"
      open={opened}
    >
      <PerfectScrollbar
        className={[classes.scrollbar, scrollBarClasses].join(" ")}
      >
        <MuiDialogContent className={classes.dialogContent}>
          {
            rules !== '' ? <EditorPreview editorData={rules} /> : <Typography className={classes.noDescription}>
              No Description
            </Typography>
          }
        </MuiDialogContent>
      </PerfectScrollbar>
      <MuiDialogActions className={classes.dialogActions}>
        {/* <ContainedButton style={{width: '60px', fontSize: '12px'}} onClick={onCloseHandler}>
					OK
				</ContainedButton> */}
        <RadiusButton
          passedClasses={classes.closeButton}
          onClick={onCloseHandler}
        >
          OK
        </RadiusButton>
      </MuiDialogActions>
    </Dialog>
  );
};

export default withStyles(styles, { withTheme: true })(RuleDialog);
