import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { FadeLoader } from 'react-spinners';
import { css } from '@emotion/core';
import { isValidDarkTheme, colors } from '../../utils/styles';

const override = css`
    position: fixed;
    left: 50%;
    top: 50%; //calc(50% - 20px);
    z-index: 3000;
`;

const styles = theme => {
    // const fontSizeUnit = getFontUnit(theme);
    // const spacingUnit = getSpacingUnit(theme);

    return {
        root: {
            position: 'fixed',
            width: '100%',
            height: '100%',
            background: '#00000024',
            top: 0,
            left: 0,
            zIndex: 2000,
			color: theme.palette.subForeColor5,
        },
        text: {
            position: 'fixed',
            top: 'calc(50% + 20px)',
            left: '50%',
            transform: 'translate(-50%, 0%)',
            color: theme.palette.subForeColor5,
            zIndex: 3000,
        },
        displayNone: {
            display: 'none'
        }
    };
};

const Spinner = ({ classes, themeType, loading, text }) => (
    <div className={classNames(classes.root, {
            [classes.displayNone]: !loading
        })}>
        <FadeLoader
            css={override}
            sizeUnit={'px'}
            height={15}
            width={5}
            radius={2}
            margin='2px'
            color={isValidDarkTheme(themeType) ? colors.blue500 : colors.purple500}
            loading={loading} />
        <Typography className={classes.text}>{text}</Typography>
    </div>
);

export const NotConnectedSpinner = withStyles(styles, { withTheme: true })(Spinner);

const mapStateToProps = state => ({
    themeType: state.theme.themeType,
});

export default connect(
    mapStateToProps,
)(withStyles(styles, { withTheme: true })(Spinner));
