import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = () => {
  return {
    root: {
      height: '80%'
    },
  };
};

class HeatMap extends React.Component {

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-forex-heat-map.js'
    script.async = true;
    script.innerHTML = JSON.stringify({
      "width": "100%",
      "height": "100%",
      "currencies": [
        "EUR",
        "USD",
        "JPY",
        "GBP",
        "CHF",
        "AUD",
        "CAD",
        "NZD",
        "CNY"
      ],
      "isTransparent": false,
      "colorTheme": "dark",
      "locale": "en"
    })
    document.getElementById("myContainer").appendChild(script);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root} id="myContainer">
        <div className="tradingview-widget-container">
          <div className="tradingview-widget-container__widget"></div>
          <div className="tradingview-widget-copyright">
            <a href="https://www.tradingview.com/markets/currencies/forex-heat-map/" rel="noopener noreferrer" target="_blank">
              <span className="blue-text">Forex Heat Map</span></a> by TradingView</div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(HeatMap);