
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import HighChart from '../../components/HighChart';
import CardWrapper from '../../hoc/CardWrapper';
const styles = theme => {
}

const HighChartCard = ({ title, subTitle, options }) => (
	<CardWrapper
		headerColor='primary'
		headerHeight='60px'
		title={title}
		subTitle={subTitle}
	>
		<HighChart options={options} />
	</CardWrapper>
    );

export default withStyles(styles, {withTheme: true})(HighChartCard);
    