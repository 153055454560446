import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import { getSpacingUnit } from '../../../../../utils/utility';

const styles = theme => {
  const spacingUnit = getSpacingUnit(theme);
  return {
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      width: '100%'
    },
    marketStatus: {
      borderRadius: ' 0px 0px 12px 0px',
      paddingLeft: spacingUnit,
      paddingRight: spacingUnit,
      backgroundColor: theme.palette.marketLabelBack,
    },
    signalActiveStatus: {
      backgroundColor: theme.palette.activeLabelBack,
      borderRadius: ' 0px 0px 0px 12px',
      paddingLeft: spacingUnit,
      paddingRight: spacingUnit,
    },
    signalCloseStatus: {
      backgroundColor: theme.palette.closeLabelBack,
      borderRadius: ' 0px 0px 0px 12px',
      paddingLeft: spacingUnit,
      paddingRight: spacingUnit,
    }
  }
}

const ListItemLablel = ({ classes, theme, marketStatus, signalStatus }) => {

  return (
    <div className={classes.root}>
      <div className={classes.marketStatus}>
        <Typography style={{ fontSize: 11, color: theme.palette.marketlabelColor }} variant='caption'>{marketStatus}</Typography>
      </div>
      <div className={signalStatus !== "ACTIVE" ? classes.signalCloseStatus : classes.signalActiveStatus}>
        <Typography style={{ fontSize: 11, color: signalStatus !== "ACTIVE" ? '#fff' : theme.palette.activeLabel }} variant='caption'>{signalStatus}</Typography>
      </div>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(ListItemLablel);