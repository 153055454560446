import React, {Fragment, useMemo} from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import {Popover} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CustomSwitch from "../../../components/CustomSwitch";
import {ArrowUpward} from "@material-ui/icons";
import {ArrowDownward} from "@material-ui/icons";
import Chip from "@material-ui/core/Chip";
import ArrowUp from '../../../assets/imgs/arrow-up.svg';
import ArrowDown from '../../../assets/imgs/arrow-down.svg';
import ContainedButton from "../../../components/ContainedButton";
import BarChartIcon from '@material-ui/icons/BarChart';
import {patternRRIsValid} from "../../../utils/utility";
import moment from "moment";

const styles = theme => {
  return {
    container: {
      marginLeft: '10px',
    },
    selectedContainer: {
      marginLeft: '10px',
      backgroundColor: 'rgb(33, 150, 243)',
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#1f272e7d',
      padding: '10px 0',
      minWidth: '400px',
    },
    patternBlock: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    arrowWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      cursor: 'pointer',
      paddingRight: '10px',
    },
    divider: {
      height: '1px',
      backgroundColor: 'aliceblue',
      margin: '10px 0',
    },
    switchesBlock: {
      display: 'flex',
      minWidth: '400px',
    },
    switchWrapper: {
      flex: 1,
      justifyContent: 'flex-start',
    },
    selectWrapper: {
      padding: '20px',
    },
    colorSwitchBase: {
      '&$colorChecked': {
        color: '#198ee8',
        '& + $colorBar': {
          backgroundColor: '#52a8ea',
        },
      },
    },
    line: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0 15px',
    },
    lineField: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flex: 1,
    },
    lineValue: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flex: 1,
    },
    technicalIndicatorBuy: {
      color: 'green',
      border: '1px solid green',
      padding: '5px',
      borderRadius: '10px',
    },
    colorBar: {},
    colorChecked: {},
    patternTypeIcon: {
      width: '15px',
      margin: '0 5px 0 -5px'
    }
  }
};

const HarmonicPatternsDivergence = ({classes, patterns = [], hidden, toggleHidden, activePattern, toggleActivePattern, technicalIndicator}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [expanded, setExpanded] = React.useState([]);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePatternHiddenChange = patternId => {
    toggleHidden(patternId);
  };

  const handleShowPattern = patternId => {
    toggleActivePattern(patternId);
    setExpanded([patternId]);
  };

  const toggleExpanded = patternId => {
    setExpanded(
      expanded.includes(patternId) ?
        expanded.filter(p => p !== patternId) :
        [...expanded, patternId]
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let {regularPatterns} = patterns.reduce(
    (acc, pattern) => {
      if (pattern.patternname === 'orderpartition') {
        acc.opPatterns.push(pattern);
      } else if (pattern.patternname === 'engulfing') {
        acc.engulfingPatterns.push(pattern);
      } else {
        acc.regularPatterns.push(pattern);
      }
      return acc;
    }
  , {regularPatterns: [], opPatterns: [], engulfingPatterns: []});

  const technicalIndicatorStyle = {color: 'green', borderColor: 'green'};

  regularPatterns = useMemo(() => (
    regularPatterns.sort((a, b) => {
      if (moment(a.createdAt).isBefore(moment(b.createdAt))) {
        return -1;
      }
      return 1;
    })
  ), [patterns]);

  return (
    <Fragment>
      <ContainedButton
        onClick={handleClick}
        customClasses={anchorEl !== null ? classes.selectedContainer : classes.container}
      >
        <BarChartIcon />
        <span>&nbsp;Divergence</span>
      </ContainedButton>
      <Popover
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={20}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.root}>
          <div className={classes.line}>
            <div className={classes.lineField}>
              <Typography>Technical Indicator</Typography>
            </div>
            <div className={classes.lineValue}>
              <Chip
                label={'Coming Soon'}
                style={technicalIndicatorStyle}
                variant='outlined'
              />
            </div>
          </div>
          <div className={classes.divider}/>
          {
            regularPatterns.map(pattern => {
              const isExpanded = expanded.includes(pattern._id);
              return (
                <div key={pattern._id}>
                  <div className={classes.patternBlock} key={pattern._id}>
                    <div
                      className={classes.switchWrapper}
                    >
                      <CustomSwitch
                        label={pattern.patternname}
                        labelPlacement='end'
                        checked={!hidden.includes(pattern._id)}
                        switchHandler={() => handlePatternHiddenChange(pattern._id)}
                        icon={
                          <img
                            src={pattern.patterntype === 'Bearish' ? ArrowDown : ArrowUp}
                            className={classes.patternTypeIcon}
                            alt='Pattern type'
                          />
                        }
                        overrideClasses={{
                          switchBase: classes.colorSwitchBase,
                          checked: classes.colorChecked,
                          bar: classes.colorBar,
                        }}
                      />
                    </div>
                    <div className={classes.arrowWrapper}>
                      <Button onClick={() => handleShowPattern(pattern._id)}>
                        {activePattern === pattern._id ? 'HIDE' : 'SHOW'}
                      </Button>
                      <div onClick={() => toggleExpanded(pattern._id)}>
                        {
                          isExpanded ?
                            <ArrowUpward style={{color: '#fff'}} /> : <ArrowDownward style={{color: '#fff'}} />
                        }
                      </div>
                    </div>
                  </div>
                  {
                    isExpanded && (
                      <div>
                        <div className={classes.line}>
                          <div className={classes.lineField}>
                            <Typography>Entry zone</Typography>
                          </div>
                          <div className={classes.lineValue}>
                            <Typography>{pattern.minprice} - {pattern.maxprice}</Typography>
                          </div>
                        </div>
                        <div className={classes.line}>
                          <div className={classes.lineField}>
                            <Typography>TP1</Typography>
                          </div>
                          <div className={classes.lineValue}>
                            <Typography>{pattern.tp1}</Typography>
                          </div>
                        </div>
                        <div className={classes.line}>
                          <div className={classes.lineField}>
                            <Typography>TP2</Typography>
                          </div>
                          <div className={classes.lineValue}>
                            <Typography>{pattern.tp2}</Typography>
                          </div>
                        </div>
                        <div className={classes.line}>
                          <div className={classes.lineField}>
                            <Typography>TP3</Typography>
                          </div>
                          <div className={classes.lineValue}>
                            <Typography>{pattern.tp3}</Typography>
                          </div>
                        </div>
                        <div className={classes.line}>
                          <div className={classes.lineField}>
                            <Typography>SL</Typography>
                          </div>
                          <div className={classes.lineValue}>
                            <Typography>{pattern.sl}</Typography>
                          </div>
                        </div>
                        {
                          patternRRIsValid(pattern.rr) && (
                            <>
                              <div className={classes.line}>
                                <div className={classes.lineField}>
                                  <Typography>Risk Reward Min</Typography>
                                </div>
                                <div className={classes.lineValue}>
                                  <Typography>
                                    {(pattern.rr.rewardMin / pattern.rr.riskMin).toFixed(2)}
                                  </Typography>
                                </div>
                              </div>
                              <div className={classes.line}>
                                <div className={classes.lineField}>
                                  <Typography>Risk Reward Max</Typography>
                                </div>
                                <div className={classes.lineValue}>
                                  <Typography>
                                    {(pattern.rr.rewardMax / pattern.rr.riskMax).toFixed(2)}
                                  </Typography>
                                </div>
                              </div>
                            </>
                          )
                        }
                      </div>
                    )
                  }
                </div>
              )
            })
          }
        </div>
      </Popover>
    </Fragment>
  )
}

export default withStyles(styles, { withTheme: true })(HarmonicPatternsDivergence);
