import { SET_BROKERS } from "./types";
import { getBrokers } from "../services/broker";
import { removeItemWithSlice, showErrorToast } from "../utils/utility";
import constants from "../constants";

export const setBrokers = (refresh) => async (dispatch, getState) => {
  try {
    const { broker } = getState();
    let brokers = broker.data;

    if (refresh || brokers.length === 0) {
      const response = await getBrokers();
      brokers = response.data;
      dispatch({
        type: SET_BROKERS,
        payload: brokers,
      });
    }

    return brokers;
  } catch (error) {
    console.log("sergey : [actions broker setBrokers] error => ", error);
    showErrorToast(constants.notifications.FAIL_CONNECT);
  }
};

export const addEditBroker = (broker) => async (dispatch, getState) => {
  let {
    broker: { data },
  } = getState();

  const targetIndex = data.findIndex((item) => item._id === broker._id);
  if (targetIndex >= 0) {
    data[targetIndex] = broker;
  } else {
    data = [...data, broker];
  }

  dispatch({
    type: SET_BROKERS,
    payload: data,
  });
};

export const removeBroker = (broker) => async (dispatch, getState) => {
  let {
    broker: { data },
  } = getState();

  const targetIndex = data.findIndex((item) => item._id === broker._id);

  data = removeItemWithSlice(data, targetIndex);
  dispatch({
    type: SET_BROKERS,
    payload: data,
  });
};

export const clearBrokers = () => (dispatch) => {
  dispatch({
    type: SET_BROKERS,
    payload: [],
  });
};
