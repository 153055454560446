import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import {
    getUserWithResetToken,
    resetPassword
} from '../../../actions/auth';
import { clearErrors } from '../../../actions/error';
import { setLoadingStatus } from '../../../actions/loading';
import { getSpacingUnit, showErrorToast, showInfoToast } from '../../../utils/utility';
import { pageLinks } from '../../../utils/links';
import constants from '../../../constants';

const styles = theme => {
    const spacingUnit = getSpacingUnit(theme);
    return {
        main: {
            width: 'auto',
            display: 'block',
            marginLeft: spacingUnit * 3,
            marginRight: spacingUnit * 3,
            [theme.breakpoints.up(400 + spacingUnit * 3 * 2)]: {
                width: 400,
                marginLeft: 'auto',
                marginRight: 'auto'
            }
        },
        paper: {
            marginTop: spacingUnit * 8,
            marginBottom: spacingUnit * 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: `${spacingUnit * 2}px ${spacingUnit * 3}px ${spacingUnit *
                3}px `
        },
        avatar: {
            margin: spacingUnit * 2,
            backgroundColor: theme.palette.mainRedColor,
            width: '3.5rem',
            height: '3.5rem'
        },
        lockIcon: {
            fontSize: '2rem'
        },
        form: {
            width: '100%',
            marginTop: spacingUnit
        },
        submit: {
            marginTop: spacingUnit * 3
        },
        signUp: {
            width: '100%',
            padding: spacingUnit * 2
        },
        errorLink: {
            color: theme.palette.mainRedColor,
            fontSize: '1.2rem'
        }
    };
};

class ResetPassword extends Component {
    state = {
        username: null,
        email: null,
        password: '',
        passwordConfirm: '',
        errors: {}
    };

    inputChangeHandler = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    submitHandler = event => {
        const { email, password, passwordConfirm } = this.state;
        event.preventDefault();
        setLoadingStatus({
            loading: true,
            text: 'Loading ...'
        });
        const user = {
            token: this.props.match.params.token,
            email,
            password,
            passwordConfirm
        };
        this.props.resetPassword(
            user,
            this.successCallback,
            this.errorCallback
        );
    };

    successCallback = message => {
        this.props.setLoadingStatus({loading: false});
        this.props.history.replace(pageLinks.Login.url);
        showInfoToast(message);
    };

    errorCallback = response => {
        this.props.setLoadingStatus({loading: false});
        if (!response) {
            showErrorToast(constants.notifications.NO_RESPONSE);
        }
    };

    signUpHandler = () => {
        this.props.history.push(pageLinks.Register.url);
    };

    async componentDidMount() {
        const { clearErrors, setLoadingStatus } = this.props;
        clearErrors();
        setLoadingStatus({
            loading: true,
            text: 'Loading ...'
        });
        const userResult = await getUserWithResetToken(this.props.match.params.token);
        if (!userResult.errors) {
            this.setState({
                username: userResult.username,
                email: userResult.email
            });
        }
        setLoadingStatus({loading: false});
    }

    render() {
        const { username, password, email, passwordConfirm } = this.state;
        const { classes, errorStatus } = this.props;

        const userInfo = username ? (
            <FormControl margin='normal' required fullWidth>
                <Typography component='h1'>Name: {username}</Typography>
                <Typography component='h1'>Email: {email}</Typography>
            </FormControl>
        ) : (
            <FormControl margin='normal' required fullWidth>
                <Typography
                    className={classes.errorLink}
                    component='h1'
                    variant='h5'>
                    The link is invalid.
                </Typography>
            </FormControl>
        );

        return (
            <main className={classes.main}>
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component='h1' variant='h5'>
                        Reset Password
                    </Typography>
                    <form
                        className={classes.form}
                        onSubmit={this.submitHandler}>
                        {userInfo}
                        <FormControl margin='normal' required fullWidth>
                            <InputLabel htmlFor='password'>Password</InputLabel>
                            <Input
                                name='password'
                                type='password'
                                id='password'
                                autoComplete='current-password'
                                className={classNames(
                                    'form-control form-control-lg',
                                    {
                                        'is-invalid': errorStatus && errorStatus.password
                                    }
                                )}
                                onChange={this.inputChangeHandler}
                                value={password} />
                            <Typography color='error'>
                                {errorStatus && errorStatus.password}
                            </Typography>
                        </FormControl>
                        <FormControl margin='normal' required fullWidth>
                            <InputLabel htmlFor='passwordConfirm'>
                                Password Confirm
                            </InputLabel>
                            <Input
                                name='passwordConfirm'
                                type='password'
                                id='passwordConfirm'
                                autoComplete='current-password'
                                className={classNames(
                                    'form-control form-control-lg',
                                    {
                                        'is-invalid':
                                            errorStatus &&
                                            errorStatus.passwordConfirm
                                    }
                                )}
                                onChange={this.inputChangeHandler}
                                value={passwordConfirm} />
                            <Typography color='error'>
                                {errorStatus && errorStatus.passwordConfirm}
                            </Typography>
                        </FormControl>
                        <Button
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            disabled={!username}
                            className={classes.submit}>
                            Reset
                        </Button>
                    </form>
                </Paper>
            </main>
        );
    }
}

ResetPassword.propTypes = {
    classes: PropTypes.object.isRequired,
    resetPassword: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    errorStatus: state.errors.errorStatus
});

const mapActionToProps = {
    resetPassword,
    clearErrors,
    setLoadingStatus
};

export default connect(
    mapStateToProps,
    mapActionToProps
)(withStyles(styles)(ResetPassword));
