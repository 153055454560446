import axios from 'axios';
import {getHarmonicPatternsUrl} from './endpoints';


export const getHarmonicPatternsList = async data => {
  const res = await axios.post(getHarmonicPatternsUrl('get'), data);
  return res.data;
};

export const getHarmonicPatternsSymbols = async () => {
  const res = await axios.get(getHarmonicPatternsUrl('symbols'));
  return res.data;
};

export const getNewHarmonicPatternsCount = async () => {
  const res = await axios.get(getHarmonicPatternsUrl('count'));
  return res.data;
};

export const resetNewHarmonicPatternsCount = async () => {
  await axios.post(getHarmonicPatternsUrl('count', 'reset'))
};

export const getPatternByOCTId = async id => {
  const res = await axios.get(getHarmonicPatternsUrl('oct', 'get', id));
  return res.data;
};

export const getPatternAddonByOCTId = async id => {
  const res = await axios.get(getHarmonicPatternsUrl('oct', 'addon', id));
  return res.data;
};
