import {
  // tool
  SET_SELECTED_STRATEGY,
  SET_SELECTED_SYMBOL_ID,
  SET_STRATEGY_SYMBOLS,
  UPDATE_SIGNALS,
  INITIALIZE_SIGNALS,
  SET_CHART_DETAILED_DATA,
  SET_CHART_INFO_DATA,

  SET_STRATEGY_TYPE,
  SET_SERVER_TIME,
  SET_NEW_SIGNAL,
  SET_LAST_QUOTE,
  CLEAR_TOOLS,
  SET_TICK_DATA,
  SET_UPDATE_SIGNAL,
  SET_REMOVE_SIGNAL,
  SET_REMOVE_SIGNALS,
} from "../actions/types";
import { getToolKey } from '../utils/utility';
import constants from '../constants';

const initialState = {
  // tool
  selectedToolStrategy: {},
  selectedToolSymbolId: {},
  strategySignals: {},
  strategyNewSignals: {},
  strategySymbols: {},
  strategyChartDetailedData: {},
  strategyChartsInfoData: {},
  toolSignals: {},

  tickData: {},
  selectedToolIndex: constants.COSMICCLOUD,
  selectedToolKey: getToolKey(constants.COSMICCLOUD),
  serverTime: null,
  newSignal: null,
  lastQuote: null,
};

const updateSignals = (signals, signal) => {
  let updatedSignals = [...signals];
  const findIndex = signals.findIndex((item) => item.id === signal._id);
  if (findIndex > -1) {
    updatedSignals[findIndex] = signal;
  }
  return updatedSignals;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_STRATEGY:
      let key = getToolKey(action.payload.toolIndex);
      const selectedToolStrategy = {
        ...state.selectedToolStrategy,
        [key]: action.payload.strategy
      };

      return {
        ...state,
        selectedToolStrategy
      };
    case SET_SELECTED_SYMBOL_ID:
      key = getToolKey(action.payload.toolIndex);
      const selectedToolSymbolId = {
        ...state.selectedToolSymbolId,
        [key]: action.payload.selectedSymbolId
      };

      return {
        ...state,
        selectedToolSymbolId
      };
    case UPDATE_SIGNALS:
      key = getToolKey(action.payload.toolIndex);
      const strategySignals = {
        ...state.strategySignals,
        [key]: action.payload.updatedData.signals
      };
      const strategyNewSignals = {
        ...state.strategyNewSignals,
        [key]: action.payload.updatedData.newSignals
      };
      return {
        ...state,
        strategySignals,
        strategyNewSignals
      };
    case SET_STRATEGY_SYMBOLS:
      key = getToolKey(action.payload.toolIndex);
      const strategySymbols = {
        ...state.strategySymbols,
        [key]: action.payload.strategySymbols
      };

      return {
        ...state,
        strategySymbols
      };
    case INITIALIZE_SIGNALS:
      return {
        ...state,
        strategySignals: {},
        strategyNewSignals: {},
      };
    case SET_CHART_DETAILED_DATA:
      key = getToolKey(action.payload.toolIndex);
      const strategyChartDetailedData = {
        ...state.strategyChartDetailedData,
        [key]: action.payload.chartData
      };

      return {
        ...state,
        strategyChartDetailedData,
      };
    case SET_CHART_INFO_DATA:
      key = getToolKey(action.payload.toolIndex);
      const strategyChartsInfoData = {
        ...state.strategyChartsInfoData,
        [key]: action.payload.chartsInfoData
      };

      return {
        ...state,
        strategyChartsInfoData
      };
    case SET_NEW_SIGNAL:
      return {
        ...state,
        newSignal: action.payload,
      };
    case SET_UPDATE_SIGNAL:
      const { signal } = action.payload;
      key = getToolKey(action.payload.toolIndex);
      let signals = updateSignals(state.toolSignals[key], signal);
      let toolSignals = {
        ...state.toolSignals,
        [key]: signals
      };

      return {
        ...state,
        toolSignals,
      };
    case SET_REMOVE_SIGNAL:
      const { signalId } = action.payload;
      key = getToolKey(action.payload.toolIndex);
      signals = state.toolSignals[key] && state.toolSignals[key].filter(signal => signal.id !== signalId);
      toolSignals = {
        ...state.toolSignals,
        [key]: signals || []
      };

      return {
        ...state,
        toolSignals,
      };
    case SET_REMOVE_SIGNALS:
      const { signalIds } = action.payload;
      key = getToolKey(action.payload.toolIndex);
      signals = state.toolSignals[key] && state.toolSignals[key].filter(signal => !signalIds.find(id => id === signal.id));
      toolSignals = {
        ...state.toolSignals,
        [key]: signals || []
      };

      return {
        ...state,
        toolSignals,
      };
    case SET_LAST_QUOTE:
      return {
        ...state,
        lastQuote: action.payload,
      };
    case SET_TICK_DATA:
      return {
        ...state,
        tickData: action.payload,
      };
    case CLEAR_TOOLS:
      return initialState;
    case SET_STRATEGY_TYPE:
      return {
        ...state,
        selectedToolIndex: action.payload,
        selectedToolKey: getToolKey(action.payload)
      };
    case SET_SERVER_TIME:
      return {
        ...state,
        serverTime: action.payload,
      };
    default:
      return state;
  }
}
