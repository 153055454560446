import React, {useRef} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = theme => {
  return {
    root: {
      color: theme.palette.mainForeColor
    },
    image: {
      width: '50px',
      height: '50px',
    }
  };
};

const CustomImageField = ({classes, image, onChange}) => {

  const picker = useRef(null);

  const handlePickerChange = e => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      onChange(reader.result);
    };
  };

  return (
    <>
      <input
        type='file'
        ref={picker}
        style={{display: 'none'}}
        onChange={handlePickerChange}
      />
      <div>
        {
          image && (
            <img src={image} alt='Custom picker' className={classes.image}/>
          )
        }
      </div>
      <div>
        <Button
          className={classes.root}
          onClick={() => picker.current.click()}
        >
          Select image
        </Button>
      </div>
    </>
  );
};

export default withStyles(styles, {withTheme: true})(CustomImageField);
