import {SET_ADDONS, SET_OCT_CONFIG} from '../actions/types';

const initialState = {
  addons: [],
  octConfig: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ADDONS:
      return {
        ...state,
        addons: action.payload,
      };
    case SET_OCT_CONFIG:
      return {
        ...state,
        octConfig: action.payload,
      }
    default:
      return state;
  }
}
