import React, {useEffect, useState} from 'react';
import {
  getConfig,
  updateConfig,
} from '../../../services/config';

import {Checkbox} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import OutlinedButton from "../../../components/OutlinedButton";
import {showErrorToast, showInfoToast} from "../../../utils/utility";


export default () => {

  const [config, setConfig] = useState(null);

  useEffect(() => {
    getConfig().then(setConfig);
  }, []);

  if (!config) {
    return null;
  }

  const handleFiledChange = (field, value) => {
    setConfig(prev => ({...prev, [field]: value}));
  };

  const handleUpdateConfig = () => {
    updateConfig(config).then(c => {
      setConfig(c);
      showInfoToast('Config updated');
    }).catch(e => {
      showErrorToast('Error updating config');
    })
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            color='primary'
            checked={config.maintenanceMode}
            onChange={e => handleFiledChange('maintenanceMode', e.target.checked)} />
        }
        label='Maintenance Mode'
      />
      <div>
        <OutlinedButton
          onClick={handleUpdateConfig}
        >
          Save
        </OutlinedButton>
      </div>
    </div>
  );
}
