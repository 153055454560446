
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

const EditIconButton = (props) => {
    return (
        <IconButton {...props}>
            <EditIcon />
        </IconButton>
    );
};

export default EditIconButton;
