import * as subscriptionService from '../services/subscription';
import {
  SET_SUBSCRIPTIONS,
  ADD_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  REMOVE_SUBSCRIPTION
} from './types';


export const getSubscriptions = () => async (dispatch) => {
  const subscriptions = await subscriptionService.getSubscriptions();
  dispatch({
    type: SET_SUBSCRIPTIONS,
    payload: subscriptions,
  });
};

export const createSubscription = (subscriptionData) => async (dispatch) => {
  const subscription = await subscriptionService.createSubscription(subscriptionData);
  dispatch({
    type: ADD_SUBSCRIPTION,
    payload: subscription,
  })
};

export const updateSubscription = (subscriptionData) => async (dispatch) => {
  const updatedSub = await subscriptionService.updateSubscription(subscriptionData);
  dispatch({
    type: UPDATE_SUBSCRIPTION,
    payload: updatedSub,
  });
};

export const removeSubscription = _id => async (dispatch) => {
  await subscriptionService.removeSubscription(_id);
  dispatch({
    type: REMOVE_SUBSCRIPTION,
    payload: {_id},
  });
};
