import { isWidthUp } from "@material-ui/core/withWidth";
import constants from "../constants";
import { hexToRgb } from "./utility";

const breakpoints = new Set(["xl", "lg", "md", "sm", "xs"]);

const checkBreakpointValidation = (breakpoint) => {
  if (!breakpoints.has(breakpoint)) {
    throw new Error("Breakpoint is invalid.");
  }
};

const isWidthWiderThan = (breakpoint, width) => {
  checkBreakpointValidation(breakpoint);

  if (isWidthUp(breakpoint, width)) {
    return true;
  }
  return false;
};

const isValidDarkTheme = (type) => {
  return type === constants.themeType.dark;
};

// TODO: * just use theme dependency no need any operation like isValidDarkTheme
const getOutlinedStyle = (theme) => {
  return {
    border: `1px solid ${
      isValidDarkTheme(theme.palette.type)
        ? theme.palette.subForeColor2
        : theme.palette.mainForeColor
      }`,
    "&:hover": {
      backgroundColor:
        // isValidDarkTheme(theme.palette.type)
        // ? theme.palette.mainBackColor
        // : theme.palette.mainForeColor,
        theme.palette.primaryColor[0],
      color: "white",
    },
    background: "transparent",
    color: theme.palette.mainForeColor,
  };
};

const getCopyStreamLinkStyle = (theme) => {
  return {
    position: "absolute",
    right: 2,
    top: 2,
    padding: 4,
    color: theme.palette.subForeColor5,
    "&:hover": {
      backgroundColor: isValidDarkTheme(theme.palette.type)
        ? theme.palette.mainBackColor
        : colors.purple300,
    },
  };
};

const getCardHeaderStyle = (theme) => {
  return {
    fontFamily: robotoFont,
    padding: "0.75rem 1.25rem",
    marginBottom: "0",
    borderBottom: "none",
    background: "transparent",
    zIndex: "3 !important",
    "&$cardHeaderPlain,&$cardHeaderIcon,&$cardHeaderStats,&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader": {
      margin: "0 15px",
      padding: "0",
      position: "relative",
      color: theme.palette.mainForeColor,
    },
    "&:first-child": {
      borderRadius: "calc(.25rem - 1px) calc(.25rem - 1px) 0 0",
    },
    "&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader": {
      "&:not($cardHeaderIcon)": {
        borderRadius: "3px",
        marginTop: "-20px",
        padding: "15px",
        [theme.breakpoints.down("sm")]: {
          "& > div p": {
            fontSize: "12px !important",
          },
          paddingLeft: "8px",
          paddingRight: "8px",
        },
      },
    },
    "&$cardHeaderStats svg": {
      fontSize: "36px",
      lineHeight: "56px",
      textAlign: "center",
      width: "36px",
      height: "36px",
      margin: "10px 10px 4px",
    },
    "&$cardHeaderStats i,&$cardHeaderStats .material-icons": {
      fontSize: "36px",
      lineHeight: "56px",
      width: "56px",
      height: "56px",
      textAlign: "center",
      overflow: "unset",
      marginBottom: "1px",
    },
    "&$cardHeaderStats$cardHeaderIcon": {
      textAlign: "right",
    },
  };
};

const getCardHeaderStatsStyle = () => {
  return {
    cardHeaderPlain: {
      marginLeft: "0px !important",
      marginRight: "0px !important",
    },
    cardHeaderStats: {
      "& $cardHeaderIcon": {
        textAlign: "right",
      },
      "& h1,& h2,& h3,& h4,& h5,& h6": {
        margin: "0 !important",
      },
    },
  };
};

const getCardHeaderIconStyle = () => {
  return {
    "&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader": {
      background: "transparent",
      boxShadow: "none",
    },
    "& i,& .material-icons": {
      width: "33px",
      height: "33px",
      textAlign: "center",
      lineHeight: "33px",
    },
    "& svg": {
      width: "24px",
      height: "24px",
      textAlign: "center",
      lineHeight: "33px",
      margin: "5px 4px 0px",
    },
  };
};

const getWarningCardHeaderStyle = (theme) => {
  return {
    color: theme.palette.subForeColor5,
    "&:not($cardHeaderIcon)": {
      background:
        "linear-gradient(60deg, " +
        theme.palette.warningColor[1] +
        ", " +
        theme.palette.warningColor[2] +
        ")",
      boxShadow:
        "0 4px 20px 0 rgba(" +
        hexToRgb(theme.palette.subForeColor2) +
        ",.14), 0 7px 10px -5px rgba(" +
        hexToRgb(theme.palette.warningColor[0]) +
        ",.4)",
    },
  };
};

const getSuccessCardHeaderStyle = (theme) => {
  return {
    color: theme.palette.mainForeColor,
    "&:not($cardHeaderIcon)": {
      background:
        "linear-gradient(60deg, " +
        theme.palette.successColor[1] +
        ", " +
        theme.palette.successColor[2] +
        ")",
      boxShadow:
        "0 4px 20px 0 rgba(" +
        hexToRgb(theme.palette.subForeColor2) +
        ",.14), 0 7px 10px -5px rgba(" +
        hexToRgb(theme.palette.successColor[0]) +
        ",.4)",
    },
  };
};

const getDangerCardHeaderStyle = (theme) => {
  return {
    color: theme.palette.mainForeColor,
    "&:not($cardHeaderIcon)": {
      background:
        "linear-gradient(60deg, " +
        theme.palette.dangerColor[1] +
        ", " +
        theme.palette.dangerColor[2] +
        ")",
      boxShadow:
        "0 4px 20px 0 rgba(" +
        hexToRgb(theme.palette.subForeColor2) +
        ",.14), 0 7px 10px -5px rgba(" +
        hexToRgb(theme.palette.dangerColor[0]) +
        ",.4)",
    },
  };
};

const getInfoCardHeaderStyle = (theme) => {
  return {
    color: theme.palette.mainForeColor,
    "&:not($cardHeaderIcon)": {
      background:
        "linear-gradient(60deg, " +
        theme.palette.infoColor[1] +
        ", " +
        theme.palette.infoColor[2] +
        ")",
      boxShadow:
        "0 4px 20px 0 rgba(" +
        hexToRgb(theme.palette.subForeColor2) +
        ",.14), 0 7px 10px -5px rgba(" +
        hexToRgb(theme.palette.infoColor[0]) +
        ",.4)",
    },
  };
};

const getPrimaryCardHeaderStyle = (theme) => {
  return {
    color: theme.palette.mainForeColor,
    "&:not($cardHeaderIcon)": {
      background:
        "linear-gradient(60deg, " +
        theme.palette.primaryColor[0] +
        ", " +
        theme.palette.primaryColor[6] +
        ")",
      boxShadow:
        "0 4px 20px 0 " +
        theme.palette.primaryColor[2] +
        ", 0 7px 10px -5px " +
        theme.palette.primaryColor[3],
    },
  };
};

const getRoseCardHeaderStyle = (theme) => {
  return {
    color: theme.palette.mainForeColor,
    "&:not($cardHeaderIcon)": {
      background:
        "linear-gradient(60deg, " +
        theme.palette.roseColor[1] +
        ", " +
        theme.palette.roseColor[2] +
        ")",
      boxShadow:
        "0 4px 20px 0 rgba(" +
        hexToRgb(theme.palette.subForeColor2) +
        ",.14), 0 7px 10px -5px rgba(" +
        hexToRgb(theme.palette.roseColor[0]) +
        ",.4)",
    },
  };
};

const getCardTitleStyle = (theme) => {
  return {
    color: theme.palette.subForeColor5,
    marginTop: "0px",
    minHeight: "auto",
    // fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: theme.palette.grayColor[1],
      fontWeight: "400",
      lineHeight: "1",
    },
  };
};

const getCardCategoryStyle = (theme) => {
  return {
    color: "rgba(" + hexToRgb(theme.palette.subForeColor5) + ",.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  };
};

const colors = {
  lightBlue: "#009cff",
  indigo500: "#3f51b5",
  amber500: "#ffc107",
  red500: "#f44336",
  lightGreen500: "#8bc34a",
  cyan400: "#26c6da",
  orange500: "#ed7d31",
  blue500: "#2196f3",
  purple300: "#59439a",
  purple400: "#ab47bc",
  purple500: "#690099",
  pink500: "#e91e63",
  grey400: "#8c8c8c",
  grey500: "#9e9e9e",
  grey600: "#757575",
  grey700: "#616161",
  grey800: "#424242",
  grey900: "#c5c5c5",
  deepPurple500: "#673ab7",
  red900: "#b71c1c",
  deepOrange400: "#ff7043",
  teal400: "#26a69a",
  blueGrey500: "#607d8b",
  white: "#ffffff",
  dark: "#131722",
  mediumDark: "#1d2025",
  black: "#000000",
  lightgrey: "#d3d3d3",
  grey: "#808080",
  ghostwhite: "#f8f8ff",
  green500: "#4caf50",
  warning1: "#ff9800",
  warning2: "#ffa726",
  warning3: "#fb8c00",
  warning4: "#ffa21a",
  danger1: "#f44336",
  danger2: "#ef5350",
  danger3: "#e53935",
  danger4: "#f55a4e",
  info1: "#00acc1",
  info2: "#26c6da",
  info3: "#00acc1",
  info4: "#00d3ee",
  success1: "#4caf50",
  success2: "#66bb6a",
  success3: "#43a047",
  success4: "#5cb860",
  primary1: "#8538be",
  primary2: "#690099",
  primary3: "rgba(228, 121, 255, 0.39)",
  primary4: "rgba(130, 130, 130, 0.39)",
  primaryScheduleItemIn: "#a162d0",
  primaryScheduleItem: "#f2f2f2",
  darkPrimary1: "#292c35",
  darkPrimary2: "#12141e",
  darkPrimary3: "rgba(29, 34, 47,.4)",
  darkPrimary4: "rgba(0, 0, 0, 0.4)",
  darkPrimary5: "#191e32",
  rose1: "#e91e63",
  rose2: "#ec407a",
  rose3: "#d81b60",
  rose4: "#eb3573",
  gray1: "#999",
  gray2: "#777",
  gray3: "#3C4858",
  gray4: "#AAAAAA",
  gray5: "#D2D2D2",
  gray6: "#DDD",
  gray7: "#b4b4b4",
  gray8: "#555555",
  gray9: "#333",
  gray10: "#a9afbb",
  gray11: "#eee",
  gray12: "#e7e7e7",
};

const colorLinks = {
  buy: colors.blue500,
  sell: colors.red500,
};

const NotificationLabels = {
  info: {
    text: "Info",
    backgroundColor: colors.indigo500,
  },
  alert: {
    text: "Alert",
    backgroundColor: colors.amber500,
  },
  important: {
    text: "Important",
    backgroundColor: colors.red500,
  },
};

const boxShadow = "0 1px 6px rgba(57,73,76,.35)";
const boxShadow1 = "2px 2px 6px 0px rgba(0,0,0,0.2)";

const robotoFont = "Roboto";

const commonMUITableOptions = {
  textLabels: {
    body: {
      noMatch: "",
      toolTip: "No data",
    },
    pagination: {
      rowsPerPage: "Items:",
    },
  },
  filterType: "dropdown",
  selectableRows: "none",
  rowsPerPageOptions: [5, 10, 20, 50, 100],
  rowsPerPage: 10,
  responsive: "scroll",
  download: false,
  print: false,
};

export {
  getOutlinedStyle,
  isValidDarkTheme,
  colors,
  NotificationLabels,
  isWidthWiderThan,
  colorLinks,
  boxShadow,
  boxShadow1,
  robotoFont,
  commonMUITableOptions,
  getCopyStreamLinkStyle,
  getCardHeaderStyle,
  getCardHeaderStatsStyle,
  getCardHeaderIconStyle,
  getWarningCardHeaderStyle,
  getSuccessCardHeaderStyle,
  getDangerCardHeaderStyle,
  getInfoCardHeaderStyle,
  getPrimaryCardHeaderStyle,
  getRoseCardHeaderStyle,
  getCardTitleStyle,
  getCardCategoryStyle,
};
