import axios from "axios";

import { getRulesUrl } from "./endpoints";

const getRules = async () => {
  const url = getRulesUrl();
  const rules = await axios.get(url);
  return rules;
};

const addRule = async (data) => {
  const url = getRulesUrl();
  const result = await axios.post(url, data);
  return result;
};

const editRule = async (data) => {
  const url = getRulesUrl();
  const result = await axios.put(url, data);
  return result;
};

const removeRule = async (_id) => {
  const url = getRulesUrl();
  const result = await axios.delete(url, {
    params: { _id },
  });
  return result;
};

const removeRules = async (ids) => {
  const url = getRulesUrl();
  const result = await axios.delete(url, { data: { ids } });
  return result;
};

export { getRules, addRule, editRule, removeRule, removeRules };
