
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { CSVLink } from 'react-csv';

import CustomMUIDataTable from '../../CustomMUIDataTable';
import OutlinedButton from '../../OutlinedButton';
import { isBuyByTitle, convertToReportTableSignals, extractDateStamp } from '../../../utils/utility';
import { colorLinks, commonMUITableOptions } from '../../../utils/styles';
import constants from '../../../constants';
import CardWrapper from '../../../hoc/CardWrapper';

const styles = theme => {

    return {
		highlight: {
			color: theme.palette.highlightColor
        },
        outlinedButton: {
            margin: 8,
            marginTop: 0
        },
        csvButton: {
            textDecoration: 'none',
            textTransform: 'none',
            color: theme.palette.mainForeColor,
            '&:hover': {
                color: theme.palette.scheduleForeColor2
            }
        },
        datePicker: {
            padding: 0,
            margin: 0
        },
        textField: {
            width: 160,
            margin: 0
        },
        selectMenu: {
            backgroundColor: theme.palette.mainBackColor
        },
        resetIcon: {
            cursor: 'pointer',
            marginTop: 16,
            marginRight: 8,
            marginLeft: 8
        }
	}
};

class ReportSignalsTable extends Component {
    state = {
        startDate: null,
        endDate: null
    }

    dateChangeHandler = (date, name) => {
        if (name === 'startDate') {
            this.setState({startDate: date});
        } else if (name === 'endDate') {
            this.setState({endDate: date});
        }
    };

    resetStartDateHandler = () => {
        this.setState({startDate: null});
    };

    resetEndDateHandler = () => {
        this.setState({endDate: null});
    };

    hasSelectedSymbol = (signalId, symbolId) => {
        const { signals } = this.props;
		const signal = signals.find(signal => signalId === signal.id);
		if (signal && signal.symbolId === symbolId) {
			return true;
		}
		return false;
	};

	columnOption = filter => {
        const { selectedSymbolId, classes } = this.props;
		return {
			filter,
			customBodyRender: (value, tableMeta) => {
                // TODO: * hardcoded 8 means signal id
				const isSelectedSymbolRow = this.hasSelectedSymbol(tableMeta.rowData[8], selectedSymbolId);
				return (
					<div className={isSelectedSymbolRow ? classes.highlight : null}>
						{value}
					</div>
				);
			}
		}
    };

    columns = () => ([
        {
            name: 'Open Time',
			options: this.columnOption(false)
        },
        {
            name: 'Close Time',
			options: this.columnOption(false)
        },
        {
            name: 'Symbol',
            options: this.columnOption(true)
        },
        {
            name: 'Type',
            options: {
                filter: true,
                customBodyRender: value => {
                    return (
                        <div style={{color: isBuyByTitle(value) ? colorLinks.buy : colorLinks.sell}}>
                            {value}
                        </div>
                    );
                }
            }
        },
        {
            name: 'Open Price',
            options: this.columnOption(false)
		},
        {
            name: 'TP',
            options: this.columnOption(false)
        },
        {
            name: 'SL',
            options: this.columnOption(false)
        },
        {
            name: 'Close Price',
            options: this.columnOption(false)
        },
        {
            name: 'id',
            options: {
                display: false,
                filter: false
            }
        },
        {
            name: 'Status',
            options: this.columnOption(true)
        },
        {
            name: 'Pips',
            options: this.columnOption(false)
        }
    ]);

    render () {
        const { title, subTitle } = this.props;
        // const { startDate, endDate } = this.state;
        const filteredTableData = () => {
            // const { signals } = this.props;
            const { signals } = this.props;
            const { startDate, endDate } = this.state;
            return convertToReportTableSignals(signals).filter(data => {
                const openDate = new Date(data[0]);
                if (startDate) {
                    const startTimeBefore = new Date(extractDateStamp(startDate));
                    if (openDate < startTimeBefore) {
                        return false
                    }
                }
                if (endDate) {
                    const endTimeAfter = new Date(extractDateStamp(endDate) + constants.DAY_TIMESTAMP);
                    if (openDate > endTimeAfter) {
                        return false;
                    }
                }
                return true;
            });
        };

        const headers = this.columns().map(item => (item.name));
        const customToolbarComponent = classes => {
            return (
                <Fragment>
                    <OutlinedButton passedClasses={classes.outlinedButton}>
                        <CSVLink
                            className={classes.csvButton}
                            data={filteredTableData()}
                            headers={headers}
                            filename='signal.csv' >
                            Download CSV
                        </CSVLink>
                    </OutlinedButton>
                </Fragment>)
        }

        const options = {
            ...commonMUITableOptions,
            customToolbar: () => {
                const { classes } = this.props;
                return customToolbarComponent(classes)
            }
        };
        return (

            <CardWrapper
                headerColor='primary'
                headerHeight='60px'
                title={title}
                subTitle={subTitle}
            >
                <CustomMUIDataTable
                    data={filteredTableData()}
                    columns={this.columns()}
                    options={options} />
            </CardWrapper>
        );
    }
}

ReportSignalsTable.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

export default withStyles(styles, {withTheme: true})(ReportSignalsTable);
