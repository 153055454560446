
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import RightIcon from '@material-ui/icons/KeyboardArrowRight';
import { boxShadow, isValidDarkTheme } from '../../../utils/styles';

const styles = theme => {
    return {
        root: {
            position: 'relative',
            width: 1,
            
            marginLeft: -9,
            marginRight: 6,
            cursor: 'pointer',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            }
        },
        roundButton: {
            
        },
        button: {
            position: 'fixed',
            marginTop: 'calc(50vh - 64px)',
            borderRadius: '50%',
            width: 36,
            height: 36,
            background: !isValidDarkTheme(theme.palette.type) ? 'white' : theme.palette.primaryColor[0],
            boxShadow: boxShadow,
            color: theme.palette.mainForeColor,
            zIndex: 1,
            padding: 4
        },
        lineSeperator: {
            position: 'absolute',
            width: 1,
            background: theme.palette.grayColor[2],
            opacity: 0.3,
            marginLeft: 14,
            marginRight: -14,
            top: 24,
            bottom: 20
        }
    };
};

const OpenIconButton = ({ classes, onToggle, opened}) => {
    return (
        <div className={classes.root}>
            {!opened &&
                <LeftIcon className={classes.button} onClick={onToggle} />
            }
            {opened && <RightIcon className={classes.button} onClick={onToggle} />}
            <div className={classes.lineSeperator} />
        </div>
    );
};

export default withStyles(styles, {withTheme: true})(OpenIconButton);
