import { SET_SYMBOLS } from "./types";
import { getSymbols } from "../services/symbol";
import { removeItemWithSlice } from "../utils/utility";

export const setSymbols = (refresh) => async (dispatch, getState) => {
  try {
    const { symbol } = getState();
    let symbols = symbol.data;

    if (refresh || symbols.length === 0) {
      const response = await getSymbols();
      symbols = response.data;
      dispatch({
        type: SET_SYMBOLS,
        payload: symbols,
      });
    }

    return symbols;
  } catch (error) {
    console.log("sergey : [actions symbol setSymbols] error => ", error);
  }
};

export const addEditSymbol = (symbol) => async (dispatch, getState) => {
  let {
    symbol: { data },
  } = getState();

  const targetIndex = data.findIndex((item) => item._id === symbol._id);
  if (targetIndex >= 0) {
    data[targetIndex] = symbol;
  } else {
    data = [...data, symbol];
  }

  dispatch({
    type: SET_SYMBOLS,
    payload: data,
  });
};

export const removeSymbol = (symbol) => async (dispatch, getState) => {
  let {
    symbol: { data },
  } = getState();

  const targetIndex = data.findIndex((item) => item._id === symbol._id);

  data = removeItemWithSlice(data, targetIndex);
  dispatch({
    type: SET_SYMBOLS,
    payload: data,
  });
};

export const removeSymbols = (symbolIds) => async (dispatch, getState) => {
  let {
    symbol: { data },
  } = getState();

  // const targetIndex = data.findIndex(item => (
  //     item._id === symbol._id
  // ));

  // data = removeItemWithSlice(data, targetIndex);
  data = data.filter((item) => !symbolIds.includes(item._id));

  dispatch({
    type: SET_SYMBOLS,
    payload: data,
  });
};

export const clearSymbols = () => (dispatch) => {
  dispatch({
    type: SET_SYMBOLS,
    payload: [],
  });
};
