import React, { Fragment } from "react";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import { getFontUnit, getTimeframeName } from "../../../utils/utility";

const styles = (theme) => {
  const fontSizeUnit = getFontUnit(theme);

  return {
    dropdown: {
      marginTop: 0,
      marginBottom: 0,
      "& div": {
        fontSize: fontSizeUnit * 1.4,
        color: theme.palette.highlightColor,
        "&::after": {
          borderBottomColor: theme.palette.subForeColor2,
        },
      },
      "& .removeIcon": {
        display: "none",
      },
    },
    noUnderline: {
      "& > div": {
        "& div": {
          color: theme.palette.mainForeColor,
        },
        color: theme.palette.mainForeColor,
        "&::before": {
          content: "none",
        },
        "&::after": {
          content: "none",
        },
      },
    },
  };
};

const StrategyTimeFrameSelector = ({
  classes,
  label,
  selectedStrategy,
  strategies,
  setStrategy,
  noUnderline,
}) => {
  const selectStrategyHandler = (strategyId) => {    
    const targetIndex = strategies.findIndex(
      (strategy) => strategy._id === strategyId
    );

    if (targetIndex >= 0) {
      setStrategy(strategies[targetIndex]);
    }
  };

  return (
    <Fragment>
      <TextField
        select
        label={label}
        className={classNames(
          classes.dropdown,
          noUnderline && classes.noUnderline
        )}
        disabled={strategies.length === 0}
        value={(selectedStrategy && selectedStrategy._id) || ""}
        onChange={(event) => selectStrategyHandler(event.target.value)}
        margin="normal"
        fullWidth
      >
        {strategies.map((item, index) => {
          return (
            <MenuItem key={index} value={item._id}>
              {getTimeframeName(item.timeframe)}
            </MenuItem>
          );
        })}
      </TextField>
    </Fragment>
  );
};

export default withStyles(styles, { withTheme: true })(
  StrategyTimeFrameSelector
);
