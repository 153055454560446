import axios from 'axios';
import {getMarketplaceItemsUrl} from './endpoints';


export const getMarketplaceItems = async options => {
  try {
    const response = await axios.get(getMarketplaceItemsUrl('list'), {
      params: options,
    });
    return response.data;
  } catch (e) {
    return [];
  }
};

export const addMarketplaceItem = async itemId => {
  await axios.post(getMarketplaceItemsUrl('add'), {itemId});
};
