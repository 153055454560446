import React, { PureComponent } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import withStyles from "@material-ui/core/styles/withStyles";

import CustomSelectValidator from "../../../../components/CustomSelectValidator";
import OutlinedButton from "../../../../components/OutlinedButton";
import CustomTextButton from "../../../../components/CustomTextButton";
import TextEditor from "../../../../components/TextEditor";
import LanguageSelector from "../../../../components/LanguageSelector";
import DialogWrapper from "../../../../hoc/DialogWrapper";
import DialogActionsWrapper from "../../../../hoc/DialogActionsWrapper";
import {
  getAddEditDialogMetadata,
  showErrorToast,
  getToolsTypesArray,
} from "../../../../utils/utility";
import { addRule, editRule } from "../../../../services/rule";
import { tools } from "../../../../constants";
import { liveLanguages } from "../../../../utils/languages";

const styles = (theme) => {

  return {
    row: {
      display: "flex",
      alignItems: "flex-end",
    },
    languageSelector: {
      minWidth: 100,
      marginBottom: 10,
    },
  };
};

class AddEditDialog extends PureComponent {
  state = {
    toolsType: 0,
    description: "",
    submitButtonDisabled: false,
    userLanguage: "",
    toolsTypeArray: [],
    addonId: null,
  };

  componentDidMount() {
    const { isAddDialog, dialogData, rules } = this.props;
    let toolsTypeArray = [];

    // edit
    if (!isAddDialog) {
      const { toolsType, description } = dialogData;
      toolsTypeArray = getToolsTypesArray(tools, rules, toolsType);
      this.setState({ toolsType, description });
    } else {
      toolsTypeArray = getToolsTypesArray(tools, rules, null);
      if (toolsTypeArray.length > 0) {
        this.setState({ toolsType: toolsTypeArray[0].value });
      }
    }
    this.setState({
      userLanguage: Object.keys(liveLanguages)[0],
      toolsTypeArray,
    });
  }

  onCloseHandler = () => {
    const { closed } = this.props;
    closed && closed();
  };

  submitHandler = async () => {
    const { toolsType, description } = this.state;
    const { isAddDialog, dialogData, addEditRule } = this.props;
    const rule = {
      _id: !isAddDialog ? dialogData._id : null,
      toolsType,
      description,
    };
    try {
      this.setState({ submitButtonDisabled: true });
      let response;
      if (isAddDialog) {
        response = await addRule(rule);
      } else {
        response = await editRule(rule);
      }
      const { data: ruleFromBackend } = response;
      addEditRule(ruleFromBackend);
    } catch (error) {
      // TODO: * axios handling module
      console.log(
        "ant : [containers Admin RuleManage AddEditDialog submitHandler] error => ",
        error
      );
      if (error.response) {
        const { message: errorMessage } = error.response.data;
        showErrorToast(errorMessage);
      }
    }
    this.onCloseHandler();
  };

  toolsTypeChangeHandler = (toolsType) => {
    this.setState({ toolsType });
  };

  updateDescriptionHandler = (descriptionValue) => {
    const { userLanguage, description } = this.state;
    const newDescription = { ...description };
    newDescription[userLanguage] = descriptionValue;
    this.setState({ description: newDescription });
  };

  userlanguageSelectHandler = (language) => {
    this.setState({ userLanguage: language });
  };

  triggerSubmitHandler = () => {
    this.form.isFormValid(false).then((isValid) => {
      if (isValid) {
        this.submitHandler();
      }
    });
  };

  render() {
    const { opened, isAddDialog, manageTitle, classes } = this.props;
    const {
      toolsType,
      description,
      submitButtonDisabled,
      userLanguage,
      toolsTypeArray,
    } = this.state;
    const {
      dialogTitle,
      dialogDescription,
      submitButtonName,
      cancelButtonName,
    } = getAddEditDialogMetadata(isAddDialog, manageTitle);

    return (
      <DialogWrapper
        opened={opened}
        dialogTitle={dialogTitle}
        dialogDescription={dialogDescription}
        onClose={this.onCloseHandler}
        disableEnforceFocus
        dialogActions={
          <DialogActionsWrapper>
            <CustomTextButton onClick={this.onCloseHandler}>
              {cancelButtonName}
            </CustomTextButton>
            <OutlinedButton
              type="button"
              disabled={submitButtonDisabled}
              onClick={this.triggerSubmitHandler}
            >
              {submitButtonName}
            </OutlinedButton>
          </DialogActionsWrapper>
        }
      >
        <ValidatorForm
          ref={(ref) => {
            this.form = ref;
          }}
          onSubmit={this.submitHandler}
          onError={(errors) => console.log(errors)}
        >
          <div className={classes.row}>
            <CustomSelectValidator
              label="Type"
              value={toolsType}
              changed={this.toolsTypeChangeHandler}
              items={toolsTypeArray}
            />
            <LanguageSelector
              passedClass={classes.languageSelector}
              selectedLanguage={userLanguage}
              languageSelected={this.userlanguageSelectHandler}
            />
          </div>
          <TextEditor
            description={description[userLanguage]}
            updatedDescription={this.updateDescriptionHandler}
          />
          <DialogActionsWrapper />
        </ValidatorForm>
      </DialogWrapper>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AddEditDialog);
