
import React, { PureComponent } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import RoleMultiSelect from '../../../../components/RoleMultiSelect';
import CustomSelectValidator from '../../../../components/CustomSelectValidator';
import OutlinedButton from '../../../../components/OutlinedButton';
import CustomTextButton from '../../../../components/CustomTextButton';
import DialogWrapper from '../../../../hoc/DialogWrapper';
import DialogActionsWrapper from '../../../../hoc/DialogActionsWrapper';
import { notificationTypes } from '../../../../constants';
import { getAddEditDialogMetadata, showErrorToast } from '../../../../utils/utility';
import { addNotification, editNotification } from '../../../../services/notification';

class AddEditDialog extends PureComponent {
    state = {
        creatorId: null,
        type: '',
        message: '',
        accessRoleValues: [],
        submitButtonDisabled: false
    };

    componentDidMount() {
        const { isAddDialog, dialogData, loggedInUser } = this.props;
        // edit
        if (!isAddDialog) {
            const { message, accessRoleValues, type } = dialogData;
            this.setState({creatorId: loggedInUser.id, message, accessRoleValues, type});
        // add
        } else {
            this.setState({creatorId: loggedInUser.id});
        }
    }

    messageChangeHandler = event => {
        this.setState({message: event.target.value});
    };

    accessRoleValuesChangeHandler = accessRoleValues => {
        this.setState({accessRoleValues});
    };

    typeChangeHandler = type => {
        this.setState({type});
    };

    onCloseHandler = () => {
        const { closed } = this.props;
        closed && closed();
    };

    submitHandler = async () => {
        const { creatorId, type, message, accessRoleValues } = this.state;
        const { isAddDialog, dialogData, addEditNotification } = this.props;
        const notification = {
            _id: !isAddDialog ? dialogData._id : null,
            creatorId,
            type,
            message,
            accessRoleValues
        };
        try {
            this.setState({submitButtonDisabled: true});
            let response;
            if (isAddDialog) {
                response = await addNotification(notification);
            } else {
                response = await editNotification(notification);
            }
            const { data: notificationFromBackend } = response;
            addEditNotification(notificationFromBackend);
        } catch (error) {
            // TODO: * axios handling module
            console.log('ray : [containers Admin NotificationManage AddEditDialog submitHandler] error => ', error);
            if (error.response) {
                const { message: errorMessage } = error.response.data;
                showErrorToast(errorMessage);
            }
        }
        this.onCloseHandler();
    };

    triggerSubmitHandler = () => {
        this.form.isFormValid(false).then((isValid) => {
            if (isValid) {
                this.submitHandler();
            }
        });
    };

    render() {
        const { opened, isAddDialog, manageTitle } = this.props;
        const { type, message, accessRoleValues, submitButtonDisabled } = this.state;
        const { dialogTitle, dialogDescription, submitButtonName, cancelButtonName } = getAddEditDialogMetadata(isAddDialog, manageTitle);

        return (
            <DialogWrapper 
                opened={opened} 
                dialogTitle={dialogTitle} 
                dialogDescription={dialogDescription} 
                onClose={this.onCloseHandler}
                dialogActions={
                    <DialogActionsWrapper>
                        <CustomTextButton onClick={this.onCloseHandler}>
                            {cancelButtonName}
                        </CustomTextButton>
                        <OutlinedButton type='button' disabled={submitButtonDisabled} onClick={this.triggerSubmitHandler}>
                            {submitButtonName}
                        </OutlinedButton>
                    </DialogActionsWrapper>
                }>
                <ValidatorForm
                    ref={(ref) => { this.form = ref; }}
                    onSubmit={this.submitHandler}
                    onError={errors => console.log(errors)}>
                    <CustomSelectValidator
                        label='Type'
                        value={type}
                        changed={this.typeChangeHandler}
                        items={notificationTypes} />
                    <TextValidator
                        fullWidth
                        multiline
                        name='message'
                        label='Message'
                        value={message}
                        margin='normal'
                        onChange={this.messageChangeHandler}
                        validators={['required']}
                        errorMessages={['Message cannot be empty']} />
                    <RoleMultiSelect
                        label='Access User Roles'
                        selectedRoleValues={accessRoleValues}
                        changed={this.accessRoleValuesChangeHandler} />
                    <DialogActionsWrapper />
                </ValidatorForm>
            </DialogWrapper>
        );
    }
}

export default AddEditDialog;
